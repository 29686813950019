import * as React from 'react';

// COMPONENTS
import SvgMask from './SvgMask';

export interface ScrollToTopButtonTypes {
	offsetValue?: number;
}

const ScrollToTopButton: React.FC<ScrollToTopButtonTypes> = ({ offsetValue }) => {
	const [visible, setVisible] = React.useState<boolean>(false);

	const scrollToTop = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	React.useEffect(() => {
		const scrollControl = () => {
			if (!visible && window.scrollY > 100) {
				setVisible(true);
			} else if (visible && window.scrollY <= 100) {
				setVisible(false);
			}
		};

		window.addEventListener('scroll', scrollControl);

		return () => {
			window.removeEventListener('scroll', scrollControl);
		};
	}, []);

	return (
		<div className="back-to-top-btn-container">
			{visible && (
				<button
					className="btn btn-link text-decoration-none"
					onClick={scrollToTop}
					style={{ transform: `translateY(-${offsetValue}%)` }}
				>
					<SvgMask path="/svg/chevron-up.svg" width={16} height={16} />
					Back to top
				</button>
			)}
		</div>
	);
};

export default ScrollToTopButton;
