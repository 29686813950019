import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// REDUX
import { useAppDispatch, useAppSelector } from '../../../store/slice';
import { ToastMessagesSlice } from '../../../store/slice/ToastMessages';

// PACKAGES
import { Container, InputGroup, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';

// API
import apibridge from '../../../apibridge';
import { ResponseStandardFail } from '../../../libs/api';
import { AccountCreateCommand, AccountCreateUserDetails } from '../../../api/models';

// UTILS
import { createErrorsObject, guid } from '../../../libs/utils';
import SvgMask from '../../../components/_Helpers/SvgMask';
import ImgRem from '../../../libs/imgRem';
import DelayedFadeIn from '../../../components/_Helpers/DelayedFadeIn';

// COMPONENTS
import RecaptchaDisclaimer from '../../../components/_Samples/RecaptchaDisclaimer';
import FormGroupWithError from '../../../components/Forms/FormGroupWithError';
import FormCustomError from '../../../components/Forms/FormCustomError';
import { UserSlice, UserType } from '../../../store/slice/User';

const Registration: FC = () => {
	const dispatch = useAppDispatch();
	const user: UserType = useAppSelector((state) => state.user);

	const [isLoading, setIsLoading] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

	const [accountDetails, setAccountDetails] = useState<AccountCreateUserDetails>();
	const [formValues, setFormValues] = useState<AccountCreateCommand>();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const systemInfo = useAppSelector((state) => state.systemInfo);
	const isTrialUser = systemInfo.isTrial;

	const searchParams = new URLSearchParams(window.location.search);
	const email = accountDetails?.email;
	const organisation = accountDetails?.organisationName;
	const token = searchParams.get('token') || '';

	const getAccountDetails = async (token: string) => {
		setIsLoading(true);
		const response = await apibridge.getAccountCreate(token);

		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const { email, firstName, lastName } = response.data.result;
				setAccountDetails(response.data.result);
				setFormValues({
					token,
					email,
					firstName,
					lastName,
					password: '',
					confirmPassword: ''
				});
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Account details error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	const handleChange = (e: ChangeEvent<HTMLFormElement>) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const accountCreate = async (values: AccountCreateCommand, formikHelpers: FormikHelpers<AccountCreateCommand>) => {
		setIsSubmitting(true);
		const { token, email, firstName, lastName, password, confirmPassword } = values;

		const response = await apibridge.postAccountCreate({
			token,
			email,
			firstName,
			lastName,
			password,
			confirmPassword
		});

		if (response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				setSubmissionSuccessful(true);
				if (user) dispatch(UserSlice.actions.logOut()); // we must always flush any previous user details for the user's browser after registering, otherwise system might think they're still logged in as them and weird bugs occur
			}
		}

		setIsSubmitting(false);
	};

	useEffect(() => {
		if (token) {
			getAccountDetails(token);
		} else {
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: 'Account details error',
					description: 'No token detected. Please check your registration link.'
				})
			);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<picture>
				<source srcSet="./img/login-bg.webp" />
				<img
					src="./img/login-bg.jpg"
					className="position-fixed w-100 h-100 object-fit object-fit-cover user-select-none"
					alt="Tiled books background"
				/>
			</picture>
			<main className="page-staff-registration d-flex align-items-center py-5">
				<Container className="position-relative z-1">
					{isLoading || !formValues ? (
						<div className="form-rounded-corners d-flex align-items-center justify-content-center p-5">
							<DelayedFadeIn>
								<Spinner />
							</DelayedFadeIn>
						</div>
					) : (
						<Formik initialValues={formValues} onSubmit={accountCreate}>
							<FormikForm onChange={handleChange} className="form-rounded-corners">
								{submissionSuccessful ? (
									<div className="d-flex flex-column align-items-center gap-4-5">
										<div className="d-flex flex-column gap-3 align-items-center">
											<ImgRem src="/svg/account-created.svg" width="160" height="160" alt="" className=" mx-auto" />
											{isTrialUser && (
												<div className="bg-light-blue rounded-5 px-2-5 py-1 text-vivid-blue">
													<strong>Trial</strong>
												</div>
											)}
										</div>
										<h2 className="text-shades-800 m-0">
											<strong>Account Created!</strong>
										</h2>
										<p className="m-0">
											You are now ready to join others at <strong>{organisation}</strong> and start using Little
											Learners Love Literacy Online.
										</p>
										<Link to="/login" className="btn btn-lg w-100">
											Go to login
										</Link>
									</div>
								) : (
									<>
										<ImgRem
											src="/svg/llll-online-contained-wide-logo.svg"
											width="231"
											height="70"
											alt="Little Learners Love Literacy Online logo"
											className="logo mx-auto"
										/>
										<h2 className="text-shades-800 m-0">
											<strong>Welcome to Little Learners Love Literacy Online</strong>
										</h2>
										<p className="m-0">
											Create a new account for <strong>{email}</strong> to join <strong>{organisation}</strong>.
										</p>

										<div>
											<FormCustomError className="text-start my-2" />
											<FormCustomError label="token" className="text-start my-2" />

											<div className="row">
												<div className="col">
													<FormGroupWithError name="firstName" className="text-start mb-3">
														<label htmlFor="first-name" className="form-label">
															First Name
														</label>
														<Field type="text" id="first-name" name="firstName" className="form-control" />
													</FormGroupWithError>
												</div>
												<div className="col">
													<FormGroupWithError name="lastName" className="text-start mb-3">
														<label htmlFor="last-name" className="form-label">
															Last Name
														</label>
														<Field type="text" id="last-name" name="lastName" className="form-control" />
													</FormGroupWithError>
												</div>
											</div>

											<FormGroupWithError name="password" className="text-start mb-3">
												<label htmlFor="password" className="form-label">
													Password
												</label>
												<InputGroup>
													<Field
														type={showPassword ? 'text' : 'password'}
														id="password"
														name="password"
														className="form-control border-end-0"
														placeholder="Create a new password"
													/>
													<InputGroup.Text className="bg-transparent p-0">
														<button
															type="button"
															className="btn btn-sm bg-transparent text-reset"
															onClick={() => setShowPassword(!showPassword)}
															tabIndex={-1}
														>
															<SvgMask
																path={showPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
																width={24}
																height={24}
															/>
														</button>
													</InputGroup.Text>
												</InputGroup>
											</FormGroupWithError>

											<FormGroupWithError name="confirmPassword" className="text-start">
												<label htmlFor="confirmPassword" className="form-label">
													Confirm Password
												</label>
												<InputGroup>
													<Field
														type={showConfirmPassword ? 'text' : 'password'}
														id="confirm-password"
														name="confirmPassword"
														className="form-control border-end-0"
														placeholder="Re-enter your password"
													/>
													<InputGroup.Text className="bg-transparent p-0">
														<button
															type="button"
															className="btn btn-sm bg-transparent text-reset"
															onClick={() => setShowConfirmPassword(!showConfirmPassword)}
															tabIndex={-1}
														>
															<SvgMask
																path={showConfirmPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
																width={24}
																height={24}
															/>
														</button>
													</InputGroup.Text>
												</InputGroup>
											</FormGroupWithError>
										</div>
										<div className="d-grid">
											<button type="submit" className="btn btn-lg" disabled={isSubmitting}>
												Create an account
											</button>
										</div>
									</>
								)}
								{!submissionSuccessful && <RecaptchaDisclaimer />}
							</FormikForm>
						</Formik>
					)}
				</Container>
			</main>
		</>
	);
};

export default Registration;
