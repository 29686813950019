import React from 'react';
import { useField } from 'formik';

// useful for rendering errors that have a key of 'null'
const FormCustomError: React.FC<{ label?: string; className?: string }> = ({ label = 'null', className = '' }) => {
	const [, meta] = useField(label);
	return meta.error ? (
		<div className={`text-danger text-new-line-wrap body-small fw-semibold ${className}`}>{meta.error}</div>
	) : null;
};

export default FormCustomError;
