import * as React from 'react';
import SvgMask from '../_Helpers/SvgMask';

interface HintMessageTypes {
	title?: string;
	message?: string;
}

const HintMessage: React.FC<HintMessageTypes> = ({ title, message }) => {
	return (
		<div className="hint-message-component d-flex">
			<SvgMask path="/svg/help-solid.svg" width={24} height={24} />

			<div className="message">
				<p className="h4 mb-0">{title || ''}</p>
				<p className="mb-0 ">{message || ''}</p>
			</div>
		</div>
	);
};

export default HintMessage;
