import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import Moment from 'moment';
import { Modal, Spinner, Table } from 'react-bootstrap';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import EditOrganisationBillingDetails from '../../components/SubscriptionDetails/EditOrganisationBillingDetails';
import ChangeSubscription from '../../components/SubscriptionDetails/ChangeSubscription';
import CancelSubscription from '../../components/SubscriptionDetails/CancelSubscription';
import ReactivateSubscription from '../../components/SubscriptionDetails/ReactivateSubscription';
import SubscriptionMessage from '../../components/SubscriptionDetails/SubscriptionMessage';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import { guid } from '../../libs/utils';

// TYPES
import apibridge from '../../apibridge';
import {
	OrganisationBillingEditEditResult,
	OrganisationSettingsSettingsResult,
	SharedModelsCountry
} from '../../api/models';
import { UserType } from '../../store/slice/User';

const maxPhotoFileSizeMb = 2; // megabytes
const megabyte = 1024 * 1024; // 1MB -> 1024 * 1024

const OrganisationDetails: FC = () => {
	const dispatch = useDispatch();
	const user: UserType = useAppSelector((state) => state.user);

	const [isLoading, setIsLoading] = useState(true);
	const [organisationSettings, setOrganisationSettings] = useState<OrganisationSettingsSettingsResult>();
	const [organisationBillingDetails, setOrganisationBillingDetails] = useState<OrganisationBillingEditEditResult>();

	const selectedBillingCountry = organisationBillingDetails?.countries?.find((country) =>
		country.stateProvinces?.some((state) => state.id === organisationBillingDetails.information?.stateProvinceId)
	);

	const generateAddress = ({
		address1,
		address2,
		city,
		postcode,
		selectedBillingCountry
	}: {
		address1?: string;
		address2?: string;
		city?: string;
		postcode?: string;
		stateProvince?: string;
		selectedBillingCountry?: SharedModelsCountry;
	}) => {
		const countryName = selectedBillingCountry?.name || '';
		const stateName =
			selectedBillingCountry?.stateProvinces?.find(
				(state) => state.id === organisationBillingDetails?.information?.stateProvinceId
			)?.name || '';
		const addressPart1 = [address1, address2, city].filter((address) => address !== undefined).join(', ');
		const addressPart2 = [postcode, stateName].filter((address) => address !== undefined).join(', ');
		return `${addressPart1} ${addressPart2} ${countryName}`;
	};

	// EDIT PHOTO
	const [showOrganisationPhotoModal, setShowOrganisationPhotoModal] = useState(false);
	const [organisationPhotoToUpload, setOrganisationPhotoToUpload] = useState<{
		inputValue: string;
		file: File;
		base64EncodedPreview: string;
	}>();
	const [organisationPhotoSubmitting, setOrganisationPhotoSubmitting] = useState(false);
	const photoFileSizeIsOverLimit = (organisationPhotoToUpload?.file.size || 0) > maxPhotoFileSizeMb * megabyte;
	const formPhotoRef = useRef<HTMLFormElement>(null);
	const fileReaderRef = useRef(new FileReader());

	const handlePhotoFileInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { target } = e;
		const file = target.files?.[0];
		if (file) {
			fileReaderRef.current.readAsDataURL(file);
			fileReaderRef.current.onloadend = () => {
				setOrganisationPhotoToUpload({
					inputValue: target?.value || '',
					file,
					base64EncodedPreview: fileReaderRef.current.result as string
				});
			};
		}
	};

	const submitChangePhoto = async (e: FormEvent) => {
		e.preventDefault();
		setOrganisationPhotoSubmitting(true);

		const formDataEntries: FormDataEntryValue[] = [];
		if (formPhotoRef.current) {
			const formData = new FormData(formPhotoRef.current);
			for (const [, value] of formData.entries()) formDataEntries.push(value);
		}

		const response = await apibridge.postOrganisationLogo(formDataEntries[0]);
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setOrganisationSettings({
					...organisationSettings,
					// can just use the preview image to save needing to re-fetch the data
					logo: organisationPhotoToUpload?.base64EncodedPreview || ''
				});
				setShowOrganisationPhotoModal(false);
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'The organisation photo has been successfully changed.',
						description: 'This may take some time to take effect.'
					})
				);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Photo submission error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setOrganisationPhotoSubmitting(false);
	};

	const [paymentUpdateDetailsUrl, setPaymentUpdateDetailsUrl] = useState('');
	const [isLoadingPaymentUrl, setIsLoadingPaymentUrl] = useState(false);
	const getPaymentUpdateDetailsUrl = async () => {
		if (paymentUpdateDetailsUrl) {
			window.open(paymentUpdateDetailsUrl);
			return;
		}

		setIsLoadingPaymentUrl(true);
		const response = await apibridge.getPaymentUpdateDetails();
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const { url = '' } = response.data.result;
				setPaymentUpdateDetailsUrl(url);
				window.open(url);
			}
		}
		setIsLoadingPaymentUrl(false);
	};

	// GET INITIAL DATA
	const getOrganisationSettings = async () => {
		const response = await apibridge.getOrganisationSettings();
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setOrganisationSettings(response.data.result);
				// setOrganisationSettings({
				// 	...response.data.result,
				// 	// TODO: am setting temp invoices data
				// 	invoices: [
				// 		{
				// 			amount: 180,
				// 			reference: 'Level 1 - 50 users',
				// 			date: '2024-06-03T09:29:27.1427475+10:00',
				// 			status: 'Paid',
				// 			invoicePdf: '/fake-path/sample-pdf.pdf'
				// 		},
				// 		{
				// 			amount: 240,
				// 			reference: 'Level 2 - 100 users',
				// 			date: '2024-06-03T09:29:27.1427475+10:00',
				// 			status: 'Failed',
				// 			invoicePdf: '/fake-path/sample-pdf.pdf'
				// 		}
				// 	]
				// });
			}
		}
	};
	const getOrganisationBillingDetails = async () => {
		if (user.permissions?.includes('OrganisationBillingEdit')) {
			const response = await apibridge.getOrganisationBillingEdit();
			if (response && response.data) {
				if (!response.data.isError && response.data.result) {
					setOrganisationBillingDetails(response.data.result);
				}
			}
		}
	};

	// is used in some components, and we want finer control over when 'isLoading' is set
	const getAllOrganisationData = async () => {
		await Promise.all([getOrganisationSettings(), getOrganisationBillingDetails()]);
	};

	const getInitialData = async () => {
		setIsLoading(true);
		await getAllOrganisationData();
		setIsLoading(false);
	};

	useEffect(() => {
		getInitialData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-settings d-flex flex-column flex-grow-1">
			<TitleSection title="Settings" description="Edit your account or organisation details and billing preferences.">
				<div className="d-flex justify-content-between">
					<div className="position-relative w-auto align-items-center">
						<nav className="nav nav-pills flex-row">
							{user.permissions?.includes('AccountSettings') && (
								<Link to={'/settings'} className="nav-pill h4 no-pseudo-hover text-decoration-none">
									<SvgMask path="/svg/profile.svg" width={24} height={24} />
									Account Details
								</Link>
							)}
							{user.permissions?.includes('OrganisationSettings') && (
								<Link
									to={'/settings/organisation-details'}
									className="nav-pill h4 no-pseudo-hover text-decoration-none active"
								>
									<SvgMask path="/svg/teachers-hat-sm.svg" width={24} height={24} />
									Organisation Details
								</Link>
							)}
						</nav>
					</div>
				</div>
			</TitleSection>
			{user.permissions?.includes('OrganisationSettings') ? (
				<div className="container h-100">
					{isLoading ? (
						<div className="d-flex align-items-center justify-content-center position-relative w-100">
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</div>
					) : (
						<div className="row row-gap-4">
							<div className="col-lg-8">
								<div className="bordered-list">
									<div className="header">
										{user.permissions.includes('OrganisationLogo') && (
											<div
												className={`account-image-wrapper organisation border overflow-hidden ${
													organisationSettings?.logo ? 'p-0' : ''
												}`}
											>
												{organisationSettings?.logo ? (
													<img
														src={organisationSettings?.logo}
														width={80}
														height={80}
														className="object-fit-cover"
														alt={`${organisationSettings?.name} logo`}
													/>
												) : (
													<img src="/svg/llll-colour-logo-full.svg" width={48} height={48} alt="Profile stencil" />
												)}
											</div>
										)}
										<div className="flex-grow-1">
											<p className="body-tiny mb-1 text-shades-500">
												<strong>Organisation</strong>
											</p>
											<h3 className="m-0 text-shades-800">{organisationSettings?.name}</h3>
										</div>
										{user.permissions.includes('OrganisationLogo') && (
											<div>
												<button
													type="button"
													className="btn btn-sm"
													onClick={() => setShowOrganisationPhotoModal(true)}
												>
													Edit photo
												</button>
											</div>
										)}
										<Modal
											show={showOrganisationPhotoModal}
											onHide={() => setShowOrganisationPhotoModal(false)}
											onExited={() => setOrganisationPhotoToUpload(undefined)}
											centered
											className="modal-upload-photo"
										>
											<Modal.Body>
												<form
													ref={formPhotoRef}
													onSubmit={submitChangePhoto}
													method="post"
													encType="multipart/form-data"
													className="d-flex flex-column gap-4-25"
												>
													<h2 className="m-0 text-shades-800">
														<strong>Edit Organisation Photo</strong>
													</h2>
													<label
														className={`upload-photo position-relative btn mx-auto ${
															organisationPhotoToUpload ? 'has-organisation-preview' : ''
														}`}
														onClick={(e) => {
															if (organisationPhotoToUpload) {
																e.preventDefault();
																setOrganisationPhotoToUpload(undefined);
															}
														}}
													>
														{organisationPhotoToUpload ? (
															<>
																<img
																	src={organisationPhotoToUpload.base64EncodedPreview}
																	className="position-absolute w-100 h-100 object-fit-cover"
																	alt={`${organisationSettings?.name} logo`}
																/>
																<button
																	type="button"
																	className="position-absolute top-0 end-0 mt-1 me-1 btn btn-sm btn-icon"
																>
																	<SvgMask path="/svg/cross-thin.svg" width={24} height={24} />
																</button>
															</>
														) : (
															<>
																<SvgMask path="/svg/teachers-hat-filled.svg" width={71} height={59} />
																<div className="btn-choose-file btn btn-sm btn-white gap-1 pe-none">
																	<SvgMask path="/svg/upload.svg" width={16} height={16} />
																	Choose File
																</div>
															</>
														)}
														<input
															type="file"
															name="file"
															id="file"
															accept=".png, .jpg, .jpeg"
															className="visually-hidden"
															onChange={handlePhotoFileInput}
														/>
													</label>
													{photoFileSizeIsOverLimit && (
														<div className="text-danger text-new-line-wrap body-small fw-semibold">
															File size too big. Please upload an image file smaller than 2MB.
														</div>
													)}
													<p className="m-0">
														Select a photo or image file from your device to use as the new organisation photo. Max file
														size of 2MB.
													</p>
													<div className="d-flex button-group mt-0">
														<button
															type="button"
															className="btn btn-white btn-lg w-100"
															onClick={() => setShowOrganisationPhotoModal(false)}
														>
															Cancel
														</button>
														<button
															type="submit"
															className="btn btn-lg w-100"
															disabled={
																!organisationPhotoToUpload || photoFileSizeIsOverLimit || organisationPhotoSubmitting
															}
														>
															Upload Photo
														</button>
													</div>
												</form>
											</Modal.Body>
										</Modal>
									</div>
								</div>
								<div className="bordered-list">
									<div className="content d-flex flex-column gap-4 px-4">
										<h3 className="m-0 text-shades-800">
											<strong>Subscription Details</strong>
										</h3>
										<div className="row row-gap-4">
											<div className="col-lg-4">
												<p className="body-tiny mb-1 text-shades-500">
													<strong>Subscription level</strong>
												</p>
												<p className="m-0">
													<strong>
														{organisationSettings?.status === 'Cancelled'
															? 'No active subscription'
															: `${organisationSettings?.subscriptionName} - ${organisationSettings?.allocation} ${
																	Number(organisationSettings?.allocation) === 1 ? 'user' : 'users'
															  }`}
													</strong>
												</p>
											</div>
											{organisationSettings?.status !== 'Cancelled' && (
												<>
													{organisationSettings?.invoiceStartDate && (
														<div className="col-lg-4">
															<p className="body-tiny mb-1 text-shades-500">
																<strong>Start date</strong>
															</p>
															<p className="m-0">
																<strong>{Moment(organisationSettings.invoiceStartDate).format('D MMM, YYYY')}</strong>
															</p>
														</div>
													)}
													{organisationSettings?.nextInvoiceDate && (
														<div className="col-lg-4">
															<p className="body-tiny mb-1 text-shades-500">
																<strong>Next billing date</strong>
															</p>
															<p className="m-0">
																<strong>{Moment(organisationSettings.nextInvoiceDate).format('D MMM, YYYY')}</strong>
															</p>
														</div>
													)}
													{organisationSettings?.currentAllocation && (
														<div className="col-lg-4">
															<p className="body-tiny mb-1 text-shades-500">
																<strong>Current no. of users in the organisation</strong>
															</p>
															<p className="m-0">
																<strong>
																	{organisationSettings?.currentAllocation}{' '}
																	{organisationSettings?.currentAllocation === 1 ? 'user' : 'users'}
																</strong>
															</p>
														</div>
													)}
												</>
											)}
										</div>
										{organisationSettings?.messages
											?.filter((message) => message.section === 'Subscription')
											.map((message, messageIndex) => (
												<SubscriptionMessage key={messageIndex} message={message} />
											))}
										{user.permissions?.some((permission) =>
											['SubscriptionChange', 'OrganisationCancel'].includes(permission)
										) && (
											<div className="row mt-2">
												{/* un-cancel the subscription (if the subscription is still available) */}
												{/* or allow user to pick another subscription if the subscription has expired */}
												{organisationSettings?.updateType === 'Cancel' ||
												organisationSettings?.status === 'Cancelled' ? (
													<ReactivateSubscription
														organisationSettings={organisationSettings}
														getOrganisationSettings={getOrganisationSettings}
													/>
												) : (
													<>
														{user.permissions?.includes('SubscriptionChange') && (
															<ChangeSubscription
																organisationSettings={organisationSettings}
																getOrganisationSettings={getOrganisationSettings}
															/>
														)}
														{user.permissions?.includes('OrganisationCancel') && (
															<CancelSubscription
																organisationSettings={organisationSettings}
																getOrganisationSettings={getOrganisationSettings}
															/>
														)}
													</>
												)}
											</div>
										)}
									</div>
								</div>
								{organisationBillingDetails && (
									<div className="bordered-list">
										<div className="content d-flex flex-column gap-4 px-4">
											<h3 className="m-0 text-shades-800">
												<strong>Billing Details</strong>
											</h3>
											<div className="row row-gap-4">
												<div className="col-lg-4">
													<p className="body-tiny mb-1 text-shades-500">
														<strong>Billing name</strong>
													</p>
													<p className="m-0">
														<strong>{organisationBillingDetails?.information?.billingName}</strong>
													</p>
												</div>
												{/* with this class structure, a long email address will safely push the Org Address onto the next line */}
												<div className="col-lg">
													<p className="body-tiny mb-1 text-shades-500">
														<strong>Billing email</strong>
													</p>
													<p className="m-0">
														<strong>{organisationBillingDetails?.information?.email}</strong>
													</p>
												</div>
												<div className="col-12">
													<p className="body-tiny mb-1 text-shades-500">
														<strong>Billing address</strong>
													</p>
													<p className="m-0">
														<strong>
															{generateAddress({
																address1: organisationBillingDetails?.information?.address1,
																address2: organisationBillingDetails?.information?.address2,
																city: organisationBillingDetails?.information?.city,
																postcode: organisationBillingDetails?.information?.postcode,
																selectedBillingCountry
															})}
														</strong>
													</p>
												</div>
											</div>
											<EditOrganisationBillingDetails
												organisationBillingDetails={organisationBillingDetails}
												getAllOrganisationData={getAllOrganisationData}
											/>
										</div>
									</div>
								)}
								<div className="bordered-list">
									<div className="content d-flex flex-column gap-4 px-4">
										<h3 className="m-0 text-shades-800">
											<strong>Payment Details</strong>
										</h3>
										<div className="row row-gap-4">
											<div className="col-lg-4">
												<p className="body-tiny mb-1 text-shades-500">
													<strong>Payment method</strong>
												</p>
												<p className="m-0">
													<strong>
														{organisationSettings?.subscriptionType
															? organisationSettings.subscriptionType === 'Paid'
																? 'Credit Card'
																: organisationSettings.subscriptionType === 'Invoiced'
																? 'School Invoice'
																: organisationSettings.subscriptionType
															: null}
													</strong>
												</p>
											</div>
											{organisationSettings?.orderNumber && (
												<div className="col-lg-4">
													<p className="body-tiny mb-1 text-shades-500">
														<strong>PO Number</strong>
													</p>
													<p className="m-0">
														<strong>{organisationSettings.orderNumber}</strong>
													</p>
												</div>
											)}
										</div>
										{organisationSettings?.subscriptionType === 'Paid' && (
											<div className="row row-gap-4 mt-2">
												<div className="col-6 col-xl-4">
													<button
														type="button"
														onClick={() => getPaymentUpdateDetailsUrl()}
														className="btn w-100"
														disabled={isLoadingPaymentUrl}
													>
														Edit payment details
														{isLoadingPaymentUrl ? (
															<Spinner animation="border" size="sm" />
														) : (
															<SvgMask path={'/svg/external.svg'} width={16} height={16} />
														)}
													</button>
												</div>
												<div className="col d-flex align-items-center">
													<span className="label text-shades-500">
														You will be redirected to Stripe in order to edit your payment details.
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								{organisationSettings?.invoices && (
									<div className="bordered-list">
										<div className="content d-flex flex-column gap-4 px-4">
											<h3 className="m-0 text-shades-800">
												<strong>Billing History</strong>
											</h3>
											<Table className="body-small align-middle m-0">
												<thead>
													<tr>
														<th className="pt-0">Billing date</th>
														<th className="pt-0">Subscription level</th>
														<th colSpan={2} className="pt-0">
															Amount
														</th>
													</tr>
												</thead>
												<tbody>
													{organisationSettings.invoices.map((invoice, invoiceIndex) => (
														<tr key={invoiceIndex}>
															<td>
																<strong>{invoice?.date ? Moment(invoice.date).format('D MMM, YYYY') : 'N/A'}</strong>
															</td>
															<td className="text-shades-500">
																<strong>{invoice.reference}</strong>
															</td>
															<td className="text-shades-500">
																<strong>${invoice.amount?.toFixed(2)}</strong>
															</td>
															<td className="text-end">
																{invoice?.invoicePdf && (
																	<Link to={invoice.invoicePdf} className="btn btn-sm btn-white">
																		View Invoice
																		<SvgMask path={'/svg/external.svg'} width={16} height={16} />
																	</Link>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			) : (
				<div className="d-flex align-items-center justify-content-center h-100">
					<h3 className="text-center text-shades-500 m-0">You don't have permission to view this content.</h3>
				</div>
			)}
		</div>
	);
};

export default OrganisationDetails;
