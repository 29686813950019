import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { Field, Formik, Form as FormikForm, FormikHelpers } from 'formik';

// PACKAGES
import { InputGroup, Modal, Spinner } from 'react-bootstrap';
import Moment from 'moment';

// COMPONENTS
import FormCustomError from '../Forms/FormCustomError';
import FormGroupWithError from '../Forms/FormGroupWithError';
import ManagePONumber from './ManagePONumber';

// UTILS
import { createErrorsObject, formatPrice, guid } from '../../libs/utils';
import SvgMask from '../_Helpers/SvgMask';

// TYPES
import apibridge from '../../apibridge';
import {
	OrganisationReactivateCommand,
	OrganisationSettingsSettingsResult,
	SubscriptionReactivateCancelledChangeCommandResponse,
	SubscriptionReactivateCancelledSubscriptionPlans
} from '../../api/models';
import { ResponseStandardFail } from '../../libs/api';
import { UserSlice, UserType } from '../../store/slice/User';

const ReactivateSubscription: FC<{
	organisationSettings: OrganisationSettingsSettingsResult | undefined;
	getOrganisationSettings: () => Promise<void>;
}> = ({ organisationSettings, getOrganisationSettings }) => {
	const dispatch = useDispatch();

	const [modalToShow, setModalToShow] = useState<
		'revokeCancellation' | 'selectNewSubscription' | 'confirmNewSubscription'
	>();

	const subscriptionHasExpired = organisationSettings?.status === 'Cancelled';
	const initialReactivateCancelledSubscriptionValues: OrganisationReactivateCommand = {
		password: ''
	};

	const [reactivateCancelledSubscriptionSubmitting, setReactivateCancelledSubscriptionSubmitting] = useState(false);
	const [reactivateCancelledSubscriptionValues, setReactivateCancelledSubscriptionValues] = useState(
		initialReactivateCancelledSubscriptionValues
	);
	const [showReactivateCancelledSubscriptionPassword, setShowReactivateCancelledSubscriptionPassword] = useState(false);

	const [selectNewSubscriptionSubmitting, setSelectNewSubscriptionSubmitting] = useState(false);
	const [confirmNewSubscriptionSubmitting, setConfirmNewSubscriptionSubmitting] = useState(false);

	const [reactivateSubscriptionList, setReactivateSubscriptionList] =
		useState<SubscriptionReactivateCancelledSubscriptionPlans>();
	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
	const [selectedSubscriptionDetails, setSelectedSubscriptionDetails] =
		useState<SubscriptionReactivateCancelledChangeCommandResponse>();
	const [PONumberField, setPONumberField] = useState(selectedSubscriptionDetails?.orderNumber || '');

	// REACTIVATING THE CANCELLED SUBSCRIPTION FLOW
	const submitReactivateCancelledSubscription = async (
		values: OrganisationReactivateCommand,
		formikHelpers: FormikHelpers<OrganisationReactivateCommand>
	) => {
		setReactivateCancelledSubscriptionSubmitting(true);

		const { password } = values;
		const response = await apibridge.postOrganisationReactivate({
			password
		});

		if (response && response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				await getOrganisationSettings();
				setModalToShow(undefined);
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Subscription reactivated',
						description: `Your organisation will continue on a ${
							organisationSettings?.subscriptionName
						} Subscription and will be renewed on ${Moment(organisationSettings?.nextInvoiceDate).format(
							'D MMM, YYYY'
						)}.`
					})
				);
			}
		}

		setReactivateCancelledSubscriptionSubmitting(false);
	};

	// SELECTING A NEW SUBSCRIPTION FLOW
	const getReactivateSubscriptionList = async () => {
		const response = await apibridge.getSubscriptionReactivateCancelled();
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setReactivateSubscriptionList(response.data.result);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Subscription list error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const getSelectedSubscriptionDetails = async () => {
		setSelectNewSubscriptionSubmitting(true);

		const response = await apibridge.postSubscriptionReactivateCancelled({
			newSubscriptionId: selectedSubscriptionId
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setSelectedSubscriptionDetails(response.data.result);
				if (response.data.result.orderNumber) setPONumberField(response.data.result.orderNumber);
				setModalToShow('confirmNewSubscription');
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Subscription item details error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setSelectNewSubscriptionSubmitting(false);
	};

	const submitConfirmNewSubscription = async () => {
		setConfirmNewSubscriptionSubmitting(true);

		const response = await apibridge.postSubscriptionReactivateCancelledApply({
			newSubscriptionId: selectedSubscriptionId,
			orderNumber: selectedSubscriptionDetails?.orderNumber || PONumberField
		});
		if (response && response.data) {
			await getOrganisationSettings();

			// re-fetch the user details and update <ToastNag />
			dispatch(UserSlice.actions.forceUpdate());

			setModalToShow(undefined);
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'success',
					heading: 'Subscription plan confirmed',
					description:
						'Your subscription plan has been successfully updated. An email has been sent confirming the change of subscription.'
				})
			);
		} else if (response.data.validationErrors) {
			for (const err of response.data.validationErrors) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: 'Subscription item details error',
						description: err.reason || 'Unknown error'
					})
				);
			}
		}

		setConfirmNewSubscriptionSubmitting(false);
	};

	return (
		<>
			<div className="col-6 col-xl-4">
				<button
					type="button"
					onClick={() => setModalToShow(subscriptionHasExpired ? 'selectNewSubscription' : 'revokeCancellation')}
					className="btn w-100"
				>
					Reactivate subscription
				</button>
			</div>
			{/* UN-CANCEL THE CANCELLED SUBSCRIPTION SINCE IT'S STILL AVAILABLE */}
			<Modal
				show={modalToShow === 'revokeCancellation'}
				onHide={() => setModalToShow(undefined)}
				onExited={() => setReactivateCancelledSubscriptionValues(initialReactivateCancelledSubscriptionValues)}
				className="modal-change-subscription"
				centered
			>
				<Modal.Body className="gap-4-5">
					<div className="d-flex flex-column gap-3-5">
						<h2 className="m-0 text-shades-800">
							<strong>Are you sure you want to reactivate your subscription?</strong>
						</h2>
						<p className="m-0">
							Your subscription is scheduled for cancellation on{' '}
							<strong>{Moment(organisationSettings?.nextInvoiceDate).format('D MMMM, YYYY')}</strong> at the end of this
							billing period.
						</p>
						<p className="m-0">
							By reactivating your subscription you are deciding to revoke your subscription cancellation and continue
							your {organisationSettings?.subscriptionName} Subscription.
						</p>
					</div>

					<p className="h4 m-0">
						Enter your password to confirm you want to reactivate your subscription and revoke your cancellation.
					</p>
					<Formik
						initialValues={initialReactivateCancelledSubscriptionValues}
						onSubmit={submitReactivateCancelledSubscription}
					>
						<FormikForm
							onChange={(e: React.ChangeEvent<HTMLFormElement>) => {
								const { name, value } = e.target;
								setReactivateCancelledSubscriptionValues({
									...reactivateCancelledSubscriptionValues,
									[name]: value
								});
							}}
						>
							<FormCustomError className="text-start my-2" />

							<FormGroupWithError name="password" className="text-start">
								<label htmlFor="password" className="form-label">
									Your password
								</label>
								<InputGroup>
									<Field
										type={showReactivateCancelledSubscriptionPassword ? 'text' : 'password'}
										id="password"
										name="password"
										className="form-control border-end-0"
										placeholder="Password"
									/>
									<InputGroup.Text className="bg-white p-0">
										<button
											type="button"
											className="btn btn-sm bg-transparent text-reset"
											onClick={() =>
												setShowReactivateCancelledSubscriptionPassword(!showReactivateCancelledSubscriptionPassword)
											}
											tabIndex={-1}
										>
											<SvgMask
												path={
													showReactivateCancelledSubscriptionPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'
												}
												width={24}
												height={24}
											/>
										</button>
									</InputGroup.Text>
								</InputGroup>
							</FormGroupWithError>

							<div className="d-flex button-group mt-5">
								<button type="button" className="btn btn-white btn-lg w-100" onClick={() => setModalToShow(undefined)}>
									Cancel
								</button>
								<button
									type="submit"
									className="btn btn-lg w-100"
									disabled={
										!reactivateCancelledSubscriptionValues.password || reactivateCancelledSubscriptionSubmitting
									}
								>
									Reactivate subscription
								</button>
							</div>
						</FormikForm>
					</Formik>
				</Modal.Body>
			</Modal>
			{/* SUBSCRIPTION HAS EXPIRED, PICK A NEW ONE */}
			{/* 
				NOTE: to debug this, can edit the DB via with a query like: update llll.OrganisationSubscriptionUpdate set
				DateApplied = '2024-06-06' to mimic an organisation that's pending deletion 
			*/}
			<Modal
				show={modalToShow === 'selectNewSubscription'}
				onShow={() => {
					if (!reactivateSubscriptionList) getReactivateSubscriptionList();
				}}
				onHide={() => setModalToShow(undefined)}
				onExited={() => {
					if (modalToShow === undefined) {
						setSelectedSubscriptionId('');
					}
				}}
				className="modal-change-subscription"
				centered
			>
				<Modal.Body className="gap-4-5">
					<div className="d-flex flex-column gap-3-5">
						<h2 className="m-0 text-shades-800">
							<strong>Reactivate subscription</strong>
						</h2>
						<p className="m-0">Choose an annual subscription option.</p>
					</div>

					{reactivateSubscriptionList?.plans?.length ? (
						<div className="d-flex flex-column gap-4-5">
							<div className="d-flex flex-column gap-3-5">
								<div className="form-selection-wrapper m-0">
									<div className="form-selection-wrapper-inner gap-0 p-0">
										{reactivateSubscriptionList.plans.map((subscription) => {
											const { allocations, cost, id = '', name } = subscription;
											return (
												<div
													key={id}
													className={`subscription-item text-start ${
														selectedSubscriptionId ? (selectedSubscriptionId === id ? 'active' : 'faded') : ''
													}`}
												>
													<label htmlFor={`subscription-${id}`} className="d-flex flex-column gap-2-5 w-100">
														<div className="d-flex align-items-center gap-2">
															<input
																type="radio"
																id={`subscription-${id}`}
																name="subscription-list"
																className="form-check-input"
																onChange={() => setSelectedSubscriptionId(subscription?.id || '')}
																checked={id === selectedSubscriptionId}
															/>
															<div>
																<strong>{name}</strong>
															</div>
															<div className="subscription-text-users">
																<strong>
																	{allocations} {Number(allocations) === 1 ? 'User' : 'Users'}
																</strong>
															</div>
														</div>
														<div className="d-flex align-items-baseline gap-1">
															<span className="body-tiny">
																<strong>AUD</strong>
															</span>
															<span className="h2">{cost && `$${cost}`}</span>
															<span className="body-tiny">
																<strong>per year</strong>
															</span>
															<span className="ms-auto body-tiny text-shades-500">
																<strong>GST Incl.</strong>
															</span>
														</div>
													</label>
												</div>
											);
										})}
									</div>
								</div>
							</div>
							<div className="d-flex button-group my-0">
								<button type="button" className="btn btn-white btn-lg w-100" onClick={() => setModalToShow(undefined)}>
									Cancel
								</button>
								<button
									type="button"
									onClick={() => getSelectedSubscriptionDetails()}
									className="btn btn-lg w-100"
									disabled={!selectedSubscriptionId || selectNewSubscriptionSubmitting}
								>
									Select this option
								</button>
							</div>
						</div>
					) : (
						<div className="d-flex align-items-center justify-content-center position-relative">
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</div>
					)}
				</Modal.Body>
			</Modal>
			{/* CONFIRM SUBSCRIPTION REACTIVATION */}
			<Modal
				show={modalToShow === 'confirmNewSubscription'}
				onHide={() => setModalToShow('selectNewSubscription')}
				className="modal-change-subscription"
				centered
			>
				<Modal.Body className="gap-4-5">
					<div className="d-flex flex-column gap-3-5">
						<h2 className="m-0 text-shades-800">
							<strong>Confirm subscription reactivation</strong>
						</h2>
					</div>
					<div className="d-flex flex-column gap-3-5">
						<h4 className="text-shades-800 m-0">You have selected</h4>
						<div className="form-selection-wrapper m-0 border-hero-blue">
							<div className="subscription-item text-start">
								<div className="d-flex flex-column gap-2-5 w-100">
									<div className="d-flex align-items-center gap-2">
										<div>
											<strong>{selectedSubscriptionDetails?.newSubscription?.name}</strong>
										</div>
										<div className="subscription-text-users">
											<strong>
												{selectedSubscriptionDetails?.newSubscription?.allocations}{' '}
												{Number(selectedSubscriptionDetails?.newSubscription?.allocations) === 1 ? 'User' : 'Users'}
											</strong>
										</div>
									</div>
									<div className="d-flex align-items-baseline gap-1">
										<span className="body-tiny">
											<strong>AUD</strong>
										</span>
										<span className="h2">
											{selectedSubscriptionDetails?.newSubscription?.cost &&
												`$${selectedSubscriptionDetails?.newSubscription.cost}`}
										</span>
										<span className="body-tiny">
											<strong>per year</strong>
										</span>
										<span className="ms-auto body-tiny text-shades-500">
											<strong>GST Incl.</strong>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column gap-3-5">
						<h4 className="text-shades-800 m-0">Please review the payment details below.</h4>
						<div className="form-selection-wrapper m-0 border-hero-blue">
							<div className="form-selection-wrapper-inner gap-0 p-0">
								<div className="subscription-item d-flex flex-column gap-3-5 text-start">
									<div className="d-flex flex-column align-items-start gap-2-5">
										<div className="d-flex justify-content-between w-100">
											<div>
												<strong>Payment Method</strong>
											</div>
											<div>
												{selectedSubscriptionDetails?.paymentType === 'Paid'
													? 'Credit Card'
													: selectedSubscriptionDetails?.paymentType === 'Invoiced'
													? 'School Invoice'
													: null}
											</div>
										</div>
										{selectedSubscriptionDetails?.paymentType === 'Invoiced' && (
											<ManagePONumber
												subscriptionDetails={selectedSubscriptionDetails}
												setPONumberField={setPONumberField}
											/>
										)}
									</div>
									<hr className="text-light-blue m-0" />
									<div className="d-flex flex-column align-items-start gap-2-5">
										<div className="d-flex justify-content-between w-100">
											<div>
												<strong>{selectedSubscriptionDetails?.newSubscription?.name} Subscription</strong>
											</div>
											<div>
												<strong>
													{selectedSubscriptionDetails?.newSubscription?.cost &&
														formatPrice(selectedSubscriptionDetails.newSubscription.cost)}
												</strong>
											</div>
										</div>
									</div>
									<hr className="text-light-blue m-0" />
									<div className="d-flex justify-content-between w-100">
										<div>
											<h3 className="m-0">
												<strong>Total</strong>
											</h3>
										</div>
										<div className="d-flex flex-column gap-2-5 text-end">
											<h3 className="m-0">
												<strong>AUD {formatPrice(selectedSubscriptionDetails?.totalAmount)}</strong>
											</h3>
											<p className="m-0 text-shades-500">
												<strong>Incl. GST {formatPrice(selectedSubscriptionDetails?.gst)}</strong>
											</p>
										</div>
									</div>
									<div className="d-flex button-group my-0">
										<button
											type="button"
											className="btn btn-white btn-lg w-100"
											onClick={() => setModalToShow('selectNewSubscription')}
										>
											Go back
										</button>
										<button
											type="button"
											onClick={() => submitConfirmNewSubscription()}
											className="btn btn-lg w-100"
											disabled={confirmNewSubscriptionSubmitting}
										>
											{confirmNewSubscriptionSubmitting ? (
												<Spinner animation="border" size="sm" />
											) : (
												'Confirm reactivation'
											)}
										</button>
									</div>
									<p className="m-0 text-shades-500 body-tiny text-center">
										{selectedSubscriptionDetails?.paymentType === 'Paid'
											? 'You will be redirected to Stripe.'
											: 'An email with the invoice will be sent to the billing email above.'}
									</p>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ReactivateSubscription;
