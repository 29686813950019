/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { SharedModelsCommandResponse } from '../models';
import { StudentActivityProgressCommand } from '../models';
import { StudentActivityStartCommand } from '../models';
import { StudentAwardsResponse } from '../models';
import { StudentBookCompleteCommand } from '../models';
import { StudentBookCompleteResponse } from '../models';
import { StudentBookProgressCommand } from '../models';
import { StudentBookRatingCommand } from '../models';
import { StudentBookViewResponse } from '../models';
import { StudentLibraryResponse } from '../models';
/**
 * StudentApi - axios parameter creator
 * @export
 */
export const StudentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates the activity progress status when a student starts interacting with the book activities
         * @param {StudentActivityProgressCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentActivityProgressPost: async (body?: StudentActivityProgressCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Student/ActivityProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the current activity id of the book when the user is about to start the activity.
         * @param {StudentActivityStartCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentActivityStartPost: async (body?: StudentActivityStartCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Student/ActivityStart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Student awards.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAwardsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Student/Awards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the completion status of the book.
         * @param {StudentBookCompleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentBookCompletePost: async (body?: StudentBookCompleteCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Student/BookComplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the book progress status when a student starts interacting with the book pages
         * @param {StudentBookProgressCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentBookProgressPost: async (body?: StudentBookProgressCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Student/BookProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the book rating.
         * @param {StudentBookRatingCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentBookRatingPost: async (body?: StudentBookRatingCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Student/BookRating`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Viewing individual book
         * @param {string} [bookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentBookViewGet: async (bookId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Student/BookView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (bookId !== undefined) {
                localVarQueryParameter['BookId'] = bookId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Student book library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentLibraryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Student/Library`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentApi - functional programming interface
 * @export
 */
export const StudentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates the activity progress status when a student starts interacting with the book activities
         * @param {StudentActivityProgressCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentActivityProgressPost(body?: StudentActivityProgressCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).apiStudentActivityProgressPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets the current activity id of the book when the user is about to start the activity.
         * @param {StudentActivityStartCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentActivityStartPost(body?: StudentActivityStartCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).apiStudentActivityStartPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Student awards.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAwardsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentAwardsResponse>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).apiStudentAwardsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates the completion status of the book.
         * @param {StudentBookCompleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentBookCompletePost(body?: StudentBookCompleteCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentBookCompleteResponse>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).apiStudentBookCompletePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates the book progress status when a student starts interacting with the book pages
         * @param {StudentBookProgressCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentBookProgressPost(body?: StudentBookProgressCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).apiStudentBookProgressPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates the book rating.
         * @param {StudentBookRatingCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentBookRatingPost(body?: StudentBookRatingCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).apiStudentBookRatingPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Viewing individual book
         * @param {string} [bookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentBookViewGet(bookId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentBookViewResponse>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).apiStudentBookViewGet(bookId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Student book library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentLibraryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentLibraryResponse>> {
            const localVarAxiosArgs = await StudentApiAxiosParamCreator(configuration).apiStudentLibraryGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudentApi - factory interface
 * @export
 */
export const StudentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Updates the activity progress status when a student starts interacting with the book activities
         * @param {StudentActivityProgressCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentActivityProgressPost(body?: StudentActivityProgressCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentApiFp(configuration).apiStudentActivityProgressPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the current activity id of the book when the user is about to start the activity.
         * @param {StudentActivityStartCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentActivityStartPost(body?: StudentActivityStartCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentApiFp(configuration).apiStudentActivityStartPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Student awards.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAwardsGet(options?: any): AxiosPromise<StudentAwardsResponse> {
            return StudentApiFp(configuration).apiStudentAwardsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the completion status of the book.
         * @param {StudentBookCompleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentBookCompletePost(body?: StudentBookCompleteCommand, options?: any): AxiosPromise<StudentBookCompleteResponse> {
            return StudentApiFp(configuration).apiStudentBookCompletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the book progress status when a student starts interacting with the book pages
         * @param {StudentBookProgressCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentBookProgressPost(body?: StudentBookProgressCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentApiFp(configuration).apiStudentBookProgressPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the book rating.
         * @param {StudentBookRatingCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentBookRatingPost(body?: StudentBookRatingCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentApiFp(configuration).apiStudentBookRatingPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Viewing individual book
         * @param {string} [bookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentBookViewGet(bookId?: string, options?: any): AxiosPromise<StudentBookViewResponse> {
            return StudentApiFp(configuration).apiStudentBookViewGet(bookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Student book library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentLibraryGet(options?: any): AxiosPromise<StudentLibraryResponse> {
            return StudentApiFp(configuration).apiStudentLibraryGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentApi - object-oriented interface
 * @export
 * @class StudentApi
 * @extends {BaseAPI}
 */
export class StudentApi extends BaseAPI {
    /**
     * 
     * @summary Updates the activity progress status when a student starts interacting with the book activities
     * @param {StudentActivityProgressCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public apiStudentActivityProgressPost(body?: StudentActivityProgressCommand, options?: any) {
        return StudentApiFp(this.configuration).apiStudentActivityProgressPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Sets the current activity id of the book when the user is about to start the activity.
     * @param {StudentActivityStartCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public apiStudentActivityStartPost(body?: StudentActivityStartCommand, options?: any) {
        return StudentApiFp(this.configuration).apiStudentActivityStartPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Student awards.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public apiStudentAwardsGet(options?: any) {
        return StudentApiFp(this.configuration).apiStudentAwardsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates the completion status of the book.
     * @param {StudentBookCompleteCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public apiStudentBookCompletePost(body?: StudentBookCompleteCommand, options?: any) {
        return StudentApiFp(this.configuration).apiStudentBookCompletePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates the book progress status when a student starts interacting with the book pages
     * @param {StudentBookProgressCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public apiStudentBookProgressPost(body?: StudentBookProgressCommand, options?: any) {
        return StudentApiFp(this.configuration).apiStudentBookProgressPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates the book rating.
     * @param {StudentBookRatingCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public apiStudentBookRatingPost(body?: StudentBookRatingCommand, options?: any) {
        return StudentApiFp(this.configuration).apiStudentBookRatingPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Viewing individual book
     * @param {string} [bookId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public apiStudentBookViewGet(bookId?: string, options?: any) {
        return StudentApiFp(this.configuration).apiStudentBookViewGet(bookId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Student book library.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public apiStudentLibraryGet(options?: any) {
        return StudentApiFp(this.configuration).apiStudentLibraryGet(options).then((request) => request(this.axios, this.basePath));
    }
}
