import { FC, useEffect, useState, MouseEvent as ReactMouseEvent, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Offcanvas, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useAppSelector } from '../../store/slice';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';

// UTILS
import { guid } from '../../libs/utils';
import SvgMask from '../../components/_Helpers/SvgMask';
import ImgRem from '../../libs/imgRem';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import {
	RoutineGroupsGroup,
	RoutineGroupsGroupsResult,
	RoutineRoutinesRoutinesResult,
	ValidationError
} from '../../api/models';

const Routines: FC = () => {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(true);
	const [routinesGroupsData, setRoutinesGroupsData] = useState<RoutineGroupsGroupsResult>();
	const [filterText, setFilterText] = useState('');
	const [showRoutineSelectorOffCanvas, setShowRoutineSelectorOffCanvas] = useState(false);
	const [showRoutineInfoOffCanvas, setShowRoutineInfoOffCanvas] = useState(false);
	const [routineInfo, setRoutineInfo] = useState<RoutineGroupsGroup>();

	const [routineGroupRoutinesData, setRoutineGroupRoutinesData] = useState<RoutineRoutinesRoutinesResult>();

	const user = useAppSelector((state) => state.user);

	const filteredRoutinesData: RoutineGroupsGroupsResult = {
		...routinesGroupsData,
		groups: routinesGroupsData?.groups?.filter((group) => {
			const { name = '' } = group;
			return !filterText || name.toLowerCase().includes(filterText.toLowerCase());
		})
	};

	const handleResponseErrors = (validationErrors: ValidationError[]) => {
		for (const err of validationErrors) {
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: 'Routines error',
					description: err.reason || 'Unknown error'
				})
			);
		}
	};

	const getRoutineGroupsData = async () => {
		const response = await apibridge.getRoutineGroups();
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setRoutinesGroupsData(response.data.result);
			} else if (response.data.validationErrors) {
				handleResponseErrors(response.data.validationErrors);
			}
		}

		setIsLoading(false);
	};

	const getRoutineGroupRoutinesData = async (id: string) => {
		const response = await apibridge.getRoutineRoutines(id);
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setRoutineGroupRoutinesData(response.data.result);
			} else if (response.data.validationErrors) {
				handleResponseErrors(response.data.validationErrors);
			}
		}
	};

	const handleShowRoutineSelectorOffCanvas = (group: RoutineGroupsGroup) => {
		setRoutineInfo(group);
		getRoutineGroupRoutinesData(group.id || '');
		setShowRoutineSelectorOffCanvas(true);
	};

	const handleShowRoutineInfoOffCanvas = (e: ReactMouseEvent | ReactKeyboardEvent, group: RoutineGroupsGroup) => {
		e.stopPropagation();
		setRoutineInfo(group);
		setShowRoutineInfoOffCanvas(true);
	};

	useEffect(() => {
		getRoutineGroupsData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-routines d-flex flex-column flex-grow-1">
			<TitleSection title="Routines">
				<div className="row">
					<div className="col-4 col-lg-3">
						<div className="position-relative d-flex align-items-center">
							<input
								type="text"
								className={`form-control field-filter ${filterText ? 'pe-5' : ''}`}
								placeholder="Filter by title"
								value={filterText}
								onChange={(e) => {
									const target = e.target as HTMLInputElement;
									setFilterText(target.value);
								}}
							/>
							{filterText && (
								<button type="button" className="btn btn-input-clear" onClick={() => setFilterText('')}>
									<span className="visually-hidden">Clear</span>
									<i></i>
								</button>
							)}
						</div>
					</div>
				</div>
			</TitleSection>
			<section className="container h-100">
				{isLoading ? (
					<div className="d-flex justify-content-center">
						<DelayedFadeIn>
							<Spinner />
						</DelayedFadeIn>
					</div>
				) : filteredRoutinesData.groups?.length ? (
					<div className="routine-cards-wrapper d-flex flex-wrap gap-4">
						{filteredRoutinesData.groups.map((group) => {
							const { coverImageUrl = '', id = '', name = '' } = group;

							return (
								<button
									key={id}
									type="button"
									className="btn-routine-card btn d-flex flex-column w-100"
									onClick={() => handleShowRoutineSelectorOffCanvas(group)}
								>
									<div className="routine-card-img-wrapper position-relative d-flex align-items-center justify-content-center">
										{coverImageUrl ? (
											<ImgRem
												src={coverImageUrl}
												className="object-fit-cover w-100 h-100"
												width={100}
												height={100}
												alt=""
											/>
										) : (
											<ImgRem
												src="/svg/routines-class-thin.svg"
												className="cover-image-placeholder"
												width={48}
												height={48}
												alt="Teacher stencil"
											/>
										)}
										<OverlayTrigger placement="top" overlay={<Tooltip id="b-1">Routine info</Tooltip>}>
											{/* can't have button-in-button, so child clickable element must be <div>... */}
											<div
												tabIndex={0}
												className="routine-btn-info btn position-absolute end-0 bottom-0"
												onClick={(e) => {
													handleShowRoutineInfoOffCanvas(e, group);
												}}
												onKeyDown={(e) => {
													if (e.code === 'Enter' || e.code === 'Space') {
														handleShowRoutineInfoOffCanvas(e, group);
													}
												}}
											>
												<SvgMask path="/svg/info-outline.svg" className="flex-shrink-0" width={16} height={16} />
											</div>
										</OverlayTrigger>
									</div>
									<div className="routine-card-text d-flex align-items-center text-center flex-grow-1">{name}</div>
								</button>
							);
						})}
					</div>
				) : (
					<div className="d-flex align-items-center justify-content-center h-100 text-shades-500 m-0">
						<h3>No results found</h3>
					</div>
				)}
			</section>

			<Offcanvas
				bsPrefix="routine-selector offcanvas"
				show={showRoutineSelectorOffCanvas}
				onHide={() => setShowRoutineSelectorOffCanvas(false)}
				placement="end"
			>
				<Offcanvas.Header className="pb-4" closeButton></Offcanvas.Header>
				<Offcanvas.Body>
					<div className="text-center">
						{routineInfo?.name && <div className="label mb-2 pb-1">{routineInfo.name}</div>}
						<h2 className="mb-0 text-shades-800">
							<strong>Select a routine</strong>
						</h2>
					</div>
					<div className="routine-selector-wrapper position-relative flex-grow-1 overflow-auto">
						<div className="position-absolute top-0 start-0 w-100 h-100">
							{user.permissions?.includes('RoutineRoutines') ? (
								<div className="routine-selector-item d-flex flex-column gap-4">
									{routineGroupRoutinesData?.stages?.map((stage, stageIndex) => {
										const { name, isRevision, routines } = stage;

										return (
											<div key={stageIndex}>
												<div className="body-small mb-2">
													<strong>{isRevision ? name : `Stage ${name}`}</strong>
												</div>
												<div className="d-flex flex-wrap gap-2">
													{routines?.map((routine) => {
														const { id = '', name } = routine;
														return (
															<Link key={id} to={id} className="btn btn-routine-filter">
																{name}
															</Link>
														);
													})}
												</div>
											</div>
										);
									})}
								</div>
							) : (
								<div className="d-flex align-items-center justify-content-center h-100 mx-3">
									<h3 className="text-center text-shades-500 m-0">You don't have permission to view this content.</h3>
								</div>
							)}
						</div>
					</div>
				</Offcanvas.Body>
			</Offcanvas>

			<Offcanvas
				bsPrefix="routine-info offcanvas"
				show={showRoutineInfoOffCanvas}
				onHide={() => setShowRoutineInfoOffCanvas(false)}
				placement="end"
			>
				<Offcanvas.Header className="pb-4" closeButton></Offcanvas.Header>
				<Offcanvas.Body>
					<div className="flex-grow-1">
						<h2 className="mb-4 text-shades-800">
							<strong>{routineInfo?.name}</strong>
						</h2>
						{routineInfo?.notes && <div dangerouslySetInnerHTML={{ __html: routineInfo.notes }}></div>}
					</div>
					<button
						type="button"
						className="btn btn-lg"
						onClick={() => {
							setShowRoutineInfoOffCanvas(false);
							if (routineInfo) handleShowRoutineSelectorOffCanvas(routineInfo);
						}}
					>
						View Routine
					</button>
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};

export default Routines;
