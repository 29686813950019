import React, { useEffect, useState } from 'react';

// PACKAGES
import { Toast, ToastProps } from 'react-bootstrap';

// UTILS
import SvgMask from './SvgMask';
import { ToastMessageType, ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { useAppDispatch } from '../../store/slice';

const ToastInstance: React.FC<{
	toastMessage: ToastMessageType;
}> = ({ toastMessage }) => {
	const [showToast, setShowToast] = useState(true);

	const dispatch = useAppDispatch();

	const { description, heading, type } = toastMessage;

	const props: ToastProps = {
		bg: type,
		show: showToast,
		animation: false,
		onClose: () => setShowToast(false)
	};
	if (type === 'info') {
		props.autohide = true;
		props.delay = 15000;
	} else if (type === 'success') {
		props.autohide = true;
		props.delay = 5000;
	}

	useEffect(() => {
		if (!showToast) {
			dispatch(ToastMessagesSlice.actions.remove(toastMessage.id));
		}
	}, [showToast]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Toast {...props}>
			<Toast.Body>
				<div className="d-flex gap-2-5">
					<i></i>
					<div className="flex-grow-1">
						<strong>{heading}</strong>
						<br />
						{description}
					</div>
					<div className="align-items-start">
						<button type="button" className="btn btn-reset" onClick={() => setShowToast(false)}>
							<SvgMask path={'/svg/cross-thin.svg'} width={24} height={24} />
						</button>
					</div>
				</div>
			</Toast.Body>
		</Toast>
	);
};

export default ToastInstance;
