/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AccountCreateCommand } from '../models';
import { AccountCreateResponse } from '../models';
import { AccountEmailChangeCommand } from '../models';
import { AccountMessagesResponse } from '../models';
import { AccountNameChangeCommand } from '../models';
import { AccountPasswordChangeCommand } from '../models';
import { AccountSettingsResponse } from '../models';
import { AccountUserInfoResponse } from '../models';
import { SharedModelsCommandResponse } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Provides the user information of the token when creating a new user.
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCreateGet: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register user details and activate new account. User details updated:   * User first and last name   * Password
         * @param {AccountCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCreatePost: async (body?: AccountCreateCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the user's email and login with the new email address. In order to complete this step,   the user must provide the login password as well.
         * @param {AccountEmailChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountEmailChangePost: async (body?: AccountEmailChangeCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/EmailChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register user details and activate new account. User details updated:   * User first and last name   * Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountFtuxCompletedPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/FtuxCompleted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides any notifications that neesd to be displayed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMessagesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the user first name and last name.
         * @param {AccountNameChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountNameChangePost: async (body?: AccountNameChangeCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/NameChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the user password
         * @param {AccountPasswordChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountPasswordChangePost: async (body?: AccountPasswordChangeCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/PasswordChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides the user details to be displayed on the settings screen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountSettingsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provide logged in user information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserInfoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/UserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Provides the user information of the token when creating a new user.
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCreateGet(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountCreateResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountCreateGet(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Register user details and activate new account. User details updated:   * User first and last name   * Password
         * @param {AccountCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCreatePost(body?: AccountCreateCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountCreatePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Changes the user's email and login with the new email address. In order to complete this step,   the user must provide the login password as well.
         * @param {AccountEmailChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountEmailChangePost(body?: AccountEmailChangeCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountEmailChangePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Register user details and activate new account. User details updated:   * User first and last name   * Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountFtuxCompletedPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountFtuxCompletedPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides any notifications that neesd to be displayed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountMessagesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountMessagesResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountMessagesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Changes the user first name and last name.
         * @param {AccountNameChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountNameChangePost(body?: AccountNameChangeCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountNameChangePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Changes the user password
         * @param {AccountPasswordChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountPasswordChangePost(body?: AccountPasswordChangeCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountPasswordChangePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides the user details to be displayed on the settings screen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountSettingsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSettingsResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountSettingsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provide logged in user information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserInfoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountUserInfoResponse>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).apiAccountUserInfoGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Provides the user information of the token when creating a new user.
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCreateGet(token?: string, options?: any): AxiosPromise<AccountCreateResponse> {
            return AccountApiFp(configuration).apiAccountCreateGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register user details and activate new account. User details updated:   * User first and last name   * Password
         * @param {AccountCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCreatePost(body?: AccountCreateCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return AccountApiFp(configuration).apiAccountCreatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the user's email and login with the new email address. In order to complete this step,   the user must provide the login password as well.
         * @param {AccountEmailChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountEmailChangePost(body?: AccountEmailChangeCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return AccountApiFp(configuration).apiAccountEmailChangePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register user details and activate new account. User details updated:   * User first and last name   * Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountFtuxCompletedPost(options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return AccountApiFp(configuration).apiAccountFtuxCompletedPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides any notifications that neesd to be displayed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMessagesGet(options?: any): AxiosPromise<AccountMessagesResponse> {
            return AccountApiFp(configuration).apiAccountMessagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the user first name and last name.
         * @param {AccountNameChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountNameChangePost(body?: AccountNameChangeCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return AccountApiFp(configuration).apiAccountNameChangePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the user password
         * @param {AccountPasswordChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountPasswordChangePost(body?: AccountPasswordChangeCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return AccountApiFp(configuration).apiAccountPasswordChangePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides the user details to be displayed on the settings screen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountSettingsGet(options?: any): AxiosPromise<AccountSettingsResponse> {
            return AccountApiFp(configuration).apiAccountSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provide logged in user information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserInfoGet(options?: any): AxiosPromise<AccountUserInfoResponse> {
            return AccountApiFp(configuration).apiAccountUserInfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Provides the user information of the token when creating a new user.
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCreateGet(token?: string, options?: any) {
        return AccountApiFp(this.configuration).apiAccountCreateGet(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Register user details and activate new account. User details updated:   * User first and last name   * Password
     * @param {AccountCreateCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCreatePost(body?: AccountCreateCommand, options?: any) {
        return AccountApiFp(this.configuration).apiAccountCreatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Changes the user's email and login with the new email address. In order to complete this step,   the user must provide the login password as well.
     * @param {AccountEmailChangeCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountEmailChangePost(body?: AccountEmailChangeCommand, options?: any) {
        return AccountApiFp(this.configuration).apiAccountEmailChangePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Register user details and activate new account. User details updated:   * User first and last name   * Password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountFtuxCompletedPost(options?: any) {
        return AccountApiFp(this.configuration).apiAccountFtuxCompletedPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides any notifications that neesd to be displayed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountMessagesGet(options?: any) {
        return AccountApiFp(this.configuration).apiAccountMessagesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Changes the user first name and last name.
     * @param {AccountNameChangeCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountNameChangePost(body?: AccountNameChangeCommand, options?: any) {
        return AccountApiFp(this.configuration).apiAccountNameChangePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Changes the user password
     * @param {AccountPasswordChangeCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountPasswordChangePost(body?: AccountPasswordChangeCommand, options?: any) {
        return AccountApiFp(this.configuration).apiAccountPasswordChangePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides the user details to be displayed on the settings screen.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountSettingsGet(options?: any) {
        return AccountApiFp(this.configuration).apiAccountSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provide logged in user information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUserInfoGet(options?: any) {
        return AccountApiFp(this.configuration).apiAccountUserInfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}
