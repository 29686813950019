import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../store/slice';

// COMPONENTS
import RichText from '../RichText/RichText';

// UTILS
import SvgMask from '../_Helpers/SvgMask';

// TYPES
import apibridge from '../../apibridge';
import { UserType } from '../../store/slice/User';
import { AccountMessagesMessage } from '../../api/models';

// the un-dismissable message that may display in bottom-right of the screen
const ToastNag: FC = () => {
	const user: UserType = useAppSelector((state) => state.user);
	const isStudent = user.profiles?.[0]?.type && user.profiles?.[0].type === 'Student';

	const [isExpanded, setIsExpanded] = useState(false);
	const [message, setMessage] = useState<AccountMessagesMessage>();

	const getMessages = async () => {
		const response = await apibridge.getMessages();
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				// we only have the UX capacity to display a single message, so am just grabbing the first in the array
				const messageToShow = response.data.result.messages?.[0];
				setMessage(messageToShow);
			} else {
				// for testing
				// const hijackedMessage = {
				// 	subject: 'Organisation access restricted due to user limit',
				// 	body: `
				// 		<p>A <strong>Level 1 Subscription</strong> only permits a total of <strong>50 staff and students</strong> to access the service.</p>
				// 		<p>There are currently <strong>84 staff and students</strong> registered. Please remove <strong>34 users</strong> to re-enable access to all staff and students.</p>
				// 		`
				// };
				// setMessage(hijackedMessage);
			}
		}
	};

	// if the user has been updated (subscription may have changed, organisation cancelled), fetch from the messages endpoint to see if they're updated
	// also get on initial log in
	useEffect(() => {
		if ((user.forceUpdate || user.loggedIn) && !isStudent) {
			getMessages();
		}
	}, [user]); // eslint-disable-line react-hooks/exhaustive-deps

	return message && user.loggedIn ? (
		<div className="toast toast-nag danger show danger">
			<div className="d-flex gap-2-5">
				<SvgMask path="/svg/alert-solid.svg" width={22} height={22} />
				<h4 className="flex-grow-1 m-0">{message.subject}</h4>
				<button type="button" onClick={() => setIsExpanded(!isExpanded)} className="btn btn-reset">
					<SvgMask
						path="/svg/chevron-down.svg"
						width={24}
						height={24}
						className={`${isExpanded ? '' : 'rotate-180'}`}
					/>
				</button>
			</div>
			{isExpanded && <RichText content={{ markup: message.body }} />}
		</div>
	) : null;
};

export default ToastNag;
