import React, { ChangeEvent, FC, SetStateAction, useRef } from 'react';
import { useAppDispatch } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { FormikProps, connect } from 'formik';
import { Spinner } from 'react-bootstrap';

// UTILS
import SvgMask from '../_Helpers/SvgMask';
import { guid } from '../../libs/utils';

// TYPES
import apibridge from '../../apibridge';
import { AddStudentsFormValuesType } from '../../pages/StudentAdmin/AddStudents';

const FileStudentImport: FC<{
	classId?: string;
	setInvitesLeft: React.Dispatch<SetStateAction<number>>;
	formik: FormikProps<AddStudentsFormValuesType>;
	submittingStudentImport: boolean;
	setSubmittingStudentImport: React.Dispatch<SetStateAction<boolean>>;
}> = ({ classId, setInvitesLeft, formik, submittingStudentImport, setSubmittingStudentImport }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const formikStudents = formik.values.students || [];

	const dispatch = useAppDispatch();

	const getInvitesLeft = async () => {
		const response = await apibridge.getInvitesLeft();
		if (response && response.data && !response.data.isError && response.data.result) {
			const { invitesLeft = 0, isUnilimited } = response.data.result;
			return isUnilimited ? Infinity : invitesLeft;
		}

		return 0;
	};

	const addToastMessage = (type: 'success' | 'info' | 'danger', heading: string, description: string) => {
		const message = {
			id: guid(),
			type: type,
			heading: heading,
			description: description
		};
		dispatch(ToastMessagesSlice.actions.add(message));
	};

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
		if (fileInputRef.current) {
			setSubmittingStudentImport(true);

			const file = fileInputRef.current.files?.[0];
			const response = await apibridge.postStudentAdminUpload(file, classId);
			if (response && response.data) {
				if (!response.data.isError && response.data.result) {
					if (file) fileInputRef.current.value = '';

					const { accounts = [] } = response.data.result;
					// finding duplicate students and setting them in advance
					accounts.forEach((student) => {
						student.firstName = student.firstName?.trim();
						student.lastName = student.lastName?.trim();
						const studentAlreadyExists = formik.values.students.some(
							(formikStudent) =>
								formikStudent.firstName === student.firstName && formikStudent.lastName === student.lastName
						);
						if (studentAlreadyExists) student.status = 'Duplicate';
					});
					const validStudents = accounts.filter((student) => student.status === 'Successful');

					if (accounts) {
						if (validStudents.length) {
							const invitesLeft = await getInvitesLeft();
							setInvitesLeft(invitesLeft);

							const updatedStudents = [...formikStudents, ...validStudents];
							const updatedCurrentInvitesLeft = invitesLeft - updatedStudents.length;
							formik.setFieldValue('students', updatedStudents);

							if (updatedCurrentInvitesLeft < 0) {
								dispatch(
									ToastMessagesSlice.actions.add({
										id: guid(),
										type: 'danger',
										heading: `Exceeded user limit`,
										description: 'Please remove excess users from the student list to proceed.'
									})
								);
							}
						}

						if (validStudents.length !== 0) {
							addToastMessage(
								'success',
								`${validStudents.length ? 'Student' : 'Students'} successfully imported`,
								`${validStudents.length} ${
									validStudents.length === 1 ? 'student was' : 'students were'
								} successfully imported via Excel!`
							);
						}

						const hasExistingProfileError = accounts.some((account) => account.status === 'Duplicate');
						if (hasExistingProfileError) {
							addToastMessage(
								'danger',
								'Cannot add students that already exist',
								'Some students in the list already exist in this class. Please add new student names to successfully add them to the class.'
							);
						}

						const hasInvalidNameError = accounts.some((account) => account.status === 'Invalid');
						if (hasInvalidNameError) {
							addToastMessage(
								'danger',
								'Cannot add students with invalid names',
								'Please fix invalid student names to successfully add them to the class.'
							);
						}
					}
				} else if (response.data.validationErrors) {
					for (const err of response.data.validationErrors) {
						dispatch(
							ToastMessagesSlice.actions.add({
								id: guid(),
								type: 'danger',
								heading: 'Import error',
								description: err.reason || 'Unknown error'
							})
						);
					}
				}
			}

			setSubmittingStudentImport(false);
		}
	};

	return (
		<div className="file-import-container">
			<input
				ref={fileInputRef}
				onChange={handleFileInput}
				type="file"
				name="file"
				id="file"
				className="d-none"
				accept=".xlsx, .xls"
			/>
			<button
				type="button"
				onClick={handleClick}
				disabled={submittingStudentImport}
				className="btn btn-white flex-1 w-100"
			>
				<SvgMask path="/svg/plus.svg" width={16} height={16} />
				Import students through Excel
				{submittingStudentImport && <Spinner animation="border" size="sm" />}
			</button>
			<p className="text-shades-500 mb-0">
				To upload students via Excel, first{' '}
				<a download={'template-students.xlsx'} href="/templates/template-students.xlsx" target="_blank">
					download the Excel Template
				</a>
			</p>
		</div>
	);
};

export default connect(FileStudentImport);
