export type DrawnLineType = {
	className?: string;
	xStart: number;
	yStart: number;
	xEnd: number;
	yEnd: number;
};

const DrawnLine: React.FC<DrawnLineType> = ({ className = '', xStart, yStart, xEnd, yEnd }) => {
	if (xStart === 0 || yStart === 0) return null;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={`${className} drawn-line`}>
			<line x1={xStart} y1={yStart} x2={xEnd} y2={yEnd} />
		</svg>
	);
};

export default DrawnLine;
