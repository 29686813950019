import { FC, useState } from 'react';

// PACKAGES
import Moment from 'moment';
import { InputGroup, Modal } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm, FormikHelpers } from 'formik';

// COMPONENTS
import FormCustomError from '../Forms/FormCustomError';
import FormGroupWithError from '../Forms/FormGroupWithError';

// UTILS
import SvgMask from '../_Helpers/SvgMask';
import { createErrorsObject, guid } from '../../libs/utils';

// TYPES
import apibridge from '../../apibridge';
import { OrganisationCancelCommand, OrganisationSettingsSettingsResult } from '../../api/models';
import { ResponseStandardFail } from '../../libs/api';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

const CancelSubscription: FC<{
	getOrganisationSettings: () => Promise<void>;
	organisationSettings: OrganisationSettingsSettingsResult | undefined;
}> = ({ getOrganisationSettings, organisationSettings }) => {
	const dispatch = useDispatch();

	const initialFormValues: OrganisationCancelCommand = {
		password: ''
	};
	const [cancelSubscriptionValues, setCancelSubscriptionValues] = useState(initialFormValues);
	const [cancelSubscriptionSubmitting, setCancelSubscriptionSubmitting] = useState(false);

	const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
	const [showCancelSubscriptionPassword, setShowCancelSubscriptionPassword] = useState(false);

	const submitCancelSubscription = async (
		values: OrganisationCancelCommand,
		formikHelpers: FormikHelpers<OrganisationCancelCommand>
	) => {
		setCancelSubscriptionSubmitting(true);
		const { password } = values;
		const response = await apibridge.postOrganisationCancel({
			password
		});
		if (response && response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				await getOrganisationSettings();
				setShowCancelSubscriptionModal(false);
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Subscription cancelled',
						description: organisationSettings?.nextInvoiceDate
							? `Your organisation can continue to use the platform until your subscription ends on ${Moment(
									organisationSettings.nextInvoiceDate
							  ).format('D MMM, YYYY')}.`
							: ''
					})
				);
			}
		}

		setCancelSubscriptionSubmitting(false);
	};

	return (
		<>
			<div className="col-6 col-xl-4">
				<button
					type="button"
					className="btn btn-destructive-outline w-100"
					onClick={() => setShowCancelSubscriptionModal(true)}
				>
					Cancel subscription
				</button>
			</div>
			<Modal
				show={showCancelSubscriptionModal}
				onHide={() => setShowCancelSubscriptionModal(false)}
				onExited={() => setCancelSubscriptionValues(initialFormValues)}
				centered
			>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>Are you sure you want to cancel your subscription?</strong>
					</h2>
					{organisationSettings?.nextInvoiceDate ? (
						<>
							<p className="m-0">
								Cancelling your subscription with Little Learners Love Literacy Online will mean all access will be
								restricted for non-admin staff and students at the end of this billing period{' '}
								<strong>{Moment(organisationSettings.nextInvoiceDate).format('D MMMM, YYYY')}</strong>.
							</p>
							<p className="m-0">
								After <strong>{Moment(organisationSettings.nextInvoiceDate).format('D MMMM, YYYY')}</strong>, your
								organisation data will be stored for <strong>30 days</strong> if you decide to reactivate your plan.
							</p>
						</>
					) : (
						<>
							<p className="m-0">
								Cancelling your subscription with Little Learners Love Literacy Online will mean all access will be
								restricted for non-admin staff and students at the end of this billing period.
							</p>
							<p className="m-0">
								Your organisation data will be stored for <strong>30 days</strong> if you decide to reactivate your
								plan.
							</p>
						</>
					)}

					<p className="h4 my-3 text-error">Enter your password to confirm you want to cancel your subscription.</p>
					<Formik initialValues={cancelSubscriptionValues} onSubmit={submitCancelSubscription}>
						<FormikForm
							onChange={(e: React.ChangeEvent<HTMLFormElement>) => {
								const { name, value } = e.target;
								setCancelSubscriptionValues({
									...cancelSubscriptionValues,
									[name]: value
								});
							}}
						>
							<FormCustomError className="text-start my-2" />

							<FormGroupWithError name="password" className="text-start">
								<label htmlFor="password" className="form-label">
									Your password
								</label>
								<InputGroup>
									<Field
										type={showCancelSubscriptionPassword ? 'text' : 'password'}
										id="password"
										name="password"
										className="form-control border-end-0"
										placeholder="Password"
									/>
									<InputGroup.Text className="bg-white p-0">
										<button
											type="button"
											className="btn btn-sm bg-transparent text-reset"
											onClick={() => setShowCancelSubscriptionPassword(!showCancelSubscriptionPassword)}
											tabIndex={-1}
										>
											<SvgMask
												path={showCancelSubscriptionPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
												width={24}
												height={24}
											/>
										</button>
									</InputGroup.Text>
								</InputGroup>
							</FormGroupWithError>

							<div className="d-flex button-group mt-5">
								<button
									type="button"
									className="btn btn-white btn-lg w-100"
									onClick={() => setShowCancelSubscriptionModal(false)}
								>
									Cancel
								</button>
								<button
									type="submit"
									className="btn btn-destructive btn-lg w-100"
									disabled={!cancelSubscriptionValues.password || cancelSubscriptionSubmitting}
								>
									Cancel subscription
								</button>
							</div>
						</FormikForm>
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default CancelSubscription;
