import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Updater, useImmer } from 'use-immer';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { UserType } from '../../store/slice/User';
import { useAppSelector } from '../../store/slice';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import CategoryDropdown from '../../components/TitleSection/CategoryDropdown';
import SwitchToggle from '../../components/_Helpers/SwitchToggle';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { ResourceFavoriteCommand, ResourceListCategoryItem, ResourceListListResult } from '../../api/models';

const ResourcesItem: FC<{
	categoryItem: ResourceListCategoryItem;
	setResourcesData: Updater<ResourceListListResult>;
	categoryId: number | undefined;
	userCanFavorite: boolean;
}> = ({ categoryItem, setResourcesData, categoryId, userCanFavorite }) => {
	const { key, isFavorite, title = '', url } = categoryItem;
	const [favoriteSubmitting, setFavoriteSubmitting] = useState(false);

	const dispatch = useDispatch();

	const postSetOrUnsetResourceFavorite = async ({ resourceKey, action }: ResourceFavoriteCommand) => {
		setFavoriteSubmitting(true);

		const response = await apibridge.postResourceFavorite({ resourceKey, action });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setResourcesData((draft) => {
					const draftCategory = draft.categories?.find((category) => category.id === categoryId);
					const draftCategoryItem = draftCategory?.categoryItems?.find((categoryItem) => categoryItem.key === key);
					if (draftCategoryItem && draftCategoryItem.isFavorite !== undefined) {
						draftCategoryItem.isFavorite = !isFavorite;
					}
				});
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Resources error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setFavoriteSubmitting(false);
	};

	return (
		<div className={`resources-item d-flex align-items-center gap-4 p-4 border-top`}>
			<div className="resources-icon d-flex align-items-center flex-shrink-0 p-2">
				<SvgMask path="/svg/resources-stack.svg" width={24} height={24} />
			</div>
			<div className="flex-grow-1">
				<a href={url} target="_blank" rel="noreferrer" className="resource-link">
					<h3 className="h4 m-0">{title}</h3>
				</a>
			</div>
			<div className="d-flex align-items-center gap-2-5">
				{userCanFavorite && (
					<button
						type="button"
						className={`position-relative btn btn-icon btn-favorite ${isFavorite ? 'favorited' : ''}`}
						disabled={favoriteSubmitting}
						onClick={() => postSetOrUnsetResourceFavorite({ resourceKey: key, action: isFavorite ? 'Unset' : 'Set' })}
					>
						{/* Note: Chrome seems to have interesting issues with the scrollbar and '.visually-hidden' class here, so am applying a pos-relative above */}
						<span className="visually-hidden">{isFavorite ? 'Unfavourite item' : 'Favourite item'}</span>
						<i></i>
					</button>
				)}
				<div className="flex-shrink-0">
					<a href={url} className="btn btn-icon btn-download" download>
						<SvgMask path={'/svg/download.svg'} width={24} height={24} />
					</a>
				</div>
			</div>
		</div>
	);
};

const Resources: FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [resourcesData, setResourcesData] = useImmer<ResourceListListResult>({});
	const [filterText, setFilterText] = useState('');
	const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);

	const user: UserType = useAppSelector((state) => state.user);
	const userCanFavorite = user.permissions?.includes('ResourceFavorite') || false;

	const filteredResourcesData: ResourceListListResult = {
		...resourcesData,
		categories: resourcesData?.categories?.map((category) => {
			return {
				...category,
				categoryItems: category.categoryItems?.filter((categoryItem) => {
					return (
						(!filterText || categoryItem.title?.toLowerCase().includes(filterText.toLowerCase())) &&
						(!showOnlyFavorites || categoryItem.isFavorite)
					);
				})
			};
		})
	};

	const categoryItems = resourcesData
		? resourcesData.categories?.map((category) => {
				return {
					domId: `#category-section-${category.id}`,
					name: category.name || ''
				};
		  })
		: null;

	const getResourcesData = async () => {
		const response = await apibridge.getResourceList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setResourcesData({
				...response.data.result,
				// don't include any empty categories
				categories: response.data.result.categories?.filter((category) => category.categoryItems?.length)
			});
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getResourcesData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-resources d-flex flex-column flex-grow-1">
			<TitleSection title="Resources" description="Helpful resources to be used in class activities.">
				<div className="row row-gap-3">
					<div className="col">
						{categoryItems && <CategoryDropdown items={categoryItems} isDisabled={filterText !== ''} />}
					</div>
					<div className="col-auto">
						{userCanFavorite && (
							<div className="d-flex align-items-center gap-2-5 h-100">
								<label htmlFor="input-favorites" className="d-flex align-items-center gap-2-5">
									<SvgMask path={'/svg/star-filled.svg'} width={24} height={24} />
									<strong>Favourites</strong>
								</label>

								<SwitchToggle
									id="input-favorites"
									checked={showOnlyFavorites}
									onChange={() => setShowOnlyFavorites(!showOnlyFavorites)}
								/>
							</div>
						)}
					</div>
					<div className="col-auto col-md-4 col-lg-3">
						<div className="position-relative d-flex align-items-center">
							<input
								type="text"
								className={`form-control field-filter ${filterText ? 'pe-5' : ''}`}
								placeholder="Filter by keyword"
								value={filterText}
								onChange={(e) => {
									const target = e.target as HTMLInputElement;
									setFilterText(target.value);
								}}
							/>
							{filterText && (
								<button type="button" className="btn btn-input-clear" onClick={() => setFilterText('')}>
									<span className="visually-hidden">Clear</span>
									<i></i>
								</button>
							)}
						</div>
					</div>
				</div>
			</TitleSection>

			<section className="container h-100">
				{isLoading ? (
					<div className="d-flex justify-content-center">
						<DelayedFadeIn>
							<Spinner />
						</DelayedFadeIn>
					</div>
				) : filteredResourcesData?.categories?.length ? (
					<div className="d-flex flex-column gap-4-5">
						{filteredResourcesData?.categories.map((category) => {
							const { categoryItems, id, name } = category;
							return (
								<div key={id} id={`category-section-${id}`} className="resources-section border gap-2-5">
									<div className="resource-title-wrapper p-4">
										<h2 className="h3 m-0">
											<strong>{name}</strong>
										</h2>
									</div>
									<div>
										{categoryItems?.map((categoryItem) => (
											<ResourcesItem
												key={categoryItem.key}
												setResourcesData={setResourcesData}
												categoryId={id}
												categoryItem={categoryItem}
												userCanFavorite={userCanFavorite}
											/>
										))}
									</div>
								</div>
							);
						})}
					</div>
				) : (
					<div className="d-flex align-items-center justify-content-center h-100 text-shades-500 m-0">
						<h3>No results found</h3>
					</div>
				)}
			</section>
		</div>
	);
};

export default Resources;
