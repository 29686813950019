import { Dispatch, FC, SetStateAction, useState } from 'react';

// TYPES
import {
	SubscriptionChangeChangeCommandResponse,
	SubscriptionReactivateCancelledChangeCommandResponse
} from '../../api/models';

const ManagePONumber: FC<{
	subscriptionDetails: SubscriptionChangeChangeCommandResponse | SubscriptionReactivateCancelledChangeCommandResponse;
	setPONumberField: Dispatch<SetStateAction<string>>;
}> = ({ subscriptionDetails, setPONumberField }) => {
	const [showPONumberField, setShowPONumberField] = useState(false);

	return (
		<>
			<div className="d-flex justify-content-between w-100">
				<div>
					<strong>Billing Email</strong>
				</div>
				<div>{subscriptionDetails?.email}</div>
			</div>
			{subscriptionDetails.orderNumber ? (
				<div className="d-flex justify-content-between w-100">
					<div>
						<strong>PO Number</strong>
					</div>
					<div>{subscriptionDetails.orderNumber}</div>
				</div>
			) : showPONumberField ? (
				<div className="form-group w-100">
					<label htmlFor="edit-po-number" className="form-label body-normal">
						PO Number
					</label>
					<input
						type="text"
						id="edit-po-number"
						className="form-control"
						placeholder="Type your PO Number"
						onChange={(e) => setPONumberField(e.target.value)}
					/>
				</div>
			) : (
				<button type="button" onClick={() => setShowPONumberField(true)} className="btn btn-link">
					+ Add PO Number
				</button>
			)}
		</>
	);
};

export default ManagePONumber;
