import { FC } from 'react';

export const generatedCropUrl = ({
	isWebP,
	src,
	width,
	height
}: {
	isWebP: boolean;
	src: string;
	width: number;
	height?: number;
}) =>
	// definitely never attempt to apply Umbraco cropping to an SVG
	src.includes('.svg')
		? src
		: `${src}?anchor=center&mode=crop&width=${width}${height ? '&height=' + height : ''}&format=${
				isWebP ? 'webp' : 'png'
		  }&quality=90`;

const CroppedImage: FC<{
	src: string;
	width: number;
	height?: number;
	alt?: string;
	usePriority?: boolean;
	className?: string;
	loadCallback?: (e: any) => void;
}> = ({ src, alt = '', usePriority, className = '', ...props }) => {
	const width = props.width ? Math.ceil(props.width) : 800;
	const height = props.height ? Math.ceil(props.height) : undefined;

	const cropProps = {
		src,
		width,
		height
	};

	const isSvg = src.includes('.svg');

	return (
		<picture>
			{!isSvg && (
				<source
					srcSet={generatedCropUrl({ ...cropProps, isWebP: true })}
					width={width}
					height={height}
					type="image/webp"
				/>
			)}
			<img
				src={isSvg ? src : generatedCropUrl({ ...cropProps, isWebP: false })}
				width={width}
				height={height}
				alt={alt}
				className={className}
				loading={usePriority ? 'eager' : 'lazy'}
				onLoad={props?.loadCallback}
			/>
		</picture>
	);
};

export default CroppedImage;
