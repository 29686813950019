import { FC, useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';

const DelayedFadeIn: FC<{ children: any; delay?: number }> = ({ children, delay = 1000 }) => {
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		const delayedTimeout = setTimeout(() => {
			setShowContent(true);
		}, delay);

		return () => clearTimeout(delayedTimeout);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fade in={showContent} timeout={600} unmountOnExit>
			{children}
		</Fade>
	);
};

export default DelayedFadeIn;
