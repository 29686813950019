import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SystemInfoType = {
	type: 'staff' | 'student' | 'buyer';
	isTrial: boolean;
};

const initialState: SystemInfoType = {
	type: 'buyer',
	isTrial: false
};

export const SystemInfoSlice = createSlice({
	name: 'systemInfo',
	initialState,
	reducers: {
		update: (state, action: PayloadAction<SystemInfoType>) => {
			state = Object.assign(state, action.payload);
		}
	}
});
