import { FC, useState } from 'react';
import { useAppSelector } from '../../store/slice';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Dropdown, Modal, Offcanvas, Spinner } from 'react-bootstrap';
import Moment from 'moment';

// API
import apibridge from '../../apibridge';

// COMPONENTS
import SvgMask from '../_Helpers/SvgMask';

// UTILS
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../_Helpers/DelayedFadeIn';

// TYPES
import {
	AccountUserInfoUserDetails,
	AccountUserInfoUserProfile,
	ClassListClass,
	ClassListClassCollection,
	StaffDashboardActiveMember,
	StaffDashboardClass,
	StaffDashboardPendingRequest,
	StaffViewClass,
	StaffViewStaffMember
} from '../../api/models';
import { TabStatus } from '../../pages/TeacherAdmin/TeacherAdmin';
import { SortOptionsType } from '../TitleSection/SortDropdown';
import { UserType } from '../../store/slice/User';

interface TeachersTypes extends StaffDashboardActiveMember, StaffDashboardPendingRequest {}

const TeachersList: FC<{
	tab: TabStatus;
	sort: SortOptionsType;
	teachersList: TeachersTypes[];
	classList?: ClassListClassCollection;
	revokeInvitation?: (profileId: string) => Promise<void>;
	resendInvitation?: (profileId: string) => Promise<void>;
	revokeAccess?: (profileId: string) => Promise<void>;
	removeAdminRole?: (profileId: string) => Promise<void>;
	makeAdmin?: (profileId: string) => Promise<void>;
	assignClass?: (profileId: string, classId: string) => Promise<void>;
	unassignClass?: (profileId: string, classId: string) => Promise<void>;
}> = ({
	tab,
	sort,
	teachersList,
	classList,
	revokeInvitation,
	resendInvitation,
	revokeAccess,
	removeAdminRole,
	makeAdmin,
	assignClass,
	unassignClass
}) => {
	const user: AccountUserInfoUserDetails = useAppSelector((state) => state.user);

	const { profiles, activeOrganisationId } = user;

	const currentActiveProfile = profiles?.find(
		(profile: AccountUserInfoUserProfile) => profile.organisationId === activeOrganisationId
	);

	return (
		<div className="bordered-list teachers-list">
			{tab === 'Active' ? (
				<>
					<div className="header px-4">
						<h3 className="d-flex align-items-center m-0">
							<strong>Active</strong>{' '}
							<span className="badge tag-label bg-primary ms-2 text-white">{teachersList.length}</span>
						</h3>
					</div>
					{tab === 'Active' && teachersList.length ? (
						teachersList
							.sort((a, b) => {
								const aFirstName = a.firstName || '';
								const bFirstName = b.firstName || '';
								const aLastName = a.lastName || '';
								const bLastName = b.lastName || '';
								return sort.value === 'asc'
									? aFirstName.localeCompare(bFirstName) || aLastName.localeCompare(aLastName)
									: bFirstName.localeCompare(aFirstName) || bLastName.localeCompare(aLastName);
							})
							.map((teacher) => {
								return (
									<div key={teacher.id} className="content px-4">
										<TeacherCard
											revokeAccess={revokeAccess}
											removeAdminRole={removeAdminRole}
											makeAdmin={makeAdmin}
											assignClass={assignClass}
											unassignClass={unassignClass}
											teacher={teacher}
											classList={classList}
											key={teacher.id}
											type={tab}
											organisation={currentActiveProfile}
										/>
									</div>
								);
							})
					) : (
						<div className="content py-5 px-4">
							<div className="d-flex align-items-center justify-content-center gap-2 text-shades-500">
								<SvgMask path="/svg/invite.svg" width={24} height={24} />
								<p className="mb-0">
									Press <strong>'Invite Teachers'</strong> to send invitations
								</p>
							</div>
						</div>
					)}
				</>
			) : (
				<>
					<div className="header px-4">
						<h3 className="d-flex align-items-center m-0">
							<strong>Pending</strong>{' '}
							<span className="badge tag-label bg-primary ms-2 text-white">{teachersList.length}</span>
						</h3>
					</div>
					{teachersList.length ? (
						teachersList
							.sort((a, b) =>
								!a.email || !b.email
									? 0
									: sort.value === 'asc'
									? a.email.localeCompare(b.email)
									: b.email.localeCompare(a.email)
							)
							.map((teacher) => {
								return (
									<div key={teacher.id} className="content px-4">
										<TeacherCard
											revokeInvitation={revokeInvitation}
											resendInvitation={resendInvitation}
											teacher={teacher}
											key={teacher.id}
											type={tab}
										/>
									</div>
								);
							})
					) : (
						<div className="content py-5 px-4">
							<div className="d-flex align-items-center justify-content-center gap-2 text-shades-500">
								<SvgMask path="/svg/invite.svg" width={24} height={24} />
								<p className="mb-0">
									Press <strong>'Invite Teachers'</strong> to send invitations
								</p>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

interface StaffDashboardTeacher extends StaffDashboardActiveMember, StaffDashboardPendingRequest {}

const TeacherCard: FC<{
	teacher: StaffDashboardTeacher;
	type: TabStatus;
	classList?: ClassListClassCollection;
	revokeInvitation?: (profileId: string) => Promise<void>;
	resendInvitation?: (profileId: string) => Promise<void>;
	removeAdminRole?: (profileId: string) => Promise<void>;
	revokeAccess?: (profileId: string) => Promise<void>;
	makeAdmin?: (profileId: string) => Promise<void>;
	assignClass?: (profileId: string, classId: string) => Promise<void>;
	unassignClass?: (profileId: string, classId: string) => Promise<void>;
	organisation?: AccountUserInfoUserProfile;
}> = ({
	teacher,
	type,
	classList,
	revokeInvitation,
	resendInvitation,
	revokeAccess,
	removeAdminRole,
	makeAdmin,
	assignClass,
	unassignClass,
	organisation
}) => {
	const dispatch = useDispatch();

	const [revokeModal, setRevokeModal] = useState(false);
	const [showReplaceTeacherModal, setShowReplaceTeacherModal] = useState(false);
	const [showOffCanvas, setShowOffCanvas] = useState(false);
	const [showViewTeacherOffCanvas, setShowViewTeacherOffCanvas] = useState(false);
	const [profileDetails, setProfileDetails] = useState<StaffViewStaffMember>();
	const [loading, setLoading] = useState(false);
	const [selectedClass, setSelectedClass] = useState<ClassListClass>();
	const { classes = [] } = teacher;

	const user: UserType = useAppSelector((state) => state.user);

	const getProfileDetails = async () => {
		setLoading(true);

		const response = await apibridge.getStaffView(teacher.id || '');
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setProfileDetails(response.data.result);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Get profile error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setLoading(false);
	};

	const handleToggleModal = () => {
		setRevokeModal(!revokeModal);
	};

	const handleToggleOffCanvas = () => {
		setSelectedClass({});
		setShowOffCanvas(!showOffCanvas);
	};

	const revokeAccessHandler = (id: string) => {
		if (revokeAccess) {
			handleToggleModal();
			revokeAccess(id);
		}
	};

	const removeAdminRoleHandler = (id: string) => {
		if (removeAdminRole) {
			removeAdminRole(id);
		}
	};

	const makeAdminHandler = (id: string) => {
		if (makeAdmin) {
			makeAdmin(id);
		}
	};

	const assignClassHandler = async (id: string, classId: string) => {
		if (assignClass) await assignClass(id || '', classId || '');
		setShowReplaceTeacherModal(false);
		setShowOffCanvas(false);

		dispatch(
			ToastMessagesSlice.actions.add({
				id: guid(),
				type: 'success',
				heading: 'Assign teacher',
				description: 'Teacher assigned successfully.'
			})
		);
	};

	const resendInvitationHandler = (id: string) => {
		if (resendInvitation) {
			resendInvitation(id);
		}
	};

	const handleOpenAssignClassCanvas = () => {
		setShowOffCanvas(!showOffCanvas);
	};

	const handleChangeCanvas = () => {
		setShowViewTeacherOffCanvas(!showViewTeacherOffCanvas);
		setShowOffCanvas(!showOffCanvas);
	};

	const handleShowViewTeacherOffCanvas = () => {
		getProfileDetails();
		setShowViewTeacherOffCanvas(!showViewTeacherOffCanvas);
	};

	const unassignClassHandler = async (classId: string) => {
		if (unassignClass) await unassignClass(teacher.id || '', classId || '');
		setShowViewTeacherOffCanvas(!showViewTeacherOffCanvas);

		dispatch(
			ToastMessagesSlice.actions.add({
				id: guid(),
				type: 'success',
				heading: 'Teacher unassigned from class',
				description: `${teacher.firstName} ${teacher.lastName} has been unassigned.`
			})
		);
	};

	return (
		<>
			<div className="row align-items-center">
				<div className="col-6">
					<div className="d-flex gap-4 align-items-center">
						<div className={`icon ${type === 'Active' ? 'active' : 'pending'}`}>
							<SvgMask path="/svg/Teachers-Hat.svg" width={24} height={24} />
						</div>
						<div className="details">
							{type === 'Active' && (
								<div className="top-row d-flex align-items-center gap-2">
									<button className="btn btn-link" onClick={() => handleShowViewTeacherOffCanvas()}>
										{teacher.firstName} {teacher.lastName}
									</button>

									<div className="tags d-flex gap-2">
										{teacher.isAdmin && <span className="admin badge rounded-pill tag-label">Admin</span>}
										{teacher.isCurrentUser && <span className="you badge rounded-pill tag-label">You</span>}
									</div>
								</div>
							)}
							<div className="bottom-row">
								<p className="email mb-0 text-shades-500 fw-semibold body-small">{teacher.email}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col">
					{type === 'Active' && user.permissions?.includes('ClassList') && (
						<div className="classes text-shades-500">
							<p className="mb-0 ">
								<strong>Assigned classes</strong>
							</p>
							<p className="mb-0 fw-semibold body-small">{classes.map((c) => c.name).join(', ') || 'None'}</p>
						</div>
					)}
				</div>
				<div className="col">
					<div className="actions-wrapper d-flex gap-4 align-items-center justify-content-end text-shades-500">
						<div className="joined">
							{type === 'Active' ? (
								<>
									<p className="mb-0">
										<strong>Joined</strong>
									</p>
									<p className="mb-0 fw-semibold body-small">
										{teacher.joined ? Moment(teacher.joined).format('DD/MM/YYYY') : 'N/A'}
									</p>
								</>
							) : (
								<>
									<p className="mb-0">
										<strong>Invited</strong>
									</p>
									<p className="mb-0 fw-semibold body-small">
										{teacher.invited ? Moment(teacher.invited).format('DD/MM/YYYY') : 'N/A'}
									</p>
								</>
							)}
						</div>
						<div className="actions">
							{user.permissions?.some((permission) =>
								type === 'Active'
									? ['StaffClassAssign', 'AdminRevoke', 'AdminGrant'].includes(permission)
									: ['StaffInviteResend', 'StaffInviteRevoke'].includes(permission)
							) && (
								<Dropdown>
									<Dropdown.Toggle className="btn btn-icon btn-dropdown">
										<SvgMask path="/svg/ellipsis-v.svg" width={24} height={24} />
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{type === 'Active' ? (
											<>
												{user.permissions?.includes('StaffClassAssign') && (
													<Dropdown.Item as={'button'} onClick={() => handleOpenAssignClassCanvas()}>
														Assign a class
													</Dropdown.Item>
												)}
												{user.permissions?.includes('AdminRevoke') && teacher.isAdmin ? (
													<Dropdown.Item as={'button'} onClick={() => removeAdminRoleHandler(teacher.id || '')}>
														Remove Admin rights
													</Dropdown.Item>
												) : (
													user.permissions?.includes('AdminGrant') && (
														<Dropdown.Item as={'button'} onClick={() => makeAdminHandler(teacher.id || '')}>
															Make Admin
														</Dropdown.Item>
													)
												)}
												{user.permissions?.includes('AdminRevoke') && (
													<Dropdown.Item
														as={'button'}
														onClick={() => setRevokeModal(true)}
														className="option-destructive"
													>
														Revoke access
													</Dropdown.Item>
												)}
											</>
										) : (
											<>
												{user.permissions?.includes('StaffInviteResend') && (
													<Dropdown.Item
														as={'button'}
														onClick={() => teacher.id && resendInvitationHandler(teacher.id)}
													>
														Resend Invite
													</Dropdown.Item>
												)}
												{user.permissions?.includes('StaffInviteRevoke') && (
													<Dropdown.Item
														as={'button'}
														onClick={() => revokeInvitation && teacher.id && revokeInvitation(teacher.id)}
														className="option-destructive"
													>
														Revoke Invitation
													</Dropdown.Item>
												)}
											</>
										)}
									</Dropdown.Menu>
								</Dropdown>
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal show={revokeModal} onHide={() => setRevokeModal(false)} centered>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>
							Revoke access for {teacher.firstName} {teacher.lastName}?
						</strong>
					</h2>
					<p className="m-0">
						Are you sure you want to remove{' '}
						<strong>
							{teacher.firstName} {teacher.lastName}
						</strong>{' '}
						from <strong>{organisation?.name}</strong>?
					</p>
					<div className="d-flex button-group">
						<button onClick={handleToggleModal} className="btn btn-white btn-lg w-100">
							Cancel
						</button>
						<button
							onClick={() => teacher.id && revokeAccessHandler(teacher.id)}
							className="btn btn-destructive btn-lg w-100"
						>
							Revoke Access
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={showReplaceTeacherModal} onHide={() => setShowReplaceTeacherModal(false)} centered>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						Replace{' '}
						<strong>
							{selectedClass?.teacher?.firstName} {selectedClass?.teacher?.lastName}
						</strong>{' '}
						as the teacher of <strong>{selectedClass?.name}</strong>?
					</h2>
					<p className="m-0">
						This will remove {selectedClass?.teacher?.firstName} {selectedClass?.teacher?.lastName} as the assigned
						teacher for this class and assign {teacher.firstName} {teacher.lastName} as the new teacher.
						<br />
						Are you sure you want to proceed?
					</p>
					<div className="d-flex button-group">
						<button onClick={() => setShowReplaceTeacherModal(false)} className="btn btn-white btn-lg w-100">
							Cancel
						</button>
						<button
							onClick={() => {
								assignClassHandler(teacher.id || '', selectedClass?.id || '');
								setShowReplaceTeacherModal(false);
								setShowOffCanvas(false);
							}}
							className="btn btn-lg w-100"
						>
							Replace teacher
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Offcanvas
				bsPrefix="class-select offcanvas"
				show={showOffCanvas}
				onHide={() => setShowOffCanvas(false)}
				onExited={() => setSelectedClass(undefined)}
				placement="end"
			>
				<Offcanvas.Header>
					<Offcanvas.Title className="h3">
						Assign a class to{' '}
						<strong>
							{teacher.firstName} {teacher.lastName}
						</strong>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className="form-selection-wrapper">
						{classList?.classes?.length ? (
							<div className="form-selection-wrapper-inner">
								{classList.classes
									.filter((classes) => classes.teacher?.id !== teacher.id)
									.sort((a, b) => {
										const aYear = a.year || '';
										const bYear = b.year || '';
										const aName = a.name || '';
										const bName = b.name || '';
										return aYear.localeCompare(bYear) || aName.localeCompare(bName);
									})
									.map((classItem) => {
										const isSelected = selectedClass?.id === classItem.id;
										return (
											<button
												key={classItem.id}
												type="button"
												onClick={() => setSelectedClass(classItem)}
												className={`btn btn-row-label ${isSelected ? 'selected' : ''}`}
											>
												<strong>{classItem.name}</strong>
												<div className="d-flex align-items-center gap-2">
													<p className={`mb-0 fw-medium ${classItem.teacher ? '' : 'text-shades-400'}`}>
														{classItem.teacher
															? classItem.teacher?.firstName + ' ' + classItem.teacher?.lastName
															: 'Unassigned'}
													</p>
													<div className="selected-icon-wrapper">
														{isSelected ? <SvgMask path="/svg/check.svg" width={16} height={16} /> : null}
													</div>
												</div>
											</button>
										);
									})}
							</div>
						) : null}
					</div>
					<div className="button-group d-flex gap-3">
						<button onClick={handleToggleOffCanvas} className="btn btn-white btn-lg w-100">
							Cancel
						</button>
						<button
							onClick={() => {
								// only show 'replace teacher confirm' modal if the class doesn't have a teacher
								if (selectedClass?.teacher?.id) {
									setShowReplaceTeacherModal(true);
								} else {
									assignClassHandler(teacher.id || '', selectedClass?.id || '');
								}
							}}
							className="btn btn-lg w-100"
							disabled={!selectedClass}
						>
							Assign class
						</button>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
			<Offcanvas
				bsPrefix="view-teacher offcanvas"
				show={showViewTeacherOffCanvas}
				onHide={() => setShowViewTeacherOffCanvas(false)}
				placement="end"
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title className="d-flex flex-column justify-content-center align-items-center w-100 gap-1">
						<div className="icon-wrapper">
							<SvgMask path="/svg/Teachers-Hat.svg" width={48} height={48} />
						</div>
						<p className="text-shades-500 body-tiny mb-0">
							<strong>
								Joined {profileDetails?.joined ? Moment(profileDetails?.joined).format('DD MMM, YYYY') : 'N/A'}
							</strong>
						</p>
						<div className="d-flex align-items-center gap-2">
							<h3 className="text-shades-800 mb-0 fw-semibold">
								{profileDetails?.firstName} {profileDetails?.lastName}
							</h3>
							{profileDetails?.isAdmin && <span className="admin badge rounded-pill tag-label">Admin</span>}
						</div>
						<p className="text-dark-text mb-0">{profileDetails?.email}</p>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body className="d-flex flex-column">
					<p className="body-small text-shades-500 mb-0">
						<strong>Assigned classes</strong>
					</p>
					{loading ? (
						<div className="d-flex align-items-center justify-content-center w-100">
							<DelayedFadeIn>
								<Spinner animation="border" role="status">
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							</DelayedFadeIn>
						</div>
					) : classes.length ? (
						classes.map((classItem) => (
							<ClassCard key={classItem.id} classItem={classItem} unassignClass={unassignClassHandler} />
						))
					) : (
						<div className="class-row">
							<div className="class-icon-wrapper">
								<SvgMask path="/svg/class.svg" width={24} height={24} className="text-shades-400" />
							</div>
							<p className="text-shades-500 mb-0 flex-grow-1">
								<strong>No class assigned</strong>
							</p>
							<button onClick={handleChangeCanvas} className="btn">
								<SvgMask path="/svg/plus.svg" width={16} height={16} />
								<strong>Assign a Class</strong>
							</button>
						</div>
					)}
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
};

interface ClassCardTypes {
	classItem: StaffViewClass | StaffDashboardClass;
	unassignClass: (classId: string) => void;
}

const ClassCard: FC<ClassCardTypes> = ({ classItem, unassignClass }) => {
	const user: UserType = useAppSelector((state) => state.user);

	return (
		<div key={classItem.id} className="class-row flex-column">
			<div className="d-flex justify-content-between align-items-center w-100 position-relative">
				<div className="d-flex flex-column gap-1">
					<h4 className="text-shades-800 mb-0">{classItem.name}</h4>
					<p className="text-shades-500 mb-0 body-small">
						<strong>{classItem.year}</strong>
					</p>
				</div>
				{user.permissions?.some((permission) => ['ClassView', 'StaffClassRemove'].includes(permission)) && (
					<Dropdown>
						<Dropdown.Toggle className="btn btn-icon btn-dropdown rotate-90">
							<SvgMask path="/svg/ellipsis-v.svg" width={24} height={24} />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{user.permissions?.includes('ClassView') && (
								<Dropdown.Item
									href={`/student-management/class/${classItem.id}`}
									className="text-reset text-decoration-none no-pseudo-hover"
								>
									View class
								</Dropdown.Item>
							)}
							{user.permissions?.includes('StaffClassRemove') && (
								<Dropdown.Item as={'button'} onClick={() => unassignClass(classItem.id || '')}>
									Unassign from class
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				)}
			</div>
			<div className="w-100 d-flex align-items-center gap-3">
				<div className="students-icon">
					<SvgMask path="/svg/students-plural.svg" width={24} height={24} />
				</div>
				<div>
					<p className="text-shades-500 mb-0 body-tiny">
						<strong>Students</strong>
					</p>
					<p className="text-dark-text mb-0">
						<strong>{classItem.students}</strong>
					</p>
				</div>
			</div>
		</div>
	);
};

export default TeachersList;
