import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { AudioPlayerContext } from './customContexts';

export function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	const [windowSize, setWindowSize] = useState<{ width: number | undefined; height: number | undefined }>({
		width: undefined,
		height: undefined
	});

	const inProgress = useRef(false);

	useEffect(() => {
		inProgress.current = false;
	}, [windowSize]);

	useEffect(() => {
		// only execute all the code below in client side
		// handler to call on window resize
		function handleResize() {
			if (inProgress.current) return;
			inProgress.current = true;

			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		}

		// use requestAnimationFrame to limit calls
		const requestHandleResize = () => requestAnimationFrame(handleResize);

		// Add event listener
		window.addEventListener('resize', requestHandleResize);

		// Call handler right away so state gets updated with initial window size
		requestHandleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', requestHandleResize);
	}, []);

	return windowSize;
}

export function useWindowScroll() {
	const scrollData = useRef({
		previousX: 0,
		previousY: 0,
		x: 0,
		y: 0
	});

	const [windowScroll, setWindowScroll] = useState({
		previousX: 0,
		previousY: 0,
		x: 0,
		y: 0
	});

	const inProgress = useRef(false);

	useEffect(() => {
		inProgress.current = false;
	}, [windowScroll]);

	useEffect(() => {
		function handleScroll() {
			if (inProgress.current) return;
			inProgress.current = true;

			const scrollX = window.pageXOffset ?? window.scrollX ?? document.documentElement.scrollLeft ?? 0;
			const scrollY = window.pageYOffset ?? window.scrollY ?? document.documentElement.scrollTop ?? 0;

			setWindowScroll({
				previousX: scrollData.current.x,
				previousY: scrollData.current.y,
				x: scrollX,
				y: scrollY
			});

			scrollData.current = {
				previousX: scrollData.current.x,
				previousY: scrollData.current.y,
				x: scrollX,
				y: scrollY
			};
		}

		const requestHandleScroll = () => requestAnimationFrame(() => handleScroll());

		window.addEventListener('scroll', requestHandleScroll);

		requestHandleScroll();

		return () => window.removeEventListener('scroll', requestHandleScroll);
	}, []);

	return windowScroll;
}

export type AudioClipType = {
	audioProps: {
		id: string;
		url: string;
	};
	audioElement: HTMLAudioElement;
};

export const AudioPlayerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	// reference: https://dev.to/amnish04/an-audio-player-hook-for-your-react-app-4gn9
	const [audioQueue, setAudioQueue] = useState<{ id: string; url: string }[]>([]);
	const [audioIsPlaying, setAudioIsPlaying] = useState(false);
	const [currentAudioClip, setCurrentAudioClip] = useState<AudioClipType | null>();

	useEffect(() => {
		if (!audioIsPlaying && audioQueue.length > 0) {
			const { id, url } = audioQueue[0];
			playAudio({ id, url });
		}
	}, [audioQueue, audioIsPlaying]);

	const playAudio = ({ id, url }: { id: string; url: string }) => {
		setAudioIsPlaying(true);
		const audioUrl = url;
		const audio = new Audio(audioUrl);
		audio.preload = 'auto';
		audio.onended = () => {
			URL.revokeObjectURL(audioUrl); // deletes the old audio
			setAudioQueue((currentQueue) => currentQueue.slice(1));
			setAudioIsPlaying(false);
			setCurrentAudioClip(null);
		};
		audio.play();
		setCurrentAudioClip({
			audioProps: {
				id,
				url
			},
			audioElement: audio
		});
	};

	const addToAudioQueue = ({ id, url }: { id: string; url: string }) => {
		setAudioQueue((currentQueue) => [...currentQueue, { id, url }]);
	};

	const clearAudioQueue = () => {
		if (currentAudioClip) {
			// stop currently playing audio and delete it
			// also check to see if it had loaded first, otherwise will cause errors (https://goo.gl/LdLk22)
			currentAudioClip.audioElement.play().then(() => {
				currentAudioClip.audioElement.pause();
				URL.revokeObjectURL(currentAudioClip.audioProps.url);
			});
		}

		setCurrentAudioClip(null);
		setAudioIsPlaying(false);
		// purge all remaining audio clips
		setAudioQueue([]);
	};

	const getAudioQueue = () => audioQueue;
	const getCurrentAudioClip = () => currentAudioClip;

	return (
		<AudioPlayerContext.Provider value={{ addToAudioQueue, clearAudioQueue, getAudioQueue, getCurrentAudioClip }}>
			{children}
		</AudioPlayerContext.Provider>
	);
};

export const useAudioPlayer = () => useContext(AudioPlayerContext);
