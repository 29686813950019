import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Modal } from 'react-bootstrap';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';

// COMPONENTS
import FormCustomError from '../Forms/FormCustomError';
import FormGroupWithError from '../Forms/FormGroupWithError';

// UTILS
import { createErrorsObject, guid } from '../../libs/utils';

// TYPES
import { OrganisationBillingEditCommand, OrganisationBillingEditEditResult } from '../../api/models';
import apibridge from '../../apibridge';
import { ResponseStandardFail } from '../../libs/api';

const EditOrganisationBillingDetails: FC<{
	organisationBillingDetails: OrganisationBillingEditEditResult | undefined;
	getAllOrganisationData: () => Promise<void>;
}> = ({ organisationBillingDetails, getAllOrganisationData }) => {
	const dispatch = useDispatch();

	const [showEditOrganisationBillingDetailsModal, setShowEditOrganisationBillingDetailsModal] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { information, countries = [] } = organisationBillingDetails || {};

	const initialFormValues: OrganisationBillingEditCommand = {
		billingName: information?.billingName || '',
		email: information?.email || '',
		address1: information?.address1 || '',
		address2: information?.address2 || '',
		city: information?.city || '',
		postcode: information?.postcode || '',
		stateProvinceId: information?.stateProvinceId || ''
	};
	const [formValues, setFormValues] = useState<OrganisationBillingEditCommand>(initialFormValues);
	const [selectedCountry, setSelectedCountry] = useState(
		countries?.find((country) => country?.stateProvinces?.some((state) => state.id === information?.stateProvinceId))
	);

	const handleChange = (e: ChangeEvent<HTMLFormElement>) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const submitEditOrganisationBillingDetails = async (
		values: OrganisationBillingEditCommand,
		formikHelpers: FormikHelpers<OrganisationBillingEditCommand>
	) => {
		setIsSubmitting(true);

		const response = await apibridge.postOrganisationBillingEdit(values);
		if (response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				await getAllOrganisationData();
				setShowEditOrganisationBillingDetailsModal(false);
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Organisation billing details updated',
						description: ''
					})
				);
			}
		}

		setIsSubmitting(false);
	};

	return (
		<>
			<div className="row row-gap-4 mt-2">
				<div className="col-6 col-xl-4">
					<button type="button" onClick={() => setShowEditOrganisationBillingDetailsModal(true)} className="btn w-100">
						Edit billing details
					</button>
				</div>
			</div>
			<Modal
				show={showEditOrganisationBillingDetailsModal}
				onHide={() => setShowEditOrganisationBillingDetailsModal(false)}
				onExited={() => setFormValues(initialFormValues)}
				className="modal-change-subscription"
				centered
			>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>Edit billing details</strong>
					</h2>
					<Formik initialValues={formValues} onSubmit={submitEditOrganisationBillingDetails}>
						<FormikForm onChange={handleChange}>
							<div>
								<FormCustomError className="text-start my-2" />

								<FormGroupWithError name="billingName" className="text-start mb-3">
									<label htmlFor="billingName" className="form-label">
										Billing name
									</label>
									<Field type="text" id="billingName" name="billingName" className="form-control" />
								</FormGroupWithError>
								<FormGroupWithError name="email" className="text-start mb-3">
									<label htmlFor="email" className="form-label">
										Billing email
									</label>
									<Field type="email" id="email" name="email" className="form-control" />
								</FormGroupWithError>
								<FormGroupWithError name="address1" className="text-start mb-3">
									<label htmlFor="address1" className="form-label">
										Billing address 1
									</label>
									<Field type="text" id="address1" name="address1" className="form-control" />
								</FormGroupWithError>
								<FormGroupWithError name="address2" className="text-start mb-3">
									<label htmlFor="address2" className="form-label">
										Billing address 2
									</label>
									<Field type="text" id="address2" name="address2" className="form-control" />
								</FormGroupWithError>
								<div className="row g-2-5">
									<div className="col">
										<FormGroupWithError name="city" className="text-start mb-3">
											<label htmlFor="city" className="form-label">
												City
											</label>
											<Field type="text" id="city" name="city" className="form-control" />
										</FormGroupWithError>
									</div>
									<div className="col">
										<FormGroupWithError name="postcode" className="text-start mb-3">
											<label htmlFor="postcode" className="form-label">
												Postcode
											</label>
											<Field type="text" id="postcode" name="postcode" className="form-control" />
										</FormGroupWithError>
									</div>
								</div>

								<div className="row g-2-5">
									<div className="col">
										<div className="form-group text-start mb-3">
											<label htmlFor="country" className="form-label">
												Country
											</label>
											<select
												id="country"
												name="country"
												className="form-select"
												onChange={(e) => {
													const updatedCountry = countries.find((country) => country.id === e.target.value);
													setSelectedCountry(updatedCountry);
												}}
												value={selectedCountry?.id}
											>
												{countries.map((country) => (
													<option key={country.id} value={country.id}>
														{country.name}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="col">
										<FormGroupWithError name="stateProvinceId" className="text-start mb-3">
											<label htmlFor="stateProvinceId" className="form-label">
												State/Province
											</label>
											<Field as="select" id="stateProvinceId" name="stateProvinceId" className="form-select">
												{selectedCountry?.stateProvinces?.map((state) => (
													<option key={state.id} value={state.id}>
														{state.name}
													</option>
												))}
											</Field>
										</FormGroupWithError>
									</div>
								</div>
							</div>
							<div className="d-flex button-group">
								<button
									type="button"
									className="btn btn-white btn-lg w-100"
									onClick={() => setShowEditOrganisationBillingDetailsModal(false)}
								>
									Cancel
								</button>
								<button type="submit" className="btn btn-lg w-100" disabled={isSubmitting}>
									Update details
								</button>
							</div>
						</FormikForm>
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default EditOrganisationBillingDetails;
