import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// API
import apibridge from '../../apibridge';

// COMPONENTS
import TitleSection, { TitleSectionType } from '../../components/TitleSection/TitleSection';
import TeachersList from '../../components/TeachersList/TeachersList';
import SvgMask from '../../components/_Helpers/SvgMask';
import SortDropdown, { SortOptionsType } from '../../components/TitleSection/SortDropdown';

// UTILS
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import { ClassListClassCollection, StaffDashboardActiveMember, StaffDashboardPendingRequest } from '../../api/models';
import { UserType } from '../../store/slice/User';

export type TabStatus = 'Active' | 'Pending';

const Teachers: FC = () => {
	const [tabStatus, setTabStatus] = useState<TabStatus>('Active');
	const [classList, setClassList] = useState<ClassListClassCollection>();
	const [activeTeachers, setActiveTeachers] = useState<Array<StaffDashboardActiveMember>>([]);
	const [pendingTeachers, setPendingTeachers] = useState<Array<StaffDashboardPendingRequest>>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [invitesLeft, setInvitesLeft] = useState(0);

	const dispatch = useAppDispatch();
	const user: UserType = useAppSelector((state) => state.user);
	const isAdmin = user.profiles?.[0].type === 'OrganisationAdministrator';
	const hasAddedStaff = user.profiles?.[0].staffAdded;

	const sortOptions: SortOptionsType[] = [
		{
			value: 'asc',
			label: 'Name A-Z'
		},
		{
			value: 'desc',
			label: 'Name Z-A'
		}
	];

	const [selectedSortOption, setSelectedSortOption] = useState<SortOptionsType>(sortOptions[0]);

	const navigate = useNavigate();

	const getDashboardDetails = async () => {
		const response = await apibridge.getStaffDashboard();
		if (response && response.data && !response.data.isError && response.data.result) {
			const { active = [], pending = [] } = response.data.result;
			setActiveTeachers(active);
			setPendingTeachers(pending);
		}
	};

	const getClassList = async () => {
		const response = await apibridge.getClassList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setClassList(response.data.result);
		}
	};

	const getInvitesLeft = async () => {
		const response = await apibridge.getInvitesLeft();
		if (response && response.data && !response.data.isError && response.data.result) {
			const { invitesLeft = 0, isUnilimited } = response.data.result;
			setInvitesLeft(isUnilimited ? Infinity : invitesLeft);
		}
	};

	const getInitialData = async () => {
		await Promise.all([getDashboardDetails(), getClassList(), getInvitesLeft()]);
		setIsLoading(false);
	};

	useEffect(() => {
		getInitialData();
	}, []); // eslint-disable-line

	// Pending Teachers functions
	const revokeInvitation = async (id: string) => {
		setIsLoading(true);

		const response = await apibridge.deleteStaffInviteRevoke({ profileId: id });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				await Promise.all([getDashboardDetails(), getInvitesLeft()]);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Revoke invitation error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	const resendInvitation = async (id: string) => {
		setIsLoading(true);

		const response = await apibridge.postStaffInviteResend({ profileId: id });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Teacher invitation resent',
						description: 'An invite link has been resent to this email address.'
					})
				);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Resend invitation error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	// Active Teachers functions
	const revokeAccess = async (id: string) => {
		setIsLoading(true);

		const response = await apibridge.deleteStaffDelete({ profileId: id });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				await getDashboardDetails();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: "You cannot remove this Admin's rights.",
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	const removeAdminRole = async (id: string) => {
		setIsLoading(true);

		const response = await apibridge.postAdminRevoke({ profileId: id });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				await getDashboardDetails();
			} else if (response.data.validationErrors) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: "You cannot remove this Admin's rights.",
						description:
							'This user has special permissions as the organisation admin, preventing them from being removed as an admin.'
					})
				);
			}
		}

		setIsLoading(false);
	};

	const makeAdmin = async (id: string) => {
		setIsLoading(true);

		const response = await apibridge.postAdminGrant({ profileId: id });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				await getDashboardDetails();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Make admin error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	const assignClass = async (id: string, classId: string) => {
		setIsLoading(true);

		const response = await apibridge.postStaffClassAssign({ profileId: id, classId });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				await Promise.all([getDashboardDetails(), getClassList()]);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Assign class error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	const unassignClass = async (id: string, classId: string) => {
		setIsLoading(true);

		const response = await apibridge.deleteStaffClassRemove({ profileId: id, classId });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				await Promise.all([getDashboardDetails(), getClassList()]);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Profile error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	const handleInviteTeacherClick = () => {
		if (invitesLeft > 0) {
			navigate('invite');
		} else {
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: 'Your organisation is full',
					description:
						'You have reached the maximum number of seats within your organisation and are unable to invite more staff.'
				})
			);
		}
	};

	const titleSectionProps: TitleSectionType = {
		title: 'Teacher Management',
		description: 'Manage your teachers and staff across the organisation.'
	};
	if (user.permissions?.includes('StaffInvite')) {
		titleSectionProps.primaryButtonText = 'Invite Teachers';
		titleSectionProps.primaryButtonIcon = '/svg/invite.svg';
		titleSectionProps.primaryButtonClick = handleInviteTeacherClick;
		if (isAdmin && !hasAddedStaff) titleSectionProps.primaryButtonClass = 'show-dot';
	}

	return (
		<div className="teachers-page">
			<TitleSection {...titleSectionProps}>
				<div className="d-flex justify-content-between">
					<div className="position-relative w-auto align-items-center">
						<nav className="nav nav-pills flex-row">
							<button
								onClick={() => setTabStatus('Active')}
								className={`nav-pill h4 ${tabStatus === 'Active' ? 'active' : ''}`}
							>
								Active
							</button>
							<button
								onClick={() => setTabStatus('Pending')}
								className={`nav-pill h4 ${tabStatus === 'Pending' ? 'active' : ''}`}
							>
								Pending
							</button>
						</nav>
					</div>
					<div className="sort-container d-flex align-items-center">
						<div className="d-flex align-items-center gap-1 h4 text-nowrap">
							<SvgMask path="/svg/sort.svg" width={24} height={24} />
							<strong>Sort by</strong>
						</div>
						<SortDropdown
							sortOptions={sortOptions}
							selectedSortOption={selectedSortOption}
							setSelectedSortOption={setSelectedSortOption}
						/>
					</div>
				</div>
			</TitleSection>
			<div className="container">
				{isLoading ? (
					<div className="d-flex align-items-center justify-content-center position-relative w-100">
						<DelayedFadeIn>
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</DelayedFadeIn>
					</div>
				) : tabStatus === 'Active' ? (
					<TeachersList
						revokeAccess={revokeAccess}
						removeAdminRole={removeAdminRole}
						makeAdmin={makeAdmin}
						assignClass={assignClass}
						unassignClass={unassignClass}
						teachersList={activeTeachers}
						classList={classList}
						tab={'Active'}
						sort={selectedSortOption}
					/>
				) : tabStatus === 'Pending' ? (
					<TeachersList
						revokeInvitation={revokeInvitation}
						resendInvitation={resendInvitation}
						teachersList={pendingTeachers}
						tab={'Pending'}
						sort={selectedSortOption}
					/>
				) : null}
			</div>
		</div>
	);
};

export default Teachers;
