import React from 'react';

interface SvgMaskTypes {
	width: number;
	height: number;
	color?: string;
	path: string;
	fit?: string;
	noRemConversion?: false;
	className?: string;
}

const SvgMask: React.FC<SvgMaskTypes> = ({ width, height, color, path, fit, noRemConversion, className }) => {
	const maskImage = `url(${path})`;
	const maskPosition = 'center';
	const maskRepeat = 'no-repeat';
	const maskSize = fit || 'contain';

	// automatically coverting PX to REM, unless otherwise specified
	const inlineStyle = {
		display: 'inline-block',
		flexShrink: 0,
		width: noRemConversion ? width : `${width / 16}rem`,
		height: noRemConversion ? height : `${height / 16}rem`,
		backgroundColor: color || 'currentColor',
		maskImage,
		maskPosition,
		maskRepeat,
		maskSize,
		WebkitMaskImage: maskImage,
		WebkitMaskPosition: maskPosition,
		WebkitMaskRepeat: maskRepeat,
		WebkitMaskSize: maskSize
	};

	return <i style={inlineStyle} className={className} />;
};

export default SvgMask;
