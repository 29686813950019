import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { useAppSelector } from '../../store/slice';

// PACKAGES
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Dropdown,
	Modal,
	Offcanvas,
	OverlayTrigger,
	Spinner,
	Tooltip
} from 'react-bootstrap';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import SvgMask from '../../components/_Helpers/SvgMask';
import Divider from '../../components/Divider/Divider';

// UTILS
import CroppedImage from '../../components/_Helpers/CroppedImage';
import { guid } from '../../libs/utils';
import ImgRem from '../../libs/imgRem';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { BookStagesStage, ClassListClass, StudentAdminViewViewResult } from '../../api/models';
import { UserType } from '../../store/slice/User';

const Student: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [, , , studentId] = location.pathname.split('/');

	const { state } = location;
	const [loading, setLoading] = useState(true);

	const [profileDetails, setProfileDetails] = useState<StudentAdminViewViewResult>({
		classId: state?.classId || '', // the class id they entered the page with: '' for archived, or a class id otherwise
		className: state?.className || '',
		firstName: state?.studentDetails?.firstName || '',
		lastName: state?.studentDetails?.lastName || ''
	});
	const [selectedBookStageId, setSelectedBookStageId] = useState('');
	const [classList, setClassList] = useState<ClassListClass[]>();
	const [selectedClassId, setSelectedClassId] = useState('');
	const [bookStages, setBookStages] = useState<BookStagesStage[]>();
	const [activeStageName, setActiveStageName] = useState('');
	const [isAssigningBooks, setIsAssigningBooks] = useState(false);

	const [showArchiveModal, setShowArchiveModal] = useState(false);
	const [showChangeClassModal, setShowChangeClassModal] = useState(false);
	const [showAssignBookModal, setShowAssignBookModal] = useState(false);
	const [showAssignBookSpecificStageModal, setShowAssignBookSpecificStageModal] = useState(false);
	const [showConfirmAssignBookModal, setShowConfirmAssignBookModal] = useState(false);
	const [showUnassignStageModal, setShowUnassignStageModal] = useState(false);
	const [showDeleteStudentModal, setShowDeleteStudentModal] = useState(false);

	const activeStageDetails = profileDetails?.stages?.find((stage) => stage.name === activeStageName);
	const studentIsArchived = !profileDetails?.className;

	const selectedBookStageBooksCount = bookStages?.find((s) => s.id === selectedBookStageId)?.books || '';

	const user: UserType = useAppSelector((state) => state.user);

	const getProfileDetails = async () => {
		const response = await apibridge.getStudentAdminView(studentId);
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setProfileDetails(response.data.result);
				if (!activeStageName && response.data.result.stages?.[0].name)
					setActiveStageName(response.data.result.stages?.[0].name);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Profile error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const getClassList = async () => {
		// get class list
		const response = await apibridge.getClassList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setClassList(response.data.result.classes || []);
		}
	};

	const getBookStages = async () => {
		const response = await apibridge.getBookStages();
		if (response && response.data && !response.data.isError && response.data.result) {
			setBookStages(response.data.result.stages || []);
			if (!activeStageName && response.data.result.stages?.[0].name)
				setActiveStageName(response.data.result.stages?.[0].name);
		}
	};

	const initialise = async () => {
		await Promise.all([getBookStages(), getProfileDetails(), getClassList()]);
		setLoading(false);
	};

	// Assign books
	const handleAssignBooks = async () => {
		setIsAssigningBooks(true);

		const response = await apibridge.postStudentAdminBookAssign({
			studentProfileIds: [studentId],
			stageIds: [selectedBookStageId],
			bookIds: [],
			seriesIds: []
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				getProfileDetails();
				setSelectedBookStageId('');

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Book assignment',
						description: 'Books successfully assigned.'
					})
				);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Book assignment error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsAssigningBooks(false);
		setShowConfirmAssignBookModal(false);
	};

	// Change class
	const handleChangeClass = async () => {
		const response = await apibridge.postStudentAdminClassSwap({
			classId: selectedClassId,
			profileIds: [studentId]
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const className = classList?.find((classItem) => classItem.id === selectedClassId)?.name || '';
				const description = `${profileDetails?.firstName || ''} ${
					profileDetails?.lastName || ''
				} has been transferred to ${className}`;

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: `Class transfer complete`,
						description: description
					})
				);

				// setSelectedClassId(classId); // but why?
				getProfileDetails();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Change class error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setShowChangeClassModal(false);
	};

	// Change class for archived student
	const handleUnarchiveStudent = async () => {
		const response = await apibridge.postStudentAdminUnarchive({
			classId: selectedClassId,
			profileIds: [studentId]
		});

		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const className = classList?.find((classItem) => classItem.id === selectedClassId)?.name || '';
				const description = `${profileDetails?.firstName || ''} ${
					profileDetails?.lastName || ''
				} has been moved to ${className}`;

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: `Student assigned to class`,
						description: description
					})
				);

				// setSelectedClassId(selectedClassId); // but why?
				getProfileDetails();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Change class error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setShowChangeClassModal(false);
	};

	// Archive
	const handleArchiveStudent = async () => {
		const response = await apibridge.deleteStudentAdminArchive({ profileIds: [studentId] });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const description = `${profileDetails?.firstName || ''} ${
					profileDetails?.lastName || ''
				} has been moved to Archive`;

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: `Student archived`,
						description: description
					})
				);

				navigate(`/student-management/class/${profileDetails.classId}`);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Archive student error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
		setShowArchiveModal(false);
	};

	const handleDeleteStudent = async () => {
		const response = await apibridge.deleteStudentAdminDelete({ profileIds: [studentId] });
		if (response && response.data) {
			if (!response.data.isError) {
				const description = `${profileDetails?.firstName || ''} ${profileDetails?.lastName || ''} has been deleted`;

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: `Student deleted`,
						description: description
					})
				);

				navigate(`/student-management/class/${profileDetails.classId}`);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Delete student error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	// Unassign stage
	const handleUnassignStage = async () => {
		const response = await apibridge.postStudentAdminBookUnassign({
			studentProfileId: studentId,
			stageIds: [activeStageDetails?.id || '']
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				getProfileDetails();

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Book assignment',
						description: 'All books in stage successfully unassigned.'
					})
				);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Unassign stage error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
		setShowUnassignStageModal(false);
	};

	const handleUnassignBook = async (bookId: string) => {
		const response = await apibridge.postStudentAdminBookUnassign({
			studentProfileId: studentId,
			bookIds: [bookId || '']
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				getProfileDetails();

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Book assignment',
						description: 'Book successfully unassigned.'
					})
				);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Unassign book error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	useEffect(() => {
		initialise();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="student-details-page d-flex flex-column flex-grow-1">
			{loading ? (
				<div className="d-flex align-items-center justify-content-center flex-grow-1">
					<DelayedFadeIn>
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</DelayedFadeIn>
				</div>
			) : (
				<>
					<TitleSection
						backToText={studentIsArchived ? 'Back to student archive' : 'Back to student list'}
						backToUrl={
							studentIsArchived
								? '/student-management/archived-students'
								: `/student-management/class/${profileDetails.classId}`
						}
						dynamicTitle={
							<div className="d-flex align-items-center gap-3-5">
								<h1 className="text-shades-800 title m-0">
									<strong>
										{profileDetails?.firstName || ''} {profileDetails?.lastName || ''}
									</strong>
								</h1>
								{user.permissions?.some((permission) =>
									(studentIsArchived
										? ['StudentAdminUnarchive', 'StudentAdminDelete']
										: ['StudentAdminBookAssign', 'StudentAdminClassSwap', 'StudentAdminArchive']
									).includes(permission)
								) && (
									<Dropdown>
										<OverlayTrigger placement="top" overlay={<Tooltip>More</Tooltip>}>
											<Dropdown.Toggle className="btn btn-sm btn-icon btn-dropdown hovered-style rotate-90">
												<SvgMask path="/svg/ellipsis-v.svg" width={24} height={24} />
											</Dropdown.Toggle>
										</OverlayTrigger>
										<Dropdown.Menu>
											{studentIsArchived ? (
												<>
													{user.permissions?.includes('StudentAdminUnarchive') && (
														<Dropdown.Item as="button" onClick={() => setShowChangeClassModal(true)}>
															Assign to class
														</Dropdown.Item>
													)}
													{user.permissions?.includes('StudentAdminDelete') && (
														<Dropdown.Item
															as="button"
															onClick={() => setShowDeleteStudentModal(true)}
															className="option-destructive"
														>
															Delete student
														</Dropdown.Item>
													)}
												</>
											) : (
												<>
													{user.permissions?.includes('StudentAdminBookAssign') && (
														<Dropdown.Item as="button" onClick={() => setShowAssignBookModal(true)}>
															Assign books
														</Dropdown.Item>
													)}
													{user.permissions?.includes('StudentAdminClassSwap') && (
														<Dropdown.Item as="button" onClick={() => setShowChangeClassModal(true)}>
															Change class
														</Dropdown.Item>
													)}
													{user.permissions?.includes('StudentAdminArchive') && (
														<Dropdown.Item
															as="button"
															onClick={() => setShowArchiveModal(true)}
															className="option-destructive"
														>
															Archive student
														</Dropdown.Item>
													)}
												</>
											)}
										</Dropdown.Menu>
									</Dropdown>
								)}
							</div>
						}
						dynamicDescription={
							<div className="d-flex align-items-center gap-3-5">
								<div className="d-flex align-items-center gap-2">
									<SvgMask path="/svg/class.svg" width={24} height={24} />
									<p className="mb-0">
										<strong>{studentIsArchived ? 'Archived' : profileDetails?.className}</strong>
									</p>
								</div>
								<div className="vr text-shades-400"></div>
								<p className="mb-0">
									<strong>
										<span className="text-shades-500 me-2">Username:</span>
										{profileDetails?.username}
									</strong>
								</p>
								<div className="vr text-shades-400"></div>
								<p className="mb-0">
									<strong>
										<span className="text-shades-500 me-2">Password:</span>
										{profileDetails?.password}
									</strong>
								</p>
							</div>
						}
					>
						{/* Stages */}
						<div className="d-flex align-items-end gap-1 stage-tab-wrapper">
							{bookStages?.map((stage) => {
								const { id = '', name = '' } = stage;
								const stageIsAssigned = profileDetails?.stages?.some((s) => s.name === name);
								const stageIsActive = stage.name === activeStageName;
								return (
									<button
										key={id}
										onClick={() => setActiveStageName(name)}
										className={`btn stage-tab d-flex align-items-center gap-2 ${
											stageIsActive ? `active` : ''
										} bg-stage-${name} ${stageIsAssigned ? 'assigned' : ''}`}
									>
										<p className="mb-0">
											<strong>{name}</strong>
										</p>
									</button>
								);
							})}
						</div>
					</TitleSection>
					<div
						className={`container content d-flex flex-column flex-grow-1 gap-3-5 stage-${
							activeStageDetails?.name || activeStageName
						}`}
					>
						{/* Title Bar */}
						<div className={`d-flex align-items-center justify-content-between title-bar `}>
							<h2>
								<strong>Stage {activeStageDetails?.name || activeStageName}</strong>
							</h2>
							{studentIsArchived ? null : activeStageDetails?.id ? (
								<button onClick={() => setShowUnassignStageModal(true)} className="btn btn-white btn-title-unassign">
									Unassign all books in Stage <SvgMask path="/svg/book-remove.svg" height={16} width={16} />
								</button>
							) : (
								<button
									onClick={() => setShowAssignBookSpecificStageModal(true)}
									className="btn btn-white btn-title-assign"
								>
									Assign Books <SvgMask path="/svg/book-add.svg" height={16} width={16} />
								</button>
							)}
						</div>

						{activeStageDetails?.serieses?.length ? (
							activeStageDetails.serieses.map((series) => (
								<div key={series.id} className="series-row">
									<h3 className="series-title py-3 px-4 text-shades-800 m-0 align-content-center">
										<strong>{series.name}</strong>
									</h3>
									<div className="booklist">
										<Accordion className="w-100 pb-2 px-2 d-flex flex-column gap-2" alwaysOpen>
											{series.books?.map((book) => (
												<div key={book.id} className="d-flex gap-2">
													<AccordionItem
														eventKey={book.id || ''}
														className={`d-flex flex-grow-1 flex-column ${!book.activities?.length ? 'disabled' : ''}`}
													>
														<AccordionHeader>
															<div className="accordion-header-custom-width-alignment">
																<div className="row row-gap-3">
																	<div className="col-6 col-lg">
																		<div className="d-flex align-items-center gap-3-5 h-100 book-title">
																			<div className="image-or-text-start-wrapper">
																				{book.coverUrl && (
																					<CroppedImage
																						src={book.coverUrl}
																						width={48}
																						height={60}
																						className="book-image rounded-1 object-fit-contain flex-shrink-0"
																						alt=""
																					/>
																				)}
																			</div>
																			<strong>{book.name}</strong>
																		</div>
																	</div>
																	<div className="col-6 col-lg">
																		<div
																			className={`d-flex align-items-center gap-2 h-100 progress-${
																				book.status ? book.status?.toLowerCase() : book.status
																			}`}
																			style={{ '--progress-variable': `${book.progress}` } as React.CSSProperties}
																		>
																			<span
																				className={`status-icon ${
																					book.status === 'InProgress' ? 'status-icon-dynamic' : ''
																				}`}
																			></span>

																			<strong>
																				{book.status === 'Pending'
																					? 'Not started'
																					: book.status === 'InProgress'
																					? 'In progress'
																					: book.status}
																			</strong>
																		</div>
																	</div>
																	<div className="col-6 col-lg">
																		<div className="d-flex align-items-center gap-2 h-100 activity-score">
																			{book.activityScore ? (
																				<strong>
																					<span className="text-shades-500 me-2">Activity Score:</span>
																					{book.activityScore}%
																				</strong>
																			) : null}
																		</div>
																	</div>
																	<div className="col-6 col-lg">
																		<div className="d-flex align-items-center gap-2 h-100">
																			<p className="text-shades-500 mb-0">
																				<strong>Rating:</strong>
																			</p>
																			<div className="d-flex align-items-center gap-1">
																				{book.emotion === 'Happy' ? (
																					<ImgRem src="/svg/happy.svg" height={24} width={24} alt="Happy face" />
																				) : (
																					<span className="dot"></span>
																				)}
																				{book.emotion === 'Confused' ? (
																					<ImgRem src="/svg/okay.svg" height={24} width={24} alt="OK face" />
																				) : (
																					<span className="dot"></span>
																				)}
																				{book.emotion === 'Unhappy' ? (
																					<ImgRem src="/svg/sad.svg" height={24} width={24} alt="Unhappy face" />
																				) : (
																					<span className="dot"></span>
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</AccordionHeader>

														<AccordionBody>
															{book.activities?.map((activity, activityIndex) => (
																<div key={activityIndex} className="activity-wrapper d-flex align-items-center">
																	<div className="accordion-body-custom-width-alignment">
																		<div key={activity.id} className="row row-gap-3 row-activity">
																			<div className="col-12 col-lg-6">
																				<div className="d-flex align-items-center gap-3-5 h-100">
																					<div className="image-or-text-start-wrapper">
																						<p className="mb-0 text-vivid-blue">
																							<strong>Q{activityIndex + 1}.</strong>
																						</p>
																					</div>
																					<p className="mb-0 activity-name">
																						<strong>{activity.name}</strong>
																					</p>
																				</div>
																			</div>
																			<div className="col-6 col-lg">
																				<div
																					className={`d-flex align-items-center gap-2 h-100 status-bar ${
																						activity.status === 'Successful'
																							? 'correct'
																							: activity.status === 'Unsuccessful'
																							? 'incorrect'
																							: ''
																					}`}
																				>
																					{activity.status && activity.status !== 'InProgress' ? (
																						<div className="activity-status-icon"></div>
																					) : null}
																					<p className="mb-0">
																						<strong>
																							{activity.status === 'Successful'
																								? 'Correct'
																								: activity.status === 'Unsuccessful'
																								? 'Incorrect'
																								: ''}
																						</strong>
																					</p>
																				</div>
																			</div>
																			<div className="col-6 col-lg">
																				<div className="d-flex align-items-center h-100">
																					<p className="mb-0">
																						{activity.attemptsCount !== 0 ? (
																							<strong>
																								<span className="text-shades-500 me-2">Attempts:</span>
																								{activity.attemptsCount}
																							</strong>
																						) : null}
																					</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															))}
														</AccordionBody>
													</AccordionItem>
													{studentIsArchived ? null : (
														<OverlayTrigger placement="top" overlay={<Tooltip id="b-1">Unassign book</Tooltip>}>
															<button onClick={() => handleUnassignBook(book.id || '')} className="btn btn-unassign">
																<SvgMask path="/svg/book-remove.svg" width={24} height={24} />
															</button>
														</OverlayTrigger>
													)}
												</div>
											))}
										</Accordion>
									</div>
								</div>
							))
						) : (
							<div className="d-flex align-items-center justify-content-center empty-state">
								<SvgMask path="/svg/book-add.svg" width={16} height={16} />
								<p className="mb-0">No books in Stage assigned.</p>
							</div>
						)}
					</div>
					{/* Archive student modal */}
					<Modal show={showArchiveModal} onHide={() => setShowArchiveModal(false)} centered>
						<Modal.Body>
							<h2 className="m-0 text-shades-800">
								<strong>Archive {profileDetails?.firstName + ' ' + profileDetails?.lastName}?</strong>
							</h2>
							<p className="m-0">
								This will move this student to <strong>Archived students.</strong> <br /> <br /> Archived students will
								be removed from the platform after 30 days of their archival. Before this, you can reassign them to
								another class.
							</p>
							<div className="button-group d-flex">
								<button onClick={() => setShowArchiveModal(false)} className="btn btn-lg btn-white w-50">
									Cancel
								</button>
								<button onClick={handleArchiveStudent} className="btn btn-lg w-50">
									Archive student
								</button>
							</div>
						</Modal.Body>
					</Modal>
					{/* Delete student modal */}
					<Modal show={showDeleteStudentModal} onHide={() => setShowDeleteStudentModal(false)} centered>
						<Modal.Body>
							<h2 className="m-0 text-shades-800">
								<strong>Delete student</strong>
							</h2>
							<p className="m-0">
								This will permanently delete this student from the organisation.
								<br />
								<br />
								Are you sure you want to continue?
							</p>
							<div className="button-group d-flex">
								<button onClick={() => setShowDeleteStudentModal(false)} className="btn btn-lg btn-white w-50">
									Cancel
								</button>
								<button onClick={handleDeleteStudent} className="btn btn-lg btn-destructive w-50">
									Delete student
								</button>
							</div>
						</Modal.Body>
					</Modal>
					{/* Assign Books modal */}
					<Modal show={showAssignBookModal} onHide={() => setShowAssignBookModal(false)} centered>
						<Modal.Body>
							<button
								type="button"
								className="btn-close close"
								aria-label="Close"
								onClick={() => setShowAssignBookModal(false)}
							></button>
							<h2 className="text-shades-800">
								<strong>Select a stage</strong>
							</h2>
							<div className="d-flex gap-3 flex-wrap">
								{bookStages?.map((stage) => {
									return (
										<button
											key={stage.id}
											className={`pill stage-${stage.name} dark stage-pill`}
											onClick={() => {
												setSelectedBookStageId(stage.id || '');
												setShowAssignBookModal(false);
												setShowConfirmAssignBookModal(true);
											}}
										>
											Stage {stage.name}
										</button>
									);
								})}
							</div>
							<Divider className="milo-blue" />
							<Link
								state={{ selectedStudents: [studentId], classId: profileDetails?.classId }}
								to={'/student-management/assign-books'}
								className="btn btn-white btn-lg"
							>
								<SvgMask path="/svg/book-az.svg" width={16} height={16} />
								Select individual books
							</Link>
						</Modal.Body>
					</Modal>
					{/* Assign Books to Specific Stage modal */}
					<Modal
						show={showAssignBookSpecificStageModal}
						onHide={() => setShowAssignBookSpecificStageModal(false)}
						centered
					>
						<Modal.Body>
							<button
								type="button"
								className="btn-close close"
								aria-label="Close"
								onClick={() => setShowAssignBookSpecificStageModal(false)}
							></button>
							<h2 className="text-shades-800">
								<strong>Assign Stage {activeStageName}</strong>
							</h2>

							<button
								className={`btn btn-white btn-lg`}
								onClick={() => {
									setSelectedBookStageId(bookStages?.find((stage) => stage.name === activeStageName)?.id || '');
									setShowAssignBookSpecificStageModal(false);
									setShowConfirmAssignBookModal(true);
								}}
							>
								<SvgMask path="/svg/book-az.svg" width={16} height={16} />
								Select all books
							</button>

							<Divider className="milo-blue" />
							<Link
								state={{ selectedStudents: [studentId], classId: profileDetails?.classId }}
								to={'/student-management/assign-books'}
								className="btn btn-white btn-lg"
							>
								<SvgMask path="/svg/book-az.svg" width={16} height={16} />
								Select individual books
							</Link>
						</Modal.Body>
					</Modal>
					{/* Confirm Assign Books modal */}
					<Modal show={showConfirmAssignBookModal} onHide={() => setShowConfirmAssignBookModal(false)} centered>
						<Modal.Body>
							<h2 className="text-shades-800">
								<strong>Assign books to student?</strong>
							</h2>
							{selectedBookStageBooksCount ? (
								<p>
									You are about to assign{' '}
									<strong>
										{selectedBookStageBooksCount} {selectedBookStageBooksCount === 1 ? 'book' : 'books'}
									</strong>{' '}
									to <strong>1 student</strong> and their eLibrary will be updated accordingly.
								</p>
							) : (
								<p>There are no books available in this stage.</p>
							)}
							<div className="button-group d-flex justify-content-center">
								<button
									onClick={() => {
										setShowConfirmAssignBookModal(false);
									}}
									className="btn btn-lg btn-white w-50"
								>
									Cancel
								</button>
								{selectedBookStageBooksCount && (
									<button onClick={handleAssignBooks} className="btn btn-lg w-50" disabled={isAssigningBooks}>
										Assign books
									</button>
								)}
							</div>
						</Modal.Body>
					</Modal>

					{/* Transfer student off canvas modal */}
					<Offcanvas
						bsPrefix="class-select offcanvas"
						scroll
						show={showChangeClassModal}
						onHide={() => setShowChangeClassModal(false)}
						placement="end"
					>
						<Offcanvas.Header>
							<Offcanvas.Title className="h3 text-shades-800">
								<strong>Transfer student to a different class</strong>
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<div className="form-selection-wrapper">
								{classList?.length ? (
									<div className="form-selection-wrapper-inner">
										{classList
											// don't include the class that's already there
											.filter((classItem) => classItem.id !== profileDetails?.classId)
											.sort((a, b) => {
												const aYear = a.year || '';
												const bYear = b.year || '';
												const aName = a.name || '';
												const bName = b.name || '';
												return aYear.localeCompare(bYear) || aName.localeCompare(bName);
											})
											.map((classItem) => {
												const isSelected = selectedClassId === classItem.id;
												return (
													<button
														key={classItem.id}
														type="button"
														onClick={() => setSelectedClassId(classItem.id || '')}
														className={`btn btn-row-label ${isSelected ? 'selected' : ''}`}
													>
														<strong>{classItem.name}</strong>
														<div className="d-flex align-items-center gap-2">
															<div className="selected-icon-wrapper">
																{isSelected ? <SvgMask path="/svg/check.svg" width={16} height={16} /> : null}
															</div>
														</div>
													</button>
												);
											})}
									</div>
								) : null}
							</div>
							<div className="button-group d-flex gap-3">
								<button
									type="button"
									onClick={() => setShowChangeClassModal(false)}
									className="btn btn-white btn-lg w-100"
								>
									Cancel
								</button>
								<button
									type="button"
									onClick={studentIsArchived ? handleUnarchiveStudent : handleChangeClass}
									disabled={!selectedClassId}
									className="btn btn-lg w-100"
								>
									Change Class
								</button>
							</div>
						</Offcanvas.Body>
					</Offcanvas>

					{/* Unassign Stage modal */}
					<Modal show={showUnassignStageModal} onHide={() => setShowUnassignStageModal(false)} centered>
						<Modal.Body>
							<h2 className="m-0 text-shades-800">
								<strong>Unassign Stage {activeStageDetails?.name}?</strong>
							</h2>
							<p className="m-0">
								This will remove all books in <strong>Stage {activeStageDetails?.name}</strong> from the student's
								eLibrary.
							</p>
							<div className="button-group d-flex gap-2">
								<button
									onClick={() => {
										setShowUnassignStageModal(false);
									}}
									className="btn btn-white btn-lg w-50"
								>
									Cancel
								</button>
								<button onClick={handleUnassignStage} className="btn btn-destructive btn-lg w-50 text-nowrap">
									Unassign all books in Stage
								</button>
							</div>
						</Modal.Body>
					</Modal>
				</>
			)}
		</div>
	);
};

export default Student;
