import { FC } from 'react';
import { Link, Location, Outlet, useLocation, useNavigate } from 'react-router-dom';

// REDUX
import { useAppDispatch, useAppSelector } from '../../../store/slice';
import { UserSlice, UserType } from '../../../store/slice/User';

// UTILS
import SvgMask from '../../_Helpers/SvgMask';
import ImgRem from '../../../libs/imgRem';
import FTUX from '../../Ftux/Ftux';
// import PermissionsToggler from '../../../libs/PermissionsToggler';

const SideNavigationLink: FC<{
	id: string;
	label: string;
	urlPath: string;
	iconPath: string;
	location: Location<any>;
	user: UserType;
}> = ({ id = '', label, urlPath, iconPath, location, user }) => {
	const isActiveLink = location.pathname.includes(urlPath);
	const [userProfile = {}] = user.profiles || [];
	const showDot =
		(userProfile.type === 'OrganisationAdministrator' && !userProfile.staffAdded && id === 'link-teachers') ||
		(userProfile.type === 'Staff' && !userProfile.studentsAdded && id === 'link-students');

	return (
		<Link
			id={id}
			className={`btn nav-link-text text-start ${isActiveLink ? 'active' : ''}`}
			to={urlPath}
			reloadDocument={isActiveLink}
		>
			<div className={`icon-wrapper d-flex align-items-center ${showDot ? 'show-dot' : ''}`}>
				<SvgMask path={iconPath} width={20} height={20} />
			</div>
			{label}
		</Link>
	);
};

const LayoutStaffDashboard: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();

	const user: UserType = useAppSelector((state) => state.user);

	const handleLogOut = () => {
		dispatch(UserSlice.actions.logOut());
		navigate('/login');
	};

	return (
		<div className="layout-staff-dashboard position-absolute w-100 h-100 d-flex">
			{/* Side Navigation */}
			<nav className="side-nav">
				<a href="/" className="logo-link">
					<ImgRem
						src="/svg/llll-online-wide-logo.svg"
						width="130"
						height="40"
						alt="Little Learners Love Literacy Online logo"
						className="logo mx-auto"
					/>
				</a>
				<div className="main-links-wrapper d-flex flex-column justify-space-between overflow-y-auto p-2">
					<div className="main-links flex-grow-1">
						{user.permissions?.some((permission) =>
							['PlannerWeek', 'RoutineGroups', 'BookList'].includes(permission)
						) && (
							<div className="pt-1 pb-2 d-flex flex-column">
								<div className="label-navigation text-hero-blue p-2">Teach</div>
								{user.permissions?.includes('PlannerWeek') && (
									<SideNavigationLink
										id="link-plans"
										label="Plans"
										urlPath="/plans"
										iconPath="/svg/plans-board.svg"
										location={location}
										user={user}
									/>
								)}
								{user.permissions?.includes('RoutineGroups') && (
									<SideNavigationLink
										id="link-routines"
										label="Routines"
										urlPath="/routines"
										iconPath="/svg/routines-class.svg"
										location={location}
										user={user}
									/>
								)}
								{user.permissions?.includes('BookList') && (
									<SideNavigationLink
										id="link-elibrary"
										label="eLibrary"
										urlPath="/elibrary"
										iconPath="/svg/library-read.svg"
										location={location}
										user={user}
									/>
								)}
							</div>
						)}
						{user.permissions?.some((permission) => ['ClassList', 'StaffDashboard'].includes(permission)) && (
							<div className="pt-1 pb-2 d-flex flex-column">
								<div className="label-navigation text-hero-blue p-2">Manage</div>
								{user.permissions?.includes('ClassList') && (
									<SideNavigationLink
										id="link-students"
										label="Students"
										urlPath="/student-management"
										iconPath="/svg/students-smile.svg"
										location={location}
										user={user}
									/>
								)}
								{user.permissions?.includes('StaffDashboard') && (
									<SideNavigationLink
										id="link-teachers"
										label="Teachers"
										urlPath="/teacher-management"
										iconPath="/svg/teachers-hat.svg"
										location={location}
										user={user}
									/>
								)}
							</div>
						)}
						{user.permissions?.some((permission) =>
							['ResourceFavorite', 'ProfessionalLearningList', 'HelpCentreList'].includes(permission)
						) && (
							<div className="pt-1 pb-2 d-flex flex-column">
								<div className="label-navigation text-hero-blue p-2">Support</div>
								{user.permissions?.includes('ResourceFavorite') && (
									<SideNavigationLink
										id="link-resources"
										label="Resources"
										urlPath="/resources"
										iconPath="/svg/resources-stack.svg"
										location={location}
										user={user}
									/>
								)}
								{user.permissions?.includes('ProfessionalLearningList') && (
									<SideNavigationLink
										id="link-professional-learning"
										label="Professional Learning"
										urlPath="/professional-learning"
										iconPath="/svg/skills-target.svg"
										location={location}
										user={user}
									/>
								)}
								{user.permissions?.includes('HelpCentreList') && (
									<SideNavigationLink
										id="link-help-centre"
										label="Help Centre"
										urlPath="/help-centre"
										iconPath="/svg/help-book.svg"
										location={location}
										user={user}
									/>
								)}
							</div>
						)}
					</div>
					<div className="secondary-links">
						<div className="pt-1 pb-2 d-flex flex-column">
							<SideNavigationLink
								id="link-settings"
								label="Settings"
								urlPath="/settings"
								iconPath="/svg/settings.svg"
								location={location}
								user={user}
							/>
							{/* Used html button instead of react-bootstrap, to remove the btn class and make it look like a link */}
							<button type="button" className="btn nav-link-text" onClick={handleLogOut}>
								<SvgMask path={'/svg/sign-out.svg'} width={20} height={20} /> Log out
							</button>
						</div>
					</div>
				</div>
			</nav>

			{/* Main Content */}
			<main id="main-content" className="main-content d-flex flex-column flex-grow-1 overflow-auto">
				{/* NOTE: This is useful for debugging permissions, and allows them to be switched on/off */}
				{/* For debugging only! */}
				{/* <PermissionsToggler /> */}
				<Outlet />
				{user.loggedIn && user.id && !user.ftuxCompleted && <FTUX user={user} />}
			</main>
		</div>
	);
};

export default LayoutStaffDashboard;
