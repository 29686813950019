import { FC, useEffect, useState, MouseEvent as ReactMouseEvent, CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/slice';

// PACKAGES
import { Modal, Spinner } from 'react-bootstrap';

// COMPONENTS
import { BooksBottom, SideUpDownButtons } from '../../../components/_Layout/LayoutStudent/LayoutStudent';
import StudentELibraryBook from '../../../components/Book/StudentElibraryBook';

// UTILS
import SvgMask from '../../../components/_Helpers/SvgMask';
import { getAndReturnBookData } from '../../BookReader/BookReader';
import ImgRem from '../../../libs/imgRem';
import DelayedFadeIn from '../../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../../apibridge';
import { BookListListResult, StudentLibraryBook } from '../../../api/models';

const StudentELibrary: FC = () => {
	const dispatch = useDispatch();
	const systemInfo = useAppSelector((state) => state.systemInfo);

	const [isLoading, setIsLoading] = useState(true);
	const [keepReadingModalData, setKeepReadingModalData] = useState<{
		bookStartUrl: string;
		resumeUrl: string;
	}>();

	const [, setStudentELibraryData] = useState<BookListListResult>();
	const [allBooks, setAllBooks] = useState<StudentLibraryBook[][]>([]);

	const handleBookClick = async (e: ReactMouseEvent, book: StudentLibraryBook, url: string) => {
		const { currentActivityId, currentPageId, id = '' } = book;

		if ((book.status !== 'Complete' && currentActivityId) || currentPageId) {
			// if it's possible to resume, get the book data and check the activities / pages to determine the URL
			e.preventDefault();
			const bookData = await getAndReturnBookData(id, dispatch, systemInfo);

			if (currentActivityId) {
				// eg: https://localhost:44466/elibrary/f0b8bc80-0fe1-4834-b522-5d237e2144ca/quiz/2
				const activityNumber = bookData?.activities?.findIndex((activity) => activity.id === currentActivityId) || 0;
				setKeepReadingModalData({ bookStartUrl: url, resumeUrl: `${url}/quiz/${activityNumber + 1}` });
			} else if (currentPageId) {
				// eg: https://localhost:44466/elibrary/f0b8bc80-0fe1-4834-b522-5d237e2144ca#3
				const bookPageNumber = bookData?.pages?.findIndex((page) => page.id === currentPageId);
				setKeepReadingModalData({
					bookStartUrl: url,
					resumeUrl: `${url}${bookPageNumber !== undefined ? '#' + bookPageNumber : ''}`
				});
			}
		}
	};

	const getStudentELibraryData = async () => {
		const response = await apibridge.getStudentLibrary();
		if (response && response.data && !response.data.isError && response.data.result) {
			setStudentELibraryData(response.data.result);
			const mappedBooks: StudentLibraryBook[][] = [];
			const { stages = [] } = response.data.result;
			let shelfIndex = 0;

			for (const stage of stages) {
				const { serieses = [] } = stage;
				for (const series of serieses) {
					const { books = [] } = series;
					for (const book of books) {
						// books to be split by sets of 5
						if (!mappedBooks[shelfIndex]) mappedBooks[shelfIndex] = [];
						if (mappedBooks[shelfIndex].length === 5) {
							mappedBooks.push([]);
							shelfIndex++;
						}
						mappedBooks[shelfIndex].push(book);
					}
				}
			}
			setAllBooks(mappedBooks);
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getStudentELibraryData();
	}, []);

	return (
		<div className="page-student-elibrary d-flex flex-column flex-grow-1">
			{isLoading ? (
				<div className="d-flex justify-content-center">
					<DelayedFadeIn>
						<Spinner />
					</DelayedFadeIn>
				</div>
			) : (
				<>
					<section className="d-flex flex-column flex-grow-1">
						{allBooks.length > 0 && <SideUpDownButtons />}
						<div className="container-fluid container-elibrary">
							<div className="book-shelves d-flex flex-column">
								{allBooks.length ? (
									allBooks.map((bookRow, bookRowIndex) => (
										<div key={bookRowIndex} className="position-relative">
											<div className="book-rows d-flex">
												{bookRow.map((book) => {
													const {
														currentActivityId = '',
														currentPageId = '',
														coverUrl = '',
														id = '',
														name = '',
														progress,
														status
													} = book;
													const isComplete = status === 'Complete';
													// TODO: URL should use slug rather than id, BED could help with this
													const url = `/elibrary/${id}`;
													return (
														<a
															key={id}
															href={url}
															className="book-link position-relative d-block text-center"
															onClick={(e) => {
																// open the 'Keep reading from' modal
																if ((currentActivityId && !isComplete) || currentPageId) handleBookClick(e, book, url);
															}}
														>
															{progress !== 0 && (
																<div
																	className={`progress-bar position-absolute start-50 translate-middle-x pe-none ${
																		isComplete ? 'is-complete' : ''
																	}`}
																	style={{ '--book-progress': `${progress}%` } as CSSProperties}
																>
																	{isComplete && (
																		<img
																			src="/svg/complete.svg"
																			className="img-complete position-absolute top-50 start-50 translate-middle z-1"
																			alt="Complete"
																		/>
																	)}
																</div>
															)}
															<StudentELibraryBook imgUrl={coverUrl} imgAlt={name} />
														</a>
													);
												})}
											</div>
											<img src="/svg/shelf.svg" className="shelf-bottom-image" alt="Shelf bottom" />
										</div>
									))
								) : (
									<div className="d-flex flex-column align-items-center gap-4-5 text-center my-5">
										<ImgRem src="/svg/library-empty.svg" width="320" height="241" alt="Ghost and bookshelf" />
										<h3 className="h1 text-shades-400">No books found</h3>
									</div>
								)}
							</div>
						</div>
					</section>
					<BooksBottom />
					<Modal
						className="modal-student-dashboard"
						show={keepReadingModalData !== undefined}
						onHide={() => setKeepReadingModalData(undefined)}
						size="lg"
						centered
					>
						<Modal.Body className="d-flex flex-column gap-4-5">
							<button
								type="button"
								className="btn-reset close"
								aria-label="Close"
								onClick={() => setKeepReadingModalData(undefined)}
							>
								<SvgMask path="/svg/cross.svg" width={16} height={16} />
							</button>
							<h2 className="h1 m-0 text-shades-800">
								<strong>Keep reading from</strong>
							</h2>
							<div className="modal-button-group d-flex gap-4-5">
								<a href={keepReadingModalData?.bookStartUrl} className="btn">
									<ImgRem src="/svg/twemoji-book.svg" width="96" height="96" alt="Person exiting door" />
									<h3 className="m-0">
										<strong>Start of book</strong>
									</h3>
								</a>
								<a href={keepReadingModalData?.resumeUrl} className="btn">
									<ImgRem src="/svg/twemoji-open-book.svg" width="96" height="96" alt="Person reading book" />
									<h3 className="m-0">
										<strong>Where I left off</strong>
									</h3>
								</a>
							</div>
						</Modal.Body>
					</Modal>
				</>
			)}
		</div>
	);
};

export default StudentELibrary;
