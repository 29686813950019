import { ChangeEvent, FC, useState } from 'react';

// REDUX
// import { useAppDispatch } from '../../store/slice';

// PACKAGES
import { Container, InputGroup } from 'react-bootstrap';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';

// API
import apibridge from '../../../apibridge';
import { ResponseStandardFail } from '../../../libs/api';
import { AccountCreateCommand } from '../../../api/models';

// UTILS
import { createErrorsObject } from '../../../libs/utils';
import SvgMask from '../../../components/_Helpers/SvgMask';
import ImgRem from '../../../libs/imgRem';

// COMPONENTS
import RecaptchaDisclaimer from '../../../components/_Samples/RecaptchaDisclaimer';
import FormGroupWithError from '../../../components/Forms/FormGroupWithError';
import FormCustomError from '../../../components/Forms/FormCustomError';

// --------------------------------------------------
// NOTE: This is post MVP, and will be coming later
// --------------------------------------------------

const JoinOrganisation: FC = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
	const [formValues, setFormValues] = useState<AccountCreateCommand>({
		token: '',
		email: 'johnathan.hegmann@education.vic.gov.au',
		firstName: '',
		lastName: '',
		password: '',
		confirmPassword: ''
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLFormElement>) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const accountCreate = async (values: AccountCreateCommand, formikHelpers: FormikHelpers<AccountCreateCommand>) => {
		setIsSubmitting(true);
		const { token, email, firstName, lastName, password, confirmPassword } = values;

		const response = await apibridge.postAccountCreate({
			token,
			email,
			firstName,
			lastName,
			password,
			confirmPassword
		});

		if (response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				setSubmissionSuccessful(true);
			}
		}

		setIsSubmitting(false);
	};

	return (
		<>
			<picture>
				<source srcSet="./img/login-bg.webp" />
				<img
					src="./img/login-bg.jpg"
					className="position-fixed w-100 h-100 object-fit object-fit-cover user-select-none"
					alt="Tiled books background"
				/>
			</picture>
			<main className="page-staff-registration d-flex align-items-center py-5">
				<Container className="position-relative z-1">
					<Formik initialValues={formValues} onSubmit={accountCreate}>
						<FormikForm onChange={handleChange} className="form-rounded-corners">
							{submissionSuccessful ? (
								<>
									<ImgRem src="/svg/account-created.svg" width="160" height="160" alt="" className="mx-auto" />
									<h2 className="text-shades-800 m-0">
										<strong>Account Created!</strong>
									</h2>
									<p className="m-0">
										Create a new account for
										<strong>johnathan.hegmann@education.vic.gov.au</strong>
									</p>
								</>
							) : (
								<>
									<ImgRem
										src="/svg/llll-online-contained-wide-logo.svg"
										width="231"
										height="70"
										alt="Little Learners Love Literacy Online logo"
										className="logo mx-auto"
									/>
									<h2 className="text-shades-800 m-0">
										<strong>Welcome to Little Learners Love Literacy Online</strong>
									</h2>
									<p className="m-0">
										Create a new account for
										<br />
										<strong>johnathan.hegmann@education.vic.gov.au</strong>
									</p>
									<div>
										<FormCustomError className="text-start my-2" />
										<FormCustomError label="token" className="text-start my-2" />

										<div className="row">
											<div className="col">
												<FormGroupWithError name="firstName" className="text-start mb-3">
													<label htmlFor="first-name" className="form-label">
														First Name
													</label>
													<Field type="text" id="first-name" name="firstName" className="form-control" />
												</FormGroupWithError>
											</div>
											<div className="col">
												<FormGroupWithError name="lastName" className="text-start mb-3">
													<label htmlFor="last-name" className="form-label">
														Last Name
													</label>
													<Field type="text" id="last-name" name="lastName" className="form-control" />
												</FormGroupWithError>
											</div>
										</div>

										<FormGroupWithError name="password" className="text-start mb-3">
											<label htmlFor="password" className="form-label">
												Password
											</label>
											<InputGroup>
												<Field
													type={showPassword ? 'text' : 'password'}
													id="password"
													name="password"
													className="form-control border-end-0"
													placeholder="Create a new password"
												/>
												<InputGroup.Text className="bg-transparent p-0">
													<button
														type="button"
														className="btn btn-sm bg-transparent text-reset"
														onClick={() => setShowPassword(!showPassword)}
														tabIndex={-1}
													>
														<SvgMask
															path={showPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
															width={24}
															height={24}
														/>
													</button>
												</InputGroup.Text>
											</InputGroup>
										</FormGroupWithError>

										<FormGroupWithError name="confirmPassword" className="text-start">
											<label htmlFor="confirmPassword" className="form-label">
												Confirm Password
											</label>
											<InputGroup>
												<Field
													type={showConfirmPassword ? 'text' : 'password'}
													id="confirm-password"
													name="confirmPassword"
													className="form-control border-end-0"
													placeholder="Re-enter your password"
												/>
												<InputGroup.Text className="bg-transparent p-0">
													<button
														type="button"
														className="btn btn-sm bg-transparent text-reset"
														onClick={() => setShowConfirmPassword(!showConfirmPassword)}
														tabIndex={-1}
													>
														<SvgMask
															path={showConfirmPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
															width={24}
															height={24}
														/>
													</button>
												</InputGroup.Text>
											</InputGroup>
										</FormGroupWithError>
									</div>
									<div className="d-grid">
										<button type="submit" className="btn btn-lg" disabled={isSubmitting}>
											Create an account
										</button>
									</div>
									<RecaptchaDisclaimer />
								</>
							)}
						</FormikForm>
					</Formik>
				</Container>
			</main>
		</>
	);
};

export default JoinOrganisation;
