import { FC } from 'react';
import { Link } from 'react-router-dom';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// UTILS
import SvgMask from '../_Helpers/SvgMask';

export type TitleSectionType = {
	backToText?: string;
	backToUrl?: string;
	title?: string;
	dynamicTitle?: any;
	description?: string;
	dynamicDescription?: any;
	primaryButtonText?: string;
	primaryButtonUrl?: string;
	primaryButtonIcon?: string;
	primaryButtonNotificationIcon?: boolean;
	primaryButtonDisabled?: boolean;
	primaryButtonLoading?: boolean;
	primaryButtonClass?: string;
	primaryButtonClick?: () => void;
	secondaryButtonText?: string;
	secondaryButtonUrl?: string;
	secondaryButtonIcon?: string;
	secondaryButtonNotificationIcon?: boolean;
	hideTitleBar?: boolean;
	children?: any;
};

const TitleSection: FC<TitleSectionType> = ({
	backToText,
	backToUrl,
	title,
	dynamicTitle,
	description,
	dynamicDescription,
	primaryButtonText,
	primaryButtonUrl,
	primaryButtonIcon,
	primaryButtonNotificationIcon,
	primaryButtonDisabled = false,
	primaryButtonLoading = false,
	primaryButtonClass = '',
	primaryButtonClick,
	secondaryButtonText,
	secondaryButtonUrl,
	secondaryButtonIcon,
	secondaryButtonNotificationIcon,
	hideTitleBar,
	children
}) => {
	return (
		// This must not be wrapped in any elements, or else the position: sticky; on the 'title-section-sub' section will no longer work
		<>
			{!hideTitleBar && (
				<div
					className={`title-section title-section-main ${title || dynamicTitle ? 'has-title' : ''} ${
						children ? 'has-children' : ''
					}`}
				>
					<div className="container">
						<div className="row">
							<div className="col col-lg">
								<div className="left-title-group">
									{backToText && backToUrl && (
										<Link to={backToUrl} className="btn btn-link">
											<SvgMask path="/svg/arrow-left.svg" width={16} height={16} />
											{backToText}
										</Link>
									)}
									{title && (
										<h1 className="text-shades-800 title m-0">
											<strong>{title}</strong>
										</h1>
									)}
									{dynamicTitle && dynamicTitle}
									{description && <p className="description">{description}</p>}
									{dynamicDescription && dynamicDescription}
								</div>
							</div>
							{primaryButtonClick ||
							primaryButtonText ||
							primaryButtonUrl ||
							secondaryButtonText ||
							secondaryButtonUrl ? (
								<div className="col-auto col-md-4 col-lg-3">
									<div className="right-button-group">
										{primaryButtonText && primaryButtonUrl && !primaryButtonClick && (
											<Link to={primaryButtonUrl} className={`btn ${primaryButtonClass}`}>
												{primaryButtonIcon && <SvgMask path={primaryButtonIcon} width={16} height={16} />}
												{primaryButtonText}
												{primaryButtonNotificationIcon && <span className="notification-dot flex-shrink-0"></span>}
											</Link>
										)}
										{primaryButtonClick && (
											<button
												onClick={primaryButtonClick}
												className={`btn text-nowrap ${primaryButtonClass}`}
												disabled={primaryButtonDisabled}
											>
												{primaryButtonIcon && <SvgMask path={primaryButtonIcon} width={16} height={16} />}
												{primaryButtonText}
												{primaryButtonNotificationIcon && <span className="notification-dot flex-shrink-0"></span>}
												{primaryButtonLoading && <Spinner animation="border" size="sm" />}
											</button>
										)}
										{secondaryButtonText && secondaryButtonUrl && (
											<Link to={secondaryButtonUrl} className="btn btn-white text-nowrap">
												{secondaryButtonIcon && <SvgMask path={secondaryButtonIcon} width={16} height={16} />}
												{secondaryButtonText}
												{secondaryButtonNotificationIcon && <span className="notification-dot flex-shrink-0"></span>}
											</Link>
										)}
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			)}
			{children && (
				<div className={`title-section title-section-sub ${hideTitleBar ? 'title-bar-hidden' : ''}`}>
					<div className="container position-sticky top-0">{children}</div>
				</div>
			)}
		</>
	);
};

export default TitleSection;
