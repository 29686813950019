import { FC, FormEvent, useEffect, useState } from 'react';

// PACKAGES
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import HintMessage from '../../components/HintMessage/HintMessage';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { ClassListClassCollection, ClassType } from '../../api/models';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { useAppDispatch } from '../../store/slice';

const AddAClass: FC = () => {
	const [existingClassData, setExistingClassData] = useState<ClassListClassCollection>();
	const [className, setClassName] = useState('');
	const [year, setYear] = useState<ClassType>();

	const [loading, setLoading] = useState(true);
	const [isCreatingClass, setIsCreatingClass] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const getExistingClassData = async () => {
		const response = await apibridge.getClassList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setExistingClassData(response.data.result);
		}

		setLoading(false);
	};

	useEffect(() => {
		getExistingClassData();
	}, []);

	const addClass = async (e: FormEvent) => {
		e.preventDefault();
		setIsCreatingClass(true);

		const response = await apibridge.postClassAdd({ name: className, year });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: `${className} successfully added to class list`,
						description: ''
					})
				);
				navigate('/student-management/add-class/' + response.data.result, { state: { newClass: true } });
				return;
			} else if (response.data.validationErrors) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: 'Cannot create class that already exists',
						description: 'Please select the existing class from the list or add a new class with a different name.'
					})
				);
			}
		}

		setIsCreatingClass(false);
	};

	return (
		<div className="add-class-page d-flex flex-column flex-grow-1">
			<TitleSection
				backToText="Back to classes"
				backToUrl="/student-management"
				title="Add a class"
				description="Set up a new class or select an existing class to add students to."
			></TitleSection>
			{loading ? (
				<div className="d-flex align-items-center justify-content-center">
					<DelayedFadeIn>
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</DelayedFadeIn>
				</div>
			) : (
				<section className="container d-flex flex-column flex-grow-1">
					<div className="row row-gap-5 flex-grow-1">
						<div className="col-lg d-flex flex-column">
							<form onSubmit={addClass} className="d-flex flex-column gap-4 flex-grow-1">
								<div className="d-flex flex-grow-1 gap-4">
									<div className="form-group w-50">
										<label htmlFor="class" className="body-tiny mb-1">
											<strong>Class name</strong>
										</label>
										<input
											type="text"
											name="className"
											className="form-control"
											id="class"
											placeholder="E.g. Class Foundation A"
											onChange={(e) => setClassName(e.target.value)}
										/>
									</div>
									<div className="form-group w-50">
										<label htmlFor="year" className="body-tiny mb-1">
											<strong>Year level</strong>
										</label>

										<select
											value={year}
											defaultValue={'-1'}
											onChange={(e) => setYear(e.target.value as ClassType)}
											className="form-select"
											name="year"
											id="year"
										>
											<option value="-1" disabled>
												Please select...
											</option>
											{existingClassData?.yearValues &&
												Object.entries(existingClassData.yearValues).map((yearValueEntry) => {
													const [key, value] = yearValueEntry;
													return (
														<option key={key} value={key}>
															{value}
														</option>
													);
												})}
										</select>
									</div>
								</div>
								<button type="submit" disabled={!className || !year || isCreatingClass} className="btn btn-lg w-100">
									Add class
								</button>
							</form>
						</div>
						<div className="col-lg-4 offset-lg-1 d-flex flex-column gap-4">
							{existingClassData?.classes && existingClassData.classes.length > 0 && (
								<>
									<div className="select-existing-class d-flex flex-column flex-grow-1 overflow-hidden">
										<div className="header">
											<h4 className="mb-0">Existing classes</h4>
										</div>
										<div className="content position-relative d-flex flex-column align-items-start justify-content-start flex-grow-1">
											<div className="position-absolute top-0 left-0 w-100 h-100">
												{existingClassData?.classes?.map((classItem) => (
													<div
														key={classItem.id}
														className="class-item d-flex align-items-center justify-content-start gap-4 w-100 p-2"
													>
														<div className="icon-wrapper">
															<SvgMask path="/svg/class.svg" width={24} height={24} />
														</div>
														<p className="mb-0 flex-grow-1">
															<strong>{classItem.name}</strong>
														</p>
														<button
															onClick={() => navigate(classItem.id || '', { state: { newClass: true } })}
															className="btn btn-icon btn-icon-white"
														>
															<SvgMask path="/svg/chevron-right.svg" width={24} height={24} />
														</button>
													</div>
												))}
											</div>
										</div>
									</div>
									<HintMessage
										title="How do I know which students have already been added?"
										message="If you select an existing class, you will be able to view all students already added to that class."
									/>
								</>
							)}
						</div>
					</div>
				</section>
			)}
		</div>
	);
};

export default AddAClass;
