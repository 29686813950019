import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { UserSlice } from './User';
import { ToastMessagesSlice } from './ToastMessages';
import { FtuxSlice } from './Ftux';
import { SystemInfoSlice } from './SystemInfo';

export const store = configureStore({
	reducer: {
		ftux: FtuxSlice.reducer,
		systemInfo: SystemInfoSlice.reducer,
		toastMessages: ToastMessagesSlice.reducer,
		user: UserSlice.reducer
	}
});

// Infer the `StateType` (RootState) and `DispatchType` (AppDispatch) types from the store itself
export type StateType = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<DispatchType>();
export const useAppSelector: TypedUseSelectorHook<StateType> = useSelector;
