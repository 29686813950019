/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { SharedModelsCommandResponse } from '../models';
import { SubscriptionChangeApplyCommand } from '../models';
import { SubscriptionChangeCommand } from '../models';
import { SubscriptionChangeCommandResponse } from '../models';
import { SubscriptionChangeQueryResponse } from '../models';
import { SubscriptionEditCommand } from '../models';
import { SubscriptionListResponse } from '../models';
import { SubscriptionReactivateCancelledApplyCommand } from '../models';
import { SubscriptionReactivateCancelledCommand } from '../models';
import { SubscriptionReactivateCancelledCommandResponse } from '../models';
import { SubscriptionReactivateCancelledQueryResponse } from '../models';
/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Applies the subscrition change:   * If upgrade, apply on the spot   * If downgrade, schedule it
         * @param {SubscriptionChangeApplyCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionChangeApplyPost: async (body?: SubscriptionChangeApplyCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/ChangeApply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list of plans and other information to be displayed on the subscription change    dialog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionChangeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/Change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides details of the selected plan (including prorated amount) for confirmation before    applying.
         * @param {SubscriptionChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionChangePost: async (body?: SubscriptionChangeCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/Change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit subscription specific information such as order number
         * @param {SubscriptionEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionEditPost: async (body?: SubscriptionEditCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/Edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list of subscription plans (mainly to display on buyer page)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply selected plan and reactivate organisation
         * @param {SubscriptionReactivateCancelledApplyCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionReactivateCancelledApplyPost: async (body?: SubscriptionReactivateCancelledApplyCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/ReactivateCancelledApply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list of subscription details for the reactivation screen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionReactivateCancelledGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/ReactivateCancelled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides details of the selected plan
         * @param {SubscriptionReactivateCancelledCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionReactivateCancelledPost: async (body?: SubscriptionReactivateCancelledCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/ReactivateCancelled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Applies the subscrition change:   * If upgrade, apply on the spot   * If downgrade, schedule it
         * @param {SubscriptionChangeApplyCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionChangeApplyPost(body?: SubscriptionChangeApplyCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).apiSubscriptionChangeApplyPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list of plans and other information to be displayed on the subscription change    dialog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionChangeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionChangeQueryResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).apiSubscriptionChangeGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides details of the selected plan (including prorated amount) for confirmation before    applying.
         * @param {SubscriptionChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionChangePost(body?: SubscriptionChangeCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionChangeCommandResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).apiSubscriptionChangePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Edit subscription specific information such as order number
         * @param {SubscriptionEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionEditPost(body?: SubscriptionEditCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).apiSubscriptionEditPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list of subscription plans (mainly to display on buyer page)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionListResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).apiSubscriptionListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Apply selected plan and reactivate organisation
         * @param {SubscriptionReactivateCancelledApplyCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionReactivateCancelledApplyPost(body?: SubscriptionReactivateCancelledApplyCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).apiSubscriptionReactivateCancelledApplyPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list of subscription details for the reactivation screen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionReactivateCancelledGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionReactivateCancelledQueryResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).apiSubscriptionReactivateCancelledGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides details of the selected plan
         * @param {SubscriptionReactivateCancelledCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionReactivateCancelledPost(body?: SubscriptionReactivateCancelledCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionReactivateCancelledCommandResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).apiSubscriptionReactivateCancelledPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Applies the subscrition change:   * If upgrade, apply on the spot   * If downgrade, schedule it
         * @param {SubscriptionChangeApplyCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionChangeApplyPost(body?: SubscriptionChangeApplyCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return SubscriptionApiFp(configuration).apiSubscriptionChangeApplyPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list of plans and other information to be displayed on the subscription change    dialog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionChangeGet(options?: any): AxiosPromise<SubscriptionChangeQueryResponse> {
            return SubscriptionApiFp(configuration).apiSubscriptionChangeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides details of the selected plan (including prorated amount) for confirmation before    applying.
         * @param {SubscriptionChangeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionChangePost(body?: SubscriptionChangeCommand, options?: any): AxiosPromise<SubscriptionChangeCommandResponse> {
            return SubscriptionApiFp(configuration).apiSubscriptionChangePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit subscription specific information such as order number
         * @param {SubscriptionEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionEditPost(body?: SubscriptionEditCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return SubscriptionApiFp(configuration).apiSubscriptionEditPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list of subscription plans (mainly to display on buyer page)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionListGet(options?: any): AxiosPromise<SubscriptionListResponse> {
            return SubscriptionApiFp(configuration).apiSubscriptionListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply selected plan and reactivate organisation
         * @param {SubscriptionReactivateCancelledApplyCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionReactivateCancelledApplyPost(body?: SubscriptionReactivateCancelledApplyCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return SubscriptionApiFp(configuration).apiSubscriptionReactivateCancelledApplyPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list of subscription details for the reactivation screen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionReactivateCancelledGet(options?: any): AxiosPromise<SubscriptionReactivateCancelledQueryResponse> {
            return SubscriptionApiFp(configuration).apiSubscriptionReactivateCancelledGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides details of the selected plan
         * @param {SubscriptionReactivateCancelledCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionReactivateCancelledPost(body?: SubscriptionReactivateCancelledCommand, options?: any): AxiosPromise<SubscriptionReactivateCancelledCommandResponse> {
            return SubscriptionApiFp(configuration).apiSubscriptionReactivateCancelledPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @summary Applies the subscrition change:   * If upgrade, apply on the spot   * If downgrade, schedule it
     * @param {SubscriptionChangeApplyCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionChangeApplyPost(body?: SubscriptionChangeApplyCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionChangeApplyPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list of plans and other information to be displayed on the subscription change    dialog.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionChangeGet(options?: any) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionChangeGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides details of the selected plan (including prorated amount) for confirmation before    applying.
     * @param {SubscriptionChangeCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionChangePost(body?: SubscriptionChangeCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionChangePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Edit subscription specific information such as order number
     * @param {SubscriptionEditCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionEditPost(body?: SubscriptionEditCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionEditPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list of subscription plans (mainly to display on buyer page)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionListGet(options?: any) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionListGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Apply selected plan and reactivate organisation
     * @param {SubscriptionReactivateCancelledApplyCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionReactivateCancelledApplyPost(body?: SubscriptionReactivateCancelledApplyCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionReactivateCancelledApplyPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list of subscription details for the reactivation screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionReactivateCancelledGet(options?: any) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionReactivateCancelledGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides details of the selected plan
     * @param {SubscriptionReactivateCancelledCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionReactivateCancelledPost(body?: SubscriptionReactivateCancelledCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionReactivateCancelledPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
