import ReactPlayer from 'react-player';

export const EmbeddedYouTubeOrVimeoVideo = ({ youTubeOrVimeoId }: { youTubeOrVimeoId: string }) => {
	// youtube IDs
	// 4AOzXIIAFcE
	// vhtRnMVgfH8

	// vimeo IDs
	// 831703290
	// 853599522

	// am just going to check to see if what's passed in can be resolved to a number; if so then assume it's Vimeo
	const isVimeo = /^\d+$/.test(youTubeOrVimeoId);
	return (
		<div className="video-embed ratio ratio-16x9">
			{isVimeo ? (
				<ReactPlayer url={`https://player.vimeo.com/video/${youTubeOrVimeoId}`} width="100%" height="100%" controls />
			) : (
				<ReactPlayer url={`https://www.youtube.com/embed/${youTubeOrVimeoId}`} width="100%" height="100%" controls />
			)}
		</div>
	);
};
