import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/slice';
import { useNavigate } from 'react-router-dom';
import { FtuxSlice } from '../../store/slice/Ftux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Modal } from 'react-bootstrap';
import Joyride, { Step, TooltipRenderProps } from 'react-joyride';

// UTILS
import { guid } from '../../libs/utils';

// TYPES
import apibridge from '../../apibridge';
import { UserType } from '../../store/slice/User';

const adminSteps: Step[] = [
	{
		target: '#link-teachers',
		content: "<strong>Let's start</strong> by inviting teachers to your organisation!",
		placement: 'right',
		disableBeacon: true,
		data: {
			primaryButtonText: 'Invite Teachers',
			primaryButtonLink: '/teacher-management/invite'
		}
	}
];

const teacherSteps: Step[] = [
	{
		target: '#link-students',
		content: "<strong>Let's start</strong> by adding your class along with the students you'll be teaching to begin.",
		placement: 'right',
		disableBeacon: true,
		data: {
			primaryButtonText: 'Add Your Class',
			primaryButtonLink: '/student-management/add-class'
		}
	}
];

const FtuxTooltip = ({ closeProps, primaryProps, step, tooltipProps }: TooltipRenderProps) => {
	// plugin lets us customise a step's button text + action by passing it through step 'data' object
	const { data = {} } = step;
	const primaryButtonText = data.primaryButtonText || primaryProps.title;

	return (
		<div {...tooltipProps} className="ftux">
			{step.content && <div dangerouslySetInnerHTML={{ __html: step.content.toString() }}></div>}

			<div className="d-grid gap-2-5">
				<button {...primaryProps} type="button" className="btn btn-primary">
					{primaryButtonText}
				</button>
				<button {...closeProps} type="button" className="btn btn-white">
					{closeProps.title}
				</button>
			</div>
		</div>
	);
};

const FTUX: FC<{ user: UserType }> = ({ user }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const ftuxState = useAppSelector((state) => state.ftux);

	const [showFirstTimeLoggingInModal, setShowFirstTimeLoggingInModal] = useState(true);
	const userIsAdmin = user.profiles?.[0]?.type === 'OrganisationAdministrator';

	const postFtuxCompleted = async () => {
		const response = await apibridge.postFtuxCompleted();
		if (response && response.data) {
			if (response.data.isError) {
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: 'FTUX error',
					description: 'Error completing FTUX'
				});
			}
		}
	};

	return (
		<>
			<Modal
				show={showFirstTimeLoggingInModal}
				onHide={() => setShowFirstTimeLoggingInModal(false)}
				onExited={() => {
					dispatch(FtuxSlice.actions.begin());
				}}
				className="modal-welcome"
				centered
			>
				<Modal.Body>
					<h2 className="h1 m-0 text-shades-800">
						<strong>Welcome to Little Learners Love Literacy Online</strong>
					</h2>

					<div className="d-flex button-group">
						<button type="button" onClick={() => setShowFirstTimeLoggingInModal(false)} className="btn btn-lg w-100">
							Let's get started!
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Joyride
				{...ftuxState}
				disableCloseOnEsc
				disableOverlayClose
				disableScrolling
				disableScrollParentFix // will allow the tooltips to display correctly over the side menu
				callback={(data) => {
					const { action, index, lifecycle, size, step, type } = data;
					const isLastStep = index + 1 === size;

					// will ensure whatever we're targeting has mounted after the page switch
					if (type === 'step:after') {
						if (action === 'next' && step.data?.primaryButtonLink) {
							navigate(step.data.primaryButtonLink);
							dispatch(FtuxSlice.actions.goNext());
						}

						if (action === 'close') {
							dispatch(FtuxSlice.actions.cancel());
							dispatch(
								ToastMessagesSlice.actions.add({
									id: guid(),
									heading: 'Walkthrough dismissed',
									description: 'You can use the Help Centre if you need further guidance.',
									type: 'info'
								})
							);
						}
					}

					if (lifecycle === 'complete' && isLastStep) {
						// FTUX is over
						dispatch(FtuxSlice.actions.cancel());
						// this only gets called once, whether the FTUX was finished normally or cancelled early
						postFtuxCompleted();
					}
				}}
				tooltipComponent={(props) =>
					FtuxTooltip({
						...props,
						closeProps: {
							...props.closeProps,
							title: 'Continue Exploring'
						}
					})
				}
				steps={userIsAdmin ? adminSteps : teacherSteps}
				styles={{
					options: {
						arrowColor: '#E0F4FF',
						spotlightShadow: 'none',
						overlayColor: 'rgba(250, 250, 255, 0.8)'
					}
				}}
				continuous
			/>
		</>
	);
};

export default FTUX;
