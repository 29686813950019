import { FC, useState } from 'react';
import { useAppSelector } from '../../store/slice';

// PACKAGES
import { Link } from 'react-router-dom';
import { Dropdown, Modal, Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap';

// COMPONENTS
import SvgMask from '../_Helpers/SvgMask';

// TYPES
import { StaffListTeacher, StudentAdminDashboardClass } from '../../api/models';
import { UserType } from '../../store/slice/User';

interface ClassCardTypes {
	classDetails?: StudentAdminDashboardClass;
	archiveStudents?: number;
	isArchive?: boolean;
	handleUnassignTeacher?: (
		profileId: string,
		classId: string,
		firstName: string,
		lastName: string,
		className: string
	) => Promise<void>;
	handleDeleteClass?: (classId: string) => Promise<void>;
	handleAssignTeacher?: (profileId: string, classId: string) => Promise<void>;
	teacherList?: StaffListTeacher[];
}

const ClassCard: FC<ClassCardTypes> = ({
	classDetails,
	archiveStudents,
	isArchive,
	handleUnassignTeacher,
	handleDeleteClass,
	handleAssignTeacher,
	teacherList
}) => {
	const [showChangeTeacherModal, setShowChangeTeacherModal] = useState(false);
	const [showDeleteClassModal, setShowDeleteClassModal] = useState(false);
	const [selectedTeacherId, setSelectedTeacherId] = useState('');

	const user: UserType = useAppSelector((state) => state.user);

	const handleAssign = () => {
		handleAssignTeacher && handleAssignTeacher(selectedTeacherId, classDetails?.id || '');
		setShowChangeTeacherModal(false);
		setSelectedTeacherId('');
	};

	return (
		<>
			<div className="col-md-6 col-lg-4 col-xl-3 ">
				<div className="position-relative d-flex flex-column class-card">
					<div className="d-flex flex-row justify-content-between align-items-start flex-grow-1">
						<div className="d-flex flex-column gap-1">
							{isArchive ? (
								<Link to="/student-management/archived-students" className="stretched-link no-pseudo-hover title">
									<h3 className="text-shades-800 h4 mb-0 title">Archived students</h3>
								</Link>
							) : (
								<>
									<Link
										to={`/student-management/class/${classDetails?.id}`}
										className="stretched-link no-pseudo-hover text-shades-800 h4 mb-0 title"
										state={{
											id: classDetails?.id,
											name: classDetails?.name,
											year: classDetails?.year,
											teacher: classDetails?.teacher
										}}
									>
										{classDetails?.name}
									</Link>
									<p className="mb-0 text-shades-500 body-small">
										<strong>{classDetails?.year}</strong>
									</p>
								</>
							)}
						</div>
						{!isArchive &&
							user.permissions?.some((permission) =>
								['StaffClassRemove', 'StaffClassAssign', 'ClassDelete'].includes(permission)
							) && (
								<Dropdown className="z-1">
									<OverlayTrigger placement="top" overlay={<Tooltip>More</Tooltip>}>
										<Dropdown.Toggle className="btn btn-sm btn-icon btn-dropdown rotate-90">
											<SvgMask path="/svg/ellipsis-v.svg" width={24} height={24} />
										</Dropdown.Toggle>
									</OverlayTrigger>
									<Dropdown.Menu>
										{classDetails?.teacher ? (
											<>
												{user.permissions?.includes('StaffClassRemove') && (
													<Dropdown.Item
														as="button"
														onClick={() => {
															handleUnassignTeacher &&
																handleUnassignTeacher(
																	classDetails?.teacher?.profileId || '',
																	classDetails?.id || '',
																	classDetails.teacher?.firstName || '',
																	classDetails.teacher?.lastName || '',
																	classDetails.name || ''
																);
														}}
													>
														Unassign teacher
													</Dropdown.Item>
												)}
												{user.permissions?.includes('StaffClassAssign') && (
													<Dropdown.Item
														as="button"
														onClick={() => {
															setShowChangeTeacherModal(true);
														}}
													>
														Change teacher
													</Dropdown.Item>
												)}
											</>
										) : (
											user.permissions?.includes('StaffClassAssign') && (
												<Dropdown.Item
													as="button"
													onClick={() => {
														setShowChangeTeacherModal(true);
													}}
												>
													Assign teacher
												</Dropdown.Item>
											)
										)}

										{user.permissions?.includes('ClassDelete') && (
											<Dropdown.Item
												as="button"
												onClick={() => {
													setShowDeleteClassModal(!showDeleteClassModal);
												}}
												className="option-destructive"
											>
												Delete class
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							)}
					</div>
					<div className="d-flex flex-column gap-2">
						{!isArchive && (
							<>
								<div className="w-100 d-flex align-items-center gap-3">
									<div
										className={`${
											classDetails?.teacher ? 'text-dark-text' : 'text-shades-400 unassigned'
										} teacher-icon`}
									>
										<SvgMask path="/svg/Teachers-Hat.svg" width={24} height={24} />
									</div>
									<div>
										<p className="text-shades-500 mb-0 body-tiny">
											<strong>Teacher</strong>
										</p>
										<div className="d-flex align-items-center gap-2">
											<p className={`${classDetails?.teacher ? 'text-dark-text' : 'text-shades-500'} mb-0`}>
												<strong>
													{classDetails?.teacher
														? classDetails?.teacher?.firstName + ' ' + classDetails?.teacher?.lastName
														: 'Unassigned'}
												</strong>
											</p>
											{classDetails?.teacher?.isCurrentProfile && (
												<span className="admin badge rounded-pill tag-label">You</span>
											)}
										</div>
									</div>
								</div>
							</>
						)}
						<div className="w-100 d-flex align-items-center gap-3">
							<div className={`students-icon ${isArchive ? 'archive text-shades-400' : ''}`}>
								<SvgMask path="/svg/students-plural.svg" width={24} height={24} />
							</div>
							<div>
								<p className="text-shades-500 mb-0 body-tiny">
									<strong>Students</strong>
								</p>
								<p className="text-dark-text mb-0">
									<strong>{classDetails?.students || archiveStudents || 0}</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal show={showDeleteClassModal} onHide={() => setShowDeleteClassModal(false)} centered>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>Delete class {classDetails?.name}?</strong>
					</h2>
					<p className="m-0">
						By deleting class <strong>{classDetails?.name}</strong>, all students attached to this class will be moved
						to <strong>Archived Students</strong>.
					</p>
					<p className="m-0">
						This action cannot be undone. <br /> Are you sure you want to delete this class?
					</p>
					<div className="button-group d-flex">
						<button onClick={() => setShowDeleteClassModal(false)} className="btn btn-white btn-lg w-100">
							Cancel
						</button>
						<button
							onClick={() => handleDeleteClass && handleDeleteClass(classDetails?.id || '')}
							className="btn btn-destructive btn-lg w-100"
						>
							Delete class
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Offcanvas
				show={showChangeTeacherModal}
				onHide={() => setShowChangeTeacherModal(false)}
				onExited={() => setSelectedTeacherId('')}
				placement="end"
				bsPrefix="class-select offcanvas"
				scroll
			>
				<Offcanvas.Header className="offcanvas-header">
					<Offcanvas.Title className="h3">
						Change the teacher for <strong>class {classDetails?.name}</strong>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className="form-selection-wrapper">
						{teacherList?.length ? (
							<div className="form-selection-wrapper-inner">
								{/* don't include the same teacher that's already assigned */}
								{teacherList
									.filter((teacher) => teacher.id !== classDetails?.teacher?.profileId)
									.map((teacher) => (
										<button
											type="button"
											onClick={() => setSelectedTeacherId(teacher.id || '')}
											className="btn btn-row-label"
											key={teacher.id}
										>
											<strong>
												{teacher.firstName} {teacher.lastName}
											</strong>
											{selectedTeacherId === teacher?.id ? (
												<SvgMask path="/svg/check.svg" width={16} height={16} />
											) : null}
										</button>
									))}
							</div>
						) : null}
					</div>
					<div className="button-group d-flex gap-3">
						<button
							onClick={() => {
								setShowChangeTeacherModal(false);
							}}
							className="btn btn-white btn-lg w-100"
						>
							Cancel
						</button>
						<button onClick={() => handleAssign()} className="btn btn-lg w-100" disabled={!selectedTeacherId}>
							{classDetails?.teacher?.profileId ? 'Change teacher' : 'Add teacher'}
						</button>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
};

export default ClassCard;
