/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ClassCreateCommand } from '../models';
import { ClassDeleteCommand } from '../models';
import { ClassListResponse } from '../models';
import { ClassStudentsResponse } from '../models';
import { ClassViewResponse } from '../models';
import { SharedModelsCommandIdResponse } from '../models';
import { SharedModelsCommandResponse } from '../models';
/**
 * ClassApi - axios parameter creator
 * @export
 */
export const ClassApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new class
         * @param {ClassCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassCreatePost: async (body?: ClassCreateCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Class/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a class and archive it's students.
         * @param {ClassDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassDeleteDelete: async (body?: ClassDeleteCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Class/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list of available classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Class/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list of students for a class
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassStudentsGet: async (classId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Class/Students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classId !== undefined) {
                localVarQueryParameter['ClassId'] = classId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View a class details.
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassViewGet: async (classId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Class/View`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classId !== undefined) {
                localVarQueryParameter['ClassId'] = classId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassApi - functional programming interface
 * @export
 */
export const ClassApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new class
         * @param {ClassCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClassCreatePost(body?: ClassCreateCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandIdResponse>> {
            const localVarAxiosArgs = await ClassApiAxiosParamCreator(configuration).apiClassCreatePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a class and archive it's students.
         * @param {ClassDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClassDeleteDelete(body?: ClassDeleteCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await ClassApiAxiosParamCreator(configuration).apiClassDeleteDelete(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list of available classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClassListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassListResponse>> {
            const localVarAxiosArgs = await ClassApiAxiosParamCreator(configuration).apiClassListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list of students for a class
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClassStudentsGet(classId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassStudentsResponse>> {
            const localVarAxiosArgs = await ClassApiAxiosParamCreator(configuration).apiClassStudentsGet(classId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary View a class details.
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClassViewGet(classId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassViewResponse>> {
            const localVarAxiosArgs = await ClassApiAxiosParamCreator(configuration).apiClassViewGet(classId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClassApi - factory interface
 * @export
 */
export const ClassApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add a new class
         * @param {ClassCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassCreatePost(body?: ClassCreateCommand, options?: any): AxiosPromise<SharedModelsCommandIdResponse> {
            return ClassApiFp(configuration).apiClassCreatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a class and archive it's students.
         * @param {ClassDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassDeleteDelete(body?: ClassDeleteCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return ClassApiFp(configuration).apiClassDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list of available classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassListGet(options?: any): AxiosPromise<ClassListResponse> {
            return ClassApiFp(configuration).apiClassListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list of students for a class
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassStudentsGet(classId?: string, options?: any): AxiosPromise<ClassStudentsResponse> {
            return ClassApiFp(configuration).apiClassStudentsGet(classId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View a class details.
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClassViewGet(classId?: string, options?: any): AxiosPromise<ClassViewResponse> {
            return ClassApiFp(configuration).apiClassViewGet(classId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClassApi - object-oriented interface
 * @export
 * @class ClassApi
 * @extends {BaseAPI}
 */
export class ClassApi extends BaseAPI {
    /**
     * 
     * @summary Add a new class
     * @param {ClassCreateCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassApi
     */
    public apiClassCreatePost(body?: ClassCreateCommand, options?: any) {
        return ClassApiFp(this.configuration).apiClassCreatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a class and archive it's students.
     * @param {ClassDeleteCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassApi
     */
    public apiClassDeleteDelete(body?: ClassDeleteCommand, options?: any) {
        return ClassApiFp(this.configuration).apiClassDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list of available classes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassApi
     */
    public apiClassListGet(options?: any) {
        return ClassApiFp(this.configuration).apiClassListGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list of students for a class
     * @param {string} [classId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassApi
     */
    public apiClassStudentsGet(classId?: string, options?: any) {
        return ClassApiFp(this.configuration).apiClassStudentsGet(classId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary View a class details.
     * @param {string} [classId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassApi
     */
    public apiClassViewGet(classId?: string, options?: any) {
        return ClassApiFp(this.configuration).apiClassViewGet(classId, options).then((request) => request(this.axios, this.basePath));
    }
}
