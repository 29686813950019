import React from 'react';
import { useField, ErrorMessage } from 'formik';

type FormGroupWithErrorTypes = {
	name: string;
	className?: string;
	showError?: boolean;
	children?: React.ReactNode;
};

export const FormGroupWithError: React.FC<FormGroupWithErrorTypes> = ({
	name,
	className = '',
	showError = true,
	children = null
}) => {
	const [, meta] = useField(name);

	return (
		<div className={`form-group ${className} ${meta.touched && meta.error ? 'has-error' : ''}`}>
			{children}

			{showError && (
				<ErrorMessage
					name={name}
					component="div"
					className="text-danger text-new-line-wrap body-small fw-semibold mt-2"
				/>
			)}
		</div>
	);
};

export default FormGroupWithError;
