import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// API
import apibridge from '../../apibridge';

// COMPONENTS
import Book from '../../components/Book/Book';
import StudentHomeButton from '../ELibrary/Student/StudentHomeButton';

// UTILS
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import { StaffBookViewBook, StudentBookViewBook } from '../../api/models';
import { SystemInfoType } from '../../store/slice/SystemInfo';

export const getAndReturnBookData = async (bookId: string, dispatch: Dispatch, systemInfo: SystemInfoType) => {
	const response =
		systemInfo.type === 'staff' ? await apibridge.getStaffBookView(bookId) : await apibridge.getStudentBookView(bookId);
	if (response && response.data.validationErrors) {
		for (const err of response.data.validationErrors) {
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: 'Book error',
					description: err.reason || 'Unknown error'
				})
			);
		}
	}

	return response && response.data && !response.data.isError && response.data.result ? response.data.result : undefined;
};

const BookReader: FC = () => {
	const dispatch = useDispatch();
	const systemInfo = useAppSelector((state) => state.systemInfo);
	const location = useLocation();
	const [, , bookIdUrlPart] = location.pathname.split('/');

	const [isLoading, setIsLoading] = useState(true);
	const [bookData, setBookData] = useState<StaffBookViewBook | StudentBookViewBook>();
	const [isZoomed, setIsZoomed] = useState(false);

	const getBookData = async () => {
		const bookData = await getAndReturnBookData(decodeURIComponent(bookIdUrlPart), dispatch, systemInfo);
		setBookData(bookData);

		setIsLoading(false);
	};

	useEffect(() => {
		getBookData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={`page-book-reader ${isZoomed ? 'is-zoomed' : ''}`}>
			{!isZoomed && <StudentHomeButton />}
			{systemInfo.type === 'staff' && (
				<button
					type="button"
					className="btn btn-top-right btn-zoom text-uppercase"
					onClick={() => setIsZoomed(!isZoomed)}
				>
					<i></i>
					<span>
						<strong>{isZoomed ? 'Zoom Out' : 'Zoom In'}</strong>
					</span>
				</button>
			)}
			{isLoading ? (
				<div className="d-flex justify-content-center w-100 my-5">
					<DelayedFadeIn>
						<Spinner />
					</DelayedFadeIn>
				</div>
			) : (
				bookData && <Book bookData={bookData} bookId={bookIdUrlPart} isZoomed={isZoomed} setIsZoomed={setIsZoomed} />
			)}
		</div>
	);
};

export default BookReader;
