import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

export type SortOptionsType = {
	value: string;
	label: string;
};

interface SortDropdownTypes {
	sortOptions: SortOptionsType[];
	selectedSortOption: SortOptionsType;
	setSelectedSortOption: React.Dispatch<React.SetStateAction<SortOptionsType>>;
}

const SortDropdown: React.FC<SortDropdownTypes> = ({ sortOptions, selectedSortOption, setSelectedSortOption }) => {
	return (
		<Dropdown className="sort-dropdown">
			<Dropdown.Toggle variant="dropdown">{selectedSortOption.label}</Dropdown.Toggle>
			<Dropdown.Menu>
				{sortOptions.map((item, index) => (
					<Dropdown.Item onClick={() => setSelectedSortOption(item)} key={index} as="button">
						{item.label}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default SortDropdown;
