import { FC } from 'react';
import CroppedImage from '../_Helpers/CroppedImage';

const StudentELibraryBook: FC<{ imgUrl: string; imgAlt: string }> = ({ imgUrl, imgAlt }) => {
	return (
		<div className="student-elibrary-book position-relative z-1">
			<CroppedImage src={imgUrl} width={484} height={600} alt={imgAlt} />
			<div className="book-shadow"></div>
		</div>
	);
};

export default StudentELibraryBook;
