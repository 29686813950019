import { Navigate, Route } from 'react-router-dom';
import { useAppSelector } from '../../store/slice';
import { UserType } from '../../store/slice/User';

// PAGES
import Page401 from '../../pages/ErrorPages/Page401';
import LayoutDashboard from '../_Layout/LayoutStaffDashboard/LayoutStaffDashboard';
import Resources from '../../pages/Resources/Resources';
import Plans from '../../pages/Plans/Plans';
import Routines from '../../pages/Routines/Routines';
import RoutineItemPage from '../../pages/Routines/RoutineItemPage';
import StaffELibrary from '../../pages/ELibrary/Staff/StaffELibrary';
import Teachers from '../../pages/TeacherAdmin/TeacherAdmin';
import InviteTeachers from '../../pages/TeacherAdmin/InviteTeachers';
import ProfessionalLearning from '../../pages/ProfessionalLearning/ProfessionalLearning';
import ProfessionalLearningItem from '../../pages/ProfessionalLearning/ProfessionalLearningItem';
import HelpCentre from '../../pages/HelpCentre/HelpCentre';
import Settings from '../../pages/Settings/Settings';
import OrganisationDetails from '../../pages/Settings/OrganisationDetails';
import ForgotPassword from '../../pages/Authentication/Staff/ForgotPassword';
import ResetPassword from '../../pages/Authentication/Staff/ResetPassword';
import Registration from '../../pages/Authentication/Staff/Registration';
import JoinOrganisation from '../../pages/Authentication/Staff/JoinOrganisation';
import StaffLogin from '../../pages/Authentication/Staff/StaffLogin';
import TrialEnded from '../../pages/Authentication/Staff/TrialEnded';
import SubscriptionEnded from '../../pages/Authentication/Staff/SubscriptionEnded';
import AccountSuspended from '../../pages/Authentication/Staff/AccountSuspended';
import Students from '../../pages/StudentAdmin/StudentAdmin';
import ClassDetails from '../../pages/StudentAdmin/ClassDetails';
import ArchiveStudents from '../../pages/StudentAdmin/ArchiveStudents';
import AddStudents from '../../pages/StudentAdmin/AddStudents';
import Student from '../../pages/StudentAdmin/Student';
import StudentAssignBooks from '../../pages/StudentAdmin/StudentAssignBooks';
import AddAClass from '../../pages/StudentAdmin/AddAClass';
import BookReader from '../../pages/BookReader/BookReader';
import LayoutQuiz from '../_Layout/LayoutQuiz/LayoutQuiz';
import QuizComplete from '../../pages/Quiz/QuizComplete';
import BookComplete from '../../pages/Quiz/BookComplete';

// UTILS
import ProtectedRoute from '../_Helpers/ProtectedRoute';

const useRoutineRoutes = ({ user }: { user: UserType }) => {
	return (
		<>
			{/* if no routine page specified, go to first page */}
			<Route index element={<Navigate replace to="1" />} />
			<Route
				path=":pageNumber"
				element={user.loggedIn ? user.permissions?.includes('RoutineView') ? <RoutineItemPage /> : <Page401 /> : null}
			/>
		</>
	);
};

const useStaffRoutes = () => {
	const user: UserType = useAppSelector((state) => state.user);
	const searchParams = new URLSearchParams(window.location.search);
	const returnUrl = searchParams.get('returnUrl') || '';

	const routineRoutes = useRoutineRoutes({ user });

	return (
		<>
			<Route path="/" element={<Navigate replace to={'/plans'} />} />
			<Route path="/login" element={user.loggedIn ? <Navigate replace to={returnUrl || '/plans'} /> : <StaffLogin />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
			<Route path="/registration" element={<Registration />} />
			<Route path="/join-organisation" element={<JoinOrganisation />} />

			<Route path="/trial-ended" element={<TrialEnded />} />
			<Route path="/subscription-ended" element={<SubscriptionEnded />} />
			<Route path="/account-suspended" element={<AccountSuspended />} />

			<Route element={<ProtectedRoute />}>
				<Route element={<LayoutDashboard />}>
					<Route
						path="/plans"
						element={user.loggedIn ? user.permissions?.includes('PlannerWeek') ? <Plans /> : <Page401 /> : null}
					/>
					<Route path="/planner" element={<Navigate replace to={'/plans'} />} />

					<Route
						path="/routines"
						element={user.loggedIn ? user.permissions?.includes('RoutineGroups') ? <Routines /> : <Page401 /> : null}
					/>

					<Route
						path="/elibrary"
						element={user.loggedIn ? user.permissions?.includes('BookList') ? <StaffELibrary /> : <Page401 /> : null}
					/>

					<Route path="/student-management">
						<Route
							index
							element={user.loggedIn ? user.permissions?.includes('ClassList') ? <Students /> : <Page401 /> : null}
						/>

						<Route path="add-class">
							<Route
								index
								element={user.loggedIn ? user.permissions?.includes('ClassCreate') ? <AddAClass /> : <Page401 /> : null}
							/>
							<Route
								path=":id"
								element={
									user.loggedIn ? (
										user.permissions?.includes('StudentAdminCreate') ? (
											<AddStudents />
										) : (
											<Page401 />
										)
									) : null
								}
							/>
						</Route>

						<Route path="class">
							{/* CLASS - if no class ID, return to higher page */}
							<Route index element={<Navigate replace to="../" relative="path" />} />
							<Route
								path=":id"
								element={
									user.loggedIn ? user.permissions?.includes('ClassView') ? <ClassDetails /> : <Page401 /> : null
								}
							/>
						</Route>

						<Route
							path="archived-students"
							element={
								user.loggedIn ? (
									user.permissions?.includes('StudentAdminArchived') ? (
										<ArchiveStudents />
									) : (
										<Page401 />
									)
								) : null
							}
						/>

						<Route path="student">
							{/* STUDENT - if no student ID, return to higher page */}
							<Route index element={<Navigate replace to="../" relative="path" />} />
							<Route
								path=":id"
								element={
									user.loggedIn ? user.permissions?.includes('StudentAdminView') ? <Student /> : <Page401 /> : null
								}
							/>
						</Route>

						<Route
							path="assign-books"
							element={
								user.loggedIn ? (
									user.permissions?.includes('StudentAdminBookAssign') ? (
										<StudentAssignBooks />
									) : (
										<Page401 />
									)
								) : null
							}
						/>
					</Route>

					<Route path="/teacher-management">
						<Route
							index
							element={user.loggedIn ? user.permissions?.includes('StaffDashboard') ? <Teachers /> : <Page401 /> : null}
						/>
						<Route
							path="invite"
							element={
								user.loggedIn ? user.permissions?.includes('StaffInvite') ? <InviteTeachers /> : <Page401 /> : null
							}
						/>
					</Route>

					<Route
						path="/resources"
						element={user.loggedIn ? user.permissions?.includes('ResourceList') ? <Resources /> : <Page401 /> : null}
					/>

					<Route path="/professional-learning">
						<Route
							index
							element={
								user.loggedIn ? (
									user.permissions?.includes('ProfessionalLearningList') ? (
										<ProfessionalLearning />
									) : (
										<Page401 />
									)
								) : null
							}
						/>
						<Route
							path=":id"
							element={
								user.loggedIn ? (
									user.permissions?.includes('ProfessionalLearningList') ? (
										<ProfessionalLearningItem />
									) : (
										<Page401 />
									)
								) : null
							}
						/>
					</Route>

					<Route
						path="/help-centre"
						element={user.loggedIn ? user.permissions?.includes('HelpCentreList') ? <HelpCentre /> : <Page401 /> : null}
					/>

					<Route path="/settings" element={<Settings />} />
					<Route path="/settings/organisation-details" element={<OrganisationDetails />} />
				</Route>

				{/* custom layouts */}
				{/* different paths depending on if user is arriving from Routines page or Plans page, since the 'Home' button navigates differently */}
				<Route path="/routines/:id">{routineRoutes}</Route>
				<Route path="/plans/routines/:id">{routineRoutes}</Route>

				<Route path="/elibrary/:id">
					<Route
						index
						element={user.loggedIn ? user.permissions?.includes('StaffBookView') ? <BookReader /> : <Page401 /> : null}
					/>
					<Route path="quiz">
						<Route index element={<Navigate replace to={'1'} />} />
						<Route
							path=":quizPageNumber"
							element={
								user.loggedIn ? user.permissions?.includes('StaffBookView') ? <LayoutQuiz /> : <Page401 /> : null
							}
						/>
					</Route>
					<Route path="quiz-complete" element={<QuizComplete />} />
					<Route
						path="book-complete"
						element={
							user.loggedIn ? user.permissions?.includes('StaffBookComplete') ? <BookComplete /> : <Page401 /> : null
						}
					/>
				</Route>
			</Route>
		</>
	);
};

export default useStaffRoutes;
