import {
	Dispatch as ReactDispatch,
	FC,
	SetStateAction,
	useEffect,
	useRef,
	useState,
	MutableRefObject,
	PointerEvent as ReactPointerEvent,
	RefObject
} from 'react';

// PACKAGES
import { Fade } from 'react-bootstrap';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import { useAudioPlayer, useWindowSize } from '../../libs/customHooks';
import { guid, shuffleArray } from '../../libs/utils';
import CroppedImage from '../../components/_Helpers/CroppedImage';

// COMPONENTS
import DrawnLine, { DrawnLineType } from '../../components/DrawnLine/DrawnLine';
import QuizIntro from '../../components/Quiz/QuizIntro';
import QuizIntroText from '../../components/Quiz/QuizIntroText';
import QuizSubmit from '../../components/Quiz/QuizSubmit';

// TYPES
import { StaffBookViewActivity, StudentBookViewActivity } from '../../api/models';
import {
	QuizStateType,
	RandomisedBookViewMatchingWithLinesOption
} from '../../components/_Layout/LayoutQuiz/LayoutQuiz';

type ClickedAnswerSelectionsType = {
	selectedAnswer1Node: HTMLButtonElement | undefined;
	selectedAnswer2Node: HTMLButtonElement | undefined;
};

type AnswerNodesRefType = MutableRefObject<
	{ answer1Nodes: HTMLButtonElement[]; answer2Nodes: HTMLButtonElement[] } | undefined
>;

const mouseMoveThresholdAmount = 5; // px radius the mouse needs to move from in order for the line draw and answer deselection to kick in

const collisionWithMouseCursor = (x: number, y: number, rects: DOMRect) =>
	x <= rects.right && x >= rects.left && y >= rects.top && y <= rects.bottom;

const lineCoordsReset: DrawnLineType = {
	xStart: 0,
	yStart: 0,
	xEnd: 0,
	yEnd: 0
};

const returnOptionWithLineBasedOnMatchedAnswers = (
	option: RandomisedBookViewMatchingWithLinesOption,
	optionIndex: number,
	options: RandomisedBookViewMatchingWithLinesOption[],
	answerNodesRef: AnswerNodesRefType,
	containerRect: DOMRect | undefined
) => {
	const updatedOption = { ...option };

	// checking through an answer set, and updating the line data if the item has a matched answer1 and answer2 somewhere
	// prevents weirdness like lines disappearing if answer2 line is drawn to answer1, then that answer1 line is drawn somewhere else; would end up with double line or a disappearing line
	// the new 'draggingLine' component prevents such weirdness, but this function needs to run when the selected answers changed so this can set proper lines lines between matched answers
	const containerRectTop = containerRect ? containerRect.top : 0;
	const containerRectLeft = containerRect ? containerRect.left : 0;
	const answerText1 = option.selectedAnswer1Text;
	const domIndexAnswer2 = options.findIndex((opt) => answerText1 !== '' && opt.randomisedText === answerText1);
	if (domIndexAnswer2 === -1) {
		updatedOption.lineCoords = { ...lineCoordsReset };
		return updatedOption;
	}

	const answer1Node = answerNodesRef.current?.answer1Nodes[optionIndex];
	const answer2Node = answerNodesRef.current?.answer2Nodes[domIndexAnswer2];

	if (answer1Node && answer2Node) {
		const answer1NodeRects = answer1Node.getBoundingClientRect();
		const answer2NodeRects = answer2Node.getBoundingClientRect();

		updatedOption.lineCoords = {
			xStart: answer1NodeRects.right - containerRectLeft,
			yStart: answer1NodeRects.top - containerRectTop + answer1NodeRects.height / 2,
			xEnd: answer2NodeRects.left - containerRectLeft,
			yEnd: answer2NodeRects.top - containerRectTop + answer2NodeRects.height / 2
		};
	}

	return updatedOption;
};

const QuizQuestion: FC<{
	option: RandomisedBookViewMatchingWithLinesOption;
	setOptions: ReactDispatch<SetStateAction<RandomisedBookViewMatchingWithLinesOption[]>>;
	containerRef: RefObject<HTMLDivElement>;
	answerNodesRef: AnswerNodesRefType;
	pointerDownBtnRef: MutableRefObject<HTMLButtonElement | undefined>;
	pointerDownBtnNode: HTMLButtonElement | undefined;
	handlePointerDownButton: (e: ReactPointerEvent) => void;
	pointerUpBtnNode: HTMLButtonElement | undefined;
	setPointerUpBtnNode: ReactDispatch<SetStateAction<HTMLButtonElement | undefined>>;
	hasReachedAttemptLimit: boolean;
	clickedAnswerSelections: ClickedAnswerSelectionsType;
	setClickedAnswerSelections: ReactDispatch<SetStateAction<ClickedAnswerSelectionsType>>;
	quizState: QuizStateType;
	setQuizState: ReactDispatch<SetStateAction<QuizStateType>>;
}> = ({
	option,
	setOptions,
	containerRef,
	answerNodesRef,
	pointerDownBtnRef,
	pointerDownBtnNode,
	handlePointerDownButton,
	pointerUpBtnNode,
	setPointerUpBtnNode,
	hasReachedAttemptLimit,
	clickedAnswerSelections,
	setClickedAnswerSelections,
	quizState,
	setQuizState
}) => {
	const {
		hoveredAnswer1Text = '',
		hoveredAnswer2Text = '',
		id = '',
		imageAudioUrl,
		imageUrl = '',
		lineCoords,
		randomisedText,
		randomisedTextAudioUrl,
		selectedAnswer1Text,
		selectedAnswer2Text,
		answer1VerifiedAsCorrect,
		answer2VerifiedAsCorrect,
		text
	} = option;

	const audioPlayer = useAudioPlayer();
	const { addToAudioQueue, clearAudioQueue, getCurrentAudioClip } = audioPlayer;

	const selectedAnswerIsCorrect = selectedAnswer1Text === text;
	const clickedNodeIsAnswer1Node = pointerDownBtnNode?.dataset.selector === 'btn-answer-1';

	const hasSubmitted = quizState !== 'unsubmitted';

	const answer1Dragging = clickedNodeIsAnswer1Node && pointerDownBtnNode?.dataset.answer === text;
	const answer2Dragging = !clickedNodeIsAnswer1Node && pointerDownBtnNode?.dataset.randomText === randomisedText;
	const answer1Selected = clickedAnswerSelections.selectedAnswer1Node
		? clickedAnswerSelections.selectedAnswer1Node.dataset.answer === text
		: false;
	const answer2Selected = clickedAnswerSelections.selectedAnswer2Node
		? clickedAnswerSelections.selectedAnswer2Node.dataset.randomText === randomisedText
		: false;
	const answer1AudioPlaying =
		imageAudioUrl &&
		pointerUpBtnNode?.dataset.selector === 'btn-answer-1' &&
		getCurrentAudioClip()?.audioProps.url === imageAudioUrl;
	const answer2AudioPlaying =
		randomisedTextAudioUrl &&
		pointerUpBtnNode?.dataset.selector === 'btn-answer-2' &&
		getCurrentAudioClip()?.audioProps.url === randomisedTextAudioUrl;

	return (
		<div className="questions-row d-flex justify-content-between gap-3 w-100">
			<button
				type="button"
				className={`
					btn btn-option btn-draw-line answer1 
					${answer1AudioPlaying ? 'audio-playing' : ''}
					${answer1Dragging || answer1Selected ? 'line-dragging' : ''}
					${hoveredAnswer1Text || selectedAnswer1Text ? 'answer-selected' : ''}
					${hasSubmitted || answer1VerifiedAsCorrect ? (selectedAnswerIsCorrect ? 'success' : 'error') : ''}
					${answer1VerifiedAsCorrect ? 'pe-none' : ''}
					${(hasSubmitted && hasReachedAttemptLimit) || ['showHappyFace', 'canProceed'].includes(quizState) ? 'pe-none' : ''}
					`}
				data-selector="btn-answer-1"
				data-id={id}
				data-random-text={randomisedText}
				data-answer={text}
				data-selected-answer={selectedAnswer1Text}
				data-has-selected-answer={selectedAnswer1Text !== ''}
				onPointerDown={handlePointerDownButton}
				onPointerUp={(e) => {
					const target = e.target as HTMLButtonElement;
					if (target) setPointerUpBtnNode(target);

					// checking for collision on pointer up, since dragging outside the button and releasing on touch devices still plays the audio...
					if (
						pointerDownBtnRef.current?.dataset.selector === target.dataset.selector &&
						collisionWithMouseCursor(e.clientX, e.clientY, target.getBoundingClientRect())
					) {
						setQuizState('unsubmitted');
						const alreadyHasConfirmedAnswer = !!option.selectedAnswer1Text;
						if (alreadyHasConfirmedAnswer) {
							// un-confirming both answers if an option with a confirmed answer is clicked again
							setOptions((options) =>
								options.map((opt, optIndex, optArr) => {
									const newOption = { ...opt };
									if (opt.text === text) newOption.selectedAnswer1Text = '';
									if (opt.randomisedText === selectedAnswer1Text) newOption.selectedAnswer2Text = '';

									const containerRect = containerRef.current?.getBoundingClientRect();
									const optionWithUpdatedLine = returnOptionWithLineBasedOnMatchedAnswers(
										newOption,
										optIndex,
										optArr,
										answerNodesRef,
										containerRect
									);

									return optionWithUpdatedLine;
								})
							);
						} else {
							setClickedAnswerSelections((selections) => {
								// deselect option if selected again
								const sameNodeAlreadySelected =
									clickedAnswerSelections.selectedAnswer1Node !== undefined &&
									clickedAnswerSelections.selectedAnswer1Node.dataset.answer === text;
								return {
									...selections,
									selectedAnswer1Node: sameNodeAlreadySelected ? undefined : target
								};
							});

							// only trigger an audio interaction if it doesn't already have a confirmed answer
							if (imageAudioUrl) {
								if (answer1AudioPlaying) {
									clearAudioQueue();
								} else {
									clearAudioQueue();
									addToAudioQueue({
										id: guid(),
										url: imageAudioUrl
									});
								}
							}
						}
					}
				}}
			>
				{imageUrl && (
					<CroppedImage
						src={imageUrl}
						className="object-fit-scale pe-none user-select-none"
						width={240}
						height={240}
						alt=""
					/>
				)}
			</button>
			<button
				type="button"
				className={`
					btn btn-option btn-draw-line answer2 
					${answer2AudioPlaying ? 'audio-playing' : ''} 
					${answer2Dragging || answer2Selected ? 'line-dragging' : ''}
					${hoveredAnswer2Text || selectedAnswer2Text ? 'answer-selected' : ''} 
					${hasSubmitted || answer2VerifiedAsCorrect ? (selectedAnswer2Text === randomisedText ? 'success' : 'error') : ''}
					${answer2VerifiedAsCorrect ? 'pe-none' : ''}
					${(hasSubmitted && hasReachedAttemptLimit) || ['showHappyFace', 'canProceed'].includes(quizState) ? 'pe-none' : ''}
				`}
				data-selector="btn-answer-2"
				data-id={id}
				data-answer={text}
				data-random-text={randomisedText}
				data-selected-answer={selectedAnswer2Text}
				data-has-selected-answer={selectedAnswer2Text !== ''}
				onPointerDown={handlePointerDownButton}
				onPointerUp={(e) => {
					const target = e.target as HTMLButtonElement;
					if (target) setPointerUpBtnNode(target);

					// checking for collision on pointer up, since dragging outside the button and releasing on touch devices still plays the audio...
					if (
						pointerDownBtnRef.current?.dataset.selector === target.dataset.selector &&
						collisionWithMouseCursor(e.clientX, e.clientY, target.getBoundingClientRect())
					) {
						setQuizState('unsubmitted');
						const alreadyHasConfirmedAnswer = !!option.selectedAnswer2Text;
						if (alreadyHasConfirmedAnswer) {
							// un-confirming both answers if an option with a confirmed answer is clicked again
							setOptions((options) =>
								options.map((opt, optIndex, optArr) => {
									const newOption = { ...opt };
									if (opt.text === selectedAnswer2Text) newOption.selectedAnswer1Text = '';
									if (opt.randomisedText === randomisedText) newOption.selectedAnswer2Text = '';

									const containerRect = containerRef.current?.getBoundingClientRect();
									const optionWithUpdatedLine = returnOptionWithLineBasedOnMatchedAnswers(
										newOption,
										optIndex,
										optArr,
										answerNodesRef,
										containerRect
									);

									return optionWithUpdatedLine;
								})
							);
						} else {
							setClickedAnswerSelections((selections) => {
								// deselect option if selected again
								const sameNodeAlreadySelected =
									clickedAnswerSelections.selectedAnswer2Node !== undefined &&
									clickedAnswerSelections.selectedAnswer2Node.dataset.randomText === randomisedText;
								return {
									...selections,
									selectedAnswer2Node: sameNodeAlreadySelected ? undefined : target
								};
							});

							// only trigger an audio interaction if it doesn't already have a confirmed answer
							if (randomisedTextAudioUrl) {
								if (answer2AudioPlaying) {
									clearAudioQueue();
								} else {
									clearAudioQueue();
									addToAudioQueue({
										id: guid(),
										url: randomisedTextAudioUrl
									});
								}
							}
						}
					}
				}}
			>
				<h3 className="h1 display m-0 text-reset pe-none">{randomisedText}</h3>
				{hasSubmitted && (
					<div
						className={`btn btn-icon pe-none position-absolute start-100 translate-middle-x ${
							hasSubmitted ? (selectedAnswer2Text === randomisedText ? 'success' : 'error') : ''
						}`}
					>
						<SvgMask
							path={selectedAnswer2Text === randomisedText ? './svg/check.svg' : './svg/cross.svg'}
							width={32}
							height={32}
						/>
					</div>
				)}
			</button>
			<DrawnLine
				className={hasSubmitted || answer1VerifiedAsCorrect ? (selectedAnswer1Text === text ? 'success' : 'error') : ''}
				xStart={lineCoords.xStart}
				yStart={lineCoords.yStart}
				xEnd={lineCoords.xEnd}
				yEnd={lineCoords.yEnd}
			/>
		</div>
	);
};

const QuizMatchinWithLines: FC<{
	activityData: StaffBookViewActivity | StudentBookViewActivity;
	bookId: string;
	questionNumber: string;
	totalQuestions: number;
}> = ({ activityData, bookId, questionNumber, totalQuestions }) => {
	const { matchinWithLines, status = '' } = activityData;
	const attempts = 'attempts' in activityData ? activityData.attempts || 0 : 0;
	const maxActivityAttempts = 'maxActivityAttempts' in activityData ? activityData.maxActivityAttempts || 2 : 2;

	const [isMounted, setIsMounted] = useState(false);
	const [showIntro, setShowIntro] = useState(questionNumber === '1');
	const [showQuiz, setShowQuiz] = useState(!showIntro);
	const [quizAttempts, setQuizAttempts] = useState(attempts);
	const [hasReachedAttemptLimit, setHasReachedAttemptLimit] = useState(false);

	const [quizState, setQuizState] = useState<QuizStateType>('unsubmitted');

	const [buttonIsClickedAndPointerIsDragging, setButtonIsClickedAndPointerIsDragging] = useState(false);
	const [hoveredAnswerNode, setHoveredAnswerNode] = useState<HTMLButtonElement>();
	const [pointerDownBtnNode, setPointerDownBtnNode] = useState<HTMLButtonElement>();
	const [pointerUpBtnNode, setPointerUpBtnNode] = useState<HTMLButtonElement>();
	const [options, setOptions] = useState<RandomisedBookViewMatchingWithLinesOption[]>([]); // for the result of the shuffled options and texts
	const [draggingLineCoords, setDraggingLineCoords] = useState<DrawnLineType>(lineCoordsReset);

	const containerRef = useRef<HTMLDivElement>(null);
	const mouseMovementThresholdRef = useRef<{ top: number; right: number; bottom: number; left: number }>();
	const pointerDownBtnRef = useRef<HTMLButtonElement>();
	const hoveredAnswerRef = useRef<HTMLButtonElement>();
	const answerNodesRef = useRef<{
		answer1Nodes: HTMLButtonElement[];
		answer2Nodes: HTMLButtonElement[];
	}>();

	const [clickedAnswerSelections, setClickedAnswerSelections] = useState<ClickedAnswerSelectionsType>({
		selectedAnswer1Node: undefined,
		selectedAnswer2Node: undefined
	});

	const windowSize = useWindowSize();

	const quizAlreadyCompleted = ['Successful', 'Unsuccessful'].includes(status);

	const handleReset = () => {
		setOptions(
			options.map((opt) => {
				const updatedOption = { ...opt };
				// separate handling for answer1 vs answer2...
				if (!updatedOption.answer1VerifiedAsCorrect) {
					updatedOption.selectedAnswer1Text = '';
					updatedOption.lineCoords = {
						xStart: 0,
						yStart: 0,
						xEnd: 0,
						yEnd: 0
					};
				}

				if (!updatedOption.answer2VerifiedAsCorrect) {
					updatedOption.selectedAnswer2Text = '';
				}

				return updatedOption;
			})
		);
	};

	const handlePointerMove = (e: PointerEvent) => {
		const clickedNodeIsAnswer1Node = pointerDownBtnRef.current?.dataset.selector === 'btn-answer-1';
		const clickedNodeRects = pointerDownBtnRef.current?.getBoundingClientRect();

		let isMouseInMovementThresholdBox = true;
		if (mouseMovementThresholdRef.current !== undefined) {
			isMouseInMovementThresholdBox = collisionWithMouseCursor(e.x, e.y, mouseMovementThresholdRef.current as DOMRect);
		}

		if (!isMouseInMovementThresholdBox && clickedNodeRects && answerNodesRef.current) {
			setButtonIsClickedAndPointerIsDragging(true);

			const containerRect = containerRef.current?.getBoundingClientRect();
			const containerRectTop = containerRect ? containerRect.top : 0;
			const containerRectLeft = containerRect ? containerRect.left : 0;
			let xEnd = 0;
			let yEnd = 0;

			// only snap the line to answers that don't already have a selected answer
			// checking the hoveredAnswerNode state doesn't work
			if (hoveredAnswerRef.current?.dataset.hasSelectedAnswer === 'false') {
				const { top, right, left, height } = hoveredAnswerRef.current.getBoundingClientRect();
				xEnd = (clickedNodeIsAnswer1Node ? left : right) - containerRectLeft;
				yEnd = top - containerRectTop + height / 2;
			} else {
				xEnd = e.x - containerRectLeft;
				yEnd = e.y - containerRectTop;
			}

			setDraggingLineCoords((coords) => {
				return {
					...coords,
					xStart: (clickedNodeIsAnswer1Node ? clickedNodeRects.right : clickedNodeRects.left) - containerRectLeft,
					yStart: clickedNodeRects.top - containerRectTop + clickedNodeRects.height / 2,
					xEnd,
					yEnd
				};
			});

			// checking for collisions against either answer1 or answer2 nodes, depending on what was clicked
			// only checking for collision if node doesn't already have an answer
			let collision: HTMLButtonElement | undefined = undefined;
			const nodesToIterate = answerNodesRef.current[clickedNodeIsAnswer1Node ? 'answer2Nodes' : 'answer1Nodes'];
			for (const answerNode of nodesToIterate.filter((node) => node.dataset.hasSelectedAnswer === 'false')) {
				const rects = answerNode.getBoundingClientRect();
				if (collisionWithMouseCursor(e.x, e.y, rects)) {
					collision = answerNode;
					break;
				}
			}

			hoveredAnswerRef.current = collision;
			setHoveredAnswerNode(collision);
		}
	};

	const handlePointerDownButton = (e: ReactPointerEvent) => {
		const target = e.target as HTMLButtonElement;
		pointerDownBtnRef.current = target;

		mouseMovementThresholdRef.current = {
			top: e.clientY - mouseMoveThresholdAmount,
			right: e.clientX + mouseMoveThresholdAmount,
			bottom: e.clientY + mouseMoveThresholdAmount,
			left: e.clientX - mouseMoveThresholdAmount
		};

		if (!answerNodesRef.current) {
			answerNodesRef.current = {
				answer1Nodes: [...document.querySelectorAll('[data-selector="btn-answer-1"]')] as HTMLButtonElement[],
				answer2Nodes: [...document.querySelectorAll('[data-selector="btn-answer-2"]')] as HTMLButtonElement[]
			};
		}
	};

	const handlePointerUp = () => {
		setButtonIsClickedAndPointerIsDragging(false);
		mouseMovementThresholdRef.current = undefined;
	};

	// lock correct answers in after submission
	useEffect(() => {
		if (quizState === 'showHappyFace' || quizState === 'showSadFace') {
			setOptions(
				options.map((option) => {
					return {
						...option,
						answer1VerifiedAsCorrect: option.selectedAnswer1Text === option.text,
						answer2VerifiedAsCorrect: option.selectedAnswer2Text === option.randomisedText
					};
				})
			);
		}
	}, [quizState]); // eslint-disable-line react-hooks/exhaustive-deps

	// setting the fixed lines again whenever the window resizes so they stay accurately drawn
	useEffect(() => {
		setOptions((options) => {
			const containerRect = containerRef.current?.getBoundingClientRect();
			const updatedOptions = options.map((opt, optIndex, optArr) => {
				return returnOptionWithLineBasedOnMatchedAnswers(opt, optIndex, optArr, answerNodesRef, containerRect);
			});
			return updatedOptions;
		});
	}, [windowSize]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		hoveredAnswerRef.current = hoveredAnswerNode;
		const clickedNodeIsAnswer1Node = pointerDownBtnNode?.dataset.selector === 'btn-answer-1';

		// updating the original question array with new answer data
		setOptions((options) =>
			options.map((opt) => {
				/*
					working both ways!
					for eg: when hovering answer1-1 against answer2-3:
					1				1
						\
					2		\		2
								\
					3				3
					answer1:
						hoveredAnswer1Text should be answer2 randomText (as only answer2 set is 'randomised')
						hoveredAnswer2Text should be empty string
					answer3:
						hoveredAnswer1Text should be empty string
						hoveredAnswer2Text should be answer1 text
				*/
				const updatedOption = { ...opt, hoveredAnswer1Text: '', hoveredAnswer2Text: '' };

				if (clickedNodeIsAnswer1Node) {
					if (pointerDownBtnNode?.dataset.answer === opt.text) {
						updatedOption.hoveredAnswer1Text = hoveredAnswerNode?.dataset.randomText || '';
					}
					if (hoveredAnswerNode?.dataset.randomText === opt.randomisedText) {
						updatedOption.hoveredAnswer2Text = pointerDownBtnNode?.dataset.answer || '';
					}
				} else {
					if (pointerDownBtnNode?.dataset.randomText === opt.randomisedText) {
						updatedOption.hoveredAnswer2Text = hoveredAnswerNode?.dataset.answer || '';
					}
					if (hoveredAnswerNode?.dataset.answer === opt.text) {
						updatedOption.hoveredAnswer1Text = pointerDownBtnNode?.dataset.randomText || '';
					}
				}

				return updatedOption;
			})
		);
	}, [hoveredAnswerNode]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const containerRect = containerRef.current?.getBoundingClientRect();
		const clickedNodeIsAnswer1Node = pointerDownBtnRef.current?.dataset.selector === 'btn-answer-1';

		if (buttonIsClickedAndPointerIsDragging) {
			setPointerDownBtnNode(pointerDownBtnRef.current);
			setQuizState('unsubmitted');

			// reset the hovered/selected answers for that answer set, depending on whether answer1 or answer2 is clicked
			setOptions((options) =>
				options.map((opt, optIndex, optArr) => {
					const newOption = { ...opt };
					if (clickedNodeIsAnswer1Node) {
						if (pointerDownBtnRef.current?.dataset.answer === opt.text) {
							newOption.hoveredAnswer1Text = '';
							newOption.selectedAnswer1Text = '';
						}

						if (pointerDownBtnRef.current?.dataset.selectedAnswer === newOption.randomisedText) {
							newOption.hoveredAnswer2Text = '';
							newOption.selectedAnswer2Text = '';
						}
					} else {
						if (pointerDownBtnRef.current?.dataset.randomText === newOption.randomisedText) {
							newOption.hoveredAnswer2Text = '';
							newOption.selectedAnswer2Text = '';
						}

						if (pointerDownBtnRef.current?.dataset.selectedAnswer === newOption.text) {
							newOption.hoveredAnswer1Text = '';
							newOption.selectedAnswer1Text = '';
						}
					}

					const optionWithUpdatedLine = returnOptionWithLineBasedOnMatchedAnswers(
						newOption,
						optIndex,
						optArr,
						answerNodesRef,
						containerRect
					);
					return optionWithUpdatedLine;
				})
			);
		} else {
			setOptions(
				options.map((opt, optIndex, optArr) => {
					const { hoveredAnswer1Text, hoveredAnswer2Text } = opt;
					const newOption = { ...opt, hoveredAnswer1Text: '', hoveredAnswer2Text: '' };

					if (clickedNodeIsAnswer1Node) {
						if (pointerDownBtnNode?.dataset.answer === opt.text) {
							newOption.selectedAnswer1Text = hoveredAnswer1Text;
						}
						if (hoveredAnswerNode?.dataset.randomText === opt.randomisedText) {
							newOption.selectedAnswer2Text = hoveredAnswer2Text;
						}
					} else {
						if (pointerDownBtnNode?.dataset.randomText === opt.randomisedText) {
							newOption.selectedAnswer2Text = hoveredAnswer2Text;
						}
						if (hoveredAnswerNode?.dataset.answer === opt.text) {
							newOption.selectedAnswer1Text = hoveredAnswer1Text;
						}
					}

					const updatedOptionWithNewLine = returnOptionWithLineBasedOnMatchedAnswers(
						newOption,
						optIndex,
						optArr,
						answerNodesRef,
						containerRect
					);

					return updatedOptionWithNewLine;
				})
			);

			setHoveredAnswerNode(undefined);
			setPointerDownBtnNode(undefined);
			hoveredAnswerRef.current = undefined;
			pointerDownBtnRef.current = undefined;
			setClickedAnswerSelections({
				selectedAnswer1Node: undefined,
				selectedAnswer2Node: undefined
			});
			setDraggingLineCoords(lineCoordsReset); // resetting dragging line coords now that the pointer has been released
		}
	}, [buttonIsClickedAndPointerIsDragging]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// setting answers based on clicked (not dragged) selections
		const containerRect = containerRef.current?.getBoundingClientRect();
		const { selectedAnswer1Node, selectedAnswer2Node } = clickedAnswerSelections;

		if (selectedAnswer1Node && selectedAnswer2Node) {
			setOptions((options) =>
				options.map((opt, optIndex, optArr) => {
					const newOption = { ...opt, hoveredAnswer1Text: '', hoveredAnswer2Text: '' };
					if (selectedAnswer1Node.dataset.answer === opt.text) {
						newOption.selectedAnswer1Text = selectedAnswer2Node.dataset.randomText || '';
					}

					if (selectedAnswer2Node.dataset.randomText === opt.randomisedText) {
						newOption.selectedAnswer2Text = selectedAnswer1Node.dataset.answer || '';
					}

					const updatedOptionWithNewLine = returnOptionWithLineBasedOnMatchedAnswers(
						newOption,
						optIndex,
						optArr,
						answerNodesRef,
						containerRect
					);

					return updatedOptionWithNewLine;
				})
			);

			// now that the answers are set, reset the clicked nodes
			setClickedAnswerSelections({
				selectedAnswer1Node: undefined,
				selectedAnswer2Node: undefined
			});
		}
	}, [clickedAnswerSelections]);

	useEffect(() => {
		if (isMounted) {
			const shuffledTextOptions = matchinWithLines?.options
				? shuffleArray(
						matchinWithLines.options.map((option) => {
							return {
								randomisedTextAudioUrl: option.textAudioUrl || '',
								text: option.text || ''
							};
						})
				  )
				: [];
			const optionsWithRandomisedText: RandomisedBookViewMatchingWithLinesOption[] = [];
			matchinWithLines?.options?.forEach((option, i) =>
				optionsWithRandomisedText.push({
					...option,
					randomisedTextAudioUrl: shuffledTextOptions[i].randomisedTextAudioUrl,
					randomisedText: shuffledTextOptions[i].text,
					hoveredAnswer1Text: '',
					hoveredAnswer2Text: '',
					selectedAnswer1Text: '',
					selectedAnswer2Text: '',
					answer1VerifiedAsCorrect: false,
					answer2VerifiedAsCorrect: false,
					lineCoords: lineCoordsReset
				})
			);
			setOptions(optionsWithRandomisedText);
		}
	}, [isMounted]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.addEventListener('pointerup', handlePointerUp);
		document.addEventListener('pointermove', handlePointerMove);
		setIsMounted(true);

		return () => {
			document.removeEventListener('pointerup', handlePointerUp);
			document.removeEventListener('pointermove', handlePointerMove);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!matchinWithLines) return null;

	const { question = '', questionAudioUrl = '' } = matchinWithLines;
	const allAnswersSelected = !!options.length && options.every((opt) => opt.selectedAnswer1Text);
	const allAnswersCorrect = !!options.length && options.every((opt) => opt.selectedAnswer1Text === opt.text);

	return (
		<div className={`quiz-matchin-with-lines d-flex flex-grow-1 ${showQuiz ? '' : 'intro-is-showing'}`}>
			<QuizIntro
				showIntro={showIntro}
				setShowIntro={setShowIntro}
				setShowQuiz={setShowQuiz}
				questionNumber={questionNumber}
				totalQuestions={totalQuestions}
			/>
			<Fade in={showQuiz} mountOnEnter>
				<div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 gap-4 text-center py-4">
					<div className="container container-large-gutters">
						<div className="row w-100">
							<div className="col-xl-8 offset-xl-2">
								<p className="text-shades-500 m-4">
									<strong>
										{questionNumber} / {totalQuestions}
									</strong>
								</p>
								<QuizIntroText questionAudioUrl={questionAudioUrl} questionText={question} />
							</div>
						</div>
					</div>
					<div className="container d-flex flex-column flex-grow-1 gap-5">
						<div className="d-flex flex-column justify-content-center flex-grow-1 w-100">
							<div
								ref={containerRef}
								className="position-relative d-flex flex-column flex-grow-1 justify-content-center gap-3"
							>
								{/* 
										rathering than managing this where a specific line from random row / column can be drawn to anywhere...
										instead:
											- on pointer down, check the answer matches to see which lines should persist
											- when moving the pointer, this temporary <DrawnLine /> is the line that should move
											- on release, delete this line and set the actual lines based on the matched answer1 / answer2 pairs
									*/}
								{draggingLineCoords && <DrawnLine {...draggingLineCoords} />}
								{options.map((option) => {
									return (
										<QuizQuestion
											key={option.id}
											option={option}
											setOptions={setOptions}
											containerRef={containerRef}
											answerNodesRef={answerNodesRef}
											pointerDownBtnRef={pointerDownBtnRef}
											pointerDownBtnNode={pointerDownBtnNode}
											handlePointerDownButton={handlePointerDownButton}
											pointerUpBtnNode={pointerUpBtnNode}
											setPointerUpBtnNode={setPointerUpBtnNode}
											hasReachedAttemptLimit={hasReachedAttemptLimit}
											clickedAnswerSelections={clickedAnswerSelections}
											setClickedAnswerSelections={setClickedAnswerSelections}
											quizState={quizState}
											setQuizState={setQuizState}
										/>
									);
								})}
							</div>
						</div>
						<QuizSubmit
							hasReachedAttemptLimit={hasReachedAttemptLimit}
							quizAlreadyCompleted={quizAlreadyCompleted}
							questionNumber={questionNumber}
							totalQuestions={totalQuestions}
							bookId={bookId}
							activityId={matchinWithLines.id || ''}
							maxActivityAttempts={maxActivityAttempts}
							allAnswersSelected={allAnswersSelected}
							allAnswersCorrect={allAnswersCorrect}
							quizAttempts={quizAttempts}
							setQuizAttempts={setQuizAttempts}
							quizState={quizState}
							setQuizState={setQuizState}
							setHasReachedAttemptLimit={setHasReachedAttemptLimit}
							handleReset={handleReset}
						/>
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default QuizMatchinWithLines;
