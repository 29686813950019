import { FC } from 'react';

// UTILS
import { useAudioPlayer } from '../../libs/customHooks';
import { guid } from '../../libs/utils';

const QuizIntroText: FC<{ questionAudioUrl: string; questionText: string }> = ({ questionAudioUrl, questionText }) => {
	const audioPlayer = useAudioPlayer();
	const { addToAudioQueue, clearAudioQueue, getCurrentAudioClip } = audioPlayer;

	const introAudioPlaying = getCurrentAudioClip()?.audioProps.url === questionAudioUrl;

	return (
		<div className={`quiz-intro d-flex justify-content-center gap-3 ${introAudioPlaying ? 'audio-playing' : ''}`}>
			{questionAudioUrl && (
				<button
					type="button"
					className="btn btn-icon btn-audio-icon"
					aria-label="Play audio"
					onClick={() => {
						if (getCurrentAudioClip()?.audioProps.url === questionAudioUrl) {
							clearAudioQueue();
						} else {
							clearAudioQueue();
							addToAudioQueue({
								id: guid(),
								url: questionAudioUrl
							});
						}
					}}
				>
					<i></i>
				</button>
			)}
			<h1 className="text-quiz-intro">
				<strong>{questionText}</strong>
			</h1>
		</div>
	);
};

export default QuizIntroText;
