import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	run: false,
	stepIndex: 0
};

// using Redux to store some FTUX state, so components can know if it's running
// say we're on the eLibrary page... if FTUX is running, we may want to open the first accordion and highlight the book
// need to read from a global store to tell if it's running so we can do that
export const FtuxSlice = createSlice({
	name: 'ftux',
	initialState,
	reducers: {
		begin: (state) => {
			state = Object.assign(state, {
				...initialState,
				run: true
			});
		},
		goNext: (state) => {
			state = Object.assign(state, {
				...state,
				stepIndex: state.stepIndex + 1
			});
		},
		cancel: (state) => {
			state = Object.assign(state, {
				...state,
				run: false
			});
		}
	}
});
