import React from 'react';
import { Dropdown } from 'react-bootstrap';

const CategoryDropdown: React.FC<{
	items: {
		domId: string;
		name: string | null;
	}[];
	isDisabled?: boolean;
}> = ({ items, isDisabled = false }) => {
	return (
		items && (
			<Dropdown>
				<Dropdown.Toggle id="dropdown-title-section" variant="dropdown" disabled={isDisabled}>
					Go to category
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{items.map((item, itemIndex) => {
						const { domId } = item;
						const name = item.name || '';
						return (
							<Dropdown.Item
								key={itemIndex}
								as="button"
								onClick={() => {
									// scroll to the section selected in the dropdown
									const mainContent = document.querySelector('#main-content');
									if (!mainContent) return;
									const helpCentreSection = mainContent.querySelector<HTMLDivElement>(domId);
									if (helpCentreSection?.offsetTop) {
										mainContent.scrollTo({ top: helpCentreSection.offsetTop - 100, behavior: 'smooth' });
									}
								}}
							>
								{name}
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown>
		)
	);
};

export default CategoryDropdown;
