import { FC, useEffect, useState } from 'react';

// PACKAGES
import { Accordion, Modal, Spinner } from 'react-bootstrap';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import RichText from '../../components/RichText/RichText';
import CategoryDropdown from '../../components/TitleSection/CategoryDropdown';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import { loadExternalScript } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { HelpCentreListCategoryItem, HelpCentreListCollection } from '../../api/models';

const HelpCentreAccordion: FC<{
	categorySectionId: number | undefined;
	categoryItem: HelpCentreListCategoryItem;
}> = ({ categorySectionId = '', categoryItem }) => {
	const [contentShow, setContentShow] = useState(false);
	const { content = '', key = '', title = '' } = categoryItem;

	return (
		<Accordion defaultActiveKey={`${key}`} alwaysOpen>
			<Accordion.Item eventKey={`${categorySectionId}-${key}`}>
				<Accordion.Header as="h3">
					<span className="h3 my-0 me-4">
						<strong>{title}</strong>
					</span>
				</Accordion.Header>
				<Accordion.Body onEnter={() => setContentShow(true)}>
					{contentShow && content && <RichText content={{ markup: content }} />}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

const HelpCentre: FC = () => {
	const [isLoading, setIsLoading] = useState(true);

	const [helpCentreData, setHelpCentreData] = useState<HelpCentreListCollection>();
	const [filterText, setFilterText] = useState('');
	const [showContactModal, setShowContactModal] = useState(false);
	const [hubspotFormSubmitted, setHubspotFormSubmitted] = useState(false);

	const filteredHelpCentreData: HelpCentreListCollection = {
		...helpCentreData,
		categories: helpCentreData?.categories?.map((category) => {
			return {
				...category,
				categoryItems: category.categoryItems?.filter((categoryItem) => {
					const filterTextLowercase = filterText.toLowerCase();
					const { content = '', title = '' } = categoryItem;
					return (
						!filterText ||
						title.toLowerCase().includes(filterTextLowercase) ||
						content.toLowerCase().includes(filterTextLowercase)
					);
				})
			};
		})
	};

	const categoryItems = helpCentreData
		? helpCentreData.categories?.map((category) => {
				return {
					domId: `#category-section-${category.id}`,
					name: category.name || ''
				};
		  })
		: null;

	const getHelpCentreData = async () => {
		const response = await apibridge.getHelpCentreList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setHelpCentreData({
				...response.data.result,
				// don't include any empty categories
				categories: response.data.result.categories?.filter((category) => category.categoryItems?.length)
			});
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getHelpCentreData();
		loadExternalScript('//js.hsforms.net/forms/embed/v2.js', 'hubspot-code');
	}, []);

	return (
		<div className="page-help-centre d-flex flex-column flex-grow-1">
			<TitleSection title="Help Centre">
				<div className="row row-gap-3">
					<div className="col">
						{categoryItems && <CategoryDropdown items={categoryItems} isDisabled={filterText !== ''} />}
					</div>
					<div className="col-auto col-md-4 col-lg-3">
						<div className="position-relative d-flex align-items-center">
							<input
								type="text"
								className={`form-control field-filter ${filterText ? 'pe-5' : ''}`}
								placeholder="Filter by keyword"
								value={filterText}
								onChange={(e) => {
									const target = e.target as HTMLInputElement;
									setFilterText(target.value);
								}}
							/>
							{filterText && (
								<button type="button" className="btn btn-input-clear" onClick={() => setFilterText('')}>
									<span className="visually-hidden">Clear</span>
									<i></i>
								</button>
							)}
						</div>
					</div>
				</div>
			</TitleSection>
			<section className="container h-100">
				{isLoading ? (
					<div className="d-flex justify-content-center">
						<DelayedFadeIn>
							<Spinner />
						</DelayedFadeIn>
					</div>
				) : (
					<>
						<div className="row h-100">
							<div className="col-xl-9 offset-xl-1 h-100">
								<div className="d-flex flex-column gap-4-5 h-100">
									{filteredHelpCentreData.categories?.length ? (
										filteredHelpCentreData.categories.map((helpCentreCategory) => {
											const { categoryItems, id, name } = helpCentreCategory;
											return (
												<div key={id} id={`category-section-${id}`} className="category-section d-flex flex-column">
													{name && (
														<div className="category-title border-bottom">
															<h3 className="h4 m-0">{name}</h3>
														</div>
													)}
													{categoryItems?.map((categoryItem) => (
														<HelpCentreAccordion
															key={`${id}-${categoryItem.key}`}
															categorySectionId={id}
															categoryItem={categoryItem}
														/>
													))}
												</div>
											);
										})
									) : (
										<div className="d-flex align-items-center justify-content-center h-100 text-shades-500 m-0">
											<h3>No results found</h3>
										</div>
									)}

									<div className="help-bar d-flex align-items-center bg-primary text-white p-4">
										<div className="d-flex flex-grow-1 gap-2">
											<div className="me-1">
												<SvgMask path={'/svg/help-solid.svg'} width={24} height={24} />
											</div>
											<div>
												<h4 className="mb-1">Can't find what you are looking for?</h4>
												<p className="m-0">Send us an enquiry and we'll be glad to help!</p>
											</div>
										</div>
										<button
											type="button"
											className="btn btn-white btn-contact-us"
											onClick={() => setShowContactModal(true)}
										>
											Contact us
										</button>
									</div>
								</div>
							</div>
						</div>
						<Modal
							show={showContactModal}
							onHide={() => setShowContactModal(false)}
							onShow={() => {
								window.hbspt.forms.create({
									region: 'na1',
									portalId: '8803439',
									formId: '47b507bd-37c3-4d4f-b1ac-458b326454c1',
									target: '#hubspot-target',
									onFormSubmit: () => {
										setHubspotFormSubmitted(true);
									}
								});
							}}
							centered
						>
							<Modal.Body>
								<button
									type="button"
									className="btn-reset close"
									aria-label="Close"
									onClick={() => setShowContactModal(false)}
								>
									<SvgMask path="/svg/cross.svg" width={16} height={16} />
								</button>
								<h2 className="m-0">
									<strong>Contact Little Learners</strong>
								</h2>
								{!hubspotFormSubmitted && (
									<p className="m-0">
										Add your message below and we will aim to get back to you within 2 business days.
									</p>
								)}
								<div id="hubspot-target">
									<div className="text-center py-5">
										<Spinner animation="border" />
									</div>
								</div>
							</Modal.Body>
						</Modal>
					</>
				)}
			</section>
		</div>
	);
};

export default HelpCentre;
