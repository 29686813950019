import React, { FC, useRef } from 'react';

// PACKAGES
import { Field, connect } from 'formik';

// COMPONENTS
import SvgMask from '../_Helpers/SvgMask';
import { StaffUploadEmail } from '../../api/models';
import { debounce } from '../../libs/utils';

interface ChipTypes {
	email: StaffUploadEmail;
	index: number;
	arrayHelpers: any;
	remainingInvites: number | undefined;
	formik?: any;
	validEmails?: StaffUploadEmail[];
}

const Chip: FC<ChipTypes> = ({ email, index, arrayHelpers, remainingInvites, formik, validEmails }) => {
	const contentEditableRef = useRef<HTMLDivElement>(null);
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

	const setExcess = () => {
		const indexValidEmail = validEmails?.findIndex(
			(item: StaffUploadEmail) => item.emailAddress === email.emailAddress
		);

		if (!remainingInvites || !indexValidEmail || indexValidEmail === -1) return;

		if (indexValidEmail > remainingInvites - 1) {
			return true;
		}
	};

	const handleChange = (event: React.FocusEvent<HTMLDivElement>) => {
		const email = event.target.textContent;

		if (email === '') {
			arrayHelpers.remove(index);
		} else {
			arrayHelpers.replace(index, {
				emailAddress: email,
				status: !emailRegex.test(email || '') ? 'Invalid' : 'Valid'
			});
		}
	};

	const handleRemove = () => {
		arrayHelpers.remove(index);
	};

	return (
		<Field key={email.emailAddress} name={`emails.${index}.emailAddress`}>
			{({ field, form, meta }: any) => (
				<div
					className={`badge chip ${
						formik.errors.emails?.some((item: StaffUploadEmail) => item.emailAddress === email.emailAddress) ||
						setExcess()
							? 'error'
							: ''
					} `}
				>
					{(formik.errors.emails?.some((item: StaffUploadEmail) => item.emailAddress === email.emailAddress) ||
						setExcess()) && <i className="error-icon"></i>}
					<div
						suppressContentEditableWarning
						contentEditable={true}
						className="email"
						onBlur={handleChange}
						dangerouslySetInnerHTML={{ __html: field.value }}
						ref={contentEditableRef}
						onPaste={(e) => e.preventDefault()}
					/>

					<button type="button" className="btn btn-reset close" aria-label="Close" onClick={() => handleRemove()}>
						<SvgMask path="/svg/cross.svg" width={16} height={16} />
					</button>
				</div>
			)}
		</Field>
	);
};

export default connect(Chip);
