import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Fade, Spinner } from 'react-bootstrap';
import HTMLFlipBook from 'react-pageflip';

// UTILS
import SvgMask from '../_Helpers/SvgMask';
import { useAudioPlayer, useWindowSize } from '../../libs/customHooks';
import CroppedImage from '../_Helpers/CroppedImage';
import { guid } from '../../libs/utils';

// TYPES
import apibridge from '../../apibridge';
import { StaffBookViewBook, StaffBookViewTag, StudentBookViewBook, StudentBookViewTag } from '../../api/models';

const Tag: React.FC<{
	tag: StaffBookViewTag | StudentBookViewTag;
}> = ({ tag }) => {
	const { audio = [], heightPercent, widthPercent, xCoordinatePercent, yCoordinatePercent } = tag;

	const audioPlayer = useAudioPlayer();
	const { addToAudioQueue, clearAudioQueue, getCurrentAudioClip } = audioPlayer;
	const currentAudioClip = getCurrentAudioClip();

	const isThisTagAudioPlaying = audio.some((audioItem) => {
		return audioItem.id === currentAudioClip?.audioProps.id && audioItem.url === currentAudioClip?.audioProps.url;
	});

	const styles = {
		top: `${yCoordinatePercent}%`,
		left: `${xCoordinatePercent}%`,
		width: `${widthPercent}%`,
		height: `${heightPercent}%`
	};

	return (
		<>
			{audio.length !== 0 && (
				<button
					type="button"
					className={`hotspot btn-reset ${isThisTagAudioPlaying ? 'audio-playing' : ''}`}
					style={{ ...styles }}
					onClick={() => {
						clearAudioQueue();

						if (!isThisTagAudioPlaying) {
							for (const audioItem of audio) {
								const { id = '', url = '' } = audioItem;
								addToAudioQueue({ id, url });
							}
						}
					}}
				>
					<span className="visually-hidden">Play audio</span>
				</button>
			)}
		</>
	);
};

const Book: React.FC<{
	bookData: StaffBookViewBook | StudentBookViewBook;
	bookId: string;
	isZoomed: boolean;
	setIsZoomed: React.Dispatch<SetStateAction<boolean>>;
}> = ({ bookData, bookId, isZoomed, setIsZoomed }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { getAudioQueue } = useAudioPlayer();
	const windowSize = useWindowSize();
	const systemInfo = useAppSelector((state) => state.systemInfo);

	const width = 1276;
	const height = 1560;
	const customWidth = width * 0.15;
	const customHeight = height * 0.15;

	const [bookIsRendered, setBookIsRendered] = useState(false);
	const [pagesInfo, setPagesInfo] = useState({ currentPage: 0, totalPages: 0 });
	const [isClicking, setIsClicking] = useState(false);
	const isClickingRef = useRef(false);
	const isZoomedRef = useRef(false);
	const [showZoomAlert, setShowZoomAlert] = useState(false);
	const [loadedPageImageTotal, setLoadedPageImageTotal] = useState(0);

	const sectionRef = useRef<HTMLDivElement>(null);
	const translateFromRef = useRef({ x: 0, y: 0 });

	const book = useRef<typeof HTMLFlipBook>();
	const bookCurrent = book.current as any; // necessary since the plugin wrapper is very poorly typed

	const bookRef = useRef<HTMLDivElement>(null);

	// the 'end book' arrow can be either behind the book, or to the right of the screen, depending on whether the last page is even or odd...
	const bookNode: HTMLDivElement | null = document.querySelector('#book');
	const bookNodeWidth = bookNode ? bookNode.clientWidth : 0;
	const windowToBookWidthDiff = (windowSize.width || 0) - bookNodeWidth;

	const isFirstPage = pagesInfo.currentPage === 0;
	const isLastPage = pagesInfo.currentPage >= pagesInfo.totalPages - 2;
	const totalPagesIsEvenNumber = pagesInfo.totalPages % 2 !== 1;
	const { pages } = bookData;

	const handlePointerMove = (e: PointerEvent) => {
		if (isClickingRef.current && isZoomedRef.current) {
			// if zoomed in, pan the screen around on click and move
			const { clientX, clientY } = e;
			const fromX = translateFromRef.current.x;
			const fromY = translateFromRef.current.y;

			if (bookRef.current) {
				bookRef.current.style.translate = `${clientX - fromX}px ${clientY - fromY}px`;
			}
		}
	};

	const handlePointerDown = (e: React.PointerEvent) => {
		if (isZoomed) {
			// save the current clicked x/y position if zoomed in, so can translate FROM it when the pointer is moving
			const { clientX, clientY } = e;
			let xTranslateFrom = 0;
			let yTranslateFrom = 0;

			if (bookRef.current?.style.translate) {
				const translateFrom = bookRef.current.style.translate.split(' ');
				xTranslateFrom = parseInt(translateFrom[0]) || 0;
				yTranslateFrom = parseInt(translateFrom[1]) || 0;
			}

			translateFromRef.current = { x: clientX - xTranslateFrom, y: clientY - yTranslateFrom };
		}

		setIsClicking(true);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'ArrowLeft') bookCurrent?.pageFlip()?.flipPrev();
		if (e.key === 'ArrowRight') bookCurrent?.pageFlip()?.flipNext();
		if (e.key === 'Escape') setIsZoomed(false);
	};

	const handlePointerUp = () => {
		setIsClicking(false);
	};

	const handleBookProgressSend = async (pageId: string) => {
		if (systemInfo.type === 'staff') return;

		const response = await apibridge.postStudentBookProgress({
			bookId,
			pageId
		});
		if (response && response.data && response.data.isError && response.data.validationErrors) {
			for (const err of response.data.validationErrors) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: 'Analytics error',
						description: err.reason || 'Unknown error'
					})
				);
			}
		}
	};

	const onInit = useCallback(
		(e: {
			data: {
				page: number;
				mode: string;
			};
			object: any;
		}) => {
			if (bookRef.current) {
				setPagesInfo({ currentPage: e.data.page, totalPages: pages?.length || 0 });
			}
		},
		[] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const onFlip = useCallback((e: { data: number; object: any }) => {
		setPagesInfo({ currentPage: e.data, totalPages: pages?.length || 0 });
		navigate(`#${e.data}`, { replace: true }); // changes the link anchor and stops the audio on page flip

		const currentPageId = pages?.[e.data].id;
		if (currentPageId) handleBookProgressSend(currentPageId);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		isClickingRef.current = isClicking;

		// adding/removing class via ref and not changing state because don't want book to re-render; weird resets can happen
		if (isClicking) sectionRef.current?.classList.add('is-clicking');
		else sectionRef.current?.classList.remove('is-clicking');
	}, [isClicking]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (loadedPageImageTotal === pages?.length) {
			// images have loaded, so show the book!
			setBookIsRendered(true);
		}
	}, [loadedPageImageTotal]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// zooming in should show alert, zooming out should clear it
		setShowZoomAlert(isZoomed);
		isZoomedRef.current = isZoomed;

		if (!isZoomed) {
			if (bookRef.current) bookRef.current.style.translate = '0px 0px';
		}
	}, [isZoomed]);

	useEffect(() => {
		// clear the 'Drag your mouse' alert upon zoom after a few seconds
		let timeoutId: NodeJS.Timeout;
		if (showZoomAlert) timeoutId = setTimeout(() => setShowZoomAlert(false), 5000);
		return () => clearTimeout(timeoutId);
	}, [showZoomAlert]);

	useEffect(() => {
		if (bookIsRendered) {
			document.addEventListener('keydown', handleKeyDown);
			document.addEventListener('pointerup', handlePointerUp);

			// on load, flip to the page represented in the anchor tag (if the anchor and page exists)
			const { hash } = location;
			// no negative or number floats in the URL anchor
			const hashNumber = hash ? Math.floor(Math.abs(parseInt(hash.replace('#', '')))) : 0;

			if (hashNumber && pages) {
				const bookPageNumber = hashNumber % 2 !== 0 && hashNumber + 1 < pages.length ? hashNumber + 1 : hashNumber;
				// book only accepts odd numbers for current page (except for 0)
				// only flip to page if page number in the anchor is valid
				if (bookPageNumber < pages.length) bookCurrent?.pageFlip()?.turnToPage(bookPageNumber);

				const currentPageId = pages?.[bookPageNumber]?.id;
				if (currentPageId) handleBookProgressSend(currentPageId);
			}
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('pointerup', handlePointerUp);
		};
	}, [bookIsRendered]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.addEventListener('pointermove', handlePointerMove);
		return () => document.removeEventListener('pointermove', handlePointerMove);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="container">
				<section ref={sectionRef} className="component-book my-4" onPointerDown={handlePointerDown}>
					<div
						ref={bookRef}
						id="book"
						className={`
							book position-relative 
							${bookIsRendered ? '' : 'opacity-0 pe-none'} 
							${isFirstPage ? 'is-first-page' : ''} 
							${isLastPage ? 'is-last-page' : ''} 
							${totalPagesIsEvenNumber ? 'total-pages-is-even' : ''} 
							${getAudioQueue().length ? 'audio-playing' : ''}
						`}
					>
						<HTMLFlipBook
							ref={book}
							startPage={pagesInfo.currentPage}
							size={'stretch'}
							width={customWidth}
							height={customHeight}
							minWidth={customWidth}
							minHeight={customHeight}
							maxWidth={width}
							maxHeight={height}
							drawShadow={true}
							flippingTime={600}
							usePortrait={true}
							startZIndex={5}
							autoSize={true}
							maxShadowOpacity={0.1}
							showCover={true}
							mobileScrollSupport={true}
							clickEventForward={false}
							// useMouseEvents={!isZoomed} // clicking book corners will still turn the page with this on and 'clickEventForward' set as false, is a plugin bug...
							useMouseEvents={false}
							swipeDistance={200}
							showPageCorners={false}
							disableFlipByClick={true}
							renderOnlyPageLengthChange={true} // will stop weird behaviour like tag flickering when clicked
							className=""
							style={{}}
							onInit={onInit}
							onFlip={onFlip}
						>
							{pages?.map((page) => {
								const { image = {}, tags } = page;
								const width = 686 * 1.25;
								const height = 840 * 1.25;

								return (
									<div key={page.id} className="page">
										{image.url && (
											<CroppedImage
												src={image.url}
												width={width}
												height={height}
												usePriority
												loadCallback={(e) => {
													setLoadedPageImageTotal((total) => total + 1);
												}}
											/>
										)}
										{tags?.map((tag) => (
											<Tag key={tag.id} tag={tag} />
										))}
									</div>
								);
							})}
						</HTMLFlipBook>
						<div className="buttons-wrapper position-absolute w-100 d-flex align-items-end justify-content-between">
							<Fade in={!isFirstPage} mountOnEnter unmountOnExit>
								<button
									type="button"
									className="btn btn-icon btn-arrow-left"
									onClick={() => {
										bookCurrent?.pageFlip()?.flipPrev();
									}}
								>
									<span className="visually-hidden">Previous Page</span>
									<i></i>
								</button>
							</Fade>
							<Fade in={!isLastPage} mountOnEnter unmountOnExit>
								<button
									type="button"
									className="btn btn-icon btn-arrow-right"
									onClick={() => bookCurrent?.pageFlip()?.flipNext()}
								>
									<span className="visually-hidden">Previous Page</span>
									<i></i>
								</button>
							</Fade>
							{/* if last page finishes on the left, then this should appear at right-side of screen */}
							{/* otherwise show behind book */}
							<Fade in={isLastPage} mountOnEnter unmountOnExit>
								<Link
									to={systemInfo.type === 'staff' ? 'quiz/1' : 'feedback'}
									className={`btn btn-icon btn-finish-book ${totalPagesIsEvenNumber ? 'right-side-button' : ''}`}
									style={{
										right: totalPagesIsEvenNumber ? `calc(${0 - windowToBookWidthDiff / 2}px)` : '0'
									}}
									// will force the page flipper to unmount, as the plugin doesn't seem to do this itself on route change with <Link>, even after .destroy() is run
									// resizing next page will return to this one
									reloadDocument
								>
									<span className="visually-hidden">Finish Book</span>
									<i></i>
								</Link>
							</Fade>
						</div>
					</div>
					<Fade timeout={300} in={!bookIsRendered} mountOnEnter unmountOnExit>
						<div className="image-preloader position-absolute top-50 start-50 translate-middle z-5 text-center text-vivid-blue">
							<div className="d-flex flex-column align-items-center gap-3">
								<Spinner animation="border" role="status" className="spinner-blue-ring" />
								<div>
									{/* <div>
										<strong>Loading {pages?.length === 1 ? 'image' : 'images'}...</strong>
									</div> */}
									<div className="h3">
										<strong>
											{loadedPageImageTotal} / {pages?.length}
										</strong>
									</div>
								</div>
							</div>
						</div>
					</Fade>
				</section>
			</div>
			<Fade timeout={5000} in={showZoomAlert} mountOnEnter unmountOnExit>
				<div className="alert-mouse-drag">
					<SvgMask path="/svg/pan.svg" width={24} height={24} />
					Drag your mouse or finger to pan the page
				</div>
			</Fade>
		</>
	);
};

export default Book;
