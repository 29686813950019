import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

// PACKAGES
import Moment from 'moment';

// COMPONENTS
import SvgMask from '../../components/_Helpers/SvgMask';

// TYPES
import { TrialRequestRequestResult } from '../../api/models';

const TrialRequestComplete: FC = () => {
	const location = useLocation();
	const { email, expiry } = (location?.state as TrialRequestRequestResult) || {};

	return (
		<main className="page-trial-request-complete d-flex flex-column flex-grow-1">
			<div className="container-buyer-journey container d-flex flex-column align-items-center justify-space-between flex-grow-1">
				<img
					src="/svg/llll-online-contained-wide-logo.svg"
					width="231"
					height="78"
					alt="Little Learners Love Literacy logo"
				/>

				<div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 gap-4 text-center fw-bold">
					<img src="/svg/check-big.svg" width={64} height={64} alt="Tick" />
					<h1 className="m-0 text-shades-800">
						<strong>Trial sign up complete!</strong>
					</h1>
					<p className="m-0 text-shades-500">
						An email has been sent to {email} with details on how to start your Little Learners Love Literacy Online
						trial!
					</p>
					<p className="m-0 text-shades-500">
						Your trial will end on {expiry ? Moment(expiry).format('D MMM, YYYY') : null}.
					</p>
				</div>

				<Link to="../" relative="path" className="btn btn-white">
					<SvgMask path="/svg/chevron-left.svg" width={16} height={16} className="icon" />
					Back to home
				</Link>
			</div>
		</main>
	);
};

export default TrialRequestComplete;
