const RecaptchaDisclaimer: React.FC = () => {
	return (
		<div>
			This site is protected by reCAPTCHA and the Google{' '}
			<a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
				Privacy Policy
			</a>{' '}
			and{' '}
			<a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
				Terms of Service
			</a>{' '}
			apply.
		</div>
	);
};

export default RecaptchaDisclaimer;
