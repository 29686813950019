/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { TrialCreateCommand } from '../models';
import { TrialCreateResponse } from '../models';
import { TrialRequestCommand } from '../models';
import { TrialRequestResponse } from '../models';
import { TrialTransferCreationInfoResponse } from '../models';
import { TrialTransferInfoResponse } from '../models';
/**
 * TrialApi - axios parameter creator
 * @export
 */
export const TrialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new trial. *** This end point is only used internally. ***
         * @param {TrialCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrialCreatePost: async (body?: TrialCreateCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Trial/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a new trial
         * @param {TrialRequestCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrialRequestPost: async (body?: TrialRequestCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Trial/Request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides creation information of a trial organisation. *** This end point is only used internally. ***
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrialTransferCreationInfoGet: async (organisationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Trial/TransferCreationInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['OrganisationId'] = organisationId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides information of a trial organisation. *** This end point is only used internally. ***
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrialTransferInfoGet: async (organisationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Trial/TransferInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['OrganisationId'] = organisationId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrialApi - functional programming interface
 * @export
 */
export const TrialApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new trial. *** This end point is only used internally. ***
         * @param {TrialCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrialCreatePost(body?: TrialCreateCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialCreateResponse>> {
            const localVarAxiosArgs = await TrialApiAxiosParamCreator(configuration).apiTrialCreatePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request a new trial
         * @param {TrialRequestCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrialRequestPost(body?: TrialRequestCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialRequestResponse>> {
            const localVarAxiosArgs = await TrialApiAxiosParamCreator(configuration).apiTrialRequestPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides creation information of a trial organisation. *** This end point is only used internally. ***
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrialTransferCreationInfoGet(organisationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialTransferCreationInfoResponse>> {
            const localVarAxiosArgs = await TrialApiAxiosParamCreator(configuration).apiTrialTransferCreationInfoGet(organisationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides information of a trial organisation. *** This end point is only used internally. ***
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrialTransferInfoGet(organisationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialTransferInfoResponse>> {
            const localVarAxiosArgs = await TrialApiAxiosParamCreator(configuration).apiTrialTransferInfoGet(organisationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrialApi - factory interface
 * @export
 */
export const TrialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a new trial. *** This end point is only used internally. ***
         * @param {TrialCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrialCreatePost(body?: TrialCreateCommand, options?: any): AxiosPromise<TrialCreateResponse> {
            return TrialApiFp(configuration).apiTrialCreatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a new trial
         * @param {TrialRequestCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrialRequestPost(body?: TrialRequestCommand, options?: any): AxiosPromise<TrialRequestResponse> {
            return TrialApiFp(configuration).apiTrialRequestPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides creation information of a trial organisation. *** This end point is only used internally. ***
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrialTransferCreationInfoGet(organisationId?: string, options?: any): AxiosPromise<TrialTransferCreationInfoResponse> {
            return TrialApiFp(configuration).apiTrialTransferCreationInfoGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides information of a trial organisation. *** This end point is only used internally. ***
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrialTransferInfoGet(organisationId?: string, options?: any): AxiosPromise<TrialTransferInfoResponse> {
            return TrialApiFp(configuration).apiTrialTransferInfoGet(organisationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrialApi - object-oriented interface
 * @export
 * @class TrialApi
 * @extends {BaseAPI}
 */
export class TrialApi extends BaseAPI {
    /**
     * 
     * @summary Create a new trial. *** This end point is only used internally. ***
     * @param {TrialCreateCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public apiTrialCreatePost(body?: TrialCreateCommand, options?: any) {
        return TrialApiFp(this.configuration).apiTrialCreatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Request a new trial
     * @param {TrialRequestCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public apiTrialRequestPost(body?: TrialRequestCommand, options?: any) {
        return TrialApiFp(this.configuration).apiTrialRequestPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides creation information of a trial organisation. *** This end point is only used internally. ***
     * @param {string} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public apiTrialTransferCreationInfoGet(organisationId?: string, options?: any) {
        return TrialApiFp(this.configuration).apiTrialTransferCreationInfoGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides information of a trial organisation. *** This end point is only used internally. ***
     * @param {string} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public apiTrialTransferInfoGet(organisationId?: string, options?: any) {
        return TrialApiFp(this.configuration).apiTrialTransferInfoGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }
}
