import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import apibridge from '../../apibridge';
import { AccountUserInfoUserDetails } from '../../api/models';

export type UserType = {
	forceUpdate?: boolean;
	loggedIn?: boolean;
} & AccountUserInfoUserDetails;

const initialState: AccountUserInfoUserDetails = {
	id: '',
	firstName: '',
	lastName: '',
	email: '',
	activeProfileId: '',
	activeOrganisationId: '',
	profiles: [],
	permissions: [],
	loginStatus: undefined
};

export const UserSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logOut: (state) => {
			apibridge.postLogout();
			state = Object.assign(state, {
				...initialState,
				forceUpdate: false,
				loggedIn: false
			});
		},
		update: (state, action: PayloadAction<UserType>) => {
			state = Object.assign(state, action.payload);
		},
		forceUpdate: (state) => {
			state = Object.assign(state, {
				forceUpdate: true
			});
		}
	}
});
