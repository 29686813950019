import { FC } from 'react';
import { Link } from 'react-router-dom';

// COMPONENTS
import StudentHomeButton from '../ELibrary/Student/StudentHomeButton';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import ImgRem from '../../libs/imgRem';

const QuizComplete: FC = () => {
	return (
		<div className="page-quiz-complete d-flex flex-column flex-grow-1">
			<StudentHomeButton />

			<div className="container d-flex flex-grow-1">
				<div className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 text-center py-4">
					<div className="row align-items-center flex-grow-1 w-100">
						<div className="col-xl-8 offset-xl-2">
							<div className="d-flex flex-column align-items-center gap-3">
								<ImgRem src="/svg/page-badge-star.svg" width="160" height="160" alt="Page badge" />
								<h1 className="display text-shades-800 m-0">
									<strong>Quiz complete!</strong>
								</h1>
							</div>
						</div>
					</div>

					<div className="quiz-submit">
						<Link
							to="../book-complete"
							relative="path"
							className="btn btn-icon btn-quiz-forward success position-absolute left-50 translate-middle-x"
						>
							<SvgMask path="/svg/arrow-right.svg" width={48} height={48} />
							<span className="visually-hidden">Proceed</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuizComplete;
