import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/slice';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// COMPONENTS
import StudentHomeButton from '../ELibrary/Student/StudentHomeButton';
import StudentELibraryBook from '../../components/Book/StudentElibraryBook';

// UTILS
import CroppedImage from '../../components/_Helpers/CroppedImage';
import SvgMask from '../../components/_Helpers/SvgMask';
import { guid } from '../../libs/utils';
import ImgRem from '../../libs/imgRem';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import {
	StaffBookCompleteBookCompleteResult,
	StaffBookCompleteResponse,
	StudentBookCompleteBookCompleteResult,
	StudentBookCompleteResponse
} from '../../api/models';

const BookComplete: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [, , bookIdUrlPart] = location.pathname.split('/');
	const systemInfo = useAppSelector((state) => state.systemInfo);

	const [isLoading, setIsLoading] = useState(true);
	const [bookCompleteData, setBookCompleteData] = useState<
		StaffBookCompleteBookCompleteResult | StudentBookCompleteBookCompleteResult
	>();
	const hasStageAward =
		bookCompleteData && 'hasStageAward' in bookCompleteData ? bookCompleteData.hasStageAward : false;
	const stage = bookCompleteData && 'stage' in bookCompleteData ? bookCompleteData.stage : undefined;

	const postBookComplete = async () => {
		const response =
			systemInfo.type === 'staff'
				? await apibridge.postStaffBookComplete({ bookId: decodeURIComponent(bookIdUrlPart) })
				: await apibridge.postStudentBookComplete({ bookId: decodeURIComponent(bookIdUrlPart) });

		if (response && response.data) {
			const data =
				systemInfo.type === 'staff'
					? (response.data as StaffBookCompleteResponse)
					: (response.data as StudentBookCompleteResponse);
			setBookCompleteData(data.result);

			if (data.isError && data.validationErrors) {
				for (const err of data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Book complete error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	useEffect(() => {
		postBookComplete();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-book-complete d-flex flex-column flex-grow-1">
			<SvgMask
				path="/svg/bg-squiggles.svg"
				width={1920}
				height={1080}
				className="position-absolute top-0 left-0 w-100 h-100 pe-none bg"
			/>
			<StudentHomeButton />

			{isLoading ? (
				<div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
					<DelayedFadeIn>
						<Spinner />
					</DelayedFadeIn>
				</div>
			) : (
				<section className="container d-flex flex-column flex-grow-1 position-relative z-1">
					<div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-4 text-center py-4">
						<div className="row align-items-center flex-grow-1 w-100 py-5">
							<div className="col-xl-8 offset-xl-2">
								<div className="d-flex flex-column gap-5">
									{bookCompleteData &&
										(hasStageAward ? (
											<>
												<div className="position-relative mx-auto">
													<div>
														{stage?.badgeUrl && (
															<CroppedImage
																src={stage?.badgeUrl}
																width={200}
																height={200}
																alt={`${stage?.name} complete badge`}
																className="rounded-circle object-fit-cover stage-complete-badge"
															/>
														)}
													</div>
													<div className="position-absolute start-50 translate-middle">
														<ImgRem src="/svg/complete.svg" width={80} height={85} alt="Green badge" className="" />
													</div>
												</div>
												<h1 className="display text-shades-800 mt-4 mb-0">
													<strong>{stage?.name} complete!</strong>
												</h1>
											</>
										) : (
											<>
												{bookCompleteData.coverUrl && (
													<div className="position-relative mx-auto">
														<StudentELibraryBook imgUrl={bookCompleteData.coverUrl} imgAlt="" />
														<ImgRem
															src="/svg/complete.svg"
															width={80}
															height={85}
															alt="Green badge"
															className="position-absolute start-50 top-100 translate-middle z-1"
														/>
													</div>
												)}
												<h1 className="display text-shades-800 mt-4 mb-0">
													<strong>Book complete!</strong>
												</h1>
											</>
										))}
								</div>
							</div>
						</div>

						<div className="d-flex flex-column gap-4 justify-content-center align-items-center w-100 pb-2">
							<Link to="/elibrary" className="btn btn-large-button gap-3">
								<SvgMask path="/svg/home.svg" width={48} height={48} />
								<h2 className="m-0">
									<strong>Back Home</strong>
								</h2>
							</Link>
							<Link
								to="../"
								relative="path"
								className="link-read-again d-inline-flex align-items-center gap-3 text-decoration-none h2 m-0 p-3 my-2"
								reloadDocument
							>
								<SvgMask path="/svg/redo.svg" width={48} height={48} />
								<strong>Read again</strong>
							</Link>
						</div>
					</div>
				</section>
			)}
		</div>
	);
};

export default BookComplete;
