import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// TYPES
import { RichTextType } from '../../libs/types';

export interface PropsType {
	content: RichTextType;
	className?: string;
	characterLimit?: number;
}

const RichText: React.FC<PropsType> = ({ content, className = '' }) => {
	const navigate = useNavigate();

	const el = useRef<HTMLDivElement>(null);
	const text = content?.markup;

	function handleClick(e: Event) {
		const { href, target } = e.target as HTMLAnchorElement;

		if (href) {
			const isExternal = href?.includes('://') && !href?.includes(window.location.host);

			if (!isExternal && target !== '_blank') {
				e.preventDefault();
				navigate(href);
			}
		}
	}

	const initialiseAnchors = () => {
		const parentEl = el.current;
		if (!parentEl) return;

		const anchors = parentEl.querySelectorAll('a[href]');
		for (const anchor of anchors) anchor.addEventListener('click', handleClick);

		return () => {
			for (const anchor of anchors) anchor.removeEventListener('click', handleClick);
		};
	};

	useEffect(() => {
		return initialiseAnchors();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (text) {
		return <div ref={el} className={`wysiwyg-content ${className}`} dangerouslySetInnerHTML={{ __html: text }}></div>;
	}

	return null;
};

export default RichText;
