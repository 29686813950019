/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { OrganisationBillingEditCommand } from '../models';
import { OrganisationBillingEditResponse } from '../models';
import { OrganisationCancelCommand } from '../models';
import { OrganisationEditCommand } from '../models';
import { OrganisationEditResponse } from '../models';
import { OrganisationInviteLeftResponse } from '../models';
import { OrganisationReactivateCommand } from '../models';
import { OrganisationRegisterCommand } from '../models';
import { OrganisationRegisterCommandResponse } from '../models';
import { OrganisationRegisterCompleteResponse } from '../models';
import { OrganisationRegisterQueryResponse } from '../models';
import { OrganisationSettingsResponse } from '../models';
import { SharedModelsCommandResponse } from '../models';
/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationBillingEditGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/BillingEdit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationBillingEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationBillingEditPost: async (body?: OrganisationBillingEditCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/BillingEdit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationCancelCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationCancelPost: async (body?: OrganisationCancelCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/Cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationEditGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/Edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationEditPost: async (body?: OrganisationEditCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/Edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Let's you know how many invites left for this organisation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationInviteLeftGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/InviteLeft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationLogoPost: async (file?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/Logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationReactivateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationReactivatePost: async (body?: OrganisationReactivateCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/Reactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationRegisterCompleteGet: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/RegisterComplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [trialId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationRegisterGet: async (id?: string, trialId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (trialId !== undefined) {
                localVarQueryParameter['TrialId'] = trialId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationRegisterCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationRegisterPost: async (body?: OrganisationRegisterCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationRevokePendingPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/RevokePending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationSettingsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/Settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationBillingEditGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationBillingEditResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationBillingEditGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OrganisationBillingEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationBillingEditPost(body?: OrganisationBillingEditCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationBillingEditPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OrganisationCancelCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationCancelPost(body?: OrganisationCancelCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationCancelPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationEditGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationEditResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationEditGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OrganisationEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationEditPost(body?: OrganisationEditCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationEditPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Let's you know how many invites left for this organisation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationInviteLeftGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationInviteLeftResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationInviteLeftGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationLogoPost(file?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationLogoPost(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OrganisationReactivateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationReactivatePost(body?: OrganisationReactivateCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationReactivatePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationRegisterCompleteGet(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationRegisterCompleteResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationRegisterCompleteGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [trialId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationRegisterGet(id?: string, trialId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationRegisterQueryResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationRegisterGet(id, trialId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OrganisationRegisterCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationRegisterPost(body?: OrganisationRegisterCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationRegisterCommandResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationRegisterPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationRevokePendingPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationRevokePendingPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationSettingsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationSettingsResponse>> {
            const localVarAxiosArgs = await OrganisationApiAxiosParamCreator(configuration).apiOrganisationSettingsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationBillingEditGet(options?: any): AxiosPromise<OrganisationBillingEditResponse> {
            return OrganisationApiFp(configuration).apiOrganisationBillingEditGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationBillingEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationBillingEditPost(body?: OrganisationBillingEditCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return OrganisationApiFp(configuration).apiOrganisationBillingEditPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationCancelCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationCancelPost(body?: OrganisationCancelCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return OrganisationApiFp(configuration).apiOrganisationCancelPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationEditGet(options?: any): AxiosPromise<OrganisationEditResponse> {
            return OrganisationApiFp(configuration).apiOrganisationEditGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationEditCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationEditPost(body?: OrganisationEditCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return OrganisationApiFp(configuration).apiOrganisationEditPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Let's you know how many invites left for this organisation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationInviteLeftGet(options?: any): AxiosPromise<OrganisationInviteLeftResponse> {
            return OrganisationApiFp(configuration).apiOrganisationInviteLeftGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationLogoPost(file?: string, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return OrganisationApiFp(configuration).apiOrganisationLogoPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationReactivateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationReactivatePost(body?: OrganisationReactivateCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return OrganisationApiFp(configuration).apiOrganisationReactivatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationRegisterCompleteGet(id?: string, options?: any): AxiosPromise<OrganisationRegisterCompleteResponse> {
            return OrganisationApiFp(configuration).apiOrganisationRegisterCompleteGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [trialId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationRegisterGet(id?: string, trialId?: string, options?: any): AxiosPromise<OrganisationRegisterQueryResponse> {
            return OrganisationApiFp(configuration).apiOrganisationRegisterGet(id, trialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationRegisterCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationRegisterPost(body?: OrganisationRegisterCommand, options?: any): AxiosPromise<OrganisationRegisterCommandResponse> {
            return OrganisationApiFp(configuration).apiOrganisationRegisterPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationRevokePendingPost(options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return OrganisationApiFp(configuration).apiOrganisationRevokePendingPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationSettingsGet(options?: any): AxiosPromise<OrganisationSettingsResponse> {
            return OrganisationApiFp(configuration).apiOrganisationSettingsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationBillingEditGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationBillingEditGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {OrganisationBillingEditCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationBillingEditPost(body?: OrganisationBillingEditCommand, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationBillingEditPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {OrganisationCancelCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationCancelPost(body?: OrganisationCancelCommand, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationCancelPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationEditGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationEditGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {OrganisationEditCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationEditPost(body?: OrganisationEditCommand, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationEditPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Let's you know how many invites left for this organisation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationInviteLeftGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationInviteLeftGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationLogoPost(file?: string, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationLogoPost(file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {OrganisationReactivateCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationReactivatePost(body?: OrganisationReactivateCommand, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationReactivatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationRegisterCompleteGet(id?: string, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationRegisterCompleteGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [id] 
     * @param {string} [trialId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationRegisterGet(id?: string, trialId?: string, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationRegisterGet(id, trialId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {OrganisationRegisterCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationRegisterPost(body?: OrganisationRegisterCommand, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationRegisterPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationRevokePendingPost(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationRevokePendingPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationSettingsGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }
}
