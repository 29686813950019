import { FC } from 'react';
import { Alert } from 'react-bootstrap';

// HELPERS
import SvgMask from '../_Helpers/SvgMask';

// TYPES
import { OrganisationSettingsMessage } from '../../api/models';

const SubscriptionMessage: FC<{ message: OrganisationSettingsMessage }> = ({ message }) => {
	const { content, type } = message;
	const typeDanger = type === 'Critical' || type === 'Warning';

	return (
		<Alert variant={typeDanger ? 'danger' : 'info'} className="m-0">
			<div className="d-flex gap-2">
				{typeDanger && <SvgMask path="/svg/alert-outline.svg" width={24} height={24} className="me-1" />}
				{content && <div>{content}</div>}
			</div>
		</Alert>
	);
};

export default SubscriptionMessage;
