import { CSSProperties, FC, useEffect, useState } from 'react';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import CategoryDropdown from '../../components/TitleSection/CategoryDropdown';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { ProfessionalLearningListCollection } from '../../api/models';

const ProfessionalLearning: FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [professionalLearningContent, setProfessionalLearningData] = useState<ProfessionalLearningListCollection>();
	const [filterText, setFilterText] = useState('');
	const categoryItems = professionalLearningContent
		? professionalLearningContent.categories?.map((item) => {
				return {
					domId: `#category-section-${item.id}`,
					name: item.name || ''
				};
		  })
		: null;

	const filteredProfessionalLearningData: ProfessionalLearningListCollection = {
		...professionalLearningContent,
		categories: professionalLearningContent?.categories?.map((category) => {
			return {
				...category,
				categoryItems: category.categoryItems?.filter((categoryItem) => {
					return !filterText || categoryItem.title?.toLowerCase().includes(filterText.toLowerCase());
				})
			};
		})
	};

	const getProfessionalLearningData = async () => {
		const response = await apibridge.getProfessionalLearningList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setProfessionalLearningData({
				...response.data.result,
				// don't include any empty categories
				categories: response.data.result.categories?.filter((category) => category.categoryItems?.length)
			});
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getProfessionalLearningData();
	}, []);

	return (
		<div className="page-professional-learning d-flex flex-column flex-grow-1">
			<TitleSection
				title="Professional Learning"
				description="Browse our range of professional learning articles and video guides."
			>
				<div className="row row-gap-3">
					<div className="col">
						{categoryItems && <CategoryDropdown items={categoryItems} isDisabled={filterText !== ''} />}
					</div>
					<div className="col-auto col-md-4 col-lg-3">
						<div className="position-relative d-flex align-items-center">
							<input
								type="text"
								className={`form-control field-filter ${filterText ? 'pe-5' : ''}`}
								placeholder="Filter by keyword"
								value={filterText}
								onChange={(e) => {
									const target = e.target as HTMLInputElement;
									setFilterText(target.value);
								}}
							/>
							{filterText && (
								<button type="button" className="btn btn-input-clear" onClick={() => setFilterText('')}>
									<span className="visually-hidden">Clear</span>
									<i></i>
								</button>
							)}
						</div>
					</div>
				</div>
			</TitleSection>
			<section className="container h-100">
				{isLoading ? (
					<div className="d-flex justify-content-center">
						<DelayedFadeIn>
							<Spinner />
						</DelayedFadeIn>
					</div>
				) : filteredProfessionalLearningData?.categories?.length ? (
					<div className="d-flex flex-column gap-4-5">
						{filteredProfessionalLearningData?.categories.map((profLearningRow) => {
							const { categoryItems, id, name } = profLearningRow;
							const backgroundColour = profLearningRow.backgroundColour ? `#${profLearningRow.backgroundColour}` : '';
							return (
								<div key={id} id={`category-section-${id}`} className="category-section d-flex flex-column">
									{name && (
										<div className="category-title border-bottom">
											<h3 className="h4 m-0">{name}</h3>
										</div>
									)}
									{categoryItems && (
										<div className="row row-learning-items">
											{categoryItems?.map((categoryItem) => {
												const { duration, key = '', link = '', title = '', type } = categoryItem;

												return (
													<div key={key} className="col-lg-6">
														<a
															href={
																type === 'Content'
																	? encodeURI(link)
																	: `/professional-learning/${encodeURIComponent(title)}`
															}
															target={type === 'Content' ? '_blank' : '_self'}
															rel="noreferrer"
															className="btn btn-learning-item h-100"
														>
															<div
																className="learning-item-thumbnail d-flex align-items-center justify-content-center flex-shrink-0"
																style={{ '--thumbnail-bg-color': backgroundColour } as CSSProperties}
															>
																<img
																	src={type === 'Content' ? '/svg/photo.svg' : '/svg/play-circle.svg'}
																	alt={`${type === 'Content' ? 'Photo' : 'Play'} Icon`}
																/>
																{duration && <div className="label-tag position-absolute">{duration}</div>}
															</div>
															<div className="d-flex align-items-center flex-grow-1">
																<h3 className="title m-0">
																	<strong>{title}</strong>
																</h3>
															</div>
															<div className="align-self-start flex-shrink-0">
																<SvgMask
																	path={type === 'Content' ? '/svg/external.svg' : '/svg/play.svg'}
																	width={24}
																	height={24}
																/>
															</div>
														</a>
													</div>
												);
											})}
										</div>
									)}
								</div>
							);
						})}
					</div>
				) : (
					<div className="d-flex align-items-center justify-content-center h-100 text-shades-500 m-0">
						<h3>No results found</h3>
					</div>
				)}
			</section>
		</div>
	);
};

export default ProfessionalLearning;
