import { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// REDUX
import { useAppDispatch, useAppSelector } from '../../../store/slice';
import { UserSlice } from '../../../store/slice/User';

// PACKAGES
import { Container, InputGroup } from 'react-bootstrap';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';

// API
import apibridge from '../../../apibridge';
import { ResponseStandardFail } from '../../../libs/api';
import { AuthenticationLoginCommand } from '../../../api/models';

// UTILS
import { SITE_KEY, createErrorsObject, getUserDomain, loadRecaptchaScript } from '../../../libs/utils';
import ImgRem from '../../../libs/imgRem';

// COMPONENTS
import FormGroupWithError from '../../../components/Forms/FormGroupWithError';
import FormCustomError from '../../../components/Forms/FormCustomError';
import SvgMask from '../../../components/_Helpers/SvgMask';

const StudentLogin: React.FC = () => {
	const dispatch = useAppDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [formValues, setFormValues] = useState<AuthenticationLoginCommand>({
		loginType: 'Student',
		userName: '',
		password: '',
		captchaToken: ''
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const systemInfo = useAppSelector((state) => state.systemInfo);
	const isTrialUser = systemInfo.isTrial;

	const handleChange = (e: ChangeEvent<HTMLFormElement>) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const login = async (
		values: AuthenticationLoginCommand,
		captchaToken: string,
		formikHelpers: FormikHelpers<AuthenticationLoginCommand>
	) => {
		const { loginType, userName, password } = values;

		const response = await apibridge.postLogin({
			loginType,
			userName,
			password,
			captchaToken
		});

		if (response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				// the login was successful!
				const { refreshToken = '', token = '' } = response.data.result;
				localStorage.setItem('refreshToken', refreshToken);
				localStorage.setItem('token', token);

				// upon login, trigger the [user] useEffect in App.tsx to get the user details
				dispatch(
					UserSlice.actions.update({
						loggedIn: true
					})
				);
			}
		}

		setIsSubmitting(false);
	};

	const doLogin = async (
		values: AuthenticationLoginCommand,
		formikHelpers: FormikHelpers<AuthenticationLoginCommand>
	) => {
		setIsSubmitting(true);

		await window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(SITE_KEY, { action: 'submit' })
				.then((captchaToken: string) => {
					login(values, captchaToken, formikHelpers);
				})
				.catch(() => {
					formikHelpers.setErrors({ captchaToken: 'Recaptcha failed!' });
					setIsSubmitting(false);
				});
		});
	};

	useEffect(() => {
		loadRecaptchaScript();
	}, []);

	return (
		<>
			<picture>
				<source srcSet="./img/login-bg.webp" />
				<img
					src="./img/login-bg.jpg"
					className="position-fixed w-100 h-100 object-fit object-fit-cover user-select-none"
					alt="Tiled books background"
				/>
			</picture>
			<main className="page-student-login d-flex flex-column align-items-center flex-grow-1">
				<Container className="position-relative z-1 d-flex flex-column flex-grow-1 align-items-center">
					<div className="d-flex flex-column flex-grow-1 justify-content-center">
						<div className="form-rounded-corners pb-0 overflow-hidden my-5">
							<Formik initialValues={formValues} onSubmit={doLogin}>
								<FormikForm onChange={handleChange} className="d-flex flex-column gap-4-5 pb-5">
									<ImgRem
										src="/svg/llll-online-contained-wide-logo.svg"
										width="231"
										height="70"
										alt="Little Learners Love Literacy Online logo"
										className="logo mx-auto"
									/>
									<div className="d-flex flex-column align-items-center gap-2-5">
										{isTrialUser && (
											<div className="bg-light-blue rounded-5 px-2-5 py-1 text-vivid-blue">
												<strong>Trial</strong>
											</div>
										)}
										<h2 className="text-shades-800 m-0">
											<strong>Log in</strong>
										</h2>
									</div>

									<div>
										<FormCustomError className="text-start my-2" />
										<FormCustomError label="captchaToken" className="text-start my-2" />

										<FormGroupWithError name="userName" className="text-start mb-3">
											<label htmlFor="username" className="form-label">
												Username
											</label>
											<Field type="text" id="username" name="userName" className="form-control" />
										</FormGroupWithError>
										<FormGroupWithError name="password" className="text-start">
											<label htmlFor="password" className="form-label">
												Password
											</label>
											<InputGroup>
												<Field
													type={showPassword ? 'text' : 'password'}
													id="password"
													name="password"
													className="form-control border-end-0"
												/>
												<InputGroup.Text className="bg-transparent p-0">
													<button
														type="button"
														className="btn btn-sm bg-transparent text-reset"
														onClick={() => setShowPassword(!showPassword)}
														tabIndex={-1}
													>
														<SvgMask
															path={showPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
															width={24}
															height={24}
														/>
													</button>
												</InputGroup.Text>
											</InputGroup>
										</FormGroupWithError>
									</div>
									<div className="d-grid">
										<button type="submit" className="btn btn-lg" disabled={isSubmitting}>
											Log In
										</button>
									</div>
								</FormikForm>
							</Formik>
							<div className="bottom-bar d-flex gap-3 text-start bg-secondary">
								<SvgMask path={'/svg/help-solid.svg'} width={32} height={32} />
								<div>
									<strong>Forgot your password?</strong>
									<br />
									Tell your teacher and they will give it to you.
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex gap-2 bg-white rounded-5 py-2-5 px-3-5 mb-3">
						{isTrialUser ? (
							<Link to={`${getUserDomain('staff-trial')}/login`} className="text-decoration-none">
								Log in as teacher
							</Link>
						) : (
							<Link to={`${getUserDomain('staff')}/login`} className="text-decoration-none">
								Log in as teacher
							</Link>
						)}
					</div>
				</Container>
			</main>
		</>
	);
};

export default StudentLogin;
