import { FC } from 'react';
import { Link } from 'react-router-dom';
import ImgRem from '../../libs/imgRem';

const Page401: FC = () => {
	return (
		<div className="page-401 d-flex flex-column flex-grow-1 align-items-center justify-content-center">
			<section className="text-center">
				<Link to={'/'} className="logo mb-5 no-pseudo-hover">
					<ImgRem
						src="/svg/llll-online-contained-wide-logo.svg"
						width="231"
						height="70"
						alt="Little Learners Love Literacy Online logo"
						className="logo mx-auto"
					/>
				</Link>
				<h2 className="mb-4">Authentication failed. You don't have permission to view this page.</h2>
				<Link to={'/'} className="btn">
					Back to Home
				</Link>
			</section>
		</div>
	);
};

export default Page401;
