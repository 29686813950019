/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * List of all policies for user interactions with the application
 */
export type Policy = 'GeneralAuthentication' | 'AccountUserInfo' | 'AccountMessages' | 'AccountFtuxComplete' | 'PlannerWeek' | 'PlannerWeekFind' | 'RoutineGroups' | 'RoutineRoutines' | 'RoutineView' | 'StaffDashboard' | 'StaffInvite' | 'StaffInviteResend' | 'StaffUpload' | 'StaffDelete' | 'StaffInviteRevoke' | 'StaffClassAssign' | 'StaffClassRemove' | 'StaffView' | 'StaffList' | 'StaffBookView' | 'StaffBookComplete' | 'AdminGrant' | 'AdminRevoke' | 'ClassView' | 'ClassCreate' | 'ClassList' | 'ClassStudents' | 'ClassDelete' | 'StudentAdminDashboard' | 'StudentAdminCreate' | 'StudentAdminUpload' | 'StudentAdminDelete' | 'StudentAdminArchive' | 'StudentAdminUnarchive' | 'StudentAdminBookAssign' | 'StudentAdminBookUnassign' | 'StudentAdminView' | 'StudentAdminArchived' | 'StudentAdminClassSwap' | 'StudentAdminCards' | 'StudentLibrary' | 'StudentAwards' | 'StudentActivityProgress' | 'StudentActivityStart' | 'StudentBookComplete' | 'StudentBookProgress' | 'StudentBookRating' | 'StudentBookView' | 'HelpCentreList' | 'ProfessionalLearningList' | 'ResourceList' | 'ResourceFavorite' | 'BookList' | 'BookFavorite' | 'BookStages' | 'OrganisationInviteLeft' | 'OrganisationSettings' | 'OrganisationLogo' | 'OrganisationCancel' | 'OrganisationReactivate' | 'OrganisationRevokePending' | 'OrganisationBillingEdit' | 'OrganisationEdit' | 'SubscriptionEdit' | 'SubscriptionChange' | 'SubscriptionReactivateCancelled' | 'AccountPasswordChange' | 'AccountEmailChange' | 'AccountSettings' | 'AccountNameChange' | 'PaymentUpdateDetails' | 'TrailEnded' | 'OrganisationCancelled' | 'OrganisationDisabled';

export const Policy = {
    GeneralAuthentication: 'GeneralAuthentication' as Policy,
    AccountUserInfo: 'AccountUserInfo' as Policy,
    AccountMessages: 'AccountMessages' as Policy,
    AccountFtuxComplete: 'AccountFtuxComplete' as Policy,
    PlannerWeek: 'PlannerWeek' as Policy,
    PlannerWeekFind: 'PlannerWeekFind' as Policy,
    RoutineGroups: 'RoutineGroups' as Policy,
    RoutineRoutines: 'RoutineRoutines' as Policy,
    RoutineView: 'RoutineView' as Policy,
    StaffDashboard: 'StaffDashboard' as Policy,
    StaffInvite: 'StaffInvite' as Policy,
    StaffInviteResend: 'StaffInviteResend' as Policy,
    StaffUpload: 'StaffUpload' as Policy,
    StaffDelete: 'StaffDelete' as Policy,
    StaffInviteRevoke: 'StaffInviteRevoke' as Policy,
    StaffClassAssign: 'StaffClassAssign' as Policy,
    StaffClassRemove: 'StaffClassRemove' as Policy,
    StaffView: 'StaffView' as Policy,
    StaffList: 'StaffList' as Policy,
    StaffBookView: 'StaffBookView' as Policy,
    StaffBookComplete: 'StaffBookComplete' as Policy,
    AdminGrant: 'AdminGrant' as Policy,
    AdminRevoke: 'AdminRevoke' as Policy,
    ClassView: 'ClassView' as Policy,
    ClassCreate: 'ClassCreate' as Policy,
    ClassList: 'ClassList' as Policy,
    ClassStudents: 'ClassStudents' as Policy,
    ClassDelete: 'ClassDelete' as Policy,
    StudentAdminDashboard: 'StudentAdminDashboard' as Policy,
    StudentAdminCreate: 'StudentAdminCreate' as Policy,
    StudentAdminUpload: 'StudentAdminUpload' as Policy,
    StudentAdminDelete: 'StudentAdminDelete' as Policy,
    StudentAdminArchive: 'StudentAdminArchive' as Policy,
    StudentAdminUnarchive: 'StudentAdminUnarchive' as Policy,
    StudentAdminBookAssign: 'StudentAdminBookAssign' as Policy,
    StudentAdminBookUnassign: 'StudentAdminBookUnassign' as Policy,
    StudentAdminView: 'StudentAdminView' as Policy,
    StudentAdminArchived: 'StudentAdminArchived' as Policy,
    StudentAdminClassSwap: 'StudentAdminClassSwap' as Policy,
    StudentAdminCards: 'StudentAdminCards' as Policy,
    StudentLibrary: 'StudentLibrary' as Policy,
    StudentAwards: 'StudentAwards' as Policy,
    StudentActivityProgress: 'StudentActivityProgress' as Policy,
    StudentActivityStart: 'StudentActivityStart' as Policy,
    StudentBookComplete: 'StudentBookComplete' as Policy,
    StudentBookProgress: 'StudentBookProgress' as Policy,
    StudentBookRating: 'StudentBookRating' as Policy,
    StudentBookView: 'StudentBookView' as Policy,
    HelpCentreList: 'HelpCentreList' as Policy,
    ProfessionalLearningList: 'ProfessionalLearningList' as Policy,
    ResourceList: 'ResourceList' as Policy,
    ResourceFavorite: 'ResourceFavorite' as Policy,
    BookList: 'BookList' as Policy,
    BookFavorite: 'BookFavorite' as Policy,
    BookStages: 'BookStages' as Policy,
    OrganisationInviteLeft: 'OrganisationInviteLeft' as Policy,
    OrganisationSettings: 'OrganisationSettings' as Policy,
    OrganisationLogo: 'OrganisationLogo' as Policy,
    OrganisationCancel: 'OrganisationCancel' as Policy,
    OrganisationReactivate: 'OrganisationReactivate' as Policy,
    OrganisationRevokePending: 'OrganisationRevokePending' as Policy,
    OrganisationBillingEdit: 'OrganisationBillingEdit' as Policy,
    OrganisationEdit: 'OrganisationEdit' as Policy,
    SubscriptionEdit: 'SubscriptionEdit' as Policy,
    SubscriptionChange: 'SubscriptionChange' as Policy,
    SubscriptionReactivateCancelled: 'SubscriptionReactivateCancelled' as Policy,
    AccountPasswordChange: 'AccountPasswordChange' as Policy,
    AccountEmailChange: 'AccountEmailChange' as Policy,
    AccountSettings: 'AccountSettings' as Policy,
    AccountNameChange: 'AccountNameChange' as Policy,
    PaymentUpdateDetails: 'PaymentUpdateDetails' as Policy,
    TrailEnded: 'TrailEnded' as Policy,
    OrganisationCancelled: 'OrganisationCancelled' as Policy,
    OrganisationDisabled: 'OrganisationDisabled' as Policy
};