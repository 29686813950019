import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserType } from '../../store/slice/User';
import { useAppSelector } from '../../store/slice';

const ProtectedRoute: React.FC = () => {
	const location = useLocation();
	const user: UserType = useAppSelector((state) => state.user);

	const generatedReturnUrl =
		!user.loggedIn &&
		location.pathname &&
		!location.pathname.includes('//') &&
		location.pathname.includes('/') &&
		location.pathname.length > 1
			? `?returnUrl=${location.pathname}${encodeURIComponent(location.search)}`
			: '';

	return user.loggedIn ? (
		user.loginStatus === 'Disabled' ? (
			<Navigate to="/account-suspended" />
		) : user.loginStatus === 'Cancelled' ? (
			user.profiles?.[0].roleName === 'Organisation Administrator' ? (
				<>
					{/* if user logged in and is admin and subscription is cancelled, take them only to the Org Settings page, and still use the dashboard layout */}
					<Navigate to="/settings/organisation-details" />
					<Outlet />
				</>
			) : (
				// otherwise if non-admin and subscription is cancelled, take them to the 'Your subscription has ended' page
				<Navigate to="/subscription-ended" />
			)
		) : user.loginStatus === 'TrialExpired' ? (
			<Navigate to="/trial-ended" />
		) : (
			<Outlet />
		)
	) : (
		<Navigate to={`/login${generatedReturnUrl}`} replace />
	);
};

export default ProtectedRoute;
