import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/slice';

// COMPONENTS
import TitleSection, { TitleSectionType } from '../../components/TitleSection/TitleSection';
import SortDropdown, { SortOptionsType } from '../../components/TitleSection/SortDropdown';
import Divider from '../../components/Divider/Divider';

// PACKAGES
import { Dropdown } from 'react-bootstrap';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { Modal, Offcanvas, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

// MODELS
import {
	BookStagesStage,
	ClassListClass,
	ClassViewClassInfo,
	ClassViewStudent,
	StaffListTeacher
} from '../../api/models';

// API
import apibridge from '../../apibridge';

// UTILS
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import SvgMask from '../../components/_Helpers/SvgMask';
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import { UserType } from '../../store/slice/User';

type SortableClassViewStudent = {
	stageNameSortable?: string;
} & ClassViewStudent;

const sortOptions: SortOptionsType[] = [
	{
		value: 'asc',
		label: 'Name A-Z'
	},
	{
		value: 'desc',
		label: 'Name Z-A'
	},
	{
		value: 'stage-asc',
		label: 'Stage ascending'
	},
	{
		value: 'stage-desc',
		label: 'Stage descending'
	}
];

const ClassDetails: FC = () => {
	const location = useLocation();
	const { pathname, state } = location;
	const [, , , classId] = pathname.split('/');

	const [loading, setLoading] = useState(true);
	const [printIdCardsLoading, setPrintIdCardsLoading] = useState(false);
	const [classViewData, setClassViewData] = useState<ClassViewClassInfo>({
		id: state?.id || '',
		name: state?.name || '',
		year: state?.year || '',
		teacher: state?.teacher || {},
		students: []
	});

	const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
	const [classList, setClassList] = useState<ClassListClass[]>([]);
	const [teacherList, setTeacherList] = useState<StaffListTeacher[]>([]);

	const [selectedClassId, setSelectedClassId] = useState('');
	const [selectedTeacherId, setSelectedTeacherId] = useState('');

	const [bookStages, setBookStages] = useState<BookStagesStage[]>([]);
	const [selectedBookStages, setSelectedBookStages] = useState('');

	const [isAssigningBooks, setIsAssigningBooks] = useState(false);

	const [idCardsUrl, setIdCardsUrl] = useState('');

	const [showIdCardsModal, setShowIdCardsModal] = useState(false);
	const [showChangeTeacherModal, setShowChangeTeacherModal] = useState(false);
	const [showChangeClassModal, setShowChangeClassModal] = useState(false);
	const [showArchiveModal, setShowArchiveModal] = useState(false);
	const [showAssignBookModal, setShowAssignBookModal] = useState(false);
	const [showConfirmAssignBookModal, setShowConfirmAssignBookModal] = useState(false);

	const { id, name, students = [], teacher } = classViewData;

	const [selectedSortOption, setSelectedSortOption] = useState<SortOptionsType>(sortOptions[0]);
	const bookAmount = bookStages.find((s) => s.id === selectedBookStages)?.books;

	const dispatch = useAppDispatch();
	const user: UserType = useAppSelector((state) => state.user);
	const [userProfile = {}] = user.profiles || [];

	const getBookStages = async () => {
		const response = await apibridge.getBookStages();
		if (response && response.data && !response.data.isError && response.data.result) {
			setBookStages(response.data.result.stages || []);
		}
	};

	const getStudentList = async () => {
		const response = await apibridge.getClassView(classId);
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				// special sorting because '+4' should have been written by LLLL as '4 Plus'
				const { students = [] } = response.data.result;
				const studentsWithSortableStages = students.map((student) => {
					const stageNameSortable = student.stage
						? student.stage === '+4'
							? 'Stage 4 Plus'
							: `Stage ${student.stage}`
						: undefined;
					return {
						...student,
						stageNameSortable
					};
				});
				setClassViewData({
					...response.data.result,
					students: studentsWithSortableStages
				});
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Student list error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const getTeacherList = async () => {
		const response = await apibridge.getStaffList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setTeacherList(response.data.result.teachers || []);
		}
	};

	const getClassList = async () => {
		// get class list
		const response = await apibridge.getClassList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setClassList(response.data.result.classes || []);
		}
	};

	const initialize = async () => {
		// doing all the async calls at once, not sequentially
		await Promise.all([getStudentList(), getTeacherList(), getClassList(), getBookStages()]);
		setLoading(false);
	};

	useEffect(() => {
		initialize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSelectAll = () => {
		if (selectedStudents.length === students.length) {
			setSelectedStudents([]);
		} else {
			setSelectedStudents(students.map((student) => student.id || ''));
		}
	};

	const handleSelectStudent = (studentId: string) => {
		if (selectedStudents.includes(studentId)) {
			setSelectedStudents(selectedStudents.filter((id) => id !== studentId));
		} else {
			setSelectedStudents([...selectedStudents, studentId]);
		}
	};

	const handleArchiveStudents = async () => {
		const response = await apibridge.deleteStudentAdminArchive({ profileIds: selectedStudents });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				let heading = `Students archived`;
				let description = `${selectedStudents.length} students have been moved to Archive`;

				if (selectedStudents.length === 1) {
					heading = `Student archived`;
					const selectedStudentDetails = students.find((student) => student.id === selectedStudents?.[0]) || {};
					description = `${selectedStudentDetails?.firstName || ''} ${
						selectedStudentDetails?.lastName || ''
					} has been moved to Archive`;
				}

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: heading,
						description: description
					})
				);

				setShowArchiveModal(false);
				setSelectedStudents([]);
				getStudentList();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Archive students error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const handleChangeClass = async () => {
		const response = await apibridge.postStudentAdminClassSwap({
			classId: selectedClassId,
			profileIds: selectedStudents
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const className = classList.find((classItem) => classItem.id === selectedClassId)?.name || '';
				let description = `${selectedStudents.length} students have been transferred to ${className}`;

				if (selectedStudents.length === 1) {
					const selectedStudentDetails = students.find((student) => student.id === selectedStudents?.[0]) || {};
					description = `${selectedStudentDetails?.firstName || ''} ${
						selectedStudentDetails?.lastName || ''
					} has been transferred to ${className}`;
				}

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: `Class transfer complete`,
						description: description
					})
				);

				setShowChangeClassModal(false);
				setSelectedStudents([]);
				setSelectedClassId(classId);
				getStudentList();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Change class error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const handleChangeTeacher = async () => {
		const response = await apibridge.postStaffClassAssign({ classId, profileId: selectedTeacherId });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Assign teacher',
						description: 'Teacher assigned successfully.'
					})
				);
				setShowChangeTeacherModal(false);
				getStudentList();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Change teacher error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const handleAssignBooks = async () => {
		setIsAssigningBooks(true);

		const response = await apibridge.postStudentAdminBookAssign({
			studentProfileIds: selectedStudents,
			stageIds: [selectedBookStages],
			bookIds: [],
			seriesIds: []
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setShowConfirmAssignBookModal(false);
				setSelectedStudents([]);
				getStudentList();

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Book assignment',
						description: 'Books successfully assigned.'
					})
				);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Assign books error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsAssigningBooks(false);
	};

	const handleGetSingleStudentDetails = (studentId: string) => {
		const student: ClassViewStudent = students.find((student) => student.id === studentId) || {};
		if (student) {
			return student;
		}

		return {};
	};

	const printCards = async () => {
		// if no students selected, print all student cards
		const studentsToPrint = students.map((student) => student.id || '');

		setPrintIdCardsLoading(true);

		try {
			const response = await apibridge.postStudentAdminCards({ studentIds: studentsToPrint });

			if (response && response.data) {
				if (!response.data.isError && response.data.result) {
					const { contentType: mimeType, file: bytesBase64 } = response.data.result;

					// construct a url from the base64 and then fetch it like a file to access the blob method
					const fileUrl = 'data:' + mimeType + ';base64,' + bytesBase64;
					const fileResponse = await fetch(fileUrl);

					let blob;
					if (fileResponse && fileResponse.ok) {
						blob = await fileResponse.blob();
					}

					if (blob) {
						setIdCardsUrl(window.URL.createObjectURL(blob));
						setShowIdCardsModal(true);
						// alternate solution here: https://stackoverflow.com/questions/2587677/avoid-browser-popup-blockers#answer-25050893
					} else {
						dispatch(
							ToastMessagesSlice.actions.add({
								id: guid(),
								type: 'danger',
								heading: 'ID Card creation failed',
								description: ''
							})
						);
						console.error('Blob creation failed');
					}
				} else if (response.data.validationErrors) {
					for (const err of response.data.validationErrors) {
						dispatch(
							ToastMessagesSlice.actions.add({
								id: guid(),
								type: 'danger',
								heading: 'Download error',
								description: err.reason || 'Unknown error'
							})
						);
					}
				}
			}
		} catch (error) {
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: 'PDF creation failed',
					description: ''
				})
			);
			console.error('PDF creation failed:', error);
		}

		setPrintIdCardsLoading(false);
	};

	const closeIdCardsModal = () => {
		setShowIdCardsModal(false);
		window.URL.revokeObjectURL(idCardsUrl);
		setIdCardsUrl('');
	};

	const openIdCardsInNewTab = () => {
		window.open(idCardsUrl);
		closeIdCardsModal();
	};

	const titleSectionProps: TitleSectionType = {};
	if (user.permissions?.includes('StudentAdminCards')) {
		titleSectionProps.primaryButtonText = 'Print ID cards';
		titleSectionProps.primaryButtonClick = printCards;
		titleSectionProps.primaryButtonIcon = '/svg/printer.svg';
		titleSectionProps.primaryButtonDisabled = students.length === 0 || printIdCardsLoading;
		titleSectionProps.primaryButtonLoading = printIdCardsLoading;
	}

	if (user.permissions?.includes('StudentAdminCreate')) {
		titleSectionProps.secondaryButtonText = 'Add students';
		titleSectionProps.secondaryButtonUrl = `/student-management/add-class/${classId}`;
		titleSectionProps.secondaryButtonIcon = '/svg/plus.svg';

		if (userProfile.type === 'Staff' && !userProfile.studentsAdded)
			titleSectionProps.secondaryButtonNotificationIcon = true;
	}

	return (
		<div className="class-details-page">
			<TitleSection
				{...titleSectionProps}
				backToText="Back to classes"
				backToUrl="/student-management"
				title={name}
				dynamicDescription={
					<div className="d-flex align-items-center gap-1">
						<SvgMask path="/svg/Teachers-Hat.svg" height={24} width={24} />
						<p className="mb-0">
							<strong>{teacher?.firstName ? teacher?.firstName + ' ' + teacher?.lastName : 'Unassigned'}</strong>
						</p>
						{user.permissions?.includes('StaffClassAssign') && (
							<button onClick={() => setShowChangeTeacherModal(true)} className="btn btn-link ms-2">
								{teacher?.firstName ? 'Change teacher' : 'Assign teacher'}
							</button>
						)}
					</div>
				}
			>
				<div className={`d-flex ${selectedStudents.length ? 'justify-content-between' : 'justify-content-end'} gap-3`}>
					{selectedStudents.length ? (
						<div className="selected-items-wrapper">
							<p className="mb-0 text-dark-text">
								<strong>
									{selectedStudents.length} student{selectedStudents.length === 1 ? '' : 's'} selected
								</strong>
							</p>
							<div className="d-flex gap-3-5">
								{user.permissions?.includes('StudentAdminArchive') && (
									<button onClick={() => setShowArchiveModal(true)} className="btn btn-link text-start">
										Archive student{selectedStudents.length === 1 ? '' : 's'}
									</button>
								)}
								{user.permissions?.includes('StudentAdminClassSwap') && (
									<button onClick={() => setShowChangeClassModal(true)} className="btn btn-link text-start">
										Change class
									</button>
								)}
								{user.permissions?.includes('StudentAdminBookAssign') && (
									<button onClick={() => setShowAssignBookModal(true)} className="btn btn-link text-start">
										Assign books
									</button>
								)}
								<button onClick={() => setSelectedStudents([])} className="btn btn-reset text-dark-text reset-select">
									<SvgMask path="/svg/cross-thin.svg" width={24} height={24} />
								</button>
							</div>
						</div>
					) : null}
					<div className="sort-container d-flex align-items-center gap-3-5 text-nowrap">
						<div className="d-flex align-items-center gap-1 h4">
							<SvgMask path="/svg/sort.svg" width={24} height={24} />
							<strong>Sort by</strong>
						</div>
						<SortDropdown
							sortOptions={sortOptions}
							selectedSortOption={selectedSortOption}
							setSelectedSortOption={setSelectedSortOption}
						/>
					</div>
				</div>
			</TitleSection>
			<div className="container">
				<div className="student-list-wrapper">
					{loading ? (
						<div className="d-flex align-items-center justify-content-center">
							<DelayedFadeIn>
								<Spinner animation="border" role="status">
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							</DelayedFadeIn>
						</div>
					) : students.length ? (
						<>
							<div className="student-list-title row">
								<div className="col-4 d-flex align-items-center gap-3-5">
									<input
										className="form-check"
										type="checkbox"
										name="select-all"
										id="select-all"
										onChange={() => {
											handleSelectAll();
										}}
										checked={selectedStudents.length === students.length}
									/>
									<label htmlFor="select-all" className="h4 mb-0">
										<strong>Select all</strong>
									</label>
								</div>
								<div className="col-8">
									<h4 className="mb-0">
										<strong>Current stage</strong>
									</h4>
								</div>
							</div>
							<div className="student-list-content">
								{students
									.sort((a: SortableClassViewStudent, b: SortableClassViewStudent) => {
										const aFirstName = a.firstName || '';
										const aLastName = a.lastName || '';
										const bFirstName = b.firstName || '';
										const bLastName = b.lastName || '';
										const aStage = a.stageNameSortable || '';
										const bStage = b.stageNameSortable || '';

										if (['desc', 'asc'].includes(selectedSortOption.value)) {
											return selectedSortOption.value === 'desc'
												? bFirstName.localeCompare(aFirstName) || bLastName.localeCompare(aLastName)
												: aFirstName.localeCompare(bFirstName) || aLastName.localeCompare(bLastName);
										} else if (['stage-desc', 'stage-asc'].includes(selectedSortOption.value)) {
											// not all students might have a stage, so am making sure ones with a stage appear at top
											if (!aStage && !bStage) {
												// if both items don't have a stage, sort by name. First check firstnames, and if they're the same, check lastnames
												return aFirstName.localeCompare(bFirstName) || aLastName.localeCompare(bLastName);
											} else if (!aStage) {
												// if only 'a' doesn't have a stage, it comes later
												return 1;
											} else if (!bStage) {
												// if only 'b' doesn't have a stage, it comes later
												return -1;
											} else {
												// both have a stage, so sort by it
												return selectedSortOption.value === 'stage-desc'
													? bStage.localeCompare(aStage)
													: aStage.localeCompare(bStage);
											}
										} else {
											return 0;
										}
									})
									.map((student) => {
										return (
											<StudentRow
												student={student}
												selectedStudents={selectedStudents}
												handleSelectStudent={handleSelectStudent}
												setShowChangeClassModal={setShowChangeClassModal}
												setShowArchiveModal={setShowArchiveModal}
												setShowAssignBookModal={setShowAssignBookModal}
												key={student.id}
												classViewData={classViewData}
												user={user}
											/>
										);
									})}
							</div>
						</>
					) : (
						<div className="d-flex justify-content-center align-items-center gap-2-5 empty-wrapper">
							<SvgMask path="/svg/invite.svg" width={24} height={24} />
							<p className="m-0">
								No students found. Press <strong>'Add students'</strong> to fill the class.
							</p>
						</div>
					)}
				</div>
			</div>

			{/* Print ID cards modal */}
			<Modal show={showIdCardsModal} onHide={closeIdCardsModal} centered>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>View ID cards</strong>
					</h2>
					<p className="m-0">Click the button below to view the ID cards pdf in a new tab.</p>
					<div className="button-group d-flex">
						<button onClick={closeIdCardsModal} className="btn btn-lg btn-white w-50">
							Cancel
						</button>
						<button onClick={openIdCardsInNewTab} className="btn btn-lg w-50">
							View ID cards
						</button>
					</div>
				</Modal.Body>
			</Modal>

			{/* Archive student modal */}
			<Modal show={showArchiveModal} onHide={() => setShowArchiveModal(false)} centered>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>
							Archive{' '}
							{selectedStudents.length > 1
								? 'students'
								: handleGetSingleStudentDetails(selectedStudents[0]).firstName +
								  ' ' +
								  handleGetSingleStudentDetails(selectedStudents[0]).lastName}
							?
						</strong>
					</h2>
					<p className="m-0">
						This will move {selectedStudents.length === 1 ? 'this student' : 'these students'} to{' '}
						<strong>Archived students.</strong> <br /> <br /> Archived students will be removed from the platform after
						30 days of their archival. Before this, you can reassign them to another class.
					</p>
					<div className="button-group d-flex">
						<button onClick={() => setShowArchiveModal(false)} className="btn btn-lg btn-white w-50">
							Cancel
						</button>
						<button onClick={handleArchiveStudents} className="btn btn-lg w-50">
							Archive student{selectedStudents.length === 1 ? '' : 's'}
						</button>
					</div>
				</Modal.Body>
			</Modal>

			{/* Assign Books modal */}
			<Modal show={showAssignBookModal} onHide={() => setShowAssignBookModal(false)} centered>
				<Modal.Body>
					<button
						type="button"
						className="btn-close close"
						aria-label="Close"
						onClick={() => setShowAssignBookModal(false)}
					></button>
					<h2 className="m-0 text-shades-800">
						<strong>Select a stage</strong>
					</h2>
					<div className="d-flex gap-3 flex-wrap">
						{bookStages.map((stage) => {
							return (
								<button
									key={stage.id}
									className={`pill stage-${stage.name} dark stage-pill`}
									onClick={() => {
										setSelectedBookStages(stage.id || '');
										setShowAssignBookModal(false);
										setShowConfirmAssignBookModal(true);
									}}
								>
									Stage {stage.name}
								</button>
							);
						})}
					</div>
					<Divider className="milo-blue" />
					<Link
						state={{ selectedStudents, classId: classId }}
						to={'/student-management/assign-books'}
						className="btn btn-white btn-lg"
					>
						<SvgMask path="/svg/book-az.svg" width={16} height={16} />
						Select individual books
					</Link>
				</Modal.Body>
			</Modal>
			{/* Confirm Assign Books modal */}
			<Modal show={showConfirmAssignBookModal} onHide={() => setShowConfirmAssignBookModal(false)} centered>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>Assign books to student{selectedStudents.length === 1 ? '' : 's'}?</strong>
					</h2>
					<p className="m-0">
						You are about to assign{' '}
						<strong>
							{bookAmount} {bookAmount === 1 ? 'book' : 'books'}
						</strong>{' '}
						to{' '}
						<strong>
							{selectedStudents.length} student{selectedStudents.length === 1 ? '' : 's'}
						</strong>{' '}
						and their {selectedStudents.length === 1 ? 'eLibrary' : 'eLibraries'} will be updated accordingly.
					</p>
					<div className="button-group d-flex">
						<button onClick={() => setShowConfirmAssignBookModal(false)} className="btn btn-lg btn-white w-50">
							Cancel
						</button>
						<button onClick={handleAssignBooks} className="btn btn-lg w-50" disabled={isAssigningBooks}>
							Assign books
						</button>
					</div>
				</Modal.Body>
			</Modal>

			{/* Transfer student off canvas modal */}
			<Offcanvas
				bsPrefix="class-select offcanvas"
				scroll
				show={showChangeClassModal}
				onHide={() => setShowChangeClassModal(false)}
				onExited={() => setSelectedClassId('')}
				placement="end"
			>
				<Offcanvas.Header>
					<Offcanvas.Title className="h3 text-shades-800">
						<strong>Transfer {selectedStudents.length === 1 ? 'student' : 'students'} to a different class</strong>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className="form-selection-wrapper">
						{classList?.length ? (
							<div className="form-selection-wrapper-inner">
								{classList
									// don't include the class that's already there
									.filter((classItem) => classItem.id !== id)
									.sort((a, b) => {
										// am just sorting the year names by alpha, then the class name if the years are the same
										const aYear = a.year || '';
										const bYear = b.year || '';
										const aName = a.name || '';
										const bName = b.name || '';
										return aYear.localeCompare(bYear) || aName.localeCompare(bName);
									})
									.map((classItem) => {
										const isSelected = selectedClassId === classItem.id;
										return (
											<button
												type="button"
												key={classItem.id}
												onClick={() => setSelectedClassId(classItem.id || '')}
												className={`btn btn-row-label ${isSelected ? 'selected' : ''}`}
											>
												<strong>{classItem.name}</strong>
												<div className="d-flex align-items-center gap-2">
													<div className="selected-icon-wrapper">
														{isSelected ? <SvgMask path="/svg/check.svg" width={16} height={16} /> : null}
													</div>
												</div>
											</button>
										);
									})}
							</div>
						) : null}
					</div>
					<div className="button-group d-flex gap-3">
						<button type="button" onClick={() => setShowChangeClassModal(false)} className="btn btn-white btn-lg w-100">
							Cancel
						</button>
						<button type="button" onClick={handleChangeClass} className="btn btn-lg w-100" disabled={!selectedClassId}>
							Change Class
						</button>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
			{/* Change teacher off canvas modal */}
			<Offcanvas
				bsPrefix="class-select offcanvas"
				scroll
				show={showChangeTeacherModal}
				onHide={() => setShowChangeTeacherModal(false)}
				onExited={() => setSelectedTeacherId('')}
				placement="end"
			>
				<Offcanvas.Header>
					<Offcanvas.Title className="h3 text-shades-800">
						Change the teacher for <strong>{name}</strong>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className="form-selection-wrapper">
						{teacherList?.length ? (
							<div className="form-selection-wrapper-inner">
								{/* don't include the same teacher that's already assigned */}
								{teacherList
									.filter((teacher) => teacher.id !== classViewData.teacher?.id)
									.map((teacher) => {
										const isSelected = selectedTeacherId === teacher.id;
										return (
											<button
												type="button"
												key={teacher.id}
												onClick={() => setSelectedTeacherId(teacher.id || '')}
												className={`btn btn-row-label ${isSelected ? 'selected' : ''}`}
											>
												<strong>
													{teacher.firstName} {teacher.lastName}
												</strong>
												<div className="d-flex align-items-center gap-2">
													<div className="selected-icon-wrapper">
														{isSelected ? <SvgMask path="/svg/check.svg" width={16} height={16} /> : null}
													</div>
												</div>
											</button>
										);
									})}
							</div>
						) : null}
					</div>
					<div className="button-group d-flex gap-3">
						<button
							type="button"
							onClick={() => setShowChangeTeacherModal(false)}
							className="btn btn-white btn-lg w-100"
						>
							Cancel
						</button>
						<button
							type="button"
							onClick={handleChangeTeacher}
							className="btn btn-lg w-100"
							disabled={!selectedTeacherId}
						>
							{teacher?.firstName ? 'Change teacher' : 'Assign teacher'}
						</button>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};

const StudentRow = (props: {
	student: ClassViewStudent;
	selectedStudents: string[];
	handleSelectStudent: (studentId: string) => void;
	setShowChangeClassModal: React.Dispatch<React.SetStateAction<boolean>>;
	setShowArchiveModal: React.Dispatch<React.SetStateAction<boolean>>;
	setShowAssignBookModal: React.Dispatch<React.SetStateAction<boolean>>;
	classViewData: ClassViewClassInfo;
	user: UserType;
}) => {
	const {
		student,
		selectedStudents,
		handleSelectStudent,
		setShowChangeClassModal,
		setShowArchiveModal,
		setShowAssignBookModal,
		classViewData,
		user
	} = props;
	const studentIsSelected = selectedStudents.includes(student.id || '');

	const studentLinkProps: LinkProps = {
		state: { classId: classViewData.id, studentDetails: student, className: classViewData.name },
		to: `/student-management/student/${student.id}`
	};

	const handleAssignBookClick = () => {
		if (!studentIsSelected) handleSelectStudent(student.id || '');
		setShowAssignBookModal(true);
	};

	const handleChangeClassClick = () => {
		if (!studentIsSelected) handleSelectStudent(student.id || '');
		setShowChangeClassModal(true);
	};

	const handleArchiveStudentClick = () => {
		if (!studentIsSelected) handleSelectStudent(student.id || '');
		setShowArchiveModal(true);
	};

	return (
		<div className="student-list-item row " key={student.id}>
			<div className="col-4 d-flex align-items-center gap-3-5">
				<input
					className="form-check"
					type="checkbox"
					name="student"
					checked={selectedStudents.includes(student.id || '')}
					id={`student-${student.id}`}
					onChange={() => handleSelectStudent(student.id || '')}
				/>
				<Link className="mb-0" {...studentLinkProps}>
					<strong>{student.firstName + ' ' + student.lastName}</strong>
				</Link>
			</div>
			<div className="col-6 d-flex align-items-center gap-4">
				{student.stage && <div className={`pill stage-${student.stage}`}>Stage {student.stage}</div>}
				<p className="mb-0 text-shades-500">
					<strong>
						{student.completedBooks} complete / <span className="text-shades-600">{student.totalBooks} assigned</span>
					</strong>
				</p>
			</div>
			<div className="col-2 d-flex align-items-center justify-content-end">
				<div className="d-flex gap-2-5">
					{user.permissions?.some((permission) =>
						['StudentAdminBookAssign', 'StudentAdminClassSwap', 'StudentAdminArchive'].includes(permission)
					) && (
						<Dropdown>
							<OverlayTrigger placement="top" overlay={<Tooltip>More</Tooltip>}>
								<Dropdown.Toggle className="btn btn-icon btn-dropdown">
									<SvgMask path="/svg/ellipsis-v.svg" width={24} height={24} />
								</Dropdown.Toggle>
							</OverlayTrigger>
							<Dropdown.Menu>
								{user.permissions?.includes('StudentAdminBookAssign') && (
									<Dropdown.Item as="button" onClick={handleAssignBookClick}>
										Assign books
									</Dropdown.Item>
								)}
								{user.permissions?.includes('StudentAdminClassSwap') && (
									<Dropdown.Item as="button" onClick={handleChangeClassClick}>
										Change class
									</Dropdown.Item>
								)}
								{user.permissions?.includes('StudentAdminArchive') && (
									<Dropdown.Item as="button" onClick={handleArchiveStudentClick} className="option-destructive">
										Archive student
									</Dropdown.Item>
								)}
							</Dropdown.Menu>
						</Dropdown>
					)}
					{user.permissions?.includes('StudentAdminView') && (
						<OverlayTrigger placement="top" overlay={<Tooltip>View student details</Tooltip>}>
							<Link className="btn btn-icon btn-dropdown" {...studentLinkProps}>
								<SvgMask path="/svg/arrow-right.svg" width={24} height={24} />
							</Link>
						</OverlayTrigger>
					)}
				</div>
			</div>
		</div>
	);
};

export default ClassDetails;
