/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Organisation subscription type enum
 */
export type OrganisationSubscriptionType = 'Trial' | 'Paid' | 'Invoiced';

export const OrganisationSubscriptionType = {
    Trial: 'Trial' as OrganisationSubscriptionType,
    Paid: 'Paid' as OrganisationSubscriptionType,
    Invoiced: 'Invoiced' as OrganisationSubscriptionType
};