/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { RoutineGroupsResponse } from '../models';
import { RoutineRoutinesResponse } from '../models';
import { RoutineViewResponse } from '../models';
/**
 * RoutineApi - axios parameter creator
 * @export
 */
export const RoutineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Provides a list of routine groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoutineGroupsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Routine/Groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list routines grouped by stages for a given routine group id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoutineRoutinesGet: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Routine/Routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a routine for a given routine id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoutineViewGet: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Routine/View`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutineApi - functional programming interface
 * @export
 */
export const RoutineApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Provides a list of routine groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoutineGroupsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutineGroupsResponse>> {
            const localVarAxiosArgs = await RoutineApiAxiosParamCreator(configuration).apiRoutineGroupsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list routines grouped by stages for a given routine group id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoutineRoutinesGet(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutineRoutinesResponse>> {
            const localVarAxiosArgs = await RoutineApiAxiosParamCreator(configuration).apiRoutineRoutinesGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a routine for a given routine id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoutineViewGet(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutineViewResponse>> {
            const localVarAxiosArgs = await RoutineApiAxiosParamCreator(configuration).apiRoutineViewGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RoutineApi - factory interface
 * @export
 */
export const RoutineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Provides a list of routine groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoutineGroupsGet(options?: any): AxiosPromise<RoutineGroupsResponse> {
            return RoutineApiFp(configuration).apiRoutineGroupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list routines grouped by stages for a given routine group id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoutineRoutinesGet(id?: string, options?: any): AxiosPromise<RoutineRoutinesResponse> {
            return RoutineApiFp(configuration).apiRoutineRoutinesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a routine for a given routine id
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoutineViewGet(id?: string, options?: any): AxiosPromise<RoutineViewResponse> {
            return RoutineApiFp(configuration).apiRoutineViewGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoutineApi - object-oriented interface
 * @export
 * @class RoutineApi
 * @extends {BaseAPI}
 */
export class RoutineApi extends BaseAPI {
    /**
     * 
     * @summary Provides a list of routine groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineApi
     */
    public apiRoutineGroupsGet(options?: any) {
        return RoutineApiFp(this.configuration).apiRoutineGroupsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list routines grouped by stages for a given routine group id
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineApi
     */
    public apiRoutineRoutinesGet(id?: string, options?: any) {
        return RoutineApiFp(this.configuration).apiRoutineRoutinesGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a routine for a given routine id
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineApi
     */
    public apiRoutineViewGet(id?: string, options?: any) {
        return RoutineApiFp(this.configuration).apiRoutineViewGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}
