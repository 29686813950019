import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { useAppSelector } from '../../store/slice';

// PACKAGES
import { Dropdown, Modal, Offcanvas, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import SortDropdown, { SortOptionsType } from '../../components/TitleSection/SortDropdown';
import SvgMask from '../../components/_Helpers/SvgMask';

// API
import apibridge from '../../apibridge';

// UTILS
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// MODELS
import { ClassListClass, StudentAdminArchivedStudent } from '../../api/models';
import { UserType } from '../../store/slice/User';

const ArchiveStudents: FC = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [archivedStudents, setArchivedStudents] = useState<StudentAdminArchivedStudent[]>([]);
	const [classList, setClassList] = useState<ClassListClass[]>([]);
	const [selectedClassId, setSelectedClassId] = useState('');
	const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
	const [showDeleteStudentsModal, setShowDeleteStudentsModal] = useState(false);
	const [showChangeClassModal, setShowChangeClassModal] = useState(false);

	const user: UserType = useAppSelector((state) => state.user);

	const sortOptions: SortOptionsType[] = [
		{
			value: 'asc',
			label: 'Name A-Z'
		},
		{
			value: 'desc',
			label: 'Name Z-A'
		}
	];
	const [selectedSortOption, setSelectedSortOption] = useState<SortOptionsType>(sortOptions[0]);

	const getArchivedStudents = async () => {
		const response = await apibridge.getStudentAdminArchived();
		// NOTE: if there are no archived students, then there is no 'result' key included!
		if (response && response.data && !response.data.isError) {
			setArchivedStudents(response.data.result?.students || []);
		}
	};

	const getClassList = async () => {
		const response = await apibridge.getClassList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setClassList(response.data.result?.classes || []);
		}
	};

	const initialize = async () => {
		await Promise.all([getArchivedStudents(), getClassList()]);
		setLoading(false);
	};

	useEffect(() => {
		initialize();
		// eslint-disable-next-line
	}, []);

	const handleSelectStudent = (studentId: string) => {
		if (selectedStudents.includes(studentId)) {
			setSelectedStudents(selectedStudents.filter((id) => id !== studentId));
		} else {
			setSelectedStudents([...selectedStudents, studentId]);
		}
	};

	const handleSelectAll = () => {
		if (selectedStudents.length === archivedStudents.length) {
			setSelectedStudents([]);
		} else {
			setSelectedStudents(archivedStudents.map((student) => student.id || ''));
		}
	};

	const handleDeleteStudent = async () => {
		setLoading(true);

		const response = await apibridge.deleteStudentAdminDelete({ profileIds: selectedStudents });
		if (response && response.data) {
			if (!response.data.isError) {
				let heading = `Students deleted`;
				let description = `${selectedStudents.length} students have been deleted`;

				if (selectedStudents.length === 1) {
					heading = `Student deleted`;
					const selectedStudentDetails = archivedStudents.find((student) => student.id === selectedStudents?.[0]) || {};
					description = `${selectedStudentDetails?.firstName || ''} ${
						selectedStudentDetails?.lastName || ''
					} has been deleted`;
				}

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: heading,
						description: description
					})
				);

				setSelectedStudents([]);
				setShowDeleteStudentsModal(false);
				await getArchivedStudents();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Delete student error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setLoading(false);
	};

	const handleUnarchiveStudent = async () => {
		setLoading(true);

		const response = await apibridge.postStudentAdminUnarchive({
			classId: selectedClassId,
			profileIds: selectedStudents
		});

		if (response && response.data) {
			if (!response.data.isError) {
				const className = classList.find((classItem) => classItem.id === selectedClassId)?.name || '';
				let heading = `Students assigned to class`;
				let description = `${selectedStudents.length} students have been moved to ${className}`;

				if (selectedStudents.length === 1) {
					heading = `Student assigned to class`;
					const selectedStudentDetails = archivedStudents.find((student) => student.id === selectedStudents?.[0]) || {};
					description = `${selectedStudentDetails?.firstName || ''} ${
						selectedStudentDetails?.lastName || ''
					} has been moved to ${className}`;
				}

				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: heading,
						description: description
					})
				);

				setShowChangeClassModal(false);
				setSelectedStudents([]);
				setSelectedClassId('');
				await getArchivedStudents();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Change class error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setLoading(false);
	};

	return (
		<div className="archive-student-page">
			<TitleSection backToText="Back to classes" backToUrl="/student-management" title="Archived Students">
				<div className={`d-flex ${selectedStudents.length ? 'justify-content-between' : 'justify-content-end'} gap-3`}>
					{selectedStudents.length ? (
						<div className="selected-items-wrapper">
							<p className="mb-0 text-dark-text">
								<strong>
									{selectedStudents.length} student{selectedStudents.length === 1 ? '' : 's'} selected
								</strong>
							</p>
							<div className="d-flex gap-3-5">
								{user.permissions?.includes('StudentAdminUnarchive') && (
									<button onClick={() => setShowChangeClassModal(true)} className="btn btn-link text-start">
										Assign to class
									</button>
								)}
								{user.permissions?.includes('StudentAdminDelete') && (
									<button
										onClick={() => setShowDeleteStudentsModal(true)}
										className="btn btn-link text-hero-rose text-start"
									>
										Delete student{selectedStudents.length === 1 ? '' : 's'}
									</button>
								)}
							</div>
							<button onClick={() => setSelectedStudents([])} className="btn btn-reset text-dark-text reset-select">
								<SvgMask path="/svg/cross-thin.svg" width={24} height={24} />
							</button>
						</div>
					) : null}
					<div className="sort-container d-flex align-items-center gap-3-5">
						<div className="d-flex align-items-center gap-1 h4 text-nowrap">
							<SvgMask path="/svg/sort.svg" width={24} height={24} />
							<strong>Sort by</strong>
						</div>
						<SortDropdown
							sortOptions={sortOptions}
							selectedSortOption={selectedSortOption}
							setSelectedSortOption={setSelectedSortOption}
						/>
					</div>
				</div>
			</TitleSection>
			<div className="container">
				<div className="student-list-wrapper">
					{loading ? (
						<div className="d-flex justify-content-center">
							<DelayedFadeIn>
								<Spinner animation="border" role="status">
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							</DelayedFadeIn>
						</div>
					) : archivedStudents.length ? (
						<>
							<div className="student-list-title d-flex align-items-center">
								<div className="d-flex align-items-center gap-3-5">
									<input
										className="form-check"
										type="checkbox"
										name="select-all"
										id="select-all"
										onChange={handleSelectAll}
										checked={selectedStudents.length === archivedStudents.length}
									/>
									<label htmlFor="select-all" className="h4">
										<strong>Select all</strong>
									</label>
								</div>
							</div>
							<div className="student-list-content">
								{archivedStudents
									.sort((a, b) => {
										const aFirstName = a.firstName || '';
										const bFirstName = b.firstName || '';
										const aLastName = a.lastName || '';
										const bLastName = b.lastName || '';
										if (selectedSortOption.value === 'desc') {
											return bFirstName.localeCompare(aFirstName) || bLastName.localeCompare(aLastName);
										} else {
											return aFirstName.localeCompare(bFirstName) || aLastName.localeCompare(bLastName);
										}
									})
									.map((student) => {
										return (
											<StudentRow
												student={student}
												selectedStudents={selectedStudents}
												handleSelectStudent={handleSelectStudent}
												setShowChangeClassModal={setShowChangeClassModal}
												setShowDeleteStudentsModal={setShowDeleteStudentsModal}
												key={student.id}
												user={user}
											/>
										);
									})}
							</div>
						</>
					) : (
						<div className="d-flex justify-content-center align-items-center gap-2-5 empty-wrapper">
							<p className="m-0">No archived students found.</p>
						</div>
					)}
				</div>
			</div>
			{/* Delete student modal */}
			<Modal show={showDeleteStudentsModal} onHide={() => setShowDeleteStudentsModal(false)} centered>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>
							Delete{' '}
							{selectedStudents.length === 1
								? archivedStudents.find((s) => s.id === selectedStudents[0])?.firstName +
								  ' ' +
								  archivedStudents.find((s) => s.id === selectedStudents[0])?.lastName
								: 'students'}
						</strong>
					</h2>
					<p className="m-0">
						This will permanently delete {selectedStudents.length === 1 ? 'this student' : 'these students'} from the
						organisation.
						<br />
						<br />
						Are you sure you want to continue?
					</p>
					<div className="button-group d-flex">
						<button
							onClick={() => {
								setShowDeleteStudentsModal(false);
							}}
							className="btn btn-lg btn-white w-50"
						>
							Cancel
						</button>
						<button onClick={handleDeleteStudent} className="btn btn-lg btn-destructive w-50">
							Delete student{selectedStudents.length === 1 ? '' : 's'}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* Transfer student off canvas modal */}
			<Offcanvas
				bsPrefix="class-select offcanvas"
				scroll
				show={showChangeClassModal}
				onHide={() => setShowChangeClassModal(false)}
				placement="end"
			>
				<Offcanvas.Header>
					<Offcanvas.Title className="h3 text-shades-800">
						<strong>Transfer student{selectedStudents.length === 1 ? '' : 's'} to a different class</strong>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className="form-selection-wrapper">
						{classList?.length ? (
							<div className="form-selection-wrapper-inner">
								{classList
									.sort((a, b) => {
										const aYear = a.year || '';
										const bYear = b.year || '';
										const aName = a.name || '';
										const bName = b.name || '';
										return aYear.localeCompare(bYear) || aName.localeCompare(bName);
									})
									.map((classItem) => {
										const isSelected = selectedClassId === classItem.id;
										return (
											<button
												key={classItem.id}
												type="button"
												onClick={() => setSelectedClassId(classItem.id || '')}
												className={`btn btn-row-label ${isSelected ? 'selected' : ''}`}
											>
												<strong>{classItem.name}</strong>
												<div className="d-flex align-items-center gap-2">
													<div className="selected-icon-wrapper">
														{isSelected ? <SvgMask path="/svg/check.svg" width={16} height={16} /> : null}
													</div>
												</div>
											</button>
										);
									})}
							</div>
						) : null}
					</div>
					<div className="button-group d-flex gap-3">
						<button
							type="button"
							onClick={() => {
								setShowChangeClassModal(false);
							}}
							className="btn btn-white btn-lg w-100"
						>
							Cancel
						</button>
						<button
							type="button"
							onClick={handleUnarchiveStudent}
							disabled={!selectedClassId}
							className="btn btn-lg w-100"
						>
							Change Class
						</button>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};

const StudentRow = (props: {
	student: StudentAdminArchivedStudent;
	selectedStudents: string[];
	handleSelectStudent: (studentId: string) => void;
	setShowChangeClassModal: React.Dispatch<React.SetStateAction<boolean>>;
	setShowDeleteStudentsModal: React.Dispatch<React.SetStateAction<boolean>>;
	user: UserType;
}) => {
	const { student, selectedStudents, handleSelectStudent, setShowChangeClassModal, setShowDeleteStudentsModal, user } =
		props;
	const studentIsSelected = selectedStudents.includes(student.id || '');

	const handleChangeClassClick = () => {
		if (!studentIsSelected) handleSelectStudent(student.id || '');
		setShowChangeClassModal(true);
	};

	const handleDeleteStudentClick = () => {
		if (!studentIsSelected) handleSelectStudent(student.id || '');
		setShowDeleteStudentsModal(true);
	};

	return (
		<div key={student.id} className="student-list-item d-flex align-items-center">
			<div className="d-flex align-items-center flex-grow-1 gap-3-5">
				<input
					className="form-check"
					type="checkbox"
					name="student"
					checked={selectedStudents.includes(student.id || '')}
					id={`student-${student.id}`}
					onChange={() => handleSelectStudent(student.id || '')}
				/>
				<label htmlFor={`student-${student.id}`}>
					<strong>{student.firstName + ' ' + student.lastName}</strong>
				</label>
			</div>

			<div className="d-flex align-items-center gap-2">
				{user.permissions?.some((permission) =>
					['StudentAdminUnarchive', 'StudentAdminDelete'].includes(permission)
				) && (
					<Dropdown>
						<OverlayTrigger placement="top" overlay={<Tooltip>More</Tooltip>}>
							<Dropdown.Toggle className="btn btn-icon btn-dropdown">
								<SvgMask path="/svg/ellipsis-v.svg" width={24} height={24} />
							</Dropdown.Toggle>
						</OverlayTrigger>
						<Dropdown.Menu>
							{user.permissions.includes('StudentAdminUnarchive') && (
								<Dropdown.Item as="button" onClick={handleChangeClassClick}>
									Change class
								</Dropdown.Item>
							)}
							{user.permissions.includes('StudentAdminDelete') && (
								<Dropdown.Item as="button" onClick={handleDeleteStudentClick} className="option-destructive">
									Delete student
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				)}
				{user.permissions?.includes('StudentAdminView') && (
					<OverlayTrigger placement="top" overlay={<Tooltip>View student details</Tooltip>}>
						<Link
							state={{ classId: '', studentDetails: student, className: '' }}
							to={`/student-management/student/${student.id}`}
							className="btn btn-icon btn-dropdown"
						>
							<SvgMask path="/svg/arrow-right.svg" width={24} height={24} />
						</Link>
					</OverlayTrigger>
				)}
			</div>
		</div>
	);
};

export default ArchiveStudents;
