import { FC, useEffect, useState } from 'react';

// API
import apibridge from '../../apibridge';

// PACKAGES
import { Link, useLocation } from 'react-router-dom';

// UTILS
import { createErrorsObject } from '../../libs/utils';
import { ResponseStandardFail } from '../../libs/api';

// COMPONENTS
import SvgMask from '../../components/_Helpers/SvgMask';

// TYPES
import { OrganisationRegisterCompleteRegisterCompleteResult, OrganisationSubscriptionType } from '../../api/models';

const BuyersJourneyComplete: FC = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const organisationId = queryParams.get('id');

	const [organisationDetails, setOrganisationDetails] = useState<OrganisationRegisterCompleteRegisterCompleteResult>(
		{}
	);

	const getOrganisationDetails = async () => {
		const response = await apibridge.getOrganisationRegisterComplete(organisationId || '');

		if (response.data.isError) {
			const errsObj = createErrorsObject(response.data as ResponseStandardFail);
			// formikHelpers.setErrors(errsObj);
		} else if (response.data.result) {
			setOrganisationDetails(response.data.result);
		}
	};

	useEffect(() => {
		getOrganisationDetails();
	}, []);

	const {
		name,
		email,
		adminEmail,
		adminFirstName,
		adminLastName,
		promocode,
		subscriptionName,
		subscriptionType,
		subscriptionUsers,
		mailingAddress,
		organisationAddress,
		originalAmount,
		discount,
		amount,
		orderNumber,
		confirmationCode,
		billingName,
		gst
	} = organisationDetails;

	const displayPaymentMethod = (method: string) => {
		if (!method) return '';

		if (method === OrganisationSubscriptionType.Paid) {
			return 'Credit Card';
		} else if (method === OrganisationSubscriptionType.Invoiced) {
			return 'School Invoice';
		} else {
			return '';
		}
	};

	return (
		<main className="page-buyer-journey-complete">
			<div className="d-flex flex-column align-items-center">
				<img
					src="/svg/LLLLO-Contained-Wide-Logo.svg"
					width="300"
					height="93"
					alt="Little Learners Love Literacy logo"
					className="logo mx-auto"
				/>
			</div>

			<div className="heading-section">
				<img src="/svg/check-big.svg" width="40" height="40" alt="Complete Icon" className="icon" />

				<h1 className="fw-bold">Purchase Complete!</h1>

				<p className="h4 text-center mb-0 mx-auto">
					An email has been sent with details on how to register your account and start using Little Learners Love
					Literacy Online!
				</p>
			</div>

			<div className="container">
				<div className="content-wrapper">
					<div className="title-section">
						<div className="title-left">
							<h2 className="mb-0 fw-bold">Purchase Summary</h2>

							{confirmationCode ? <h3 className="order-number">Order Confirmation No. {confirmationCode}</h3> : null}
						</div>

						<button
							className="btn btn-primary"
							onClick={() => {
								window.print();
							}}
						>
							<SvgMask path="/svg/printer.svg" width={16} height={16} className="icon" /> Print
						</button>
					</div>

					{subscriptionType === OrganisationSubscriptionType.Invoiced ? (
						<div className="email-notification-box">
							<SvgMask path="/svg/email.svg" width={24} height={24} className="icon" />
							<p className="text">
								An email with the invoice will be sent to <strong>{email}</strong> providing details on how to pay.
							</p>
						</div>
					) : null}

					<div className="divider"></div>

					<div className="detail-section">
						<h3 className="section-name">Subscription Details</h3>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Subscription Level</p>
							</div>

							<div className="col-md-9">
								<p>
									{subscriptionName} {subscriptionUsers ? <>- {subscriptionUsers}</> : null} users
								</p>
							</div>
						</div>
					</div>

					<div className="divider"></div>

					<div className="detail-section">
						<h3 className="section-name">Account Details</h3>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Full Name</p>
							</div>

							<div className="col-md-9">
								<p>
									{adminFirstName} {adminLastName}
								</p>
							</div>
						</div>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Email Address</p>
							</div>

							<div className="col-md-9">
								<p>{adminEmail}</p>
							</div>
						</div>
					</div>

					<div className="divider"></div>

					<div className="detail-section">
						<h3 className="section-name">Organisation Details</h3>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Organisation Name</p>
							</div>

							<div className="col-md-9">
								<p>{name}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Organisation Email</p>
							</div>

							<div className="col-md-9">
								<p>{email}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Organisation Address</p>
							</div>

							<div className="col-md-9">
								<p>
									{organisationAddress?.address1}, {organisationAddress?.city} {organisationAddress?.postcode}
									<br />
									{organisationAddress?.stateProvince} {organisationAddress?.country}
								</p>
							</div>
						</div>
					</div>

					<div className="divider"></div>

					<div className="detail-section">
						<h3 className="section-name">Billing & Payment Details</h3>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Billing Name</p>
							</div>

							<div className="col-md-9">
								<p>{billingName}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Billing Address</p>
							</div>

							<div className="col-md-9">
								<p>
									{mailingAddress?.address1}, {mailingAddress?.city} {mailingAddress?.postcode}
									<br />
									{mailingAddress?.stateProvince} {mailingAddress?.country}
								</p>
							</div>
						</div>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Payment Method</p>
							</div>

							<div className="col-md-9">
								<p>{displayPaymentMethod(subscriptionType || '')}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-md-3">
								<p className="fw-bold">Billing Email</p>
							</div>

							<div className="col-md-9">
								<p>{email}</p>
							</div>
						</div>

						{orderNumber ? (
							<div className="row">
								<div className="col-md-3">
									<p className="fw-bold">PO Number</p>
								</div>

								<div className="col-md-9">
									<p>{orderNumber}</p>
								</div>
							</div>
						) : null}
					</div>

					<div className="divider"></div>

					<div className="summary-box">
						<div className="d-flex justify-content-between">
							<div className="plan-info d-flex flex-column flex-md-row justify-content-start">
								<h3 className="mb-0 fw-bold">{subscriptionName}</h3>

								{subscriptionUsers ? <h3 className="user-number fw-bold">{subscriptionUsers} users</h3> : null}
							</div>

							<div className="text-end">
								<h3 className="mb-0 fw-bold">${Number(originalAmount).toFixed(2)}</h3>
							</div>
						</div>

						<div className="d-flex justify-content-between">
							<div className="promo-info d-flex justify-content-start align-items-center">
								{promocode ? (
									<>
										<SvgMask path="/svg/promo.svg" width={24} height={24} className="icon" />
										<p className="mb-0 fw-bold">{promocode}</p>
									</>
								) : null}
							</div>

							{discount ? <span className="discount-amount fw-bold">-${Number(discount).toFixed(2)}</span> : null}
						</div>

						<div className="divider"></div>

						<div className="d-flex justify-content-between">
							<h2 className="fw-bold">Total</h2>

							<div className="row-right text-end">
								<h3 className="h2 fw-bold mb-0">AUD ${Number(amount).toFixed(2)}</h3>

								<span className="total-text">GST Incl. ${Number(gst).toFixed(2)}</span>
							</div>
						</div>
					</div>
				</div>

				<div className="button-wrapper text-center my-5">
					<Link to="/" className="btn btn-white text-nowrap">
						<SvgMask path="/svg/chevron-left.svg" width={16} height={16} className="icon" /> Back to home
					</Link>
				</div>
			</div>
		</main>
	);
};

export default BuyersJourneyComplete;
