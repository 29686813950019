import ReactSwitchImport from 'react-switch';

const SwitchToggle: React.FC<any> = (props) => {
	// some kind of type issue with updated React types means that 'any' must be used
	// https://github.com/markusenglund/react-switch/issues/106#issuecomment-1094162864
	const ReactSwitch = ReactSwitchImport as any;

	const defaultProps = {
		width: 66,
		height: 24,
		offColor: '#e6e7e8',
		onColor: '#0078d0',
		offHandleColor: '#b1b3b5',
		onHandleColor: '#e0f4ff',
		handleDiameter: 32,
		uncheckedIcon: false,
		checkedIcon: false,
		activeBoxShadow: '0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2)',
		...props
	};

	return <ReactSwitch {...defaultProps} className="react-switch" />;
};

export default SwitchToggle;
