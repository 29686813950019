import { createContext } from 'react';

// TYPES
import { AudioClipType } from './customHooks';

type AudioPlayerContentType = {
	addToAudioQueue: ({ id, url }: { id: string; url: string }) => void;
	clearAudioQueue: () => void;
	getAudioQueue: () => AudioClipType['audioProps'][];
	getCurrentAudioClip: () => AudioClipType | null | undefined;
};

export const AudioPlayerContext = createContext<AudioPlayerContentType>({
	addToAudioQueue: () => {},
	clearAudioQueue: () => {},
	getAudioQueue: () => [],
	getCurrentAudioClip: () => null
});
