import { FC } from 'react';

const StudentHomeButton: FC = () => (
	<a href="/elibrary" className="btn btn-home-corner">
		<i></i>
		<span className="visually-hidden">Home</span>
	</a>
);

export default StudentHomeButton;
