import { Dispatch, FC, SetStateAction } from 'react';

// PACKAGES
import { Fade } from 'react-bootstrap';

// UTILS
import SvgMask from '../_Helpers/SvgMask';
import ImgRem from '../../libs/imgRem';

const QuizIntro: FC<{
	showIntro: boolean;
	setShowIntro: Dispatch<SetStateAction<boolean>>;
	setShowQuiz: Dispatch<SetStateAction<boolean>>;
	questionNumber: string;
	totalQuestions: number;
}> = ({ showIntro, setShowIntro, setShowQuiz, questionNumber, totalQuestions }) => {
	return (
		<Fade in={showIntro} onExited={() => setShowQuiz(true)} unmountOnExit>
			<div className="container d-flex flex-column flex-grow-1">
				<div className="row py-4 flex-grow-1">
					<div className="col-xl-6 offset-xl-3 d-flex flex-column align-items-center gap-5">
						<div className="d-flex flex-column align-items-center justify-content-center gap-5 flex-grow-1 text-center">
							<div className="d-flex flex-column align-items-center gap-3">
								<ImgRem
									src="/svg/quiz-card-illustration.svg"
									className="object-fit-scale"
									width="160"
									height="160"
									alt="Quiz paper"
								/>
								<p className="text-shades-500 m-0">
									<strong>
										{questionNumber} / {totalQuestions}
									</strong>
								</p>
								<h1 className="display text-shades-800 m-0">It's quiz time!</h1>
							</div>
						</div>

						<button type="button" className="btn btn-large-button btn-green" onClick={() => setShowIntro(false)}>
							Start <SvgMask path="/svg/arrow-right.svg" width={48} height={48} />
						</button>
					</div>
				</div>
			</div>
		</Fade>
	);
};

export default QuizIntro;
