/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { SharedModelsCommandResponse } from '../models';
import { StudentAdminArchiveCommand } from '../models';
import { StudentAdminArchivedResponse } from '../models';
import { StudentAdminBookAssignCommand } from '../models';
import { StudentAdminBookUnassignCommand } from '../models';
import { StudentAdminCardsCommand } from '../models';
import { StudentAdminCardsResponse } from '../models';
import { StudentAdminClassSwapCommand } from '../models';
import { StudentAdminCreateCommand } from '../models';
import { StudentAdminCreateResponse } from '../models';
import { StudentAdminDashboardResponse } from '../models';
import { StudentAdminDeleteCommand } from '../models';
import { StudentAdminUnarchiveCommand } from '../models';
import { StudentAdminUploadResponse } from '../models';
import { StudentAdminViewResponse } from '../models';
/**
 * StudentAdminApi - axios parameter creator
 * @export
 */
export const StudentAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive student profile and remove from class
         * @param {StudentAdminArchiveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminArchiveDelete: async (body?: StudentAdminArchiveCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/Archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list of archived students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminArchivedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/Archived`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign books to students.
         * @param {StudentAdminBookAssignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminBookAssignPost: async (body?: StudentAdminBookAssignCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/BookAssign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassign books from a student
         * @param {StudentAdminBookUnassignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminBookUnassignPost: async (body?: StudentAdminBookUnassignCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/BookUnassign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates a PDF file stream which contains the priteable cards
         * @param {StudentAdminCardsCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminCardsPost: async (body?: StudentAdminCardsCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/Cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Swap student class.
         * @param {StudentAdminClassSwapCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminClassSwapPost: async (body?: StudentAdminClassSwapCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/ClassSwap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides the relevant information to be shown on the teacher invite screen
         * @param {StudentAdminCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminCreatePost: async (body?: StudentAdminCreateCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Student dashboard which provides class information and archived students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminDashboardGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/Dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a student profile
         * @param {StudentAdminDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminDeleteDelete: async (body?: StudentAdminDeleteCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchive student profile and assign to a class
         * @param {StudentAdminUnarchiveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminUnarchivePost: async (body?: StudentAdminUnarchiveCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/Unarchive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads a list of first name/last name list in an excel file and performs the following:    * Validates excel file    * Retrieves the list of names from the excel file    * Creates user with a valid username    * Returns the list of users with their status
         * @param {string} [file] 
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminUploadPost: async (file?: string, classId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/Upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (classId !== undefined) {
                localVarQueryParameter['ClassId'] = classId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provide individual student information
         * @param {string} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminViewGet: async (profileId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StudentAdmin/View`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['ProfileId'] = profileId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentAdminApi - functional programming interface
 * @export
 */
export const StudentAdminApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive student profile and remove from class
         * @param {StudentAdminArchiveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminArchiveDelete(body?: StudentAdminArchiveCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminArchiveDelete(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list of archived students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminArchivedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentAdminArchivedResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminArchivedGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Assign books to students.
         * @param {StudentAdminBookAssignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminBookAssignPost(body?: StudentAdminBookAssignCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminBookAssignPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unassign books from a student
         * @param {StudentAdminBookUnassignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminBookUnassignPost(body?: StudentAdminBookUnassignCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminBookUnassignPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generates a PDF file stream which contains the priteable cards
         * @param {StudentAdminCardsCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminCardsPost(body?: StudentAdminCardsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentAdminCardsResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminCardsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Swap student class.
         * @param {StudentAdminClassSwapCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminClassSwapPost(body?: StudentAdminClassSwapCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminClassSwapPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides the relevant information to be shown on the teacher invite screen
         * @param {StudentAdminCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminCreatePost(body?: StudentAdminCreateCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentAdminCreateResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminCreatePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Student dashboard which provides class information and archived students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminDashboardGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentAdminDashboardResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminDashboardGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a student profile
         * @param {StudentAdminDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminDeleteDelete(body?: StudentAdminDeleteCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminDeleteDelete(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unarchive student profile and assign to a class
         * @param {StudentAdminUnarchiveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminUnarchivePost(body?: StudentAdminUnarchiveCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminUnarchivePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Uploads a list of first name/last name list in an excel file and performs the following:    * Validates excel file    * Retrieves the list of names from the excel file    * Creates user with a valid username    * Returns the list of users with their status
         * @param {string} [file] 
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminUploadPost(file?: string, classId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentAdminUploadResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminUploadPost(file, classId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provide individual student information
         * @param {string} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStudentAdminViewGet(profileId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudentAdminViewResponse>> {
            const localVarAxiosArgs = await StudentAdminApiAxiosParamCreator(configuration).apiStudentAdminViewGet(profileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudentAdminApi - factory interface
 * @export
 */
export const StudentAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Archive student profile and remove from class
         * @param {StudentAdminArchiveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminArchiveDelete(body?: StudentAdminArchiveCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminArchiveDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list of archived students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminArchivedGet(options?: any): AxiosPromise<StudentAdminArchivedResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminArchivedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign books to students.
         * @param {StudentAdminBookAssignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminBookAssignPost(body?: StudentAdminBookAssignCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminBookAssignPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unassign books from a student
         * @param {StudentAdminBookUnassignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminBookUnassignPost(body?: StudentAdminBookUnassignCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminBookUnassignPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates a PDF file stream which contains the priteable cards
         * @param {StudentAdminCardsCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminCardsPost(body?: StudentAdminCardsCommand, options?: any): AxiosPromise<StudentAdminCardsResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminCardsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Swap student class.
         * @param {StudentAdminClassSwapCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminClassSwapPost(body?: StudentAdminClassSwapCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminClassSwapPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides the relevant information to be shown on the teacher invite screen
         * @param {StudentAdminCreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminCreatePost(body?: StudentAdminCreateCommand, options?: any): AxiosPromise<StudentAdminCreateResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminCreatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Student dashboard which provides class information and archived students.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminDashboardGet(options?: any): AxiosPromise<StudentAdminDashboardResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminDashboardGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a student profile
         * @param {StudentAdminDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminDeleteDelete(body?: StudentAdminDeleteCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unarchive student profile and assign to a class
         * @param {StudentAdminUnarchiveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminUnarchivePost(body?: StudentAdminUnarchiveCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminUnarchivePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads a list of first name/last name list in an excel file and performs the following:    * Validates excel file    * Retrieves the list of names from the excel file    * Creates user with a valid username    * Returns the list of users with their status
         * @param {string} [file] 
         * @param {string} [classId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminUploadPost(file?: string, classId?: string, options?: any): AxiosPromise<StudentAdminUploadResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminUploadPost(file, classId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provide individual student information
         * @param {string} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStudentAdminViewGet(profileId?: string, options?: any): AxiosPromise<StudentAdminViewResponse> {
            return StudentAdminApiFp(configuration).apiStudentAdminViewGet(profileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentAdminApi - object-oriented interface
 * @export
 * @class StudentAdminApi
 * @extends {BaseAPI}
 */
export class StudentAdminApi extends BaseAPI {
    /**
     * 
     * @summary Archive student profile and remove from class
     * @param {StudentAdminArchiveCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminArchiveDelete(body?: StudentAdminArchiveCommand, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminArchiveDelete(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list of archived students.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminArchivedGet(options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminArchivedGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Assign books to students.
     * @param {StudentAdminBookAssignCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminBookAssignPost(body?: StudentAdminBookAssignCommand, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminBookAssignPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Unassign books from a student
     * @param {StudentAdminBookUnassignCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminBookUnassignPost(body?: StudentAdminBookUnassignCommand, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminBookUnassignPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Generates a PDF file stream which contains the priteable cards
     * @param {StudentAdminCardsCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminCardsPost(body?: StudentAdminCardsCommand, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminCardsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Swap student class.
     * @param {StudentAdminClassSwapCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminClassSwapPost(body?: StudentAdminClassSwapCommand, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminClassSwapPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides the relevant information to be shown on the teacher invite screen
     * @param {StudentAdminCreateCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminCreatePost(body?: StudentAdminCreateCommand, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminCreatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Student dashboard which provides class information and archived students.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminDashboardGet(options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminDashboardGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a student profile
     * @param {StudentAdminDeleteCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminDeleteDelete(body?: StudentAdminDeleteCommand, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Unarchive student profile and assign to a class
     * @param {StudentAdminUnarchiveCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminUnarchivePost(body?: StudentAdminUnarchiveCommand, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminUnarchivePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Uploads a list of first name/last name list in an excel file and performs the following:    * Validates excel file    * Retrieves the list of names from the excel file    * Creates user with a valid username    * Returns the list of users with their status
     * @param {string} [file] 
     * @param {string} [classId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminUploadPost(file?: string, classId?: string, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminUploadPost(file, classId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provide individual student information
     * @param {string} [profileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentAdminApi
     */
    public apiStudentAdminViewGet(profileId?: string, options?: any) {
        return StudentAdminApiFp(this.configuration).apiStudentAdminViewGet(profileId, options).then((request) => request(this.axios, this.basePath));
    }
}
