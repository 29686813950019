// if user has opened a new tab and logged in -  the token will differ to what is stored in this scope
const userSession = (() => {
	let comparisonCheckForUser: string | null = null;

	return {
		set: (token: string | null) => {
			comparisonCheckForUser = token;
		},
		check: () => {
			// check for multiple tabs that are logged in
			if (comparisonCheckForUser && comparisonCheckForUser !== (localStorage.getItem('token') || '')) {
				window.location.reload();
				return false;
			}

			return true;
		}
	};
})();

export const setUserSession = userSession.set;
export const checkUserSession = userSession.check;
