import { ChangeEvent, FC, useEffect, useState } from 'react';

// PACKAGES
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Formik, FormikHelpers, Form as FormikForm, Field } from 'formik';

// API
import apibridge from '../../../apibridge';
import { ResponseStandardFail } from '../../../libs/api';
import { AuthenticationForgotPasswordCommand } from '../../../api/models';

// UTILS
import { SITE_KEY, createErrorsObject, loadRecaptchaScript } from '../../../libs/utils';
import SvgMask from '../../../components/_Helpers/SvgMask';
import ImgRem from '../../../libs/imgRem';

// COMPONENTS
import FormGroupWithError from '../../../components/Forms/FormGroupWithError';
import FormCustomError from '../../../components/Forms/FormCustomError';

const ForgotPassword: FC = () => {
	const [formValues, setFormValues] = useState<AuthenticationForgotPasswordCommand>({
		email: '',
		captchaToken: ''
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLFormElement>) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const forgotPassword = async (
		values: AuthenticationForgotPasswordCommand,
		captchaToken: string,
		formikHelpers: FormikHelpers<AuthenticationForgotPasswordCommand>
	) => {
		const { email = '' } = values;

		const response = await apibridge.postForgotPassword({
			email,
			captchaToken
		});

		if (response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				setSubmissionSuccessful(true);
			}
		}

		setIsSubmitting(false);
	};

	const doForgotPassword = async (
		values: AuthenticationForgotPasswordCommand,
		formikHelpers: FormikHelpers<AuthenticationForgotPasswordCommand>
	) => {
		setIsSubmitting(true);

		await window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(SITE_KEY, { action: 'submit' })
				.then((captchaToken: string) => {
					forgotPassword(values, captchaToken, formikHelpers);
				})
				.catch(() => {
					formikHelpers.setErrors({ captchaToken: 'Recaptcha failed!' });
					setIsSubmitting(false);
				});
		});
	};

	useEffect(() => {
		loadRecaptchaScript();
	}, []);

	return (
		<>
			<picture>
				<source srcSet="./img/login-bg.webp" />
				<img
					src="./img/login-bg.jpg"
					className="position-fixed w-100 h-100 object-fit object-fit-cover user-select-none"
					alt="Tiled books background"
				/>
			</picture>
			<main className="page-staff-forgot-password d-flex align-items-center py-5">
				<Container className="position-relative z-1">
					<Formik initialValues={formValues} onSubmit={doForgotPassword}>
						<FormikForm onChange={handleChange} className="form-rounded-corners">
							{submissionSuccessful ? (
								<>
									<ImgRem src="/svg/email-sent.svg" width="160" height="160" alt="Envelope" className="mx-auto" />
									<h2 className="text-shades-800 m-0">
										<strong>Check your email</strong>
									</h2>
									<p className="m-0">
										We have sent an email to <strong>{formValues.email}</strong> with instructions on how to reset your
										password.
									</p>
									<p className="m-0">
										If you don't see the email soon, please check your spam folder or{' '}
										<button type="submit" className="btn btn-reset btn-link" disabled={isSubmitting}>
											resend the email.
										</button>
									</p>
								</>
							) : (
								<>
									<ImgRem
										src="/svg/llll-online-contained-wide-logo.svg"
										width="231"
										height="70"
										alt="Little Learners Love Literacy Online logo"
										className="logo mx-auto"
									/>
									<h2 className="text-shades-800 m-0">
										<strong>Reset your password</strong>
									</h2>
									<p className="m-0">
										Type the email address linked to your account and we'll send you instructions on how to reset your
										password.
									</p>
									<div>
										<FormCustomError label="captchaToken" className="text-start mb-2" />
										<FormGroupWithError name="email" className="text-start">
											<label htmlFor="email-address" className="form-label">
												Email Address
											</label>
											<Field type="email" name="email" id="email-address" className="form-control" />
										</FormGroupWithError>
									</div>
									<div className="d-grid">
										<button type="submit" className="btn btn-lg" disabled={isSubmitting}>
											Reset password
										</button>
									</div>
								</>
							)}

							<div>
								<Link to={'/login'} className="btn btn-link">
									<SvgMask path={'/svg/arrow-left.svg'} width={24} height={24} />
									Back to login
								</Link>
							</div>
						</FormikForm>
					</Formik>
				</Container>
			</main>
		</>
	);
};

export default ForgotPassword;
