import * as React from 'react';

interface DividerTypes {
	className?: string;
}

const Divider: React.FC<DividerTypes> = ({ className }) => {
	return (
		<div className={`divider ${className || ''}`}>
			<div className="left"></div>
			<div className="h4 text-shades-400">OR</div>
			<div className="right"></div>
		</div>
	);
};

export default Divider;
