import { ChangeEvent, FC, useState } from 'react';

// PACKAGES
import { Container, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Formik, FormikHelpers, Form as FormikForm, Field } from 'formik';

// API
import apibridge from '../../../apibridge';
import { ResponseStandardFail } from '../../../libs/api';
import { AuthenticationResetPasswordCommand } from '../../../api/models';

// UTILS
import { createErrorsObject } from '../../../libs/utils';
import ImgRem from '../../../libs/imgRem';

// COMPONENTS
import FormGroupWithError from '../../../components/Forms/FormGroupWithError';
import FormCustomError from '../../../components/Forms/FormCustomError';
import SvgMask from '../../../components/_Helpers/SvgMask';

const ResetPassword: FC = () => {
	const [formValues, setFormValues] = useState<AuthenticationResetPasswordCommand>({
		token: '',
		email: '',
		password: '',
		confirmPassword: ''
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLFormElement>) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const resetPassword = async (
		values: AuthenticationResetPasswordCommand,
		formikHelpers: FormikHelpers<AuthenticationResetPasswordCommand>
	) => {
		const { confirmPassword, password } = values;
		const searchParams = new URLSearchParams(window.location.search);
		const email = searchParams.get('email') || '';
		const token = searchParams.get('token') || '';

		const response = await apibridge.postResetPassword({
			token,
			email,
			password,
			confirmPassword
		});

		if (response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				setSubmissionSuccessful(true);
			}
		}

		setIsSubmitting(false);
	};

	const doResetPassword = async (
		values: AuthenticationResetPasswordCommand,
		formikHelpers: FormikHelpers<AuthenticationResetPasswordCommand>
	) => {
		setIsSubmitting(true);
		resetPassword(values, formikHelpers);
	};

	return (
		<>
			<picture>
				<source srcSet="./img/login-bg.webp" />
				<img
					src="./img/login-bg.jpg"
					className="position-fixed w-100 h-100 object-fit object-fit-cover user-select-none"
					alt="Tiled books background"
				/>
			</picture>
			<main className="page-staff-reset-password d-flex align-items-center py-5">
				<Container className="position-relative z-1">
					<Formik initialValues={formValues} onSubmit={doResetPassword}>
						<FormikForm onChange={handleChange} className="form-rounded-corners">
							<ImgRem
								src="/svg/llll-online-contained-wide-logo.svg"
								width="231"
								height="70"
								alt="Little Learners Love Literacy Online logo"
								className="logo mx-auto"
							/>
							<h2 className="text-shades-800 m-0">
								<strong>Reset password for your account</strong>
							</h2>

							{submissionSuccessful ? (
								<>
									<p className="m-0">Your password has now been updated.</p>
									<div className="text-center">
										<Link to={'/login'} className="btn btn-link">
											<SvgMask path="/svg/arrow-left.svg" width={24} height={24} />
											Back To Log In
										</Link>
									</div>
								</>
							) : (
								<div>
									<FormCustomError className="text-start my-2" />
									<FormCustomError label="email" className="text-start my-2" />
									<FormCustomError label="token" className="text-start my-2" />

									<FormGroupWithError name="password" className="text-start mb-3">
										<label htmlFor="password" className="form-label">
											New Password
										</label>
										<InputGroup>
											<Field
												type={showPassword ? 'text' : 'password'}
												name="password"
												id="password"
												className="form-control border-end-0"
											/>
											<InputGroup.Text className="bg-transparent p-0">
												<button
													type="button"
													className="btn btn-sm bg-transparent text-reset"
													onClick={() => setShowPassword(!showPassword)}
													tabIndex={-1}
												>
													<SvgMask
														path={showPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
														width={24}
														height={24}
													/>
												</button>
											</InputGroup.Text>
										</InputGroup>
									</FormGroupWithError>
									<FormGroupWithError name="confirmPassword" className="text-start">
										<label htmlFor="confirm-password" className="form-label">
											Confirm New Password
										</label>
										<InputGroup>
											<Field
												type={showConfirmPassword ? 'text' : 'password'}
												name="confirmPassword"
												id="confirm-password"
												className="form-control border-end-0"
											/>
											<InputGroup.Text className="bg-transparent p-0">
												<button
													type="button"
													className="btn btn-sm bg-transparent text-reset"
													onClick={() => setShowConfirmPassword(!showConfirmPassword)}
													tabIndex={-1}
												>
													<SvgMask
														path={showConfirmPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
														width={24}
														height={24}
													/>
												</button>
											</InputGroup.Text>
										</InputGroup>
									</FormGroupWithError>
									<div className="d-grid mt-5">
										<button type="submit" className="btn btn-lg" disabled={isSubmitting}>
											Change Password
										</button>
									</div>
								</div>
							)}
						</FormikForm>
					</Formik>
				</Container>
			</main>
		</>
	);
};

export default ResetPassword;
