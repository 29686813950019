import React from 'react';

// UITLS
import { guid } from '../../libs/utils';

// TYPES
import { ToastMessageType, ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { useAppDispatch } from '../../store/slice';

const testToastMessages: ToastMessageType[] = [
	{
		id: '4776d05e-47cb-4924-975d-87214d2a72b0',
		type: 'success',
		heading: 'Success Toast Heading',
		description:
			'Descriptio ut laoreet porttitor velit id tempor egest dignissim. Quis senectus id euismod quis libero.'
	},
	{
		id: 'f36a67f1-5639-4833-b89f-b416d92793a9',
		type: 'info',
		heading: 'Info Toast Heading',
		description:
			'Descriptio ut laoreet porttitor velit id tempor egest dignissim. Quis senectus id euismod quis libero.'
	},
	{
		id: '41d0efbe-34b4-478a-88a4-d906794be7cf',
		type: 'danger',
		heading: 'Warning Toast Heading',
		description:
			'Descriptio ut laoreet porttitor velit id tempor egest dignissim. Quis senectus id euismod quis libero.'
	}
];

const Styleguide: React.FC = () => {
	const dispatch = useAppDispatch();

	const addToastMessage = () => {
		const nextMessageIndex = Math.floor(Math.random() * 3); // grab one of three random messages to test with
		const nextMessage = { ...testToastMessages[nextMessageIndex], id: guid() }; // randomising GUID to prevent repeat keys by accident when using the 'Test Toast Messages' button
		dispatch(ToastMessagesSlice.actions.add(nextMessage));
	};

	return (
		<div className="container">
			<button type="button" className="btn mb-3" onClick={() => addToastMessage()}>
				Test Toast Messages
			</button>

			<h2>Staff</h2>
			<h3>Authentication</h3>
			<ul>
				<li>
					<a href="/login">Login</a>
				</li>
				<li>
					<a href="/forgot-password">Forgot Password</a>
				</li>
				<li>
					<a href="/reset-password">Reset Password</a>
				</li>
				<li>
					<a href="/registration">Registration</a>
				</li>
				<li>
					<a href="/join-organisation">Existing User Joining Organisation</a>
				</li>
			</ul>

			<h3>Planner</h3>
			<ul>
				<li>
					<a href="/planner">Planner</a>
				</li>
			</ul>

			<h3>Routines</h3>
			<ul>
				<li>
					<a href="/routines">Routines</a>
				</li>
			</ul>

			<h3>Resources</h3>
			<ul>
				<li>
					<a href="/resources">Resources</a>
				</li>
			</ul>

			<h3>Professional Learning</h3>
			<ul>
				<li>
					<a href="/professional-learning">Listing</a>
				</li>
				<li>
					<a href="/professional-learning/Little%20Learners%20Love%20Literacy%20-%20YouTube">Item</a>
				</li>
			</ul>

			<h3>Help Centre</h3>
			<ul>
				<li>
					<a href="/help-centre">Help Centre</a>
				</li>
			</ul>

			<hr />

			<h2>Student</h2>
			<h3>Authentication</h3>
			<ul>
				<li>
					<a href="/login">Login</a>
				</li>
			</ul>

			<h3>E-Library</h3>
			<ul>
				<li>
					<a href="/elibrary">E-Library</a>
				</li>
				<li>
					<a href="/awards">Awards</a>
				</li>
			</ul>

			<h3>Reading Mode</h3>
			<ul>
				{/* If testing, will need to grab a book ID from the student's bookshelf using Swagger */}
				<li>
					<a href="/elibrary/c72ffa21-d523-471d-9ee2-b5617d9a7ad6">Book</a>
				</li>
				<li>
					<a href="/elibrary/c72ffa21-d523-471d-9ee2-b5617d9a7ad6/feedback">Feedback</a>
				</li>
			</ul>

			<h3>Post-Reading Activities</h3>
			<ul>
				<li>
					<a href="/elibrary/f0b8bc80-0fe1-4834-b522-5d237e2144ca/quiz/1">Drag / Drop</a>
				</li>
				<li>
					<a href="/elibrary/f0b8bc80-0fe1-4834-b522-5d237e2144ca/quiz/2">Select Right Answer</a>
				</li>
				<li>
					<a href="/elibrary/f0b8bc80-0fe1-4834-b522-5d237e2144ca/quiz/5">Match Lines</a>
				</li>
			</ul>
		</div>
	);
};

export default Styleguide;
