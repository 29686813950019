import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { UserSlice, UserType } from '../../store/slice/User';

// PACKAGES
import Moment from 'moment';
import { InputGroup, Modal, Spinner } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm, FormikHelpers } from 'formik';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import FormCustomError from '../../components/Forms/FormCustomError';
import FormGroupWithError from '../../components/Forms/FormGroupWithError';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import { createErrorsObject, guid } from '../../libs/utils';

// TYPES
import apibridge from '../../apibridge';
import { ResponseStandardFail } from '../../libs/api';
import {
	AccountEmailChangeCommand,
	AccountNameChangeCommand,
	AccountPasswordChangeCommand,
	AccountSettingsSettingsResult
} from '../../api/models';

const Settings: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const user: UserType = useAppSelector((state) => state.user);

	const [isLoading, setIsLoading] = useState(true);

	const [settings, setSettings] = useState<AccountSettingsSettingsResult>();

	// EDIT NAME
	const initialEditNameValues: AccountNameChangeCommand = {
		firstName: settings?.firstName,
		lastName: settings?.lastName
	};
	const [editNameValues, setEditNameValues] = useState<AccountNameChangeCommand>(initialEditNameValues);
	const [editNameSubmitting, setEditNameSubmitting] = useState(false);
	const [showEditNameModal, setShowEditNameModal] = useState(false);

	const postEditName = async (
		values: AccountNameChangeCommand,
		formikHelpers: FormikHelpers<AccountNameChangeCommand>
	) => {
		setEditNameSubmitting(true);

		const { firstName, lastName } = values;
		const response = await apibridge.postNameChange({
			firstName,
			lastName
		});

		if (response && response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				setSettings({
					...settings,
					firstName,
					lastName
				});
				setShowEditNameModal(false);
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Name change complete.',
						description: ''
					})
				);

				// re-fetch the user details and update <ToastNag />
				dispatch(UserSlice.actions.forceUpdate());
			}
		}

		setEditNameSubmitting(false);
	};
	const validateEditName = (values: AccountNameChangeCommand) => {
		const errors: { [field: string]: string } = {};
		if (values['firstName'] === settings?.firstName && values['lastName'] === settings?.lastName) {
			errors.sameName = 'Same full name entered. Please enter a new name to change it.';
		}

		return errors;
	};

	// CHANGE EMAIL
	const initialChangeEmailValues: AccountEmailChangeCommand = {
		newEmail: '',
		password: ''
	};
	const [changeEmailValues, setChangeEmailValues] = useState<AccountEmailChangeCommand>(initialChangeEmailValues);
	const [changeEmailSubmitting, setChangeEmailSubmitting] = useState(false);
	const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
	const [emailChangeSuccessful, setEmailChangeSuccessful] = useState(false);
	const [showChangeEmailPassword, setShowChangeEmailPassword] = useState(false);

	const postChangeEmail = async (
		values: AccountEmailChangeCommand,
		formikHelpers: FormikHelpers<AccountEmailChangeCommand>
	) => {
		setChangeEmailSubmitting(true);

		const { newEmail, password } = values;
		const response = await apibridge.postEmailChange({
			newEmail,
			password
		});

		if (response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				setShowChangeEmailModal(false);
				setEmailChangeSuccessful(true);
			}
		}

		setChangeEmailSubmitting(false);
	};

	// CHANGE PASSWORD
	const initialChangePasswordValues: AccountPasswordChangeCommand = {
		password: '',
		newPassword: '',
		confirmPassword: ''
	};
	const [changePasswordSubmitting, setChangePasswordSubmitting] = useState(false);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [passwordChangeSuccessful, setPasswordChangeSuccessful] = useState(false);
	const [changePasswordValues, setChangePasswordValues] = useState(initialChangePasswordValues);

	const postChangePassword = async (
		values: AccountPasswordChangeCommand,
		formikHelpers: FormikHelpers<AccountPasswordChangeCommand>
	) => {
		setChangePasswordSubmitting(true);

		const { password, confirmPassword, newPassword } = values;
		const response = await apibridge.postPasswordChange({
			password,
			newPassword,
			confirmPassword
		});

		if (response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				setShowChangePasswordModal(false);
				setPasswordChangeSuccessful(true);
			}
		}

		setChangePasswordSubmitting(false);
	};

	const getSettings = async () => {
		setIsLoading(true);

		const response = await apibridge.getSettings();
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const { firstName, lastName } = response.data.result;
				setSettings(response.data.result);
				setEditNameValues({
					firstName,
					lastName
				});
			}
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getSettings();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-settings d-flex flex-column flex-grow-1">
			<TitleSection title="Settings" description="Edit your account or organisation details and billing preferences.">
				<div className="d-flex justify-content-between">
					<div className="position-relative w-auto align-items-center">
						<nav className="nav nav-pills flex-row">
							{user.permissions?.includes('AccountSettings') && (
								<Link to={'/settings'} className="nav-pill h4 no-pseudo-hover text-decoration-none active">
									<SvgMask path="/svg/profile.svg" width={24} height={24} />
									Account Details
								</Link>
							)}
							{user.permissions?.includes('OrganisationSettings') && (
								<Link
									to={'/settings/organisation-details'}
									className="nav-pill h4 no-pseudo-hover text-decoration-none"
								>
									<SvgMask path="/svg/teachers-hat-sm.svg" width={24} height={24} />
									Organisation Details
								</Link>
							)}
						</nav>
					</div>
				</div>
			</TitleSection>
			<div className="container h-100">
				{isLoading ? (
					<div className="d-flex align-items-center justify-content-center position-relative w-100">
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				) : user.permissions?.includes('AccountSettings') ? (
					<>
						<div className="row">
							<div className="col-lg-8">
								<div className="bordered-list">
									<div className="header">
										<div className="account-image-wrapper">
											<img src="/svg/user.svg" width={48} height={48} alt="Profile stencil" />
										</div>
										<div className="flex-grow-1">
											<p className="body-tiny mb-1 text-shades-500">
												<strong>
													Joined {settings?.joined ? Moment(settings.joined).format('D MMM, YYYY') : 'N/A'}
												</strong>
											</p>
											<h3 className="d-flex align-items-center gap-2 m-0">
												{settings?.firstName} {settings?.lastName}
												{user.profiles?.[0].roleName === 'Organisation Administrator' && (
													<span className="admin badge rounded-pill tag-label">Admin</span>
												)}
											</h3>
										</div>
										{user.permissions.includes('AccountNameChange') && (
											<div>
												<button type="button" className="btn btn-sm" onClick={() => setShowEditNameModal(true)}>
													Edit Name
												</button>
											</div>
										)}
									</div>
									<div className="content d-flex flex-column gap-4 px-4">
										<div>
											<p className="body-tiny mb-1 text-shades-500">
												<strong>Email Address</strong>
											</p>
											<p className="m-0">
												<strong>{settings?.email}</strong>
											</p>
										</div>
										{user.permissions?.some((permission) =>
											['AccountEmailChange', 'AccountPasswordChange'].includes(permission)
										) && (
											<div className="row mt-2">
												{user.permissions.includes('AccountEmailChange') && (
													<div className="col-4">
														<button type="button" onClick={() => setShowChangeEmailModal(true)} className="btn w-100">
															Change email
														</button>
													</div>
												)}
												{user.permissions.includes('AccountPasswordChange') && (
													<div className="col-4">
														<button
															type="button"
															onClick={() => setShowChangePasswordModal(true)}
															className="btn btn-white w-100"
														>
															Change password
														</button>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<Modal
							show={showEditNameModal}
							onHide={() => setShowEditNameModal(false)}
							onExited={() => setEditNameValues(initialEditNameValues)}
							centered
						>
							<Modal.Body>
								<h2 className="m-0 text-shades-800">
									<strong>Edit Your Name</strong>
								</h2>
								<p className="m-0">What would you like to change your name to?</p>

								<Formik initialValues={editNameValues} validate={validateEditName} onSubmit={postEditName}>
									<FormikForm
										onChange={(e: ChangeEvent<HTMLFormElement>) => {
											const { name, value } = e.target;
											setEditNameValues({
												...editNameValues,
												[name]: value
											});
										}}
									>
										<FormCustomError className="text-start my-2" />

										<FormGroupWithError name="firstName" className="text-start mb-3">
											<label htmlFor="firstName" className="form-label">
												First Name
											</label>
											<Field type="text" id="firstName" name="firstName" className="form-control" />
										</FormGroupWithError>
										<FormGroupWithError name="lastName" className="text-start mb-3">
											<label htmlFor="lastName" className="form-label">
												Last Name
											</label>
											<Field type="text" id="lastName" name="lastName" className="form-control" />
										</FormGroupWithError>

										<FormCustomError label="sameName" className="text-start my-2" />

										<div className="d-flex button-group mt-4-5">
											<button
												type="button"
												className="btn btn-white btn-lg w-100"
												onClick={() => setShowEditNameModal(false)}
											>
												Cancel
											</button>
											<button type="submit" className="btn btn-lg w-100" disabled={editNameSubmitting}>
												Change name
											</button>
										</div>
									</FormikForm>
								</Formik>
							</Modal.Body>
						</Modal>

						<Modal
							show={showChangeEmailModal}
							onHide={() => setShowChangeEmailModal(false)}
							onExited={() => setChangeEmailValues(initialChangeEmailValues)}
							centered
						>
							<Modal.Body className="gap-0">
								<div className="d-flex flex-column gap-3-5">
									<h2 className="m-0 text-shades-800">
										<strong>Change your email address</strong>
									</h2>
								</div>

								<Formik initialValues={changeEmailValues} onSubmit={postChangeEmail}>
									<FormikForm
										onChange={(e: ChangeEvent<HTMLFormElement>) => {
											const { name, value } = e.target;
											setChangeEmailValues({
												...changeEmailValues,
												[name]: value
											});
										}}
										className="mt-4-5"
									>
										<FormCustomError className="text-start my-2" />

										<FormGroupWithError name="newEmail" className="text-start">
											<label htmlFor="newEmail" className="form-label">
												New email address
											</label>
											<Field type="email" id="newEmail" name="newEmail" className="form-control" />
										</FormGroupWithError>
										<hr className="mt-4-5 mb-4-5 text-shades-400" />
										<p className="h4 text-center">
											<strong>Enter your password to confirm your new email address.</strong>
										</p>
										<p className="h4 text-center">
											<strong>
												Once confirmed, you will be logged out and asked to log in again with your new email address.
											</strong>
										</p>
										<FormGroupWithError name="password" className="mt-4-5 text-start">
											<label htmlFor="password" className="form-label">
												Your password
											</label>
											<InputGroup>
												<Field
													type={showChangeEmailPassword ? 'text' : 'password'}
													id="password"
													name="password"
													className="form-control border-end-0"
												/>
												<InputGroup.Text className="bg-white p-0">
													<button
														type="button"
														className="btn btn-sm bg-transparent text-reset"
														onClick={() => setShowChangeEmailPassword(!showChangeEmailPassword)}
														tabIndex={-1}
													>
														<SvgMask
															path={showChangeEmailPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
															width={24}
															height={24}
														/>
													</button>
												</InputGroup.Text>
											</InputGroup>
										</FormGroupWithError>

										<div className="d-flex button-group">
											<button
												type="button"
												className="btn btn-white btn-lg w-100"
												onClick={() => setShowChangeEmailModal(false)}
											>
												Cancel
											</button>
											<button type="submit" className="btn btn-lg w-100" disabled={changeEmailSubmitting}>
												Change email
											</button>
										</div>
									</FormikForm>
								</Formik>
							</Modal.Body>
						</Modal>
						<Modal
							show={emailChangeSuccessful}
							onHide={() => setEmailChangeSuccessful(false)}
							onExited={() => {
								dispatch(UserSlice.actions.logOut());
								navigate('/settings'); // will boot the user back to the login screen thanks to StaffRoutes, and then take them back here
							}}
							centered
						>
							<Modal.Body>
								<h2 className="m-0 text-shades-800">
									<strong>Email changed</strong>
								</h2>
								<p className="m-0">You have successfully changed your email.</p>
								<p className="m-0">
									You will now be logged out.
									<br />
									Please use your new email address to log back in.
								</p>
								<div className="d-flex button-group">
									<button type="button" onClick={() => setEmailChangeSuccessful(false)} className="btn btn-lg w-100">
										Close
									</button>
								</div>
							</Modal.Body>
						</Modal>

						<Modal
							show={showChangePasswordModal}
							onHide={() => setShowChangePasswordModal(false)}
							onExited={() => setChangePasswordValues(initialChangePasswordValues)}
							centered
						>
							<Modal.Body>
								<h2 className="m-0 text-shades-800">
									<strong>Change your password</strong>
								</h2>
								<p className="m-0">
									After confirming the new password, you will be logged out and asked to log in again.
								</p>
								<Formik initialValues={changePasswordValues} onSubmit={postChangePassword}>
									<FormikForm
										onChange={(e: ChangeEvent<HTMLFormElement>) => {
											const { name, value } = e.target;
											setChangePasswordValues({
												...changePasswordValues,
												[name]: value
											});
										}}
									>
										<FormCustomError className="text-start my-2" />

										<FormGroupWithError name="password" className="text-start mb-4">
											<label htmlFor="password" className="form-label">
												Current password
											</label>
											<InputGroup>
												<Field
													type={showPassword ? 'text' : 'password'}
													id="password"
													name="password"
													className="form-control border-end-0"
													placeholder="Type your current password"
												/>
												<InputGroup.Text className="bg-white p-0">
													<button
														type="button"
														className="btn btn-sm bg-transparent text-reset"
														onClick={() => setShowPassword(!showPassword)}
														tabIndex={-1}
													>
														<SvgMask
															path={showPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
															width={24}
															height={24}
														/>
													</button>
												</InputGroup.Text>
											</InputGroup>
										</FormGroupWithError>
										<FormGroupWithError name="newPassword" className="text-start mb-4">
											<label htmlFor="newPassword" className="form-label">
												New password
											</label>
											<InputGroup>
												<Field
													type={showNewPassword ? 'text' : 'password'}
													id="newPassword"
													name="newPassword"
													className="form-control border-end-0"
													placeholder="Type your new password"
												/>
												<InputGroup.Text className="bg-white p-0">
													<button
														type="button"
														className="btn btn-sm bg-transparent text-reset"
														onClick={() => setShowNewPassword(!showNewPassword)}
														tabIndex={-1}
													>
														<SvgMask
															path={showNewPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
															width={24}
															height={24}
														/>
													</button>
												</InputGroup.Text>
											</InputGroup>
										</FormGroupWithError>
										<FormGroupWithError name="confirmPassword" className="text-start mb-4">
											<label htmlFor="confirmPassword" className="form-label">
												Confirm new password
											</label>
											<InputGroup>
												<Field
													type={showConfirmPassword ? 'text' : 'password'}
													id="confirmPassword"
													name="confirmPassword"
													className="form-control border-end-0"
													placeholder="Password"
												/>
												<InputGroup.Text className="bg-white p-0">
													<button
														type="button"
														className="btn btn-sm bg-transparent text-reset"
														onClick={() => setShowConfirmPassword(!showConfirmPassword)}
														tabIndex={-1}
													>
														<SvgMask
															path={showConfirmPassword ? '/svg/visible-off.svg' : '/svg/visible-on.svg'}
															width={24}
															height={24}
														/>
													</button>
												</InputGroup.Text>
											</InputGroup>
										</FormGroupWithError>

										<div className="d-flex button-group mt-4-5">
											<button
												type="button"
												className="btn btn-white btn-lg w-100"
												onClick={() => setShowChangePasswordModal(false)}
											>
												Cancel
											</button>
											<button type="submit" className="btn btn-lg w-100" disabled={changePasswordSubmitting}>
												Change password
											</button>
										</div>
									</FormikForm>
								</Formik>
							</Modal.Body>
						</Modal>
						<Modal
							show={passwordChangeSuccessful}
							onHide={() => setPasswordChangeSuccessful(false)}
							onExited={() => {
								dispatch(UserSlice.actions.logOut());
								navigate('/settings'); // will boot the user back to the login screen thanks to StaffRoutes, and then take them back here
							}}
							centered
						>
							<Modal.Body>
								<h2 className="m-0 text-shades-800">
									<strong>Password changed</strong>
								</h2>
								<p className="m-0">You have successfully changed your password.</p>
								<p className="m-0">
									You will now be logged out.
									<br />
									Please use your new password to log back in.
								</p>
								<div className="d-flex button-group">
									<button type="button" className="btn btn-lg w-100" onClick={() => setPasswordChangeSuccessful(false)}>
										Close
									</button>
								</div>
							</Modal.Body>
						</Modal>
					</>
				) : (
					<div className="d-flex align-items-center justify-content-center h-100">
						<h3 className="text-center text-shades-500 m-0">You don't have permission to view this content.</h3>
					</div>
				)}
			</div>
		</div>
	);
};

export default Settings;
