/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { SharedModelsCommandResponse } from '../models';
import { StaffBookCompleteCommand } from '../models';
import { StaffBookCompleteResponse } from '../models';
import { StaffBookViewResponse } from '../models';
import { StaffClassAssignCommand } from '../models';
import { StaffClassRemoveCommand } from '../models';
import { StaffDashboardResponse } from '../models';
import { StaffDeleteCommand } from '../models';
import { StaffInviteCommand } from '../models';
import { StaffInviteResendCommand } from '../models';
import { StaffInviteResponse } from '../models';
import { StaffInviteRevokeCommand } from '../models';
import { StaffListResponse } from '../models';
import { StaffUploadResponse } from '../models';
import { StaffViewResponse } from '../models';
/**
 * StaffApi - axios parameter creator
 * @export
 */
export const StaffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Call this end point to retrieve book data (i.e. cover url) when staff completes the book.
         * @param {StaffBookCompleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffBookCompletePost: async (body?: StaffBookCompleteCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/BookComplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Viewing individual book
         * @param {string} [bookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffBookViewGet: async (bookId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/BookView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (bookId !== undefined) {
                localVarQueryParameter['BookId'] = bookId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign class to a teacher profile
         * @param {StaffClassAssignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffClassAssignPost: async (body?: StaffClassAssignCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/ClassAssign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove class from a teacher profile.
         * @param {StaffClassRemoveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffClassRemoveDelete: async (body?: StaffClassRemoveCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/ClassRemove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Staff dashboard which contains active members and pending requests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffDashboardGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/Dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a teacher profile
         * @param {StaffDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffDeleteDelete: async (body?: StaffDeleteCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives a list of email addresses and performs tasks below:   * Validates emails   * Create accounts/profiles in the system   * Send invite emails
         * @param {StaffInviteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffInvitePost: async (body?: StaffInviteCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/Invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resends invitation email
         * @param {StaffInviteResendCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffInviteResendPost: async (body?: StaffInviteResendCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/InviteResend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revoke invitation to protential teacher before the user accepts it.
         * @param {StaffInviteRevokeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffInviteRevokeDelete: async (body?: StaffInviteRevokeCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/InviteRevoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list of active staff members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads a list of emails in an excel file and performs the following:    * Validates excel file    * Retrieves the list of emails from the excel file    * Ensure email address are valid    * Returns the list of email address with their status
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffUploadPost: async (file?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/Upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View a staff member's details.
         * @param {string} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffViewGet: async (profileId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staff/View`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['ProfileId'] = profileId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Call this end point to retrieve book data (i.e. cover url) when staff completes the book.
         * @param {StaffBookCompleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffBookCompletePost(body?: StaffBookCompleteCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffBookCompleteResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffBookCompletePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Viewing individual book
         * @param {string} [bookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffBookViewGet(bookId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffBookViewResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffBookViewGet(bookId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Assign class to a teacher profile
         * @param {StaffClassAssignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffClassAssignPost(body?: StaffClassAssignCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffClassAssignPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Remove class from a teacher profile.
         * @param {StaffClassRemoveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffClassRemoveDelete(body?: StaffClassRemoveCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffClassRemoveDelete(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Staff dashboard which contains active members and pending requests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffDashboardGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDashboardResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffDashboardGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a teacher profile
         * @param {StaffDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffDeleteDelete(body?: StaffDeleteCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffDeleteDelete(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Receives a list of email addresses and performs tasks below:   * Validates emails   * Create accounts/profiles in the system   * Send invite emails
         * @param {StaffInviteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffInvitePost(body?: StaffInviteCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInviteResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffInvitePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Resends invitation email
         * @param {StaffInviteResendCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffInviteResendPost(body?: StaffInviteResendCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffInviteResendPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Revoke invitation to protential teacher before the user accepts it.
         * @param {StaffInviteRevokeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffInviteRevokeDelete(body?: StaffInviteRevokeCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffInviteRevokeDelete(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list of active staff members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffListResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Uploads a list of emails in an excel file and performs the following:    * Validates excel file    * Retrieves the list of emails from the excel file    * Ensure email address are valid    * Returns the list of email address with their status
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffUploadPost(file?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffUploadResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffUploadPost(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary View a staff member's details.
         * @param {string} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffViewGet(profileId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffViewResponse>> {
            const localVarAxiosArgs = await StaffApiAxiosParamCreator(configuration).apiStaffViewGet(profileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Call this end point to retrieve book data (i.e. cover url) when staff completes the book.
         * @param {StaffBookCompleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffBookCompletePost(body?: StaffBookCompleteCommand, options?: any): AxiosPromise<StaffBookCompleteResponse> {
            return StaffApiFp(configuration).apiStaffBookCompletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Viewing individual book
         * @param {string} [bookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffBookViewGet(bookId?: string, options?: any): AxiosPromise<StaffBookViewResponse> {
            return StaffApiFp(configuration).apiStaffBookViewGet(bookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign class to a teacher profile
         * @param {StaffClassAssignCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffClassAssignPost(body?: StaffClassAssignCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StaffApiFp(configuration).apiStaffClassAssignPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove class from a teacher profile.
         * @param {StaffClassRemoveCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffClassRemoveDelete(body?: StaffClassRemoveCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StaffApiFp(configuration).apiStaffClassRemoveDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Staff dashboard which contains active members and pending requests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffDashboardGet(options?: any): AxiosPromise<StaffDashboardResponse> {
            return StaffApiFp(configuration).apiStaffDashboardGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a teacher profile
         * @param {StaffDeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffDeleteDelete(body?: StaffDeleteCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StaffApiFp(configuration).apiStaffDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives a list of email addresses and performs tasks below:   * Validates emails   * Create accounts/profiles in the system   * Send invite emails
         * @param {StaffInviteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffInvitePost(body?: StaffInviteCommand, options?: any): AxiosPromise<StaffInviteResponse> {
            return StaffApiFp(configuration).apiStaffInvitePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resends invitation email
         * @param {StaffInviteResendCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffInviteResendPost(body?: StaffInviteResendCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StaffApiFp(configuration).apiStaffInviteResendPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revoke invitation to protential teacher before the user accepts it.
         * @param {StaffInviteRevokeCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffInviteRevokeDelete(body?: StaffInviteRevokeCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return StaffApiFp(configuration).apiStaffInviteRevokeDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list of active staff members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffListGet(options?: any): AxiosPromise<StaffListResponse> {
            return StaffApiFp(configuration).apiStaffListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads a list of emails in an excel file and performs the following:    * Validates excel file    * Retrieves the list of emails from the excel file    * Ensure email address are valid    * Returns the list of email address with their status
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffUploadPost(file?: string, options?: any): AxiosPromise<StaffUploadResponse> {
            return StaffApiFp(configuration).apiStaffUploadPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View a staff member's details.
         * @param {string} [profileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffViewGet(profileId?: string, options?: any): AxiosPromise<StaffViewResponse> {
            return StaffApiFp(configuration).apiStaffViewGet(profileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
    /**
     * 
     * @summary Call this end point to retrieve book data (i.e. cover url) when staff completes the book.
     * @param {StaffBookCompleteCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffBookCompletePost(body?: StaffBookCompleteCommand, options?: any) {
        return StaffApiFp(this.configuration).apiStaffBookCompletePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Viewing individual book
     * @param {string} [bookId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffBookViewGet(bookId?: string, options?: any) {
        return StaffApiFp(this.configuration).apiStaffBookViewGet(bookId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Assign class to a teacher profile
     * @param {StaffClassAssignCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffClassAssignPost(body?: StaffClassAssignCommand, options?: any) {
        return StaffApiFp(this.configuration).apiStaffClassAssignPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Remove class from a teacher profile.
     * @param {StaffClassRemoveCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffClassRemoveDelete(body?: StaffClassRemoveCommand, options?: any) {
        return StaffApiFp(this.configuration).apiStaffClassRemoveDelete(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Staff dashboard which contains active members and pending requests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffDashboardGet(options?: any) {
        return StaffApiFp(this.configuration).apiStaffDashboardGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a teacher profile
     * @param {StaffDeleteCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffDeleteDelete(body?: StaffDeleteCommand, options?: any) {
        return StaffApiFp(this.configuration).apiStaffDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Receives a list of email addresses and performs tasks below:   * Validates emails   * Create accounts/profiles in the system   * Send invite emails
     * @param {StaffInviteCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffInvitePost(body?: StaffInviteCommand, options?: any) {
        return StaffApiFp(this.configuration).apiStaffInvitePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Resends invitation email
     * @param {StaffInviteResendCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffInviteResendPost(body?: StaffInviteResendCommand, options?: any) {
        return StaffApiFp(this.configuration).apiStaffInviteResendPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Revoke invitation to protential teacher before the user accepts it.
     * @param {StaffInviteRevokeCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffInviteRevokeDelete(body?: StaffInviteRevokeCommand, options?: any) {
        return StaffApiFp(this.configuration).apiStaffInviteRevokeDelete(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list of active staff members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffListGet(options?: any) {
        return StaffApiFp(this.configuration).apiStaffListGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Uploads a list of emails in an excel file and performs the following:    * Validates excel file    * Retrieves the list of emails from the excel file    * Ensure email address are valid    * Returns the list of email address with their status
     * @param {string} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffUploadPost(file?: string, options?: any) {
        return StaffApiFp(this.configuration).apiStaffUploadPost(file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary View a staff member's details.
     * @param {string} [profileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffViewGet(profileId?: string, options?: any) {
        return StaffApiFp(this.configuration).apiStaffViewGet(profileId, options).then((request) => request(this.axios, this.basePath));
    }
}
