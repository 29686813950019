import { FC } from 'react';

// PACKAGES
import { Container } from 'react-bootstrap';

// UTILS
import ImgRem from '../../../libs/imgRem';

const AccountSuspended: FC = () => {
	return (
		<>
			<picture>
				<source srcSet="./img/login-bg.webp" />
				<img
					src="./img/login-bg.jpg"
					className="position-fixed w-100 h-100 object-fit object-fit-cover user-select-none"
					alt="Tiled books background"
				/>
			</picture>
			<main className="page-staff-login d-flex align-items-center py-5">
				<Container className="position-relative z-1">
					<div className="form-rounded-corners">
						<ImgRem
							src="/svg/llll-online-contained-wide-logo.svg"
							width="231"
							height="70"
							alt="Little Learners Love Literacy Online logo"
							className="logo mx-auto"
						/>
						<h2 className="text-shades-800 m-0">
							<strong>Your organisation's subscription has been temporarily suspended.</strong>
						</h2>
						<div>
							<p className="m-0">
								If you feel you've reached this page in error, please ask your school's administrator to contact Little
								Learners Love Literacy.
							</p>
						</div>
					</div>
				</Container>
			</main>
		</>
	);
};

export default AccountSuspended;
