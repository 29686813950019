import { Navigate, Route } from 'react-router-dom';
import { useAppSelector } from '../../store/slice';
import { UserType } from '../../store/slice/User';

// LAYOUTS
import LayoutStudent from '../_Layout/LayoutStudent/LayoutStudent';
import LayoutQuiz from '../_Layout/LayoutQuiz/LayoutQuiz';

// PAGES
import StudentELibrary from '../../pages/ELibrary/Student/StudentELibrary';
import Awards from '../../pages/ELibrary/Student/Awards';
import StudentLogin from '../../pages/Authentication/Student/StudentLogin';
import BookReader from '../../pages/BookReader/BookReader';
import Feedback from '../../pages/Feedback/Feedback';
import QuizComplete from '../../pages/Quiz/QuizComplete';
import BookComplete from '../../pages/Quiz/BookComplete';

// UTILS
import ProtectedRoute from '../_Helpers/ProtectedRoute';

const useStudentRoutes = () => {
	const user: UserType = useAppSelector((state) => state.user);
	const searchParams = new URLSearchParams(window.location.search);
	const returnUrl = searchParams.get('returnUrl') || '';

	return (
		<>
			<Route path="/" element={<Navigate replace to={'/elibrary'} />} />
			<Route
				path="/login"
				element={user.loggedIn ? <Navigate replace to={returnUrl || '/elibrary'} /> : <StudentLogin />}
			/>
			<Route element={<ProtectedRoute />}>
				<Route
					path="/elibrary"
					element={
						<LayoutStudent>
							<StudentELibrary />
						</LayoutStudent>
					}
				/>
				<Route
					path="/awards"
					element={
						<LayoutStudent>
							<Awards />
						</LayoutStudent>
					}
				/>

				<Route path="/elibrary/:id">
					<Route index element={<BookReader />} />
					<Route path="feedback" element={<Feedback />} />
					<Route path="quiz">
						<Route index element={<Navigate replace to={'1'} />} />
						<Route path=":quizPageNumber" element={<LayoutQuiz />} />
					</Route>
					<Route path="quiz-complete" element={<QuizComplete />} />
					<Route path="book-complete" element={<BookComplete />} />
				</Route>
			</Route>
		</>
	);
};

export default useStudentRoutes;
