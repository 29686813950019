/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type StudentAdminCreateAccountStatus = 'Successful' | 'Invalid' | 'Duplicate';

export const StudentAdminCreateAccountStatus = {
    Successful: 'Successful' as StudentAdminCreateAccountStatus,
    Invalid: 'Invalid' as StudentAdminCreateAccountStatus,
    Duplicate: 'Duplicate' as StudentAdminCreateAccountStatus
};