import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ToastMessageType = {
	description: string;
	heading: string;
	id: string; // needed to differentiate toasts by key, using 'index' is not enough since the message array order can change
	type: 'success' | 'info' | 'danger';
};

const initialState: ToastMessageType[] = [];

export const ToastMessagesSlice = createSlice({
	name: 'toastMessages',
	initialState,
	reducers: {
		add: (state, action: PayloadAction<ToastMessageType>) => {
			// only allow 4 toasts at once to save space, and boot the first if there are more spawned
			if (state.length > 3) state.splice(0, 1);
			state.push(action.payload);
		},
		remove: (state, action: PayloadAction<ToastMessageType['id']>) => {
			state.splice(
				state.findIndex((i) => i.id === action.payload),
				1
			);
		}
	}
});
