import React, { useEffect, useState } from 'react';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// COMPONENTS
import { BooksBottom, SideUpDownButtons } from '../../../components/_Layout/LayoutStudent/LayoutStudent';

// HELPERS
import CroppedImage from '../../../components/_Helpers/CroppedImage';
import SvgMask from '../../../components/_Helpers/SvgMask';
import ImgRem from '../../../libs/imgRem';
import DelayedFadeIn from '../../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../../apibridge';
import { StudentAwardsAwardsResult } from '../../../api/models';

const Awards: React.FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [awards, setAwards] = useState<StudentAwardsAwardsResult>();

	const booksCompleted = awards?.booksCompleted || 0;
	const totalDaysRead = awards?.days?.filter((day) => day.hasRead).length || 0;
	const allDaysRead = totalDaysRead === 5;

	const getStudentAwardsData = async () => {
		const response = await apibridge.getStudentAwards();
		if (response && response.data && !response.data.isError && response.data.result) {
			setAwards(response.data.result);
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getStudentAwardsData();
	}, []);

	return (
		<div className="page-awards d-flex flex-column flex-grow-1">
			{isLoading ? (
				<div className="d-flex justify-content-center my-5">
					<DelayedFadeIn>
						<Spinner />
					</DelayedFadeIn>
				</div>
			) : (
				<>
					<section className="flex-grow-1">
						<SideUpDownButtons />
						<div className="container-fluid container-awards">
							<div className="main-wrapper d-flex flex-column">
								<div className="section-awards-top d-flex flex-column flex-lg-row align-items-lg-center">
									<div className="awards-top-box d-flex align-items-center justify-content-center gap-2-5 bg-secondary">
										<div className="d-flex">
											<ImgRem src="/svg/award-check.svg" width="120" height="120" alt="Checkmark" />
										</div>
										<div className="d-flex flex-column align-items-center">
											<span className="h1 display">{booksCompleted}</span>
											<span className="h4">book{booksCompleted === 1 ? '' : 's'}</span>
										</div>
									</div>
									<div className="awards-top-box d-flex flex-column flex-lg-row flex-grow-1 bg-secondary overflow-x-auto">
										<div className="d-flex align-items-center justify-content-center gap-2-5">
											<div className="d-flex flex-shrink-0">
												<ImgRem src="/svg/award-calendar.svg" width="120" height="120" alt="Calendar" />
											</div>
											<div className="d-flex flex-column align-items-center">
												<span className="h1 display">{totalDaysRead}</span>
												<span className="h4">day{totalDaysRead === 1 ? '' : 's'}</span>
											</div>
										</div>
										<div
											className={`d-flex align-items-center justify-content-between flex-grow-1 gap-3 overflow-hidden ${
												allDaysRead ? 'all-days-read' : ''
											}`}
										>
											{awards?.days?.map((day, dayIndex, daysArr) => {
												const { dayOfWeek = '', hasRead } = day;
												return (
													<div key={dayOfWeek} className={`day position-relative d-flex ${hasRead ? 'has-read' : ''} `}>
														<div className="text-center">
															<h3 className={`mb-3 ${hasRead ? 'text-vivid-blue' : 'text-shades-500'}`}>
																<strong>{dayOfWeek.substring(0, 3)}</strong>
															</h3>
															{dayIndex !== 0 && <div className={`day-line z-${daysArr.length - dayIndex}`}></div>}
															<div className="day-circle d-flex align-items-center justify-content-center rounded-circle">
																{hasRead && (
																	<SvgMask
																		path={allDaysRead ? '/svg/star-filled.svg' : '/svg/check.svg'}
																		width={32}
																		height={32}
																	/>
																)}
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
								<div className="section-awards-badges bg-secondary">
									{awards?.stages?.length ? (
										<div className="row row-awards">
											{awards?.stages?.map((stage) => {
												const { badgeUrl = '/img/medal.png', hasAward, id, name } = stage;
												return (
													<div key={id} className="col-3">
														<div className="award-wrapper d-flex flex-column align-items-center p-2 text-center">
															<div className="award">
																{hasAward ? (
																	<CroppedImage
																		src={badgeUrl}
																		width={400}
																		height={400}
																		alt={`Award ${name}`}
																		className="rounded-circle object-fit-cover"
																	/>
																) : (
																	<div className="dashed-border rounded-circle"></div>
																)}
															</div>
															<h4 className={`m-0 text-uppercase ${hasAward ? 'text-vivid-blue' : ''}`}>{name}</h4>
														</div>
													</div>
												);
											})}
										</div>
									) : null}
								</div>
							</div>
						</div>
					</section>
					<BooksBottom />
				</>
			)}
		</div>
	);
};

export default Awards;
