import { FC, ImgHTMLAttributes } from 'react';

// converts required img width/height (required to keep Lighthouse happy) to rem via a style tag, because otherwise images are too big on tablet
interface ImgRemProps extends ImgHTMLAttributes<HTMLImageElement> {
	width: string | number;
	height: string | number;
	alt: string;
}
const ImgRem: FC<ImgRemProps> = (props) => {
	const updatedProps = {
		...props,
		style: {
			...props.style,
			width: `${parseInt(props.width.toString()) / 16}rem`,
			height: `${parseInt(props.height.toString()) / 16}rem`
		}
	};

	return <img {...updatedProps} alt={props?.alt || ''} />;
};

export default ImgRem;
