import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// API
import apibridge from '../../apibridge';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// COMPONENTS
import TitleSection, { TitleSectionType } from '../../components/TitleSection/TitleSection';
import SvgMask from '../../components/_Helpers/SvgMask';
import ClassCard from '../../components/ClassCard/ClassCard';

// UTILS
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import { StaffListTeacher, StudentAdminDashboardDashboardInfo } from '../../api/models';
import { UserType } from '../../store/slice/User';

const StudentAdmin: FC = () => {
	const [loading, setLoading] = useState(true);

	const [dashboardData, setDashboardData] = useState<StudentAdminDashboardDashboardInfo>({});
	const [teacherList, setTeacherList] = useState<StaffListTeacher[]>([]);
	const [invitesLeft, setInvitesLeft] = useState(0);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { archivedStudents = 0, otherClasses = [], yourClasses = [] } = dashboardData;

	const user: UserType = useAppSelector((state) => state.user);

	const getStudentDashboard = async () => {
		const response = await apibridge.getStudentAdminDashboard();
		if (response && response.data && !response.data.isError && response.data.result) {
			setDashboardData(response.data.result);
		}
	};

	const getTeacherList = async () => {
		const response = await apibridge.getStaffList();
		if (response && response.data && !response.data.isError && response.data.result) {
			setTeacherList(response.data.result.teachers || []);
		}
	};

	const getInvitesLeft = async () => {
		const response = await apibridge.getInvitesLeft();
		if (response && response.data && !response.data.isError && response.data.result) {
			const { invitesLeft = 0, isUnilimited } = response.data.result;
			setInvitesLeft(isUnilimited ? Infinity : invitesLeft);
		}
	};

	const initialize = async () => {
		await Promise.all([getStudentDashboard(), getTeacherList(), getInvitesLeft()]);
		setLoading(false);
	};

	useEffect(() => {
		initialize();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleUnassignTeacher = async (
		profileId: string,
		classId: string,
		firstName: string,
		lastName: string,
		className: string
	) => {
		const response = await apibridge.deleteStaffClassRemove({ profileId, classId });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Teacher unassigned from class',
						description: `${firstName} ${lastName} has been unassigned from ${className}.`
					})
				);
				getStudentDashboard();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Unassign teacher error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const handleDeleteClass = async (classId: string) => {
		const response = await apibridge.deleteClassDelete({ classId });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				getStudentDashboard();
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Delete class error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const handleAssignTeacher = async (profileId: string, classId: string) => {
		const response = await apibridge.postStaffClassAssign({
			profileId,
			classId
		});

		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				getStudentDashboard();
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Assign teacher',
						description: 'Teacher assigned successfully.'
					})
				);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Assign teacher error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const handleInviteStudentsClick = () => {
		if (invitesLeft > 0) {
			navigate('/student-management/add-class');
		} else {
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: 'Your organisation is full',
					description:
						'You have reached the maximum number of seats within your organisation and are unable to add more students.'
				})
			);
		}
	};

	const titleSectionProps: TitleSectionType = {
		title: 'Student Management',
		description: "Manage your organisation's classes and students."
	};
	if (user.permissions?.includes('ClassCreate')) {
		titleSectionProps.primaryButtonText = 'Add a class';
		titleSectionProps.primaryButtonClick = handleInviteStudentsClick;
		titleSectionProps.primaryButtonIcon = '/svg/class.svg';
		// not showing a dot next to 'Add a class' for now
		// if (isStaff && !hasAddedStudents) titleSectionProps.primaryButtonClass = 'show-dot';
	}

	return (
		<div className="student-admin-page d-flex flex-column flex-grow-1">
			<TitleSection {...titleSectionProps} />
			<div className="container h-100">
				{loading ? (
					<div className="d-flex align-items-center justify-content-center">
						<DelayedFadeIn>
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</DelayedFadeIn>
					</div>
				) : user.permissions?.includes('ClassList') ? (
					<div className="student-management-wrapper">
						<div className="student-management-item d-flex flex-column gap-4">
							<div className="heading">
								<h2 className="h3 mb-0">
									<strong>Your classes</strong>
								</h2>
							</div>
							<div className="your-classes-wrapper d-flex flex-row row-gap-4 row flex-wrap">
								{yourClasses.length ? (
									yourClasses.map((classData) => {
										return (
											<ClassCard
												key={classData.id}
												classDetails={classData}
												handleUnassignTeacher={handleUnassignTeacher}
												handleDeleteClass={handleDeleteClass}
												handleAssignTeacher={handleAssignTeacher}
												teacherList={teacherList}
											/>
										);
									})
								) : (
									<div className="d-flex align-items-center gap-3">
										<div className="class-icon-wrapper text-shades-500">
											<SvgMask path="/svg/class.svg" width={24} height={24} />
										</div>
										<p className="text-shades-500 mb-0 flex-grow-1">
											<strong>No classes assigned to you</strong>
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="student-management-item d-flex flex-column gap-4">
							<div className="heading">
								<h2 className="h3 mb-0">
									<strong>Other classes</strong>
								</h2>
							</div>
							<div className="your-classes-wrapper d-flex flex-row row-gap-4 row flex-wrap">
								{otherClasses.length ? (
									otherClasses.map((classData) => {
										return (
											<ClassCard
												key={classData.id}
												classDetails={classData}
												handleUnassignTeacher={handleUnassignTeacher}
												handleDeleteClass={handleDeleteClass}
												handleAssignTeacher={handleAssignTeacher}
												teacherList={teacherList}
											/>
										);
									})
								) : (
									<div className="d-flex align-items-center gap-3">
										<div className="class-icon-wrapper text-shades-500">
											<SvgMask path="/svg/class.svg" width={24} height={24} />
										</div>
										<p className="text-shades-500 mb-0 flex-grow-1">
											<strong>No other classes</strong>
										</p>
									</div>
								)}
							</div>
						</div>
						{user.permissions.includes('StudentAdminArchived') && (
							<div className="student-management-item d-flex flex-column gap-4">
								<div className="heading">
									<h2 className="h3 mb-0">
										<strong>Archive</strong>
									</h2>
								</div>
								<div className="your-classes-wrapper d-flex flex-row row-gap-4 row flex-wrap">
									{archivedStudents ? (
										<ClassCard archiveStudents={archivedStudents} isArchive />
									) : (
										<div className="d-flex align-items-center gap-3">
											<div className="class-icon-wrapper text-shades-500">
												<SvgMask path="/svg/class.svg" width={24} height={24} />
											</div>
											<p className="text-shades-500 mb-0 flex-grow-1">
												<strong>No archived students</strong>
											</p>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				) : (
					<div className="d-flex align-items-center justify-content-center h-100">
						<h3 className="text-center text-shades-500 m-0">You don't have permission to view this content.</h3>
					</div>
				)}
			</div>
		</div>
	);
};

export default StudentAdmin;
