/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BookBooksResponse } from '../models';
import { BookFavoriteCommand } from '../models';
import { BookListResponse } from '../models';
import { BookStagesResponse } from '../models';
import { SharedModelsCommandResponse } from '../models';
/**
 * BookApi - axios parameter creator
 * @export
 */
export const BookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a list of books.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBookBooksGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Book/Books`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set/Unset as favorite.
         * @param {BookFavoriteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBookFavoritePost: async (body?: BookFavoriteCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Book/Favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of stages->serieses->books.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBookListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Book/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a list of book stages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBookStagesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Book/Stages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookApi - functional programming interface
 * @export
 */
export const BookApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a list of books.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBookBooksGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookBooksResponse>> {
            const localVarAxiosArgs = await BookApiAxiosParamCreator(configuration).apiBookBooksGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set/Unset as favorite.
         * @param {BookFavoriteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBookFavoritePost(body?: BookFavoriteCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await BookApiAxiosParamCreator(configuration).apiBookFavoritePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a list of stages->serieses->books.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBookListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookListResponse>> {
            const localVarAxiosArgs = await BookApiAxiosParamCreator(configuration).apiBookListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provides a list of book stages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBookStagesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookStagesResponse>> {
            const localVarAxiosArgs = await BookApiAxiosParamCreator(configuration).apiBookStagesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BookApi - factory interface
 * @export
 */
export const BookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Returns a list of books.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBookBooksGet(options?: any): AxiosPromise<BookBooksResponse> {
            return BookApiFp(configuration).apiBookBooksGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set/Unset as favorite.
         * @param {BookFavoriteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBookFavoritePost(body?: BookFavoriteCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return BookApiFp(configuration).apiBookFavoritePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of stages->serieses->books.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBookListGet(options?: any): AxiosPromise<BookListResponse> {
            return BookApiFp(configuration).apiBookListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a list of book stages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBookStagesGet(options?: any): AxiosPromise<BookStagesResponse> {
            return BookApiFp(configuration).apiBookStagesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookApi - object-oriented interface
 * @export
 * @class BookApi
 * @extends {BaseAPI}
 */
export class BookApi extends BaseAPI {
    /**
     * 
     * @summary Returns a list of books.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookApi
     */
    public apiBookBooksGet(options?: any) {
        return BookApiFp(this.configuration).apiBookBooksGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Set/Unset as favorite.
     * @param {BookFavoriteCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookApi
     */
    public apiBookFavoritePost(body?: BookFavoriteCommand, options?: any) {
        return BookApiFp(this.configuration).apiBookFavoritePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns a list of stages->serieses->books.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookApi
     */
    public apiBookListGet(options?: any) {
        return BookApiFp(this.configuration).apiBookListGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Provides a list of book stages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookApi
     */
    public apiBookStagesGet(options?: any) {
        return BookApiFp(this.configuration).apiBookStagesGet(options).then((request) => request(this.axios, this.basePath));
    }
}
