import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import { EmbeddedYouTubeOrVimeoVideo } from '../../components/_Helpers/Video';

// UTILS
import { Spinner } from 'react-bootstrap';
import { guid } from '../../libs/utils';
import SvgMask from '../../components/_Helpers/SvgMask';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { ProfessionalLearningListCategoryItem } from '../../api/models';
import { useAppDispatch } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

type ProfessionalLearningType = {
	categoryName: String;
	item: ProfessionalLearningListCategoryItem;
} | null;

const ProfessionalLearningItem: React.FC = () => {
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [professionalLearningItem, setProfessionalLearningItem] = useState<ProfessionalLearningType>(null);

	const location = useLocation();
	const { pathname } = location;

	const getProfessionalLearningData = async () => {
		const response = await apibridge.getProfessionalLearningList();

		if (response && response.data && !response.data.isError && response.data.result) {
			const splitPath = pathname.split('/');
			const pathPart = decodeURIComponent(splitPath[splitPath.length - 1]);

			const { categories = [] } = response.data.result;
			let matchedProfessionalLearningItem: ProfessionalLearningType = null;
			// get the details of the item we need by matching its name against the page URL path
			for (const category of categories) {
				if (matchedProfessionalLearningItem) break;

				const { categoryItems = [] } = category;
				const categoryName = category.name || '';
				for (const item of categoryItems) {
					const { title = '' } = item;
					if (title === pathPart) {
						matchedProfessionalLearningItem = { categoryName, item };
						setProfessionalLearningItem(matchedProfessionalLearningItem);
						break;
					}
				}
			}

			// if no item found, stop loading spinner and trigger error toast
			if (!matchedProfessionalLearningItem) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: 'Item not found',
						description: 'A professional learning item matching this path could not be found'
					})
				);
			}
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getProfessionalLearningData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-professional-learning-item">
			<TitleSection hideTitleBar>
				<Link to="../" relative="path" className="btn btn-link">
					<SvgMask path="/svg/arrow-left.svg" width={16} height={16} />
					Back to Professional Learning
				</Link>
			</TitleSection>
			<section className="container">
				{isLoading ? (
					<div className="d-flex justify-content-center">
						<DelayedFadeIn>
							<Spinner />
						</DelayedFadeIn>
					</div>
				) : (
					professionalLearningItem && (
						<div className="d-flex flex-column gap-4-5">
							{professionalLearningItem.item.videoId && (
								<EmbeddedYouTubeOrVimeoVideo youTubeOrVimeoId={professionalLearningItem.item.videoId} />
							)}
							<div>
								<h4>{professionalLearningItem.categoryName}</h4>
								{professionalLearningItem.item?.title && (
									<h1 className="text-shades-800 h2 my-4">
										<strong>{professionalLearningItem.item.title}</strong>
									</h1>
								)}
								{professionalLearningItem.item?.description && <p>{professionalLearningItem.item.description}</p>}
							</div>
						</div>
					)
				)}
			</section>
		</div>
	);
};

export default ProfessionalLearningItem;
